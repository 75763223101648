import React, { createContext, useState, useEffect } from 'react';
import _ from 'lodash';

import en from '../translations/en';
import { FARE_TYPES, ID_TICKET_TYPE, TRAVEL_TYPES } from '../constants/constants';

import { IUserConcessionProps, ILtTravelTypeOption } from '../interfaces';

import { checkIsFocFare, checkIsId50Fare } from '../helpers';

import { useAppSelector } from '../app/hooks';

import { selectUser } from '../slice/userSlice';

interface IStateType {
  travelTypeOptions: Array<ILtTravelTypeOption>;
  selectedCarrierGroup: string;
  isShowDefaultTravelTypeOption: boolean;
}

interface IActionType {
  setTravelTypeOptions: any;
  setSelectedCarrierGroup: any;
  setIsShowDefaultTravelTypeOption: any;
}

const TravelTypeListLtState = createContext<IStateType>({
  travelTypeOptions: [],
  isShowDefaultTravelTypeOption: false,
  selectedCarrierGroup: '',
});

const TravelTypeListLtAction = createContext<IActionType>({
  setTravelTypeOptions: null,
  setIsShowDefaultTravelTypeOption: null,
  setSelectedCarrierGroup: null,
});

const TravelTypeListELTContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { concession } = useAppSelector(selectUser) || {};
  const { leisureTravel } = concession || {};
  const defaultCxCarrier = en.booking.flightSearchResultCarrierOptions.cx;

  // LT Zone CX/OW/OAL
  const isContainZedFareType = () => {
    return leisureTravel?.find(
      (concession: IUserConcessionProps) => concession?.concessionDef?.travelType === ID_TICKET_TYPE.SUBLO,
    );
  };

  const zoneConcessionList: ILtTravelTypeOption[] = isContainZedFareType()
    ? [
        {
          id: '0',
          val: {
            label: `${en.booking.fareType.zone}`,
            sub: ID_TICKET_TYPE.SUBLO as string,
            carrier: '',
            carrierGroup: '',
            concessionRuleId: '',
            fareType: FARE_TYPES.ZED,
            type: TRAVEL_TYPES.ZONE,
            boardingPriority: '',
            bookingClass: undefined,
            regradeClass: '',
            allowSeatForInfant: false,
            advanceBookingDay: undefined,
          },
        },
      ]
    : [];

  const getFOCID50ConcessionList = ({
    list,
    travelType,
  }: {
    list: IUserConcessionProps[];
    travelType: keyof typeof TRAVEL_TYPES;
  }) => {
    if (!list?.length) return [];

    const tmpList = _.cloneDeep(list);

    // Filter list by fare type is `ID`
    const filterList = tmpList.filter(
      (item: IUserConcessionProps) => item?.concessionDef?.travelType === ID_TICKET_TYPE.NOSUB,
    );

    // then sort by `FOC` naming
    // then sort by Alphabetic with displayName
    const sortedList = _.sortBy(
      filterList,
      (item: IUserConcessionProps) => [item?.displayName, item?.concessionDef?.concessionName]?.includes(travelType),
      (item: IUserConcessionProps) => parseInt(item?.boardingPriority || ''),
      'displayName',
      'asc',
    );

    const massagedConcessionList = sortedList.map((item: IUserConcessionProps, index: number) => {
      const { _id: concessionRuleId, concessionDef, boardingPriority, bookingClass, regradeClass } = item;
      const { carrierGroup, fareType, travelType, fareDiscount, allowSeatForInfant, advanceBookingDay } =
        concessionDef || {};

      const isFOC = checkIsFocFare({ fareType, fareDiscount });
      const isID50 = checkIsId50Fare({ fareType, fareDiscount });

      return {
        id: `${index + (zoneConcessionList?.length || 0) + 1}`,
        val: {
          label: item.displayName || '',
          sub: travelType || '', // display NOSUB, SUBLO
          carrier: defaultCxCarrier, // will revise logic by Rbds record later on. current use `CX` first.
          carrierGroup: carrierGroup || '',
          concessionRuleId,
          fareType,
          type: isFOC ? TRAVEL_TYPES.FOC : isID50 ? TRAVEL_TYPES.ID50 : TRAVEL_TYPES.OTHER,
          boardingPriority,
          bookingClass,
          regradeClass,
          allowSeatForInfant,
          advanceBookingDay,
        },
      };
    });
    return massagedConcessionList;
  };

  // LT FOC/ID50
  const focID50ConcessionList: ILtTravelTypeOption[] = getFOCID50ConcessionList({
    list: leisureTravel,
    travelType: TRAVEL_TYPES.FOC as keyof typeof TRAVEL_TYPES,
  });
  const [focConcessionList, id50ConcessionList] = _.partition(
    focID50ConcessionList,
    (concession) => concession.val?.fareType === FARE_TYPES.ID && concession.val?.type === TRAVEL_TYPES.FOC,
  );

  // [ETP-4337] re-sequence: FOC(NOSUB) > ZON > ID50
  const defaultTravelTypeOptions = [...focConcessionList, ...zoneConcessionList, ...id50ConcessionList];

  const [travelTypeOptions, setTravelTypeOptions] = useState<IStateType['travelTypeOptions']>(defaultTravelTypeOptions);
  const [isShowDefaultTravelTypeOption, setIsShowDefaultTravelTypeOption] =
    useState<IStateType['isShowDefaultTravelTypeOption']>(false);
  const [selectedCarrierGroup, setSelectedCarrierGroup] = useState<IStateType['selectedCarrierGroup']>('');

  // update LT Concession list when concession data changed
  useEffect(() => {
    setTravelTypeOptions([...focConcessionList, ...zoneConcessionList, ...id50ConcessionList]);
  }, [leisureTravel]);

  return (
    <TravelTypeListLtState.Provider
      value={{
        travelTypeOptions,
        isShowDefaultTravelTypeOption,
        selectedCarrierGroup,
      }}
    >
      <TravelTypeListLtAction.Provider
        value={{
          setTravelTypeOptions,
          setIsShowDefaultTravelTypeOption,
          setSelectedCarrierGroup,
        }}
      >
        {children}
      </TravelTypeListLtAction.Provider>
    </TravelTypeListLtState.Provider>
  );
};

export { TravelTypeListELTContextProvider, TravelTypeListLtState, TravelTypeListLtAction };
