import { IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { UI_STYLES } from '../../constants/constants';

const AdminActionButton = ({
  onClick,
  textLabel,
  isEnabled,
  customStyles,
}: {
  onClick: () => void;
  textLabel: string;
  isEnabled?: boolean;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  const enabledStyle = {
    border: `2px solid ${theme.color.utility.link.option_3}`,
    color: theme.color.utility.link.option_3,
  };

  const disabledStyle = {
    border: `2px solid ${theme.color.secondary.grey.option_6}`,
    color: theme.color.secondary.grey.option_6,
  };

  return (
    <IconButton sx={{ p: 0, ...customStyles }} onClick={onClick} disabled={!isEnabled}>
      <Typography
        variant="body_2_bold"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '32px',
          width: UI_STYLES.searchBarDatePickerOverlayHeight,
          borderRadius: 1,
          background: theme.palette.bgColor.main,
          ...(isEnabled ? enabledStyle : disabledStyle),
        }}
      >
        {textLabel}
      </Typography>
    </IconButton>
  );
};

export default AdminActionButton;
