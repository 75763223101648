import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Box, useTheme, Autocomplete, TextField, Button } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { UI_STYLES, DATE_FORMAT } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import { imgAdminTableArrowRight, DownArrowInheritIcon } from '../../../assets/images';

import { IAdminNote, INoteSorting } from '../../../interfaces';

import { formatDateAsString, getDisplayVal, getFilterOptions } from '../../../helpers';

import { AdminAccessEmployeeState, AdminAccessEmployeeAction } from '../../../context';

import { BaseTable } from '../../../components';

const NotesList = ({ setIsPopupActive }: { setIsPopupActive: (value: boolean) => void }) => {
  const theme = useTheme();
  const fieldLabel = en.admin.notes.fields;

  const [filterData, setFilterData] = useState<IAdminNote[]>([]);
  const [filterModifiedBy, setFilterModifiedBy] = useState<string>('');
  const [sortBy, setSortBy] = useState<INoteSorting>({
    key: '',
    filter: null,
    reverse: null, // true base on backend data
  });
  const [filterType, setFilterType] = useState<string>('');
  const { employeeNotes } = useContext(AdminAccessEmployeeState);
  const { setFocusNote, setEmployeeNotes } = useContext(AdminAccessEmployeeAction);

  const modifiedByOptions = getFilterOptions(employeeNotes, 'modifiedByName');
  const typeOptions = getFilterOptions(employeeNotes, 'type');

  const handleRowDataOnClick = (rowData: any) => {
    if (employeeNotes) setFocusNote(employeeNotes.find((item: IAdminNote) => item.noteId === rowData.noteId) || null);
  };

  const tableSchema = [
    {
      key: 'createdDate',
      isHiddenField: false,
    },
    {
      key: 'lastModifiedDate',
      isHiddenField: false,
    },
    {
      key: 'modifiedByName',
      isHiddenField: false,
    },
    {
      key: 'type',
      isHiddenField: false,
    },
    {
      key: 'subject',
      isHiddenField: false,
    },
  ];

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          pr: item.key === 'subject' ? 3 : 2,
          flex: index + 1 === tableSchema.length ? 2 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
        },
        isHiddenField: item.isHiddenField,
        sortingFunc:
          index === 0 || index === 1
            ? () => {
                setSortBy((state: INoteSorting) => {
                  if (state.key === item.key) {
                    return {
                      key: state.key,
                      filter: false,
                      reverse: true,
                    };
                  } else {
                    return {
                      key: item.key,
                      filter: true,
                      reverse: false,
                    };
                  }
                });
              }
            : null,
      };
    });
  }, [filterData]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(filterData);

    return tmpData.map((dataItem: any) => {
      dataItem.createdDate = formatDateAsString(dataItem.createdDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);
      dataItem.lastModifiedDate = formatDateAsString(
        dataItem.lastModifiedDate,
        DATE_FORMAT.ddmmmyyyy,
        DATE_FORMAT.date,
      );

      return {
        id: dataItem.noteId,
        noteId: dataItem.noteId,
        sxProps: {
          display: 'flex',
          position: 'relative',
          '&:: after': {
            content: `""`,
            width: '28px',
            height: '28px',
            position: 'absolute',
            right: 0,
            transform: 'translateY(50%)',
            backgroundSize: 'cover',
            backgroundImage: `url(${imgAdminTableArrowRight})`,
          },
        },
        columns: tableSchema.map(({ key, isHiddenField }, index) => {
          const columnSxProps = {};
          let newValue = '';
          const currentValue = dataItem?.[key as keyof IAdminNote];
          // Special handle for modifiedByName: e.g CX Admin (123456A)
          if (key === 'modifiedByName') {
            newValue = `${currentValue} (${getDisplayVal(dataItem?.modifiedByErn)})`;
          } else {
            newValue = `${currentValue}`;
          }

          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              py: 1.25,
              pr: key === 'subject' ? 3 : 2,
              overflow: 'hidden',
              cursor: 'pointer',
              flex: index + 1 === tableSchema.length ? 2 : 1,
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              ...columnSxProps,
            },
            isHiddenField,
            columnOnClick: dataItem?.handleColumnOnClick,
          };
        }),
        rowOnClick: handleRowDataOnClick,
      };
    });
  }, [filterData]);

  // filter by "modifyBy" or "type"
  useEffect(() => {
    if (employeeNotes?.length > 0) {
      const tempData = employeeNotes.filter((item: IAdminNote) => {
        const isModifiedBy = !filterModifiedBy || filterModifiedBy === item.modifiedByName;
        const isType = !filterType || filterType === item.type;
        return isModifiedBy && isType;
      });
      setFilterData(tempData);
    } else {
      setFilterData([]);
    }
  }, [filterModifiedBy, filterType, employeeNotes]);

  useEffect(() => {
    if (employeeNotes?.length > 1 && sortBy.key) {
      // filter
      if (sortBy.filter) {
        const sortNotes = _.sortBy(employeeNotes, (item: IAdminNote) => new Date(_.get(item, sortBy.key)));
        setEmployeeNotes(sortNotes);
      }

      // reverse
      if (sortBy.reverse) {
        const cloneEmployeeNotes = _.clone(employeeNotes);
        cloneEmployeeNotes.reverse();
        setEmployeeNotes(cloneEmployeeNotes);
      }
    }
  }, [sortBy]);

  return (
    <Box
      className="note_list"
      sx={{
        mt: 4,
        width: '100%',
      }}
    >
      <Autocomplete
        options={modifiedByOptions}
        popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
        // ETP-3204 use default clear behavior
        // clearIcon={null}
        clearOnEscape
        disablePortal
        sx={{
          width: '143px',
          height: '48px',
          float: 'left',
          mr: 2,
          '.MuiFilledInput-root': {
            height: UI_STYLES.concessionTravellerHeight,
            borderRadius: 0.5,
            border: `2px solid ${theme.color.secondary.slate.option_3}`,
            background: theme.palette.primary.light,
            color: theme.color.secondary.dark_grey.option_3,
            paddingTop: '0px',
            '&.Mui-error': {
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
            },
            '&.Mui-selected, &:hover, &.Mui-filled': {
              background: 'white',
            },
            '&.Mui-focused': {
              background: theme.palette.primary.light,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
            },
            '&.Mui-disabled': {
              background: theme.color.utility.unselectable.option_7,
            },
            '&: after, &: before': {
              display: 'none',
            },
            // input style
            '.MuiFilledInput-input': {
              mt: 1.5,
              fontWeight: FONT_WEIGHT.medium,
              fontSize: theme.typography.body_2_medium.fontSize,
              color: theme.color.secondary.dark_grey.option_1,
            },
          },
          '.MuiInputLabel-root': {
            top: '-2px',
            color: theme.color.secondary.grey.option_3,
            fontSize: theme.typography.body_2_medium.fontSize,
            '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
              color: theme.color.secondary.grey.option_3,
            },
          },
        }}
        onChange={(_, newValue) => {
          setFilterModifiedBy(newValue?.value || '');
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              height: UI_STYLES.concessionTravellerHeight,
            }}
            variant="filled"
            {...params}
            label={fieldLabel.modifiedByName || ''}
          />
        )}
      />
      <Autocomplete
        options={typeOptions}
        popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
        // ETP-3204 use default clear behavior
        // clearIcon={null}
        clearOnEscape
        disablePortal
        sx={{
          width: '143px',
          height: '48px',
          float: 'left',
          '.MuiFilledInput-root': {
            height: UI_STYLES.concessionTravellerHeight,
            borderRadius: 0.5,
            border: `2px solid ${theme.color.secondary.slate.option_3}`,
            background: theme.palette.primary.light,
            color: theme.color.secondary.dark_grey.option_3,
            paddingTop: '0px',
            '&.Mui-error': {
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
            },
            '&.Mui-selected, &:hover, &.Mui-filled': {
              background: 'white',
            },
            '&.Mui-focused': {
              background: theme.palette.primary.light,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
            },
            '&.Mui-disabled': {
              background: theme.color.utility.unselectable.option_7,
            },
            '&: after, &: before': {
              display: 'none',
            },
            // input style
            '.MuiFilledInput-input': {
              mt: 1.5,
              fontWeight: FONT_WEIGHT.medium,
              fontSize: theme.typography.body_2_medium.fontSize,
              color: theme.color.secondary.dark_grey.option_1,
            },
          },
          '.MuiInputLabel-root': {
            top: '-2px',
            color: theme.color.secondary.grey.option_3,
            fontSize: theme.typography.body_2_medium.fontSize,
            '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
              color: theme.color.secondary.grey.option_3,
            },
          },
        }}
        onChange={(_, newValue) => {
          setFilterType(newValue?.value || '');
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              height: UI_STYLES.concessionTravellerHeight,
            }}
            variant="filled"
            {...params}
            label={fieldLabel.type || ''}
          />
        )}
      />
      <Button
        variant="outlined"
        sx={{
          p: 0,
          width: '122px',
          height: '48px',
          float: 'right',
          color: theme.status.selected,
          border: `2px solid ${theme.status.selected}`,
          fontSize: theme.typography.navigation_bold.fontSize,
          fontWeight: theme.typography.navigation_bold.fontWeight,
          '&: hover': {
            background: 'white',
            border: `2px solid ${theme.status.selected}`,
          },
        }}
        onClick={() => setIsPopupActive(true)}
      >
        {en.admin.notes.createNewNote}
      </Button>

      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default NotesList;
