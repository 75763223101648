import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Box, IconButton, Typography, useTheme, Popper } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { Configuration } from 'configuration';

import {
  ADMIN_HISTORY_REPORT_ALL_TYPE,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  DATE_FORMAT,
  LABEL_CATEGORY,
  UI_STYLES,
} from '../../constants/constants';
import en from '../../translations/en';

import { checkValidDateRange, compareDate, formatDateAsString, getLocalDate } from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { AdminAccessEmployeeState, AdminAccessEmployeeAction } from '../../context';
import { selectConfiguration } from '../../slice/configurationSlice';
import { getEmployee, getSuggestion } from '../../services/admin/employee';
import { getTravelHistoryReport } from '../../services/admin/report';

import { AdminInformationBar } from '../../containers/AdminMaintainEmployeeDetail';
import {
  ScrollableView,
  AutoCompleteBox,
  BoldButtonTypography,
  InputFieldDatePicker,
  ReduxFormSelectInput,
} from '../../components';

const AdminTravelHistoryReport = () => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const BOX_WIDTH = '240px';
  const BOX_TEXT_HEIGHT = '48px';
  const POPPER_WIDTH = '500px';

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const [suggestionList, setSuggestionList] = useState<any[] | null>(null);
  const [searchResult, setSearchResult] = useState<any[] | null>(null);
  const [isOnSelect, setIsOnSelect] = useState<boolean>(false);

  // 4 input fields
  const [ernValue, setErnValue] = useState<string>('');
  const [historyReportFromDate, setHistoryReportFromDate] = useState<string>('');
  const [historyReportToDate, setHistoryReportToDate] = useState<string>('');
  const [travelType, setTravelType] = useState<string>('');
  const [errorToDateMessage, setErrorToDateMessage] = useState<string>('');

  const { employeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { setEmployeeDetail } = useContext(AdminAccessEmployeeAction) || {};

  const {
    title: travelHistoryReportLbl,
    submit,
    travelType: travelTypeLbl,
    inputValidDateRange,
    travelFrom,
    travelTo,
  } = en.admin.feature.travelHistoryReport;
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;
  const { maintainEmployeeDetails } = en.admin.feature;

  const allTravelTypeOptions: Configuration.CodeLabel[] = ADMIN_HISTORY_REPORT_ALL_TYPE;

  const BOOKING_TYPE_OPTIONS = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.bookingType,
  );

  const TRAVEL_TYPE_OPTIONS = [...allTravelTypeOptions, ...BOOKING_TYPE_OPTIONS];

  const isSubmitButtonDisabled = useMemo(() => {
    // all 4 filed need to be filled
    return !(searchResult && historyReportFromDate && historyReportToDate && travelType && ernValue.length > 0);
  }, [searchResult, historyReportFromDate, historyReportToDate, travelType, ernValue]);

  const isFoundNoMatchSearch = useMemo(() => {
    return ernValue.length > 4 && suggestionList && suggestionList.length === 0;
  }, [suggestionList, ernValue]);

  const paperPopupWidth = useMemo(() => {
    // autocomplete box popup width, if no data, set to 500px
    return isFoundNoMatchSearch ? POPPER_WIDTH : BOX_WIDTH;
  }, [isFoundNoMatchSearch]);

  const getSuggestionList = async (payload: { userId?: string }) => {
    const suggestionResult = (await getSuggestion(payload)) || [];
    const suggestionOption = suggestionResult.map((item: string) => {
      return { label: item, code: item };
    });
    setSuggestionList(suggestionOption);
  };

  const handleFetchEmployee = async (searchValue?: { userId?: string }) => {
    const userId = searchValue?.userId || ernValue;

    if (userId) {
      if (userId.length < 5) {
        setSearchResult(null);
        return;
      }
      searchValue ??= { userId: ernValue };
    }

    if (searchValue) {
      const employeeList = await getEmployee(searchValue);
      setEmployeeDetail(null);
      setSearchResult(employeeList);
    }
  };

  const dropDownListPop = (props: any) => {
    if ((suggestionList && suggestionList.length > 0) || isFoundNoMatchSearch) {
      return <Popper {...props} placement="bottom-start" />;
    }
    return null;
  };

  useEffect(() => {
    setEmployeeDetail(null);
  }, []);

  useEffect(() => {
    // get the first isAccessible employee
    const employee = searchResult?.find((item: any) => item.profile.isAccessible);
    if (employee) {
      setEmployeeDetail(employee);
    }
  }, [searchResult]);

  useEffect(() => {
    if (ernValue.length > 4) {
      !suggestionList && getSuggestionList({ userId: ernValue });
    } else if (ernValue.length === 0) {
      setSuggestionList(null);
      setEmployeeDetail(null);
      setSearchResult(null);
    } else {
      suggestionList && setSuggestionList(null);
    }
  }, [ernValue]);

  const handleUpdateValue = (selectType: string, value: string) => {
    if (selectType === ADMIN_PERSONALINFO_SELECT_TYPE.travelHistoryReportFrom) {
      setHistoryReportFromDate(value);
    } else {
      setHistoryReportToDate(value);
    }
  };

  const getTravelHistoryReportAction = async () => {
    const { employeeId } = employeeDetail.profile;
    const today = getLocalDate();
    const downloadedFileName = `Travel_History_Report_${employeeId}_${formatDateAsString(
      today,
      DATE_FORMAT.yyyymmdd_hhmmss,
    )}.csv`;

    const report = await getTravelHistoryReport({
      employeeId,
      from: historyReportFromDate,
      to: historyReportToDate,
      type: travelType,
    });

    if (report) {
      const url = window.URL.createObjectURL(new Blob([report]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadedFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <Box
      component={ScrollableView}
      sx={{
        pt: 2,
        pb: 10,
        background: theme.palette.primary.light,
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
      ref={scrollViewRef}
    >
      <Box sx={{ width: UI_STYLES.historyReportWidth, mx: 'auto', mt: 2 }}>
        <Typography variant="large_title_1_bold" color={theme.color.secondary.dark_grey.option_1}>
          {travelHistoryReportLbl}
        </Typography>
        <Box
          className="admin_travel_history_box"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ mr: 2, width: BOX_WIDTH, height: BOX_TEXT_HEIGHT }}>
              <AutoCompleteBox
                label={maintainEmployeeDetails.ern}
                options={suggestionList || []}
                value={ernValue}
                inputValue={ernValue}
                noOptionsText={
                  isFoundNoMatchSearch
                    ? `${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`
                    : en.common.noMatchResult
                }
                onChange={(newValue: { code: string } | null): void => {
                  const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                  setErnValue(upperValue);
                  if (upperValue) {
                    handleFetchEmployee({ userId: upperValue });
                  }
                }}
                onInputChange={(newInputValue: any) => {
                  setErnValue(newInputValue.toUpperCase());
                }}
                onKeyDown={() => {
                  if (!isOnSelect) {
                    ernValue && handleFetchEmployee();
                  }
                }}
                onHighlightChange={(event) => {
                  event && setIsOnSelect(true);
                }}
                onClose={() => {
                  setIsOnSelect(false);
                }}
                dropDownListPop={(props: any) => (ernValue ? dropDownListPop(props) : null)}
                renderOption={undefined}
                filterOptions={(array) =>
                  array.filter((item: { code: string; label: string }) => {
                    return item.label?.includes(ernValue);
                  })
                }
                customStyles={{
                  pl: 0,
                  '.MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  },
                }}
                paperStyles={{
                  width: paperPopupWidth,
                  position: 'absolute',
                  left: '-16px',
                  mt: 0.5,
                }}
                textFieldStyle={{
                  height: BOX_TEXT_HEIGHT,
                  borderRadius: 0.5,
                  background: 'white',
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                }}
              />
            </Box>

            <Box sx={{ width: BOX_WIDTH, mr: 2 }}>
              <InputFieldDatePicker
                selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.travelHistoryReportFrom}
                dateFieldProps={{
                  defaultDate: '',
                  errorMessage: '',
                  disabled: false,
                  labelText: travelFrom,
                }}
                customStyles={{
                  height: BOX_TEXT_HEIGHT,
                  mt: -5,
                  pl: 4,
                }}
                setSelectedValue={handleUpdateValue}
              />
            </Box>

            <Box sx={{ width: BOX_WIDTH, mr: 2 }}>
              <InputFieldDatePicker
                selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.travelHistoryReportTo}
                dateFieldProps={{
                  defaultDate: '',
                  errorMessage: errorToDateMessage,
                  disabled: false,
                  labelText: travelTo,
                  errorMessagePaddingTop: '-10px',
                }}
                customStyles={{
                  height: BOX_TEXT_HEIGHT,
                  mt: -5,
                  pl: 4,
                }}
                setSelectedValue={handleUpdateValue}
              />
            </Box>

            <ReduxFormSelectInput
              input={{
                value: travelType,
                onChange: (input: Configuration.CodeLabel) => {
                  setTravelType(input.code);
                },
              }}
              title={travelTypeLbl}
              options={TRAVEL_TYPE_OPTIONS}
              autocomplete={{
                customOpen: true,
              }}
              customInputStyle={{
                width: BOX_WIDTH,
                height: BOX_TEXT_HEIGHT,
                mt: 0,
                mr: 2,
              }}
            />

            <IconButton
              sx={{ p: 0, mt: -2 }}
              onClick={() => {
                if (!checkValidDateRange(historyReportFromDate, historyReportToDate)) {
                  setErrorToDateMessage(editProfilePopup.inputValidDateRange);
                } else if (compareDate(historyReportToDate, historyReportFromDate) > 365) {
                  // error message: 'Travel date range should be within 1 year.'
                  setErrorToDateMessage(inputValidDateRange);
                } else {
                  setErrorToDateMessage('');
                  getTravelHistoryReportAction();
                }
              }}
              disabled={isSubmitButtonDisabled}
            >
              <BoldButtonTypography
                textLabel={submit}
                customStyles={{
                  mt: -0.5,
                  height: BOX_TEXT_HEIGHT,
                  background: isSubmitButtonDisabled
                    ? theme.color.utility.unselectable.option_7
                    : theme.color.utility.link.option_3,
                  color: isSubmitButtonDisabled ? theme.palette.text.disabled : theme.palette.bgColor.main,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {employeeDetail && (
        <>
          <Box sx={{ width: UI_STYLES.historyReportWidth, mx: 'auto', mt: 4 }}>
            <AdminInformationBar />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdminTravelHistoryReport;
