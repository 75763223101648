import { HTTP_REQUEST_TYPE, PREPAID_API } from '../constants/api';

import {
  IPrepaidBookFlightLtParams,
  IPrepaidRetrieveAndPaytBookFlightLtParams,
  IPrepaidCancelBookingParams,
} from '../interfaces';

import en from '../translations/en';

import { sendHttpRequest } from './api';

const prepaidAuth = async (payload: IPrepaidBookFlightLtParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.prepaidAuth,
    payload,
  });

  return resp;
};

const prepaidBook = async (
  userId: string,
  tpAppRef: string,
  dismissCallBackFunc?: (dismissCallbackPayload: any) => void,
) => {
  const [error, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.prepaidBook,
    apiTitle: en.errorAlert.bookFlights,
    payload: {
      userId,
      tpAppRef,
      ...(dismissCallBackFunc && {
        dismissCallBackFunc,
      }),
    },
  });

  return {
    ...resp,
    error,
  };
};

const retrieveAndPayAuth = async (payload: IPrepaidRetrieveAndPaytBookFlightLtParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.retrieveAndPayAuth,
    payload,
  });

  return resp;
};

const prepaidCancelBooking = async (payload: IPrepaidCancelBookingParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.prepaidCancelBooking,
    // needLoading: false,
    hideErrorDialog: true,
    payload,
  });

  return resp;
};

export { prepaidAuth, prepaidBook, retrieveAndPayAuth, prepaidCancelBooking };
