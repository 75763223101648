import { useContext, useEffect, useRef } from 'react';
import { reset } from 'redux-form';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import { FORM, COMPANION_REGISTRATION_FLOW_PAGE_NO, UI_STYLES } from '../../../../constants/constants';
import { USER_PROFILE_PATHS, NOMINATION_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { NominationAction, NominationState } from '../../../../context';

import { selectAuth } from '../../../../slice/authSlice';
import { selectApp, setLoading } from '../../../../slice/appSlice';
import { setIsLoadedDependent } from '../../../../slice/userSlice';

import { addCompanionRegistration, getNominationViewModeList } from '../../../../services/user';

import {
  CompanionFillInPassportContainer,
  CompanionPersonalContainer,
  CompanionReviewContainer,
  NomineeFooterComponent,
  OverlayContainer,
  CompanionAcknowledgePolicyContainer,
  CompanionNominationPolicyContainer,
  CompanionCompletedContainer,
} from '../../..';
import { Header, DesktopView, ScrollableView, Div100vhView, FormButton } from '../../../../components';

const FlowContainer = ({ routePages, data }: { routePages: any[]; data: any }) => {
  const { companionRegistrationFlow } = useContext(NominationState) || {};

  const { step: routeToStep } = companionRegistrationFlow.routeTo || {};

  return <OverlayContainer {...routePages[routeToStep]} data={data} />;
};

const CompanionRegistrationContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const screenHeight = use100vh();

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { companionRegistrationFlow, nominationFlow, isAllowNominate } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow, resetCompanionRegistrationFlowData, setNominationFlow, setIsAllowNominate } =
    useContext(NominationAction) || {};

  const {
    isPersonalInputValid,
    isPassportInputValid,
    isAgreeDisclaimer,
    companionRegistrationData,
    isSelectDependentRelationship,
  } = companionRegistrationFlow || {};
  const { companionInfo, companionPassport } = companionRegistrationData || {};

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const { step: routeToStep } = companionRegistrationFlow.routeTo || {};

  const isAcknowledgePolicyPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.acknowledgePolicy;
  const isPersonalPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.personal;
  const isPassportPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.passport;
  const isReviewAndConfirmPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.reviewAndConfirm;
  const isNominationPolicyPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.nominationPolicy;
  const isCompletedPage = routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.completed;

  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;

  const completedPageButtonWidth = '240px';

  // define pages structure
  const RoutePages = [
    {
      component: CompanionAcknowledgePolicyContainer,
    },
    {
      component: CompanionPersonalContainer,
    },
    {
      component: CompanionFillInPassportContainer,
    },
    {
      component: CompanionReviewContainer,
    },
    {
      component: CompanionNominationPolicyContainer,
    },
    {
      component: CompanionCompletedContainer,
    },
  ];

  const footerPrimaryBtnOnClick = () => {
    if (routeToStep < COMPANION_REGISTRATION_FLOW_PAGE_NO.nominationPolicy) {
      // go next step
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        routeTo: {
          step:
            // Route step range use '2' because skip passport page when register dependent companion(when `isSelectDependentRelationship` is true)
            routeToStep +
            (isSelectDependentRelationship && routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.personal ? 2 : 1),
        },

        // Review & Confirm Page > when click confirm button, just clear disclaimer flag control
        ...(!isNominationPolicyPage && {
          isAgreeDisclaimer: false,
        }),
      });
    }

    // Nominations Policy Page > call API
    if (isNominationPolicyPage) {
      addNominationAction();
    }

    // when completed page clicked the "Back to nominees details" button
    if (isCompletedPage) {
      // clear data
      clearRegistrationData();

      // redirect to nominees detail page
      history.push(USER_PROFILE_PATHS.dependentDetails);

      // clear isLoadedDependent flag in redux when back to other page
      dispatch(setIsLoadedDependent(false));
    }
  };

  // validate footer by scenario
  const validateFooter = () => {
    if (isAcknowledgePolicyPage) {
      return true;
    }

    if (isPersonalPage) {
      return isPersonalInputValid;
    }

    if (isPassportPage) {
      return isPassportInputValid;
    }

    if (isReviewAndConfirmPage) {
      return true;
    }

    if (isNominationPolicyPage) {
      return isAgreeDisclaimer;
    }

    if (isCompletedPage) {
      return true;
    }

    return false;
  };

  const isFooterValid = validateFooter();
  const footerPrimaryButtonText = () => {
    let text = en.common.continue;

    // acknowledge page
    if (isAcknowledgePolicyPage) text = companionRegistrationLbl.acknowledgePolicy.continueButton;

    // review & confirm page + nomination policy page
    if (isReviewAndConfirmPage || isNominationPolicyPage) text = companionRegistrationLbl.confirm;

    // completed page
    if (isCompletedPage) text = companionRegistrationLbl.completed.back;
    return text;
  };

  // Review & confirm page > trigger to open dialog
  const triggerCancelRegistrationDialog = () => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      isOpenCancelRegistrationDialog: true,
    });
  };

  // Review & confirm page
  const cancelRegistrationDialogConfirmOnClick = () => {
    // clear data
    clearRegistrationData();

    // redirect to nominees detail page
    history.push(USER_PROFILE_PATHS.dependentDetails);
  };

  // Nomination Policy Page > Confirm to call API
  const addNominationAction = async () => {
    const companionData = {
      userId: ern,
      companionInfo: {
        // [ETP-4847] Year and Month known field
        ...(companionInfo?.yearKnown && {
          yearKnown: parseInt(companionInfo?.yearKnown as string),
        }),
        ...(companionInfo?.monthKnown && {
          monthKnown: parseInt(companionInfo?.monthKnown as string),
        }),
        ...(companionInfo?.selectedDependant
          ? {
              dependentId: companionInfo.selectedDependant.code,
            }
          : {
              relationshipCode: companionInfo?.relationship.code || '',
              dateOfBirth: companionInfo?.dateOfBirth || '',
              title: companionInfo?.title.code.toUpperCase() || '',
              gender: companionInfo?.gender.code || '',
              firstName: companionInfo?.firstName.trim() || '',
              lastName: companionInfo?.lastName.trim() || '',
              middleName: companionInfo?.middleName.trim() || '',
            }),
      },
      ...(!companionInfo?.selectedDependant && {
        companionPassport: {
          passportNumber: companionPassport?.passportNumber || '',
          passportFirstName: companionPassport?.passportFirstName.trim() || '',
          passportLastName: companionPassport?.passportLastName.trim() || '',
          passportExpirationDate: companionPassport?.passportExpirationDate || '',
          passportNationality: companionPassport?.passportNationality.code || '',
          passportCountry: companionPassport?.passportCountry.code || '',
        },
      }),
    };

    const result = await addCompanionRegistration(companionData);

    if (result) {
      // redirect to completed page
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        routeTo: {
          step: COMPANION_REGISTRATION_FLOW_PAGE_NO.completed,
        },
      });

      setIsAllowNominate(result?.isAllowNominate);
    }
  };

  // Completed Page
  const redirectNominationPageHandler = async () => {
    // [ETP-4643] Nomination view mode
    // set localStorage because of different context provider initialization
    localStorage.setItem('isTriggerNomineeTypeSelectionPage', 'true');

    const result = await getNominationViewModeList(ern);

    if (result) {
      setNominationFlow({
        ...nominationFlow,
        nominationViewModeData: result,
      });
      dispatch(setLoading(false));

      history.push(NOMINATION_PATHS.nominationFlow);
    }
  };

  const clearRegistrationData = () => {
    // clear redux-from data
    dispatch(reset(FORM.companionPersonal));
    dispatch(reset(FORM.companionPassport));

    // clear context data
    resetCompanionRegistrationFlowData();
  };

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }
  }, [routeToStep]);

  useEffect(() => {
    return () => {
      clearRegistrationData();
    };
  }, []);

  const constructBackButtonLabelText = (): string => {
    if (isAcknowledgePolicyPage) {
      return companionRegistrationLbl.backToNomineesDetails;
    } else if (isPersonalPage) {
      return companionRegistrationLbl.backToTravelPolicy;
    } else if (isPassportPage || isReviewAndConfirmPage) {
      return companionRegistrationLbl.backToTravelCompanionDetails;
    } else if (isNominationPolicyPage) {
      return companionRegistrationLbl.backToReviewAndConfirm;
    }
    return '';
  };

  const backToPrevPageLabel = constructBackButtonLabelText();

  return (
    <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
      {!isCompletedPage && (
        <DesktopView component={Grid}>
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              px: 21,
              boxShadow: theme.boxShadow.important,
              height: '64px',
              my: 'auto',
            }}
          >
            <Header
              iconColor={theme.color.utility.link.option_3}
              leftChild={
                <Typography
                  variant="headline_bold"
                  color={theme.color.utility.link.option_3}
                  sx={{ ml: 1, cursor: 'pointer' }}
                  onClick={() => {
                    // Back button on click
                    if (routeToStep > COMPANION_REGISTRATION_FLOW_PAGE_NO.acknowledgePolicy) {
                      setCompanionRegistrationFlow({
                        ...companionRegistrationFlow,
                        routeTo: {
                          step:
                            // Route step range use '2' because skip passport page when register dependent companion(when `isSelectDependentRelationship` is true)
                            routeToStep -
                            (isSelectDependentRelationship &&
                            routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.reviewAndConfirm
                              ? 2
                              : 1),
                        },
                      });
                    } else if (isAcknowledgePolicyPage) {
                      // preset loading UI for the nominees detail re-loading
                      dispatch(setLoading(true));

                      // redirect to nominees detail page
                      history.push(USER_PROFILE_PATHS.dependentDetails);
                    }
                  }}
                >
                  {backToPrevPageLabel}
                </Typography>
              }
              handleOnBack={() => {
                history?.goBack();
              }}
            />
          </Box>
        </DesktopView>
      )}

      <Grid
        component={ScrollableView}
        ref={scrollViewRef}
        sx={{
          height: isDesktop
            ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.desktopHeaderHeight} )`
            : undefined,
        }}
        container
        px={{
          xs: 2,
          sm: 0,
        }}
      >
        <Grid container direction="column">
          <Grid
            item
            py={2}
            sx={{
              ...(isDesktop && {
                display: 'flex',
                justifyContent: 'center',
              }),
            }}
          >
            <FlowContainer
              routePages={RoutePages}
              data={{
                cancelRegistrationDialogConfirmOnClick,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {isFooterValid && (
        <NomineeFooterComponent
          {...(isReviewAndConfirmPage && {
            leftChild: (
              <FormButton
                theme={theme}
                colour="transparent"
                size="large"
                sx={{
                  height: '44px',
                  display: 'flex',
                  justifyContent: 'center',
                  ...{ minWidth: 0 },
                }}
                onClick={triggerCancelRegistrationDialog}
              >
                {en.common.cancel}
              </FormButton>
            ),
          })}
          {...(isCompletedPage && {
            leftChild: (
              <>
                <FormButton
                  theme={theme}
                  color="primary"
                  disabled={!isAllowNominate}
                  colour="outline"
                  variant="outlined"
                  size="large"
                  onClick={redirectNominationPageHandler}
                  customStyles={{
                    marginLeft: 0,
                    borderRadius: 8,
                    minWidth: completedPageButtonWidth,
                    height: 44,
                    marginTop: isDesktop ? 0 : 8,
                    ...(!isDesktop && {
                      width: '100%',
                      marginBottom: '16px',
                    }),
                    '&.Mui-disabled': {
                      border: `2px solid ${theme.color.secondary.slate.option_3}`,
                    },
                  }}
                >
                  {en.userProfile.nomination.common.redirectNominationPage}
                </FormButton>
              </>
            ),
            leftColumnSx: {
              // mobile handle reverse button display
              ...(!isDesktop && {
                flexDirection: 'column-reverse',
                width: '100%',
              }),
              justifyContent: 'flex-end',
            },
          })}
          primaryBtn={{
            isFullWidth: isReviewAndConfirmPage ? false : !isDesktop,
            text: footerPrimaryButtonText(),
            customOnClick: footerPrimaryBtnOnClick,
            customButtonStyles: {
              // Completed page for desktop view style handling
              ...(isCompletedPage &&
                isDesktop && {
                  width: completedPageButtonWidth,
                  marginLeft: '16px',
                }),
            },
          }}
        />
      )}
    </Div100vhView>
  );
};

export default CompanionRegistrationContainer;
