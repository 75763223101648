import { Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Configuration } from 'configuration';
import en from '../translations/en';
import {
  BENEFICIARY_TYPE_CODE,
  NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE,
  USER_ROLE,
  USER_PROFILE_TYPE,
} from '../constants/constants';
import { USER_PROFILE_PATHS } from '../constants/paths';
import { Employee, RetireeSpecialProfile } from 'employee';

import {
  setRole,
  setAdminRoles,
  setAllowDelegation,
  setDelegatedBy,
  getConcessionThunk,
  getConcessionAdminThunk,
  getConcessionDelegationThunk,
} from '../slice/userSlice';
import { INominationViewModeItem } from '../interfaces';

import { getDisplayVal } from './booking';

import { getRole } from '../services/user';

import { isRetireeSpecialProfile, isRetiree } from '../helpers';

async function retrieveUserModeStatus(userId: string, dispatch: any, isChangeRole?: boolean) {
  if (!isChangeRole) {
    const { user } = (await getRole()) || {};

    const { role, adminRoles, allowDelegation, delegatedBy } = user || {};
    const { type: roleType, id: roleId } = role || {};

    dispatch(setRole({ ...role }));
    dispatch(setAllowDelegation(allowDelegation));
    dispatch(setDelegatedBy(delegatedBy));

    dispatch(setAdminRoles(adminRoles));
    if (roleType === USER_ROLE.admin) {
      dispatch(getConcessionAdminThunk());
    } else if (roleType === USER_ROLE.delegation) {
      dispatch(getConcessionDelegationThunk(roleId));
    } else {
      dispatch(getConcessionThunk(userId));
    }
  }
}

const handleBackToUserProfile = (history: ReturnType<typeof useHistory>) => {
  /* Handle below pages go back to Profile menu page:
    - payment method
    - payment history
  */
  if (
    [
      USER_PROFILE_PATHS.dependentDetails,
      USER_PROFILE_PATHS.paymentMethod,
      USER_PROFILE_PATHS.paymentHistory,
    ]?.includes(history?.location?.pathname)
  ) {
    history?.push(USER_PROFILE_PATHS.menu);
  } else if (history?.location?.pathname === USER_PROFILE_PATHS.menu) {
    // handle Profile menu page go back to profile page (mobile view)
    history?.push(USER_PROFILE_PATHS.root);
  } else {
    history?.goBack();
  }
};

const flownSuspensionPopup = (
  startDate: string | Date,
  endDate: string | Date,
  content: string,
  fontWeight: number,
  link?: string,
) => {
  const theme = useTheme();
  const replaceStartDateContent = content.split(en.userProfile.flownSuspension.startDate);
  const replaceEndDateContent = replaceStartDateContent[1].split(en.userProfile.flownSuspension.endDate);
  const replaceLinkContent = replaceEndDateContent[1].split(link || '');
  const replaceEndDateContentTemp = (
    <Typography
      variant={link ? 'body_1_medium' : 'body_2_regular'}
      sx={{
        color: theme.color.secondary.dark_grey.option_1,
        display: 'inline',
      }}
    >
      {replaceStartDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(startDate)}
      </Typography>
      {replaceEndDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(endDate)}
      </Typography>
      {link ? (
        <>
          {replaceLinkContent[0]}
          <Typography
            variant={link ? 'body_1_medium' : 'body_2_regular'}
            sx={{
              display: 'inline',
              cursor: 'pointer',
              color: theme.color.utility.link.option_3,
            }}
            onClick={() => {
              // click the linkage to redirect to other website
              if (window.config?.flownSuspensionMoreDetailLink)
                window.open(window.config.flownSuspensionMoreDetailLink);
            }}
          >
            {en.userProfile.flownSuspension.link}
          </Typography>
          {replaceLinkContent[1]}
        </>
      ) : (
        replaceEndDateContent[1]
      )}
    </Typography>
  );
  return replaceEndDateContentTemp;
};

const nominationFlowLbl = en.userProfile.nomination.nominationFlow;
const getEligibleCarrierJoinedText = (eligibleCarrier: string[] | undefined) => {
  return eligibleCarrier
    ?.map(
      (carrier: string) =>
        nominationFlowLbl.eligibilityCarrier?.[carrier as keyof typeof nominationFlowLbl.eligibilityCarrier],
    )
    .join(', ');
};

const handleEligibleCarrier = (oalEligibilityOptions: Configuration.OALEligibilityLabel | undefined) => {
  const eligibilityCarrierLbl = nominationFlowLbl.eligibilityCarrier;

  const eligibleCarrier: string[] = [];

  // ['cx', 'oneworld', 'zed']
  const eligibleCarrierKeys = Object.keys(eligibilityCarrierLbl);

  // [(cx)true/false, (oneworld)true/false, (zed)true/false]
  const eligibilityCarrierBooleanArray = [
    oalEligibilityOptions?.cx,
    oalEligibilityOptions?.oneworld,
    oalEligibilityOptions?.zed,
  ];

  eligibleCarrierKeys.forEach((value: string, index: number) => {
    // map eligibleCarrierKeys & eligibilityCarrierBooleanArray
    if (eligibilityCarrierBooleanArray[index]) {
      // if true, push eligibleCarrierKeys's value('cx','oneworld','zed') to eligibleCarrier[]
      eligibleCarrier.push(value);
    }
  });

  return eligibleCarrier;
};

const handleNominatedEligibleCarrier = (
  configurations: Configuration.ConfigurationsClient,
  nominationList: INominationViewModeItem[],
) => {
  const isSpouseOrCompanion = (beneficiaryTypeCode: string) =>
    NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE.includes(beneficiaryTypeCode);
  const isChildrenDependent = (beneficiaryTypeCode: string) => beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.children;

  return nominationList.map((item: INominationViewModeItem) => {
    const oalEligibilityOptions = configurations.airlineEligibility.find(
      (oalEligibility: Configuration.OALEligibilityLabel) =>
        item.beneficiaryTypeCode === oalEligibility.beneficiaryTypeCode &&
        (isSpouseOrCompanion(item.beneficiaryTypeCode)
          ? item.isAssignedOAL === oalEligibility.withOALEligibility // spo or frd use "isAssignedOAL" <-> "withOALEligibility"
          : isChildrenDependent(item.beneficiaryTypeCode) // chd use "false" data (TODO: check nominationType:"CHILDREN_UNDER_24" in future story)
          ? oalEligibility.withOALEligibility === false
          : true), // other type not check withOALEligibility flag
    );

    const eligibleCarrier = handleEligibleCarrier(oalEligibilityOptions);

    return {
      ...item,
      eligibleCarrier,
    };
  });
};

const filterCompanionData = (list: INominationViewModeItem[]) => {
  if (!list) return [];

  return list.filter((item: INominationViewModeItem) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion);
};

const validateForRegisterMaxNumberOfTravelCompanion = <T,>(travelCompanionList: T[]): boolean => {
  const registerMaxNumberOfTravelCompanion = window.config.registerMaxNumberOfTravelCompanion;
  // bypass checking if Jenkins env variable undefined
  if (!registerMaxNumberOfTravelCompanion) return true;

  return travelCompanionList.length < registerMaxNumberOfTravelCompanion;
};

const getRetireeSwitchProfileLabel = (profile: Employee.Profile) => {
  if (!profile || (profile && !isRetireeSpecialProfile(profile) && !isRetiree(profile))) return '';

  const retireeSpecialProfileLbl = en.userProfile.retireeSpecialProfile;

  /* 
    If current profile is special profile,
    the label should be for Retiree normal profile,
    else the label as Retiree special profile.
  */
  const switchProfileLabel = isRetireeSpecialProfile(profile)
    ? retireeSpecialProfileLbl.retireeProfileTitle
    : retireeSpecialProfileLbl.switchProfileDialog.specialProfileLabel;

  return switchProfileLabel;
};

const getRetireeOriginEmployeeIdByRetireeSpecialProfile = (profile: Employee.Profile) => {
  if (profile?.employeeId) {
    return profile.employeeId.split('_')[0];
  }
};

const trimRetireeSpecialEmployeeId = (employeeId: string) => {
  return employeeId.replace(`_${USER_PROFILE_TYPE.retireeSpecialProfile}`, '');
};

const handleRetireeSwitchEmployeeId = (
  profile: Employee.Profile,
  retireeSpecialProfile: RetireeSpecialProfile | undefined,
) => {
  let employeeId = '';
  if (isRetiree(profile) && retireeSpecialProfile) {
    employeeId = retireeSpecialProfile.employeeId;
  } else if (isRetireeSpecialProfile(profile)) {
    employeeId = getRetireeOriginEmployeeIdByRetireeSpecialProfile(profile) || '';
  }

  return employeeId;
};

export {
  retrieveUserModeStatus,
  handleBackToUserProfile,
  flownSuspensionPopup,
  getEligibleCarrierJoinedText,
  handleEligibleCarrier,
  handleNominatedEligibleCarrier,
  filterCompanionData,
  validateForRegisterMaxNumberOfTravelCompanion,
  getRetireeSwitchProfileLabel,
  getRetireeOriginEmployeeIdByRetireeSpecialProfile,
  trimRetireeSpecialEmployeeId,
  handleRetireeSwitchEmployeeId,
};
