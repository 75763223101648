import React from 'react';
import { Typography, useTheme, SxProps, Theme } from '@mui/material';

import { IReplaceContent } from '../../../interfaces';

import { IReplaceContentProps, IParagraphVariantProps } from './ParagraphMasterContainer';

import { splitStringWithPlaceholders } from '../../../helpers';

import InsertContentPattern from './InsertContentPattern';

interface IDefaultParagraphProps extends IReplaceContentProps, IParagraphVariantProps {
  text: string;
  defaultParagraphStyles?: SxProps<Theme>;
  paragraphTypoSxProps?: SxProps<Theme>;
}

const matchedTextWithReplaceContent = (text: string, replaceContent: any) => {
  return replaceContent?.filter((replaceContentItem: IReplaceContent) => {
    // Check if the replaceContentItem's matchText is included in the item
    return text.includes(replaceContentItem.matchText);
  });
};

const DefaultParagraph = ({
  text,
  replaceContent,
  defaultParagraphStyles,
  paragraphVariant,
  paragraphTypoSxProps,
}: IDefaultParagraphProps) => {
  const theme = useTheme();
  const splitedWarning = splitStringWithPlaceholders(text);
  const matchObjs = matchedTextWithReplaceContent(text, replaceContent);

  const mixedSxProps = {
    ...defaultParagraphStyles,
    ...paragraphTypoSxProps,
  };
  return (
    <Typography
      className="default_paragraph"
      variant={paragraphVariant || 'body_2_regular'}
      sx={{
        color: theme.color.secondary.dark_grey.option_3,
        display: 'inline',
        whiteSpace: 'pre-line',
        ...mixedSxProps,
      }}
    >
      {InsertContentPattern({ splitedWarning, matchObjs, paragraphVariant })}
    </Typography>
  );
};

export default DefaultParagraph;
