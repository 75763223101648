import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { use100vh } from 'react-div-100vh';

import en from '../../../translations/en';
import {
  FLIGHT_AVAILABILITY_STATUS,
  SEAT_CLASS,
  SEAT_CLASS_OPTIONS,
  UI_STYLES,
  USER_ROLE,
} from '../../../constants/constants';

import { getColorOfSeatClassStatus, getSeatClassImage, getSeatDisplayByAvailability } from '../../../helpers';

import { FlightSearch } from 'flightSearch';
import { IButtonDetails, IButtonValue, IClassAvailability } from '../../../interfaces';
import { RADIO_BUTTON_GROUP_VARIANT } from '../../../components/Button/RadioButtonGroup';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking, setOutwardFlightClass, setReturnFlightClass } from '../../../slice/bookingSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectApp } from '../../../slice/appSlice';

import { Footer, Header, RadioButtonGroup, ScrollableView, FormAlert, DesktopBreadcrumb } from '../../../components';
import { SegmentInfo } from '../..';

const SeatClassContainer = ({
  handleBackClick,
  handleFooterClick,
  routeToStep,
}: {
  handleBackClick?: () => void;
  handleFooterClick?: () => void;
  routeToStep?: number;
}) => {
  const theme = useTheme();
  const { outwardFlight, returnFlight } = useAppSelector(selectBooking, shallowEqual);
  const dispatch = useAppDispatch();
  const {
    seatClass: outwardFlightSeatClass,
    availability: outwardFlightAvailability,
    flightRestriction: outwardFlightRestriction,
  } = outwardFlight || {};
  const {
    seatClass: returnFlightSeatClass,
    availability: returnFlightAvailability,
    flightRestriction: returnFlightRestriction,
  } = returnFlight || {};

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};

  const screenHeight = use100vh();

  const { type: roleType } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: grey[50],
      }}
    >
      <Box
        component={ScrollableView}
        sx={
          isDesktop
            ? {
                height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${
                  UI_STYLES.desktopFooterHeight
                } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'})`,
              }
            : {
                mt: -5.3,
                px: 2,
                height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${
                  UI_STYLES.footerHeight
                } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'} + 43px)`,
              }
        }
      >
        <DesktopBreadcrumb step={routeToStep} />
        {isDesktop && (
          <Box
            sx={{
              mx: 'auto',
              maxWidth: '518px',
            }}
          >
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleBackClick?.();
                  }}
                >
                  {en.booking.flightClass.backSearchResult}
                </Typography>
              }
              customStyles={{
                mb: 3,
              }}
              handleOnBack={handleBackClick}
            />
          </Box>
        )}
        {outwardFlight && (
          <SegmentInfo
            flightInfo={outwardFlight}
            subcomponent={{
              component: SeatClassSelection,
              props: {
                flightAvailability: outwardFlightAvailability,
                flightRestriction: outwardFlightRestriction,
                defaultSelected: outwardFlightSeatClass,
                onSeatClassChangeHandler: (availability: IClassAvailability) =>
                  dispatch(setOutwardFlightClass(availability)),
              },
            }}
            isShowSegmentStatus={false}
          />
        )}

        {returnFlight && (
          <SegmentInfo
            flightInfo={returnFlight}
            subcomponent={{
              component: SeatClassSelection,
              props: {
                flightAvailability: returnFlightAvailability,
                flightRestriction: returnFlightRestriction,
                defaultSelected: returnFlightSeatClass,
                onSeatClassChangeHandler: (availability: IClassAvailability) =>
                  dispatch(setReturnFlightClass(availability)),
              },
            }}
            isShowSegmentStatus={false}
          />
        )}
      </Box>

      {outwardFlightSeatClass && (
        <Footer
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.common.continue,
            customOnClick: handleFooterClick,
          }}
          customStyles={
            isDesktop
              ? {
                  px: 22,
                  borderRadius: '24px 24px 0px 0px',
                  boxShadow: theme.boxShadow.important,
                }
              : {
                  px: 2,
                }
          }
        />
      )}
    </Box>
  );
};

const SeatClassSelection = ({
  flightAvailability,
  flightRestriction,
  defaultSelected,
  onSeatClassChangeHandler,
}: {
  flightAvailability: FlightSearch.FlightAvailability;
  flightRestriction: FlightSearch.FlightRestriction;
  defaultSelected?: IClassAvailability;
  onSeatClassChangeHandler: (IClassAvailability: IClassAvailability) => void;
}) => {
  const { first: firstAvail, business: businessAvail, econ: econAvail } = flightAvailability || {};
  const { first: firstRestrict, business: businessRestrict, econ: econRestrict } = flightRestriction || {};

  const theme = useTheme();

  const flightAvailabilityIsRestricted = {
    [SEAT_CLASS.first]: {
      availability: firstAvail,
      isRestricted: firstRestrict,
    },
    [SEAT_CLASS.business]: {
      availability: businessAvail,
      isRestricted: businessRestrict,
    },
    [SEAT_CLASS.econ]: {
      availability: econAvail,
      isRestricted: econRestrict,
    },
  };

  const seatClassOptions: IButtonDetails[] = useMemo(() => {
    const results: IButtonDetails[] = [];

    SEAT_CLASS_OPTIONS.forEach((item: { val: string; title: string }) => {
      const { val, title } = item || {};

      const availabilityIsRestricted = flightAvailabilityIsRestricted[val];

      const { availability, isRestricted } = availabilityIsRestricted || {};

      if (availability !== undefined) {
        const status = getSeatDisplayByAvailability(availability);

        const isDisabled =
          isRestricted ||
          (availability !== FLIGHT_AVAILABILITY_STATUS.confirm && availability !== FLIGHT_AVAILABILITY_STATUS.waitlist); // undefine restriction would be filtered out

        results.push({
          ...item,
          id: val,
          val: {
            title,
            availability,
          },
          subtitle: status,
          imgSrc: getSeatClassImage(val),
          customSubtitleStyle: {
            color: isDisabled ? theme.color.secondary.grey.option_5 : getColorOfSeatClassStatus(status),
            ...theme.typography.body_2_medium,
          },
          isDisabled,
        });
      }
    });

    return results;
  }, [flightAvailability]);

  const onSeatClassChange = (data: IButtonValue) => {
    const { id: type, val } = data || {};
    const { availability } = val || {};
    if (type) {
      onSeatClassChangeHandler({ type, availability });
    }
  };

  return (
    <Box sx={{ mb: 0, display: 'flex', flexWrap: 'wrap' }} justifyContent="center">
      {seatClassOptions.find((item) => item.isDisabled) && (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            mb: 0.5,
            '& > *': {
              mb: '0 !important',
            },
          }}
        >
          <FormAlert
            severity="info"
            component={
              <Typography variant="body_2_medium" color={theme.color.secondary.dark_grey.option_3} sx={{ ml: 1 }}>
                {en.booking.flightClass.seatAvailability}
              </Typography>
            }
          />
        </Box>
      )}
      <RadioButtonGroup
        options={seatClassOptions}
        defaultVal={defaultSelected?.type}
        variant={RADIO_BUTTON_GROUP_VARIANT.ICON}
        onChange={onSeatClassChange}
        customButtonStyle={{
          height: '165px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Box>
  );
};

export default SeatClassContainer;
