import React, { useEffect, useRef, useContext, useCallback, useState, useMemo } from 'react';
import { Box, Collapse, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';

import en from '../../../translations/en';
import {
  DATE_FORMAT,
  SAVE_DATA_TO,
  TRAVEL_PURPOSE,
  GOOGLE_ANALYTICS_EVENTS,
  PAYMENT_MEDIUM,
  HKG,
  USER_ROLE,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  DATE_UNIT,
  TRAVEL_TYPES,
  PAYMENT_SETUP_STATUS,
} from '../../../constants/constants';
import { BOOKING_PATHS } from '../../../constants/paths';

import { IWarningArray } from '../../../interfaces';

import {
  setInputValue,
  formatAirportValue,
  formatDateAsString,
  getNonEnglishCharacterTravellers,
  getConcessionSelectionValue,
  handlePaymentAndFlownSuspensionMessageAction,
} from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { usePaymentMethodIndicator, useEmailRegisterIndicator } from '../../../hooks';

import {
  selectBooking,
  setDestination,
  setOrigin,
  setStartDate,
  setDetail,
  setLeisureTravelDetails,
  setType,
  leisureTravelDetailInitialState,
  travelDetailInitialState,
  setOutwardFlight,
  setBookingStep,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
  setViewBookingApplicationId,
  setTpAppRef,
} from '../../../slice/bookingSlice';
import { selectUser } from '../../../slice/userSlice';
import { setIsOpenBillingAgreementDialog } from '../../../slice/paypalSlice';
import { setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData } from '../../../slice/appSlice';
import {
  EditSearchBarState,
  EditSearchBarAction,
  TravelTypeListLtState,
  CountryOfResidenceAction,
} from '../../../context';

import { FormButton, CommonWarning } from '../../../components';
import {
  DateSelection,
  TravelTypeWithIcon,
  LocationSelection,
  ODExchange,
  ConcessionSelectionContainer,
  MobileSearchCriteriaButton,
} from '../../../containers';

const MobileSearchCriteriaBox = ({
  saveTo = SAVE_DATA_TO.redux,
  handleSearchClick,
  customStyles,
}: {
  saveTo?: string;
  isSearchResultPage?: boolean;
  handleSearchClick?: () => void;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const originRef = useRef<HTMLInputElement>(null);
  const destinationRef = useRef<HTMLInputElement>(null);

  const { concession, profile, role } = useAppSelector(selectUser) || {};
  const { dutyTravel, leisureTravel } = concession || {};

  const isEditSearch = saveTo === SAVE_DATA_TO.context;
  const editSearchData = useContext(EditSearchBarState) || {};
  const { isShowDefaultTravelTypeOption } = useContext(TravelTypeListLtState) || {};
  const { origin, destination, startDate, travelDetails, leisureTravelDetails, travelType } = isEditSearch
    ? editSearchData
    : useAppSelector(selectBooking, shallowEqual) || {};
  const {
    setEditOrigin,
    setEditDestination,
    setEditStartDate,
    setEditTravelDetails,
    setEditLeisureTravelDetails,
    setEditTravelType,
  } = useContext(EditSearchBarAction) || {};

  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const [isExpand, setIsExpand] = useState(false);
  const [warningArray, setWarningArray] = useState<IWarningArray[]>([]);

  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;
  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const regulatoryRegion = profile?.regulatoryRegion;

  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;
  const travelTypeTag = isDT
    ? en.booking.travelType.dutyTravel
    : isLT
    ? en.booking.travelType.leisureTravel.title
    : en.booking.travelType.other;

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  //Personal email indicator
  const [emailRegisterIndicator] = useEmailRegisterIndicator();

  const paxList = (concession?.paxList || []) as any;
  const isSelfRole = role.type === USER_ROLE.self;

  // handle delegation role
  const isDelegationRole = role.type === USER_ROLE.delegation;
  const isAdminRole = role.type === USER_ROLE.admin;

  // for non english char label
  const nonEnglishCharHKGLbl = en.booking.nonEnglishCharacter.hkg;
  const nonEnglishCharNonHKGLbl = en.booking.nonEnglishCharacter.notHkg;

  const concessionSelectionValue = useMemo(() => {
    return getConcessionSelectionValue({
      isSearchResultPage,
      isLT,
      isDT,
      travelDetails,
      leisureTravelDetails,
      isShowDefaultTravelTypeOption,
    });
  }, [isLT, isDT, travelDetails, leisureTravelDetails, isShowDefaultTravelTypeOption]);

  const canSearch =
    origin &&
    destination &&
    startDate &&
    (travelDetails?.title || leisureTravelDetails?.type) &&
    concessionSelectionValue;

  const saveSearchDataToRedux = useCallback(() => {
    dispatch(setOrigin(origin));
    dispatch(setDestination(destination));
    dispatch(setStartDate(startDate));
    dispatch(setType(travelType));

    /**
     * Special handle: Clear the other travel detail data in redux when Search
     * Case (isDT): clear LT data
     * Case (isLT): clear DT data
     */
    if (isDT) {
      dispatch(setDetail(travelDetails));
      dispatch(setLeisureTravelDetails(leisureTravelDetailInitialState));
    } else if (isLT) {
      dispatch(
        setDetail({
          ...travelDetailInitialState,
          advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
          advanceDayUnit: DATE_UNIT.DYS,
        }),
      );
      dispatch(setLeisureTravelDetails(leisureTravelDetails));
    }
  }, [origin, destination, startDate, travelType, travelDetails, dutyTravel, leisureTravel, leisureTravelDetails]);
  const saveSearchDataToContext = useCallback(() => {
    setEditOrigin?.(origin);
    setEditDestination?.(destination);
    setEditStartDate?.(startDate);
    setEditTravelDetails?.(travelDetails);
    setEditTravelType?.(travelType);

    /**
     * Special handle: Clear the other travel detail data in context when Search
     * Case (isDT): clear LT data
     * Case (isLT): clear DT data
     */
    if (isDT) {
      setEditTravelDetails?.(travelDetails);
      setEditLeisureTravelDetails?.(leisureTravelDetailInitialState);
    } else if (isLT) {
      setEditTravelDetails?.({
        ...travelDetailInitialState,
        advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
        advanceDayUnit: DATE_UNIT.DYS,
      });
      setEditLeisureTravelDetails?.(leisureTravelDetails);
    }
  }, [origin, destination, startDate, travelType, travelDetails, dutyTravel, leisureTravel, leisureTravelDetails]);

  useEffect(() => {
    if (originRef.current && destinationRef.current) {
      setInputValue(originRef.current, formatAirportValue(origin));
      setInputValue(destinationRef.current, formatAirportValue(destination));
    }
  }, [origin, destination]);

  useEffect(() => {
    const isShowNonEnglishCharacter = !!getNonEnglishCharacterTravellers(paxList, isDelegationRole ? true : false);

    let replaceTarget = en.payment.replaceTarget;
    const myCasesLinkPrefix = en.payment.directDebit.myCasesLink;
    if (paymentMethodIndicator?.isSuspensionStatus) replaceTarget = en.payment.link; // ETP-2431
    else if (!paymentMethodIndicator.isDirectDebitSetupSuspended) replaceTarget = en.payment.replaceTarget;
    else replaceTarget = '';

    // [ETP-4266]
    if (paymentMethodIndicator?.paymentSetUpStatus === PAYMENT_SETUP_STATUS.UNABLE_RETRIEVE) {
      replaceTarget = '';
    }

    // [ETP-4526] suspended message
    if (paymentMethodIndicator?.paymentSetUpStatus === PAYMENT_SETUP_STATUS.SUSPENDED) {
      replaceTarget = myCasesLinkPrefix;
    }

    let array = [
      {
        id: 0,
        msgText: paymentMethodIndicator?.paymentSetUpWarningMessage,
        // handle `isSuspensionStatus` & `isDirectDebitSetupSuspended` cases
        replaceTarget,
        actionFunc: () => {
          if (paymentMethodIndicator.isRetireeWidowRole) {
            if (
              profile &&
              (!profile.retireeWidow?.countryOfResidential ||
                (profile.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT && !profile.isPayPalBillingAgreementSetup))
            ) {
              setOpenCountryOfResidenceDialog(true);
            } else if (profile && !profile.isPayPalBillingAgreementSetup) {
              dispatch(setIsOpenBillingAgreementDialog(true));
            }
          } else {
            // Paypal flow - open billing agreement dialog
            if (paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT) {
              dispatch(setIsOpenBillingAgreementDialog(true));
            } else {
              handlePaymentAndFlownSuspensionMessageAction({
                paymentMethodIndicator,
              });
            }
          }
        },
      },
      {
        id: 1,
        msgText: isDelegationRole
          ? en.booking.nonEnglishCharacter.message
          : regulatoryRegion === HKG
          ? nonEnglishCharHKGLbl
          : nonEnglishCharNonHKGLbl,
      },
      {
        id: 2,
        msgText: emailRegisterIndicator?.emailRegisterMessage,
      },
    ];
    if (
      !(
        isSelfRole &&
        !isSearchResultPage &&
        paymentMethodIndicator?.isShowWarning &&
        paymentMethodIndicator?.paymentSetUpWarningMessage
      ) ||
      isAdminRole
    ) {
      array = array?.filter((item: IWarningArray) => item.id != 0);
    }

    // hide msg for admin role
    if (!isShowNonEnglishCharacter || isAdminRole) {
      array = array?.filter((item: IWarningArray) => item.id != 1);
    }
    if (!emailRegisterIndicator?.isShowWarning || !emailRegisterIndicator?.emailRegisterMessage) {
      array = array?.filter((item: IWarningArray) => item.id != 2);
    }
    setWarningArray(array);
  }, [paymentMethodIndicator, concession, isDelegationRole]);

  const checkIsDisplayTravellerCount = () => {
    // check is default ZONE CX
    if (isLT) {
      const isNonZoneType =
        leisureTravelDetails?.type && leisureTravelDetails?.type.toUpperCase() !== TRAVEL_TYPES.ZONE;

      const { adultCount, childrenCount, infantsCount } = leisureTravelDetails || {};
      const totalTravellerCount = adultCount + childrenCount + infantsCount;
      return isNonZoneType ? totalTravellerCount : undefined;
    }

    if (isDT) return undefined;
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 1.5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: theme.backgroundColor.wheat,
        borderRadius: 1.5,
        boxShadow: theme.boxShadow.important,
        ...customStyles,
      }}
    >
      {!!travelType && (!!travelDetails?.title || !!leisureTravelDetails?.type) && isShowDefaultTravelTypeOption && (
        <TravelTypeWithIcon travelType={travelTypeTag} />
      )}

      {!isEditSearch &&
        (warningArray?.length > 1 ? (
          <Collapse in={isExpand} collapsedSize={`${18 * 3.1}px`}>
            <CommonWarning
              isShowIcon={true}
              msgText={
                en.booking.nonEnglishCharacter.defaultFirstMsg +
                warningArray?.length +
                en.booking.nonEnglishCharacter.defaultSecondMsg
              }
            />
            {isExpand &&
              warningArray?.map((item: IWarningArray, index: number) => {
                return (
                  <Box key={index} sx={{ ml: 3, mb: 2 }}>
                    <CommonWarning
                      isShowIcon={false}
                      msgText={item.msgText}
                      replaceTarget={item.replaceTarget}
                      actionFunc={item.actionFunc}
                    />
                  </Box>
                );
              })}
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Typography
                variant="navigation_bold"
                sx={{
                  color: theme.color.utility.link.option_3,
                  cursor: 'pointer',
                  mt: -0.5,
                }}
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand ? en.booking.nonEnglishCharacter.viewLess : en.booking.nonEnglishCharacter.viewMore}
              </Typography>
            </Box>
          </Collapse>
        ) : (
          warningArray?.length == 1 && (
            <CommonWarning
              msgText={warningArray[0].msgText}
              replaceTarget={warningArray[0].replaceTarget}
              actionFunc={warningArray[0].actionFunc}
            />
          )
        ))}

      <Box sx={{ position: 'relative' }}>
        <MobileSearchCriteriaButton
          label={en.booking.from}
          inputRef={originRef}
          value={formatAirportValue(origin)}
          shrink={{
            shrink: !!origin,
          }}
          title={isEditSearch ? en.booking.edit.title : en.home.whereAreYouFlyingTo}
          component={LocationSelection}
          data={{
            saveTo,
          }}
        />

        <MobileSearchCriteriaButton
          label={en.booking.to}
          inputRef={destinationRef}
          value={formatAirportValue(destination)}
          shrink={{
            shrink: !!destination,
          }}
          title={isEditSearch ? en.booking.edit.title : en.home.whereAreYouFlyingTo}
          component={LocationSelection}
          data={{
            saveTo,
          }}
        />

        <MobileSearchCriteriaButton
          label={en.booking.searchBar.date}
          value={formatDateAsString(startDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}
          shrink={{
            shrink: !!startDate,
          }}
          title={isEditSearch ? en.booking.edit.title : en.home.whenWillYouBeThere}
          component={DateSelection}
          data={{
            saveTo,
          }}
        />

        <MobileSearchCriteriaButton
          label={
            isDT
              ? en.booking.searchBar.dutyTravelConcession
              : isLT
              ? en.booking.searchBar.leisureTravelConcession
              : en.booking.searchBar.otherTravelConcession
          }
          value={concessionSelectionValue}
          shrink={{
            shrink: !!concessionSelectionValue,
          }}
          title={isEditSearch ? en.booking.edit.title : en.home.howTravel}
          component={ConcessionSelectionContainer}
          travellerCount={checkIsDisplayTravellerCount()}
          data={{
            saveTo,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '16px',
            top: '34px',
          }}
        >
          <ODExchange originRef={originRef} destinationRef={destinationRef} saveTo={saveTo} customStyles={{ pr: 0 }} />
        </Box>
      </Box>

      {canSearch && (
        <FormButton
          className="mobile_flight_search_btn"
          theme={theme}
          colour="blue"
          variant="contained"
          onClick={() => {
            // [ETP-4379] clear normal prepaid flow + retrieve and pay flow related Redux data
            dispatch(setIsCalledPrepaidAuth(false));
            dispatch(setRetrieveAndPayCppParams(null));

            dispatch(setBookingStep(null));

            dispatch(setPrepaidAuthFlowType(''));
            dispatch(setRetrieveAndPaySuccessData(null));
            dispatch(setNormalPrepaidSuccessData(null));

            dispatch(setViewBookingApplicationId(''));
            dispatch(setTpAppRef(''));

            /**
             * Search onClick will store data to upper level
             * Case "context":
             * - store data to redux
             *
             * Special handle: fix data in context not sync when switching between mobile and desktop
             * Case "redux":
             * - store data to redux
             * - store data to context
             */
            if (isEditSearch) {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.searchFlow,
                action: `${GOOGLE_ANALYTICS_EVENTS.action.editSearch}${GOOGLE_ANALYTICS_EVENTS.action.mobile}`,
              });

              saveSearchDataToRedux();
              dispatch(setOutwardFlight(null));
            } else if (saveTo === SAVE_DATA_TO.redux) {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.searchFlow,
                action: `${GOOGLE_ANALYTICS_EVENTS.action.search}${GOOGLE_ANALYTICS_EVENTS.action.mobile}`,
              });

              saveSearchDataToContext();
              saveSearchDataToRedux();
            }

            // clear booking step before access to flight search result page
            dispatch(setBookingStep(null));

            handleSearchClick?.();
          }}
          customStyles={{
            marginTop: '24px',
            height: '44px',
            width: '100%',
            borderRadius: '8px',
          }}
        >
          {en.common.search}
        </FormButton>
      )}
    </Box>
  );
};

export default MobileSearchCriteriaBox;
