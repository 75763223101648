import { useContext, useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography, useTheme, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import {
  NOMINATION_FLOW_PAGE_NO,
  NOMINATION_SUB_ROUTE_PAGE,
  UI_STYLES,
  NOMINATION_TYPE_TO_BACKEND,
  CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT,
  NOMINATION_TYPE,
  REGEX,
} from '../../../../constants/constants';
import { USER_PROFILE_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';
import { BackIcon, AlertIcon } from '../../../../assets/images';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { NominationAction, NominationState } from '../../../../context';
import {
  IOALEligibilitySelectedData,
  ICommonProgressbarStepList,
  INominationViewModeItem,
} from '../../../../interfaces';
import { getDisplayName } from '../../../../helpers';

import { selectApp, setLoading, setSnackBarData } from '../../../../slice/appSlice';
import { selectAuth } from '../../../../slice/authSlice';
import { getNominationSelectionList, getNominationViewModeList, saveNomination } from '../../../../services/user';

import {
  NomineeFooterComponent,
  NominationOverlayContainer,
  NomineeTypeSelectionContainer,
  NominationViewModeContainer,
  NominationCartPageContainer,
  NominationInterlineEligibilityContainer,
  NominationReviewAndConfirmContainer,
  NominationDisclaimerContainer,
  ChangeTypeDialogMessageComponent,
} from '../../..';
import { Header, ScrollableView, Div100vhView, FormButton, CancelDialog } from '../../../../components';
import { Progressbar, DesktopProgressbar } from '../../../../containers';

const FlowContainer = ({ routePages, data }: { routePages: any[]; data: any }) => {
  const { nominationFlow } = useContext(NominationState) || {};
  const { step: routeToStep } = nominationFlow?.nominationFlowRouteTo || {};

  return <NominationOverlayContainer open={true} {...routePages[routeToStep || 0]} data={data} />;
};

const NominationFlowContainer = () => {
  const history = useHistory();
  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useAppDispatch();

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const {
    nominationFlow,
    nominationTransformData,
    clonedNominationTempData,
    nominationChangeTypeData,
    clonedNominationChangeTypeData,
    isAllowNominate,
  } = useContext(NominationState) || {};
  const {
    setNominationFlow,
    setNominationTransformData,
    setClonedNominationTempData,
    resetNominationFlowToViewModePageData,
    setNominationChangeTypeData,
    setClonedNominationChangeTypeData,
    setIsAllowNominate,
  } = useContext(NominationAction) || {};

  const [isUpdatedDependentListByNominateApiResponse, setIsUpdatedDependentListByNominateApiResponse] = useState(false);

  const { step: routeToStep } = nominationFlow?.nominationFlowRouteTo || {};
  const {
    nominationSubRoutePage: subRoute,
    isAgreeDisclaimer,
    oalEligibilityTickedDependents,
    cancelNominationFlowDialogTriggeringPoint,
    isTriggerChangeTypeDialog,
    nominationFlowSelectedType,
  } = nominationFlow;

  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const isNominationViewModePage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationViewMode;
  const isNomineeTypeSelectionPage = routeToStep === NOMINATION_FLOW_PAGE_NO.selectNominationType;
  const isNominationCartPage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationCart;
  const isNominationOALEligibilityPage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationOALEligibility;
  const isNominationReviewAndConfirmPage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationReviewAndConfirm;
  const isNominationDisclaimerPage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationDisclaimer;

  // "add nominee" dependents list
  const toBeAddedDependents: INominationViewModeItem[] = nominationTransformData.filter(
    (item) => item.isSelected && !item.isLocked,
  );
  // "change type" dependents list
  const toBeChangeTypeDependents: INominationViewModeItem[] = nominationChangeTypeData.filter(
    (item) => item.isSelected,
  );

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const RoutePages: any = [
    {
      component: NominationViewModeContainer,
    },
    {
      component: NomineeTypeSelectionContainer,
    },
    {
      component: NominationCartPageContainer,
    },
    {
      component: NominationInterlineEligibilityContainer,
    },
    {
      component: NominationReviewAndConfirmContainer,
    },
    {
      component: NominationDisclaimerContainer,
    },
  ];

  // [ETP-4670] conduct progressbar data set
  const getNominationProgressbarStepList = (): ICommonProgressbarStepList[] => {
    const stepListLbl = nominationFlowLbl.stepList;
    const clonedNominationFlowPageNoList = _.omit(_.cloneDeep(NOMINATION_FLOW_PAGE_NO), 'nominationViewMode');

    const nominationPageNoForProgressbar: ICommonProgressbarStepList[] = Object.entries(clonedNominationFlowPageNoList)
      .map(([key, value]) => {
        // validate current step is active or not
        const isActiveCurrentStep = routeToStep === value;
        return { key, step: value, label: stepListLbl[key as keyof typeof stepListLbl], isActive: isActiveCurrentStep };
      })
      .sort((a, b) => a.step - b.step);

    return nominationPageNoForProgressbar;
  };
  const nominationProgressbarStepList = getNominationProgressbarStepList();

  const triggerCancelNominationFlowDialog = (
    triggeringPoint: keyof typeof CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT,
  ) => {
    // the triggering point is refer to CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT and allow empty string for default setting
    setNominationFlow({
      ...nominationFlow,
      cancelNominationFlowDialogTriggeringPoint: triggeringPoint,
    });
  };

  // back to previous page handler
  const backToPrevPageHandler = () => {
    setNominationFlow({
      ...nominationFlow,
      nominationFlowRouteTo: {
        step: routeToStep - 1,
      },
      // clear isAgreeDisclaimer: false,  when Disclaimer page back to Review & confirm page
      ...(isNominationDisclaimerPage && {
        isAgreeDisclaimer: false,
      }),
      // when back to `Nomination view mode page` from `Nominee type selection page`
      // also clear the selected nomination type
      ...(isNomineeTypeSelectionPage && {
        nominationFlowSelectedType: '',
      }),
      // when back to `Nominee type selection page` from `Nomination OAL eligibility page`
      // set selectedOALEligibilityDependentId to "" empty string
      ...(isNominationOALEligibilityPage && {
        selectedOALEligibilityDependentId: '',
      }),
      // hide cancellation prompt
      cancelNominationFlowDialogTriggeringPoint: false,
    });
  };

  const backButtonHandler = () => {
    if (routeToStep > NOMINATION_FLOW_PAGE_NO.nominationViewMode) {
      // current Page is Step 2 Nomination cart page > Showing the list including the nominated dependents and the to be added dependents
      if (
        isNominationCartPage &&
        nominationFlow?.nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart
      ) {
        backToPrevPageHandler();
      } else if (
        isNominationCartPage &&
        nominationFlow?.nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection
      ) {
        // current Page is Step 2 Nomination cart page > Showing the selection of not nominated dependent

        // reset data
        const tempList = _.cloneDeep(clonedNominationTempData);
        setNominationTransformData(tempList);
        setClonedNominationTempData([]);

        const tempChangeTypeList = _.cloneDeep(clonedNominationChangeTypeData);
        setNominationChangeTypeData(tempChangeTypeList);
        setClonedNominationChangeTypeData([]);

        // back to show the list including the nominated dependents and the to be added dependents
        setNominationFlow({
          ...nominationFlow,
          nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart,
        });
      } else {
        // other page just back to the previous page
        backToPrevPageHandler();
      }
    } else if (isNominationViewModePage) {
      history?.push(USER_PROFILE_PATHS.menu);
    }
  };

  const getNominationViewModeListAction = async () => {
    const result = await getNominationViewModeList(ern);

    setNominationFlow({
      ...nominationFlow,
      nominationViewModeData: result,
    });

    setIsAllowNominate(result?.isAllowNominate);

    dispatch(setLoading(false));
  };

  const showToastMessageHandler = async () => {
    // display toast message
    dispatch(
      setSnackBarData({
        message: nominationFlowLbl.saveNominationSuccessToastMessage,
        isShow: true,
        delay: 700,
        customStyles: {
          // TMP support desktop or mobile view.
          mb: isDesktop ? 9 : 10.5,
        },
      }),
    );

    setNominationFlow({
      ...nominationFlow,
      isTriggerSaveNominationToast: false,
    });
  };

  // [ETP-4667] Disclaimer page > Confirm button
  const confirmDisclaimerContinueClickAction = async () => {
    // transform data structure for request params
    const dependentListToBeSaved = _.cloneDeep(nominationTransformData)
      .filter((item: INominationViewModeItem) => item.isSelected && !item.isLocked)
      .map((item) => {
        const matchedOAL = oalEligibilityTickedDependents?.find(
          (oal: IOALEligibilitySelectedData) => oal.dependentId === item.dependentId,
        );

        return {
          dependentId: item.dependentId,
          ...(matchedOAL && {
            isAssignedOAL: matchedOAL.isAssignedOAL,
          }),
        };
      });

    // change type data no need pass "isAssignedOAL"
    const toBeChangeTypeDependent = _.cloneDeep(nominationChangeTypeData)
      .filter((item: INominationViewModeItem) => item.isSelected && !item.isLocked)
      .map((item) => {
        return { dependentId: item.dependentId };
      });

    // call API to save nomination data
    const saveNominationResult = await saveNomination(ern, {
      nominationType:
        NOMINATION_TYPE_TO_BACKEND?.[
          nominationFlow?.nominationFlowSelectedType as keyof typeof NOMINATION_TYPE_TO_BACKEND
        ] || '',
      dependentList: [...dependentListToBeSaved, ...toBeChangeTypeDependent],
    });

    // in API response, will return the updated Nomination view mode data.
    if (saveNominationResult) {
      setIsUpdatedDependentListByNominateApiResponse(true);
      // after API call success, trigger to refresh Nomination view mode page, then show toast message
      setNominationFlow({
        ...nominationFlow,
        isTriggerSaveNominationToast: true,
        // redirect to Nomination view mode page
        nominationFlowRouteTo: {
          step: NOMINATION_FLOW_PAGE_NO.nominationViewMode,
        },
        // set the updated data into context
        nominationViewModeData: saveNominationResult,
        // set default sub route page
        nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart,
        // clear selected nominee type
        nominationFlowSelectedType: '',
      });

      setIsAllowNominate(saveNominationResult?.isAllowNominate);
    }
  };

  const getFooterObj = () => {
    const baseFooterObj: {
      isFullWidth: boolean;
      text: string;
      handleContinueClickAction?: () => void;
      restProps?: any;
      isShowSecondaryButton?: boolean;
      secondaryText?: string;
      secondaryHandleContinueClickAction?: () => void;
    } = {
      isFullWidth: !isDesktop,
      text: en.common.continue,
      isShowSecondaryButton: false,
    };

    // Nomination view mode (step 0)
    if (isNominationViewModePage) {
      baseFooterObj.text = nominationFlowLbl.nominationViewMode.continueButton;

      if (!isAllowNominate) {
        baseFooterObj.restProps = {
          disabled: true,
          colour: 'grey',
        };
      }

      baseFooterObj.handleContinueClickAction = () => {
        setNominationFlow({
          ...nominationFlow,
          nominationFlowRouteTo: {
            step: routeToStep + 1,
          },
        });
      };

      return baseFooterObj;
    }

    // Nominee type selection (step 1)
    if (isNomineeTypeSelectionPage && nominationFlow?.isNominationTypeValid) {
      baseFooterObj.handleContinueClickAction = async () => {
        const nominationSelectionData = await getNominationSelectionList(ern);
        if (_.isUndefined(nominationSelectionData)) return;

        // set nomination change type data: from another nomination type
        const otherSelectedType =
          nominationFlow.nominationFlowSelectedType === NOMINATION_TYPE.plusOne
            ? NOMINATION_TYPE.travelNominees
            : NOMINATION_TYPE.plusOne;
        const otherNominatedData = nominationFlow.nominationViewModeData?.[otherSelectedType]?.list;

        const clonedOtherNominatedData = _.cloneDeep(
          otherNominatedData?.filter((nominated: INominationViewModeItem) => !nominated.isPlusOneTicketIssued),
        );
        clonedOtherNominatedData?.forEach((item: INominationViewModeItem) => {
          item.isSelected = false;
          item.isGreyedOut = false;
          item.isLocked = false;
        });

        setNominationChangeTypeData(clonedOtherNominatedData);

        setClonedNominationTempData([]);
        setClonedNominationChangeTypeData([]);
        setNominationFlow({
          ...nominationFlow,
          nominationSelectionData,
          nominationFlowRouteTo: {
            step: routeToStep + 1,
          },
        });
      };

      return baseFooterObj;
    }

    // pre-config Cancel button (for step 2-1, and Review & confirm page)
    if (
      subRoute === NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart ||
      isNominationReviewAndConfirmPage
    ) {
      baseFooterObj.isShowSecondaryButton = true;
      baseFooterObj.secondaryText = en.common.cancel;

      // [ETP-4751] cancellation prompt
      baseFooterObj.secondaryHandleContinueClickAction = () => {
        // trigger the opening of dialog
        triggerCancelNominationFlowDialog(
          CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT.cancelButton as keyof typeof CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT,
        );
      };
    }

    // Nomination Cart Page (step 2)
    if (isNominationCartPage) {
      // (step 2-2) that means the step for selection of not nominated dependent
      if (subRoute === NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection) {
        // Continue button is not shown until at least 1 dependent is selected
        if (toBeAddedDependents.length > 0 || toBeChangeTypeDependents.length > 0) {
          baseFooterObj.handleContinueClickAction = () => {
            if (toBeChangeTypeDependents.length > 0) {
              // if has tick change type dependent, need to trigger a prompt dialog
              setNominationFlow({
                ...nominationFlow,
                isTriggerChangeTypeDialog: true,
              });
            } else {
              setNominationFlow({
                ...nominationFlow,
                oalEligibilityTickedDependents: [],
                isOALEligibilityEnableContinue: false,
                selectedOALEligibilityDependentId: '',
                nominationFlowRouteTo: {
                  step: NOMINATION_FLOW_PAGE_NO.nominationOALEligibility,
                },
              });
            }
          };
          return baseFooterObj;
        }
      }
    }

    // Interline page (step 3)
    if (isNominationOALEligibilityPage) {
      if (!nominationFlow?.isOALEligibilityEnableContinue) {
        baseFooterObj.restProps = {
          disabled: true,
          colour: 'grey',
        };
      }
      baseFooterObj.text = en.common.continue;
      baseFooterObj.isFullWidth = !isDesktop; // desktop mode - false, mobile mode - true
      baseFooterObj.handleContinueClickAction = () => {
        setNominationFlow({
          ...nominationFlow,
          nominationFlowRouteTo: {
            step: NOMINATION_FLOW_PAGE_NO.nominationReviewAndConfirm,
          },
        });
      };

      return baseFooterObj;
    }

    // Review & Confirm page (step 4)
    if (isNominationReviewAndConfirmPage) {
      baseFooterObj.text = en.common.confirm;
      baseFooterObj.isFullWidth = false;
      baseFooterObj.handleContinueClickAction = () => {
        // go next step for Disclaimer page
        setNominationFlow({
          ...nominationFlow,
          isAgreeDisclaimer: false,
          nominationFlowRouteTo: {
            step: routeToStep + 1,
          },
        });
      };
      return baseFooterObj;
    }

    // Disclaimer page (step 5)
    if (isNominationDisclaimerPage) {
      // clear the cancel button config
      baseFooterObj.isShowSecondaryButton = false;
      baseFooterObj.secondaryText = '';
      baseFooterObj.secondaryHandleContinueClickAction = undefined;

      // Hide Confirm button
      if (!isAgreeDisclaimer) return null;

      baseFooterObj.text = en.common.confirmAndSubmit;
      baseFooterObj.handleContinueClickAction = confirmDisclaimerContinueClickAction;
      return baseFooterObj;
    }

    return null;
  };

  const footerObj = getFooterObj();

  // Nomination view mode > fetch API for view mode data
  useEffect(() => {
    // trigger Nominee type selection page by Companion registration completed page
    const isTriggerNomineeTypeSelectionPage = localStorage.getItem('isTriggerNomineeTypeSelectionPage');

    // Nomination view mode is the first page in nomination flow
    if (isNominationViewModePage) {
      // but need to check if the page is triggered by Companion registration completed page
      if (isTriggerNomineeTypeSelectionPage) {
        setNominationFlow({
          ...nominationFlow,
          nominationFlowRouteTo: {
            step: NOMINATION_FLOW_PAGE_NO.selectNominationType,
          },
        });
        localStorage.removeItem('isTriggerNomineeTypeSelectionPage');
      } else {
        // prevent to fetch api when checked already updated data by nominate api response
        if (!isUpdatedDependentListByNominateApiResponse) {
          dispatch(setLoading(true));
          getNominationViewModeListAction();
        }
      }
    }
  }, [isNominationViewModePage, isUpdatedDependentListByNominateApiResponse]);

  // [ETP-4667] refresh updated nomination data after save nomination success
  useEffect(() => {
    if (nominationFlow.isTriggerSaveNominationToast) {
      showToastMessageHandler();
    }
  }, [nominationFlow]);

  // cater scrolling top handling
  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }
  }, [nominationFlow.nominationFlowRouteTo.step, nominationFlow.nominationSubRoutePage]);

  useEffect(() => {
    return () => {
      // clear nominationFlow context data if exit this page
      setNominationFlow({
        nominationFlowRouteTo: {
          step: 0,
        },
        nominationFlowSelectedType: '',
        isNominationTypeValid: false,
        nominationSelectionData: [],
        nominationChangeTypeData: [],
      });
      setIsAllowNominate(false);
    };
  }, []);

  let toBeChangeDependentName = '';
  if (toBeChangeTypeDependents.length > 0) {
    toBeChangeDependentName = getDisplayName({
      firstName: toBeChangeTypeDependents[0]?.firstName,
      lastName: toBeChangeTypeDependents[0]?.lastName,
    });
  }

  const baseChangeTypeLabel =
    nominationFlowSelectedType === NOMINATION_TYPE.plusOne
      ? nominationFlowLbl.toPlusOne
      : nominationFlowLbl.toTravelNominee;
  const baseChangeTypeSubtitle = `${nominationFlowLbl.ifYouConfirm.replace(
    REGEX.specificString,
    toBeChangeDependentName,
  )}${nominationFlowLbl.willBeChanged}`;

  const changeTypeDialogTitle = `${nominationFlowLbl.nomineeTypeChange} ${baseChangeTypeLabel}`;
  const changeTypeDialogSubtitle = isDesktop
    ? `${baseChangeTypeSubtitle} <b>${baseChangeTypeLabel}.</b>`
    : baseChangeTypeSubtitle;
  const changeTypeDialogLabel = isDesktop ? '' : `${baseChangeTypeLabel}.`;

  const constructBackButtonLabelText = (): string => {
    if (isNomineeTypeSelectionPage) {
      return nominationFlowLbl.backToNomination;
    } else if (isNominationCartPage) {
      return nominationFlowLbl.backToSelect;
    } else if (isNominationOALEligibilityPage) {
      return nominationFlowLbl.backToCathay;
    } else if (isNominationReviewAndConfirmPage) {
      return nominationFlowLbl.backToInterline;
    } else if (isNominationDisclaimerPage) {
      return nominationFlowLbl.backToReview;
    }

    return '';
  };

  const backToPrevPageLabel = constructBackButtonLabelText();

  return (
    <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
      {isDesktop && (
        <>
          {isNominationViewModePage ? (
            <Box
              sx={{
                display: 'flex',
                background: 'white',
                px: 21,
                boxShadow: theme.boxShadow.important,
                height: UI_STYLES.homepageNavBarHeight,
                my: 'auto',
              }}
            >
              <Header
                iconColor={theme.color.utility.link.option_3}
                leftChild={
                  <Typography
                    variant="headline_bold"
                    color={theme.color.utility.link.option_3}
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => {
                      backButtonHandler();
                    }}
                  >
                    {en.userProfile.backToProfile}
                  </Typography>
                }
                handleOnBack={() => {
                  backButtonHandler();
                }}
              />
            </Box>
          ) : (
            <Box
              className="desktop_progressbar_container"
              sx={{
                background: 'white',
              }}
            >
              <Box
                className="desktop_progressbar_inner_container"
                sx={{ width: '100%', display: 'flex', pl: 22 }}
                borderBottom={`1px solid ${theme.color.secondary.dark_grey.option_7}`}
              >
                <DesktopProgressbar stepList={nominationProgressbarStepList} currentStep={routeToStep} />
              </Box>
            </Box>
          )}
        </>
      )}

      <Grid
        component={ScrollableView}
        ref={scrollViewRef}
        sx={{
          ...(isDesktop && {
            height: `calc(${screenHeight}px - ${
              isNominationViewModePage ? UI_STYLES.homepageNavBarHeight : UI_STYLES.overlayHeaderHeightSearchCriteria
            } - ${UI_STYLES.desktopHeaderHeight} )`,
          }),
        }}
        container
        px={{
          xs: 2,
          sm: 0,
        }}
      >
        <Grid container direction="column">
          {isDesktop ? (
            !isNominationViewModePage && (
              <Grid
                item
                className="desktop_nomination_flow_container_header"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    height: '44px',
                    mt: '16px',
                    width: '488px',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      backButtonHandler?.();
                    }}
                    sx={{ py: '13px' }}
                  >
                    <BackIcon fill={theme.color.utility.link.option_3} />
                    <Typography
                      variant="body_1_bold"
                      color={theme.color.utility.link.option_3}
                      sx={{ ml: 2.5, cursor: 'pointer' }}
                    >
                      {backToPrevPageLabel}
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
            )
          ) : (
            <Grid item className="mobile_nomination_flow_container_header" sx={{ mt: '6px', minHeight: '60px' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <IconButton
                  onClick={() => {
                    backButtonHandler?.();
                  }}
                  sx={{ py: '13px', pl: 0, mt: '8px' }}
                >
                  <BackIcon fill={theme.color.utility.link.option_3} />
                </IconButton>
                {!isNominationViewModePage && (
                  <Progressbar stepList={nominationProgressbarStepList} currentStep={routeToStep} />
                )}
              </Box>
            </Grid>
          )}

          <Grid
            className="nomination_flow_container_content"
            item
            pt={'16px'}
            pb={'16px'}
            sx={{
              ...(isDesktop && {
                display: 'flex',
                justifyContent: 'center',
              }),
            }}
          >
            <FlowContainer
              routePages={RoutePages}
              data={{
                backButtonHandler,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {footerObj && (
        <NomineeFooterComponent
          primaryBtn={{
            isFullWidth: footerObj.isFullWidth, //,!isDesktop,
            text: footerObj.text,
            customOnClick: footerObj.handleContinueClickAction,
            restProps: footerObj.restProps,
          }}
          {...(footerObj?.isShowSecondaryButton && {
            leftChild: (
              <FormButton
                theme={theme}
                colour="transparent"
                size="large"
                sx={{
                  height: '44px',
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: 0,
                }}
                onClick={footerObj.secondaryHandleContinueClickAction}
              >
                {footerObj.secondaryText}
              </FormButton>
            ),
          })}
        />
      )}

      <CancelDialog
        open={!!cancelNominationFlowDialogTriggeringPoint}
        showCancelIcon={false}
        handleConfirmAction={() => {
          if (
            // trigger by cancel button
            cancelNominationFlowDialogTriggeringPoint === CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT.cancelButton
          ) {
            // back to Nomination view mode page
            resetNominationFlowToViewModePageData();
            // re-load view mode api data
            setIsUpdatedDependentListByNominateApiResponse(false);
          }
        }}
        handleCloseDialog={() => {
          setNominationFlow({
            ...nominationFlow,
            cancelNominationFlowDialogTriggeringPoint: '',
          });
        }}
        confirmToClose={false}
        IconComponent={<AlertIcon />}
        dialogContent={{
          title: nominationFlowLbl.cancelNominationFlowDialog.title,
          message: nominationFlowLbl.cancelNominationFlowDialog.description,
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.back,
        }}
      />

      <CancelDialog
        open={isTriggerChangeTypeDialog}
        showCancelIcon={false}
        handleConfirmAction={() => {
          setNominationFlow({
            ...nominationFlow,
            isTriggerChangeTypeDialog: false,
            selectedOALEligibilityDependentId: '',
            nominationFlowRouteTo: {
              step: NOMINATION_FLOW_PAGE_NO.nominationOALEligibility,
            },
          });
        }}
        handleCloseDialog={() => {
          setNominationFlow({
            ...nominationFlow,
            isTriggerChangeTypeDialog: false,
          });
        }}
        confirmToClose={false}
        dialogContent={{
          title: changeTypeDialogTitle,
          message: (
            <ChangeTypeDialogMessageComponent
              {...{
                changeTypeDialogSubtitle,
                changeTypeDialogLabel,
                changeTypeContent: nominationFlowLbl.changeTypeContent,
              }}
            />
          ),
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.back,
        }}
      />
    </Div100vhView>
  );
};

export default NominationFlowContainer;
