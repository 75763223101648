import React, { CSSProperties } from 'react';
import { Typography, useTheme } from '@mui/material';
import en from '../../../translations/en';
import { PARAGRAPH_TYPE } from '../../../constants/constants';

import { IReplaceContentItemProps, IParagraphVariantProps } from './ParagraphMasterContainer';

const StyledLinkText = ({
  displayText,
  link,
  textType,
  color = useTheme().color.utility.link.option_3,
  paragraphVariant,
}: {
  displayText: string;
  link: string;
  textType: string;
  color?: CSSProperties['color'];
  paragraphVariant?: IParagraphVariantProps['paragraphVariant'];
}) => {
  return (
    <Typography
      variant={paragraphVariant || 'body_2_regular'}
      sx={{
        display: 'inline',
        cursor: 'pointer',
        color,
      }}
      onClick={() => {
        if (textType === PARAGRAPH_TYPE.link.toLowerCase()) {
          window.open(link);
        } else if (textType === PARAGRAPH_TYPE.email.toLowerCase()) {
          window.location.href = en.multipleWarning.mailto + link;
        }
      }}
    >
      {displayText}
    </Typography>
  );
};

const InsertContentPattern = ({
  splitedWarning,
  matchObjs,
  paragraphVariant,
}: {
  splitedWarning: string[];
  matchObjs: IReplaceContentItemProps[];
  paragraphVariant?: IParagraphVariantProps['paragraphVariant'];
}) => {
  return splitedWarning?.map((parts: string, index: number) => {
    const specialContent = matchObjs?.find((matchObj: IReplaceContentItemProps) => matchObj.matchText === parts);

    if (specialContent) {
      // handle link styled text
      if (
        [PARAGRAPH_TYPE.link.toLowerCase(), PARAGRAPH_TYPE.email.toLowerCase()].includes(specialContent.type) &&
        specialContent.link
      ) {
        return (
          <StyledLinkText
            key={index}
            displayText={specialContent.displayText}
            link={specialContent.link}
            textType={specialContent.type}
            paragraphVariant={paragraphVariant}
          />
        );
      }

      // handle bold style text
      if (specialContent.type === PARAGRAPH_TYPE.bold.toLowerCase()) {
        return (
          <Typography
            key={index}
            variant={paragraphVariant || 'body_2_bold'}
            sx={{
              display: 'inline',
            }}
          >
            {specialContent.displayText}
          </Typography>
        );
      }
    } else {
      return <span key={index}>{parts}</span>;
    }
  });
};

export default InsertContentPattern;
