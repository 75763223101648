import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import {
  IParagraphSxProps,
  IReplaceContentProps,
  IContentProps,
  IParagraphVariantProps,
} from './ParagraphMasterContainer';

import DefaultParagraph from './DefaultParagraph';

interface ISingleParagraphProps extends IParagraphSxProps, IParagraphVariantProps, IReplaceContentProps {
  singleContent?: IContentProps;
  paragraphTypoSxProps?: SxProps<Theme>;
}

const SingleParagraph = ({
  singleContent,
  replaceContent,
  paragraphSxProps,
  paragraphVariant,
  paragraphTypoSxProps,
}: ISingleParagraphProps) => {
  if (!singleContent?.text) return <></>;
  return (
    <Box className="single_paragraph" sx={{ pb: 2, ...paragraphSxProps }}>
      <DefaultParagraph
        text={singleContent.text}
        replaceContent={replaceContent}
        paragraphVariant={paragraphVariant}
        paragraphTypoSxProps={paragraphTypoSxProps}
      />
    </Box>
  );
};

export default SingleParagraph;
