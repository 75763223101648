import React, { useState } from 'react';
import { InputLabelProps, Box } from '@mui/material';

import theme from '../../../style/theme';

import { IBookingFlowOverlayData } from '../../../interfaces';

import { TextInput, DrawerOverlay } from '../../../components';
import { LeisureTravellerCount } from '../../../containers';

const MobileSearchCriteriaButton = ({
  label,
  inputRef,
  value,
  shrink,
  travellerCount,
  title,
  component,
  data,
}: {
  label: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  value?: string;
  shrink?: Partial<InputLabelProps>;
  travellerCount?: number;
  title: string;
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  return (
    <Box
      className="mobile_btn"
      sx={{
        my: 1,
        display: 'flex',
        alignItems: 'center',
        background: theme.color.secondary.light_slate.option_6,
        borderRadius: '6px',
      }}
    >
      <DrawerOverlay
        showOverlay={showOverlay}
        handleClose={() => {
          setShowOverlay(false);
        }}
        title={title}
        component={component}
        data={data}
      />
      <TextInput
        variant="filled"
        label={label}
        color="info"
        value={value}
        onClick={() => setShowOverlay(true)}
        inputRef={inputRef}
        InputLabelProps={shrink}
        autoComplete="off"
        sx={{
          width: '100%',
          m: 0,
        }}
        inputProps={{
          style: {
            ...theme.typography.body_1_medium,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: theme.color.secondary.dark_grey.option_3,
          },
          readOnly: true,
        }}
      />
      {!!travellerCount && (
        <Box sx={{ mr: 2 }}>
          <LeisureTravellerCount handleClick={() => setShowOverlay(true)} travellerCount={travellerCount} />
        </Box>
      )}
    </Box>
  );
};

MobileSearchCriteriaButton.defaultProps = {
  travellerCount: undefined,
};

export default MobileSearchCriteriaButton;
