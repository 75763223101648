import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import _ from 'lodash';

import { DeleteIcon, EditIcon } from '../../../assets/images';

import { ADMIN_ACTION_TYPE } from '../../../constants/constants';
import en from '../../../translations/en';

import { IAdminFlownSuspension } from '../../../interfaces';
import { AdminAccessEmployeeAction, AdminAccessEmployeeState } from '../../../context';

import { formatDMYDateWithSpace } from '../../../helpers';

import { BaseTable } from '../../../components';
import { AdminFlownSuspensionPopup } from '.';

const AdminOperateComponent = ({
  isAllowEdit,
  isAllowDelete,
  editAction,
  deleteAction,
}: {
  isAllowEdit: boolean;
  isAllowDelete: boolean;
  editAction: () => void;
  deleteAction: () => void;
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {isAllowEdit && (
        <IconButton sx={{ cursor: 'pointer' }} onClick={editAction}>
          <EditIcon height="35px" width="35px" />
        </IconButton>
      )}
      {isAllowDelete && (
        <IconButton sx={{ cursor: 'pointer' }} onClick={deleteAction}>
          <DeleteIcon height="35px" width="35px" />
        </IconButton>
      )}
    </Box>
  );
};

const AdminFlownSuspensionList = () => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.personalInformation.flownSuspensionDetails;
  const { viewLess, viewMore } = en.booking.nonEnglishCharacter;

  const [flownSuspensionData, setFlownSuspensionData] = useState<IAdminFlownSuspension[]>([]);
  const [isExpand, setIsExpand] = useState(false);
  const [editFlownSuspensionId, setEditFlownSuspensionId] = useState<string>('');
  const [cloneFlownSuspensions, setCloneFlownSuspensions] = useState<IAdminFlownSuspension[]>([]);

  const { isEditingAdminProfile, employeeDetail, editEmployeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { setEditEmployeeDetail } = useContext(AdminAccessEmployeeAction) || {};
  const { flownSuspensionDetails } = employeeDetail || {};
  const { flownSuspension: editFlownSuspension } = editEmployeeDetail || {};

  const tableSchema = [
    {
      key: 'flownSuspensionStartDate',
      isHiddenField: false,
    },
    {
      key: 'flownSuspensionEndDate',
      isHiddenField: false,
    },
    {
      key: 'reason',
      isHiddenField: false,
    },
    {
      key: 'status',
      isHiddenField: false,
      sxProps: {
        pl: 15,
        maxWidth: 230,
      },
    },
    {
      key: 'action',
      isHiddenField: false,
      sxProps: {
        maxWidth: 100,
      },
    },
  ];

  const handleUpdateFlownSuspension = ({
    updateType,
    updateFlownSuspensionId,
    setFlownSuspensionStartDate,
    setFlownSuspensionEndDate,
  }: {
    updateType: string;
    updateFlownSuspensionId: string;
    setFlownSuspensionStartDate: string;
    setFlownSuspensionEndDate: string;
  }) => {
    const updateFlownSuspension = [...cloneFlownSuspensions];
    // update set start date and end date value
    updateFlownSuspension.forEach((item: IAdminFlownSuspension) => {
      if (updateFlownSuspensionId === item.flownSuspensionId) {
        if (updateType === ADMIN_ACTION_TYPE.DELETE) {
          item.isDeleted = true;
        } else if (updateType === ADMIN_ACTION_TYPE.EDIT) {
          item.isDeleted = false;
          item.setFlownSuspensionStartDate = setFlownSuspensionStartDate;
          item.setFlownSuspensionEndDate = setFlownSuspensionEndDate;
        }
      }
    });

    setCloneFlownSuspensions(updateFlownSuspension);
    setEditEmployeeDetail({
      ...editEmployeeDetail,
      editFlownSuspensionList: updateFlownSuspension,
    });
  };

  const isEmptyFlownSuspension = useMemo(() => {
    return _.isEmpty(flownSuspensionDetails);
  }, [flownSuspensionDetails]);

  const isShowViewButton = useMemo(() => {
    return flownSuspensionDetails?.length >= 2;
  }, [flownSuspensionDetails]);

  const isShowAddFlownPeriodNote = useMemo(() => {
    return (
      !_.isEmpty(editFlownSuspension?.flownSuspensionStartDate) ||
      !_.isEmpty(editFlownSuspension?.flownSuspensionEndDate)
    );
  }, [editFlownSuspension]);

  useEffect(() => {
    setCloneFlownSuspensions(flownSuspensionDetails);
  }, [flownSuspensionDetails]);

  useEffect(() => {
    if (!isEmptyFlownSuspension) {
      if (editEmployeeDetail?.editFlownSuspensionList) {
        setFlownSuspensionData(
          editEmployeeDetail?.editFlownSuspensionList.filter(
            (item: IAdminFlownSuspension) => !item.isDeleted,
          ) as IAdminFlownSuspension[],
        );
      } else {
        setFlownSuspensionData(flownSuspensionDetails as IAdminFlownSuspension[]);
      }
    }

    // clear deleted records & edited records of flown suspension when clicked quit without saving
    if (!editEmployeeDetail?.editFlownSuspensionList) {
      const cleanFlownSuspensionList: IAdminFlownSuspension[] = flownSuspensionDetails?.map(
        (item: IAdminFlownSuspension) => {
          return {
            ...item,
            isDeleted: false,
            setFlownSuspensionStartDate: item.flownSuspensionStartDate,
            setFlownSuspensionEndDate: item.flownSuspensionEndDate,
          };
        },
      );
      setFlownSuspensionData(cleanFlownSuspensionList);
    }
  }, [flownSuspensionDetails, isExpand, editEmployeeDetail]);

  const getTableContentValue = (tableHeader: string, dataItem: IAdminFlownSuspension) => {
    switch (tableHeader) {
      case 'flownSuspensionStartDate':
        return isEditingAdminProfile
          ? formatDMYDateWithSpace(dataItem?.setFlownSuspensionStartDate ?? dataItem?.flownSuspensionStartDate)
          : formatDMYDateWithSpace(dataItem?.flownSuspensionStartDate);
      case 'flownSuspensionEndDate':
        return isEditingAdminProfile
          ? formatDMYDateWithSpace(dataItem?.setFlownSuspensionEndDate ?? dataItem?.flownSuspensionEndDate)
          : formatDMYDateWithSpace(dataItem?.flownSuspensionEndDate);
      case 'reason':
        return dataItem?.reason;
      case 'status':
        return dataItem?.status;
      case 'action':
        return isEditingAdminProfile ? (
          <AdminOperateComponent
            isAllowEdit={dataItem?.isAllowEdit}
            isAllowDelete={dataItem?.isAllowDelete}
            editAction={() => {
              setEditFlownSuspensionId(dataItem?.flownSuspensionId);
            }}
            deleteAction={() => {
              handleUpdateFlownSuspension({
                updateType: ADMIN_ACTION_TYPE.DELETE,
                updateFlownSuspensionId: dataItem?.flownSuspensionId,
                setFlownSuspensionStartDate: '',
                setFlownSuspensionEndDate: '',
              });
            }}
          />
        ) : undefined;

      default:
        return en.common.empty;
    }
  };

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          px: 0.5,
          width: 'auto',
          flex: 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
          ...item.sxProps,
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [flownSuspensionData]);

  const transformedTableContents = useMemo(() => {
    const cloneFlownSuspensionData = [...flownSuspensionData];
    const tmpData = isExpand ? cloneFlownSuspensionData : cloneFlownSuspensionData.slice(0, 1);

    return tmpData?.map((dataItem: IAdminFlownSuspension) => {
      return {
        sxProps: {
          display: 'flex',
          position: 'relative',
        },
        hover: false,
        columns: tableSchema.map(({ key, isHiddenField }) => {
          const newValue = getTableContentValue(key, dataItem);

          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              py: 1.25,
              px: 0.5,
              overflow: 'hidden',
              lineHeight: '30px',
              ...(key === 'status' && {
                pl: 15,
                maxWidth: 230,
              }),
              ...(key === 'action' && {
                maxWidth: 100,
                justifyContent: 'end',
              }),
              ...(!isShowViewButton && {
                borderBottomColor: 'transparent',
              }),
            },
            isHiddenField,
          };
        }),
      };
    });
  }, [flownSuspensionData, isEditingAdminProfile, isShowViewButton, isExpand]);

  return (
    <Box
      className="admin_flown_suspension_list"
      sx={{
        mt: 1,
        width: '100%',
      }}
    >
      {isShowAddFlownPeriodNote && (
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Typography variant="body_2_bold" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {fieldLabel.addFlownNote}
          </Typography>
        </Box>
      )}

      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />

      {/* no flown suspension record */}
      {isEmptyFlownSuspension && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography variant="body_2_bold" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
            {fieldLabel.NoFlownSuspensionRecord}
          </Typography>
        </Box>
      )}

      {isShowViewButton && (
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
          <Typography
            variant="body_2_bold"
            sx={{
              color: theme.color.utility.link.option_3,
              cursor: 'pointer',
            }}
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? viewLess : viewMore}
          </Typography>
        </Box>
      )}

      {editFlownSuspensionId && !_.isEmpty(flownSuspensionData) && (
        <AdminFlownSuspensionPopup
          setEditFlownSuspensionId={setEditFlownSuspensionId}
          data={
            flownSuspensionData.filter(
              (item: IAdminFlownSuspension) => editFlownSuspensionId === item.flownSuspensionId,
            )[0]
          }
          handleConfirmAction={(updateFlownSuspensionId, setFlownSuspensionStartDate, setFlownSuspensionEndDate) => {
            handleUpdateFlownSuspension({
              updateType: ADMIN_ACTION_TYPE.EDIT,
              updateFlownSuspensionId,
              setFlownSuspensionStartDate,
              setFlownSuspensionEndDate,
            });
          }}
        />
      )}
    </Box>
  );
};

export default AdminFlownSuspensionList;
