import { LeisureTravelBackgroundLessIcon, DutyTravelBackgroundLessIcon } from '../assets/images';
import en from '../translations/en';

const ETP_CLIENT_VER = 2.3;
const ETP_CLIENT_ID_KEY = 'ETP_CLIENT_ID';

const booking = en.booking;

enum ID_TICKET_TYPE {
  NOSUB = 'NOSUB',
  SUBLO = 'SUBLO',
}
const ID_TICKET_TYPE_MAP_TRANSLATION = {
  NOSUB: 'noSubLoad',
  SUBLO: 'subLoad',
};

enum CARRIER_OPTION {
  CX = 'CX',
  ALL = 'ALL',
  ONEWORLD = 'ONEWORLD',
  OTHER_CARRIER = 'OTHER_CARRIER',
  ALL_CARRIER = 'ALL_CARRIER',
  OTHER = 'OTHER',
}

enum OWOAL_TYPE {
  OW = 'OW',
  OAL = 'OAL',
}

// Map with en.ts
const CARRIER_OPTION_MAP_TRANSLATION = {
  CX: 'cx',
  ALL: 'all',
  ONEWORLD: 'oneWorld',
  OTHER_CARRIER: 'otherCarrier',
  ALL_CARRIER: 'allCarrier',
  OTHER: 'other',
};

// for search backend API pararm - `type`
const LT_SEARCH_FLIGHT_OPTION = {
  CX: 'cx',
  OTHER_CARRIER: 'other',
  ALL_CARRIER: 'all',
};

// for ZONE
const CARRIER_LIST = [
  {
    value: CARRIER_OPTION.CX,
  },
  {
    value: CARRIER_OPTION.OTHER_CARRIER,
  },
  {
    value: CARRIER_OPTION.ALL_CARRIER,
  },
];

const NOT_APPLICABLE = 'N/A';

const USER_ROLE = {
  self: 'SELF',
  admin: 'ADMIN',
  delegation: 'DELEGATION',
  system: 'SYSTEM',
};

const USER_PROFILE_TYPE = {
  retiree: 'RETIREE',
  widow: 'WIDOW',
  retireeSpecialProfile: 'RETIREE_SPECIAL_PROFILE',
};

const TRAVELLER_TYPE = {
  employee: 'EMP',
  nonEmployee: 'NON-EMP',
  both: 'BOTH',
};

const TRAVELLER_INFO = {
  type: TRAVELLER_TYPE.employee,
  employee: {
    ern: '',
    name: '',
  },
  salutation: null,
  firstName: '',
  lastName: '',
};

const SALUTATION = [
  {
    code: 'Mr',
    label: 'Mr',
  },
  {
    code: 'Mrs',
    label: 'Mrs',
  },
  {
    code: 'Ms',
    label: 'Ms',
  },
  {
    code: 'Miss',
    label: 'Miss',
  },
];

const CONCESSION_GROUPING = [
  {
    code: 'All',
    label: 'All',
    value: '',
  },
  {
    code: 'Individual',
    label: 'Individual',
    value: 'I',
  },
  {
    code: 'Pool',
    label: 'Pool',
    value: 'P',
  },
];

const REMOVE_DIALOG_TYPE = {
  concession: 'concession',
  traveller: 'traveller',
};

const ADMIN_CONCESSION = {
  ALL: 'All',
  EMPLOYEE: 'Employee',
  EMP: 'EMP',
  DISPLAY_NAME: 'displayName',
};

const ADMIN_HISTORY_REPORT_ALL_TYPE = [
  // pass "ALL" to backend, frontend display "All"
  {
    category: 'All',
    code: 'ALL',
    label: 'All',
  },
];

const UI_STYLES = {
  navBarHeight: '72px',
  homepageNavBarHeight: '80px',
  desktopHeaderHeight: '72px',
  desktopHeaderNavWidth: '534px',
  desktopReferenceHeaderHeight: '80px',
  desktopFooterHeight: '76px',
  desktopBookingDetailsWidth: '478px',
  footerHeight: '76px',
  overlayHeaderHeightSearchCriteria: '56px',
  overlayHeaderHeightBookingFlow: '115px',
  overlayHeaderHeightBookingCompleted: '240px',
  overlayEditHeader: '143px',
  searchBarHeight: '136px',
  searchBarInputOverlayHeight: '424px',
  searchBarInputOverlayMinHeight: '310px',
  searchBarInputOverlayMinWidth: '672px',
  searchBarDatePickerOverlayHeight: '360px',
  searchBarResultPageHeight: '80px',
  indicatorBarHeight: '40px',
  mobileHeaderMarginTopBookingCompleted: '50px',
  flightLoadBarWidth: '24px',
  flightLoadBarHeight: '36px',
  concessionTravellerHeight: '48px',
  adminMaintainWidth: '1112px',
  historyReportWidth: '1144px',
  flightInfoWidth: '343px',
  eligibleTravellerItemHeight: '76px',
  viewDetailsLeftWidth: '480px',
  retrieveAndPayViewDetailsLeftWidth: '320px',
};

const UI_METRICS_IN_PX = {
  mobileHeaderHeight: 210,
  scrollBuffer: 10,
  inputFieldDatePickerDisplayHeight: 715,
};

const DATE_FORMAT = {
  ddmmmyyyy: 'DD MMM YYYY',
  dddDDMMM: 'ddd, DD MMM',
  mmmmyyyy: 'MMMM yyyy',
  date: 'YYYY-MM-DD',
  dateTime: 'YYYY-MM-DD HH:mm',
  dateHourMin: 'DD MMM YYYY HH:mm',
  ddmmyyyy: 'DD/MM/YYYY',
  yyyymmdd_hhmmss: 'YYYYMMDD_HHmmss',
};

// Not for label display, for dayjs calculation used
const DATE_UNIT = {
  DYS: 'day',
  MON: 'month',
  YRS: 'year',
};

const DATE_UNIT_CODE = {
  day: 'DYS',
  month: 'MON',
  year: 'YRS',
};

const SEAT_CLASS = {
  first: 'F',
  business: 'J',
  premiumEcon: 'W',
  econ: 'Y',
};

const SEAT_CLASS_MAX_LOADING: {
  [key: string]: string;
} = {
  F: '4',
  J: '10',
  W: '10',
  Y: '40',
};

const FLIGHT_LOAD_STATUS = {
  green: 'GREEN',
  yellow: 'YELLOW',
  red: 'RED',
};

const BOOKING_CLASS = ['F', 'J', 'W', 'Y'];

const SEAT_CLASS_TYPE_MAPPING = {
  [SEAT_CLASS.first]: booking.flightClass.first,
  [SEAT_CLASS.business]: booking.flightClass.business,
  [SEAT_CLASS.premiumEcon]: booking.flightClass.premiumEconomy,
  [SEAT_CLASS.econ]: booking.flightClass.economy,
};

const SEAT_CLASS_OPTIONS = Object.values(SEAT_CLASS).map((seatClass) => {
  return { val: seatClass, title: SEAT_CLASS_TYPE_MAPPING[seatClass] };
});

const FLIGHT_AVAILABILITY_STATUS = {
  notAvailable: 'notAvailable',
  others: 'others',
  confirm: 'confirm',
  waitlist: 'waitlist',
};

const TRAVEL_PURPOSE = {
  employeeDutyTravel: 'EDT',
  employeeLeisureTravel: 'ELT',
  employeeOther: 'OTHER',
};

const TRAVEL_PURPOSE_TOGGLE = [
  {
    id: TRAVEL_PURPOSE.employeeLeisureTravel,
    val: TRAVEL_PURPOSE.employeeLeisureTravel,
    title: en.booking.travelType.leisureTravel.title,
    icon: LeisureTravelBackgroundLessIcon,
  },
  {
    id: TRAVEL_PURPOSE.employeeDutyTravel,
    val: TRAVEL_PURPOSE.employeeDutyTravel,
    title: en.booking.travelType.dutyTravel,
    icon: DutyTravelBackgroundLessIcon,
  },
  // {
  //   id: TRAVEL_PURPOSE.employeeOther,
  //   val: TRAVEL_PURPOSE.employeeOther,
  //   title: en.booking.travelType.other,
  // },
];

const TRAVEL_DETAIL_PURPOSE = [
  {
    code: booking.travelDetail.purposeOptions.meeting,
    label: booking.travelDetail.purposeOptions.meeting,
  },
  {
    code: booking.travelDetail.purposeOptions.training,
    label: booking.travelDetail.purposeOptions.training,
  },
  {
    code: booking.travelDetail.purposeOptions.familiarization,
    label: booking.travelDetail.purposeOptions.familiarization,
  },
  {
    code: booking.travelDetail.purposeOptions.conference,
    label: booking.travelDetail.purposeOptions.conference,
  },
  {
    code: booking.travelDetail.purposeOptions.project,
    label: booking.travelDetail.purposeOptions.project,
  },
];

const FORM = {
  travelDetails: 'TravelDetailsForm',
  travellerDetails: 'TravellerDetailsForm',
  passportDetails: 'PassportDetailsForm',
  contactDetails: 'ContactDetailsForm',
  usAddress: 'UsAddressForm',
  addDelegatee: 'AddDelegatee',
  ltSeatClassSelection: 'LTSeatClassSelection',
  ltAccompanyForm: 'LTAccompanyForm',
  companionPersonal: 'companionPersonalForm',
  companionPassport: 'companionPassportForm',
};

const REGEX = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  phoneNum: /[^0-9-]/g,
  numOrAlphaOrSpace: /[^a-zA-Z\s\d]/g,
  num: /[^\d]/g,
  alphaOrSpace: /[^a-zA-Z\s]/g,
  numOrAlpha: /[^a-zA-Z\d]/g,
  specificString: /%s/g,
  sixCharacters: /^[a-zA-Z\d]{6}$/,
  notEmpty: /.*[a-zA-Z]/g,
  alphabet: /^[a-zA-Z]+$/,
  alphabetWithSpace: /^(?!^\s*$)[A-Za-z\s]+$/,
};

const MANDATORY_BOOKING_FIELDS = {
  passport: ['dateOfBirth', 'gender', 'passportNumber', 'expiryDate', 'nationality', 'issuingCountry'],
  travelDetails: ['purpose', 'description', 'costCentre', 'accountCode'],
  usAddress: ['street', 'city', 'state', 'zipCode'],
  contactInformation: ['email', 'phoneNumber'],
};

const LABEL_CATEGORY = {
  bookingType: 'Booking Type',
  company: 'Company',
  gender: 'Gender',
  paymentConcept: 'Payment Concept',
  paymentMedium: 'Payment Medium',
  paypalStatus: 'PayPal Status',
  flightCardLabel: 'Flight Card Label', // [ETP-4562]
  prepaidStatus: 'Prepaid Status', // [ETP-4562]
  segmentStatus: 'Segment Status',
  ticketCouponStatus: 'Ticket Coupon Status',
  travelerType: 'Traveler Type',
  lounge: 'Lounge',
  adminNoteType: 'Admin Note Type',
  unit: 'Unit',
  title: 'Title',
  relationship: 'Relationship',
  nomineeType: 'Nominee type',
};

const BOOKING_STATUS = {
  booked: 'BOOKED',
  cancelled: 'CANCELLED',
  flown: 'FLOWN',
  modified: 'MODIFIED',
  protectedDifferentCarrier: 'PROTECTED_DIFFERENT_CARRIER',
  autoCancelBA: 'AUTO_CANCEL_BA',
  pending: 'PENDING',
  authPending: 'AUTH_PENDING',
  pendingSupport: 'PENDING_SUPPORT',
  retrieveAndPay: 'RETRIEVE_AND_PAY',
  notRefund: 'NOT_REFUND',
  refunded: 'REFUNDED',
  rebook: 'REBOOK',
  refund: 'REFUND',
  original: 'ORIGINAL',
  unsuccessful: 'UNSUCCESSFUL',
};

const BOOKING_FLOW_PAGE_NO = {
  searchResult: 0,
  seatClass: 1,
  travelDetailsInputPage: 2,
  confirmation: 3,
  completed: 4,
  viewBooking: 5,
  retrieveAndPayRepricing: 6,
};

const BOOKING_UNUC_TYPE = {
  noProtection: 'NO_PROTECTION',
  protected: 'PROTECTED',
  protectedDifferentCarrier: 'PROTECTED_DIFFERENT_CARRIER',
};

const ADMIN_BOOKING_SEARCH_TYPE = {
  default: 0,
  searchByRecLoc: 6,
  searchByErn: 7,
};

const USER_PROFILE_DETAILS = {
  width: '424px',
};

const SAVE_DATA_TO = {
  redux: 'REDUX',
  context: 'CONTEXT',
  useState: 'USE_STATE',
};

const INFINITE_DATE = '2099-12-31';

const GOOGLE_ANALYTICS_EVENTS = {
  category: {
    page: 'Page',
    searchFlow: 'Search Flow',
    bookingFlow: 'Booking Flow',
    manageBookingFlow: 'Manage Booking Flow',
    callingAPI: 'Calling API',
    callingAPIError: 'Error - Calling API',
    appError: 'Error - App',
  },
  action: {
    search: 'Search',
    editSearch: 'Edit Search',
    viewAll: 'View All',
    desktop: ' (Desktop)',
    mobile: ' (Mobile)',
  },
};

const NOMINATED_PASSENGER_AGE_TYPE = {
  adult: 'ADT',
  child: 'CHD',
  infant: 'INF',
  infantWithSeat: 'INS',
};

/* HARDCODED
related story: 1981.
*/
const HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS = 180;

enum PAYMENT_CONCEPT {
  PREPAID = 'PRP', // prepaid
  FLOWN = 'FWN', // flown
}

enum PAYMENT_MEDIUM {
  COST_CENTER_PAYMENT = 'CCP', // cost center payment
  DIRECT_DEBIT_PAYMENT = 'DDP', // direct debit payment (as PHUN just call it DD)
  PAYPAL_PAYMENT = 'PPP', // paypal payment
  MANUAL_PAYMENT = 'MLP', // manual payment
  CREDIT_CARD_PAYMENT = 'CRP', // credit card payment
}

enum PAYMENT_SETUP_STATUS {
  NOT_YET_SET_UP = 'NOT_YET_SET_UP',
  SUSPENDED = 'SUSPENDED', // [ETP-4526] for direct debit used
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  UNABLE_RETRIEVE = 'UNABLE_RETRIEVE',
}

enum PAYPAL_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

enum PREPAID_STATUS {
  NOT_PAID = 'NOT_PAID', // for LT review & confirm page > Payment status label display
  NOT_REFUND = 'NOT_REFUND',
  REFUNDED = 'REFUNDED',
  REBOOK = 'REBOOK',
  RETRIEVE_AND_PAY = 'RETRIEVE_AND_PAY',
  AUTH_PENDING = 'AUTH_PENDING',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  PAID = 'PAID', // status after RETRIEVE_AND_PAY paid

  // [ETP-4379] relate with backend CPP part
  CAPTURE_PENDING = 'CAPTURE_PENDING',
  CAPTURE_SUCCESS = 'CAPTURE_SUCCESS',
  CAPTURE_FAILED = 'CAPTURE_FAILED',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCEL_SUCCESS = 'CANCEL_SUCCESS',
  CANCEL_FAILED = 'CANCEL_FAILED',
}

const RETRIEVE_AND_PAY_COMPLETED_ISSUED_TICKETS_STATUS = [
  PREPAID_STATUS.CAPTURE_PENDING,
  PREPAID_STATUS.CAPTURE_SUCCESS,
  PREPAID_STATUS.CAPTURE_FAILED,
];

const PREPAID_LABELS_STATUS = [
  // prepaid status in configuration labels
  PREPAID_STATUS.NOT_REFUND,
  PREPAID_STATUS.REFUNDED,
  PREPAID_STATUS.PAID,
  PREPAID_STATUS.RETRIEVE_AND_PAY,
];

enum PREPAID_PAYMENT_STATUS {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  PENDING_FOR_REFUND = 'PENDING_FOR_REFUND',
}

const PAYMENT = {
  fieldKey: {
    paymentConcept: 'paymentConcept',
    paymentMedium: 'paymentMedium',
    paypalStatus: 'paypalStatus',
  },
  type: {
    paypal: 'PAYPAL',
    manual: 'MANUAL',
    directDirect: 'PAYMENT',
  },
  paymentConcept: {
    flown: PAYMENT_CONCEPT.FLOWN,
    prepaid: PAYMENT_CONCEPT.PREPAID,
  },
  paymentMedium: {
    costCenter: PAYMENT_MEDIUM.COST_CENTER_PAYMENT,
    directDebit: PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT,
    paypal: PAYMENT_MEDIUM.PAYPAL_PAYMENT,
    manual: PAYMENT_MEDIUM.MANUAL_PAYMENT,
    creditCard: PAYMENT_MEDIUM.CREDIT_CARD_PAYMENT,
  },
  paymentHistory: {
    minDateString: '2013-01-01',
    paypalStatus: {
      pending: PAYPAL_STATUS.PENDING,
      success: PAYPAL_STATUS.SUCCESS,
      fail: PAYPAL_STATUS.FAIL,
    },
  },
  paymentStatusValue: {
    empty: null,
    yes: 'Y',
    no: 'N',
  },
};

const HKG = 'HKG';

const SEGMENT_STATUS = {
  // [ETP-4804] Segment status not allow retrieve and pay
  confirmingFromWaitlist: 'KL',
  confirmedCheckTime: 'TK',
  waitlistedCheckTime: 'TL',
};

const NOT_ALLOW_RETRIEVE_AND_PAY_SEGMENT_STATUS = [
  SEGMENT_STATUS.confirmingFromWaitlist,
  SEGMENT_STATUS.confirmedCheckTime,
  SEGMENT_STATUS.waitlistedCheckTime,
];

const TRAVEL_TYPES = {
  ZONE: 'ZONE', // LT ZONE, including: CX, OW/OAL.
  FOC: 'FOC', // LT FOC
  ID50: 'ID50', // LT ID50
  OTHER: 'OTHER', //LT OTHER
};

const FARE_TYPES = {
  ZED: 'ZED', // LT ZONE CX/OAL
  ID: 'ID', // LT FOC/ID50
  FOC: 'FOC', // DT
};

const FARE_DISCOUNTS = {
  ID50: 50, // LT ID50
  FOC: 100, // LT FOC
};

const DEFAULT_ZONE_CX_CARRIER = {
  id: '-1', // if 0 , will auto select ZONE concession
  type: '', // ZONE, FOC, ID50...etc
  fareType: FARE_TYPES.ZED, // ZED, ID...etc
  sub: ID_TICKET_TYPE.SUBLO, // default SUBLO
  carrier: '',
  carrierGroup: '',
  adultCount: 1,
  childrenCount: 0,
  infantsCount: 0,
  infantsWithoutSeatCount: 0,
};

const ACKNOWLEDGE_FLOWN_SUSPENSION = 'ACKNOWLEDGE_FLOWN_SUSPENSION';

enum BOOKING_FOOTER_ACTION {
  cancel = 'CANCEL_BOOKING',
  rebook = 'REBOOK',
  refund = 'REFUND',
  remove = 'REMOVE_PAX',
  retrieveAndPay = 'RETRIEVE_AND_PAY',
  confirmAndPay = 'ConfirmAndPay',
}

enum CANCEL_BOOKING_ACTION {
  cancelBookingAndTicket = 'CANCEL_BOOKING_AND_TICKET',
  cancelBooking = 'CANCEL_BOOKING',
  cancelTicket = 'CANCEL_TICKET',
  // TODO: After discussion with BU on 8 Aug 2023 meeting, no refund related button in view booking later on, refund related button will on payment history page
  // refund = 'REFUND',
  cancelAndRefund = 'CANCEL_AND_REFUND',
}

enum LABEL_VALUE_TYPE {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  COUNTRIES = 'COUNTRIES',
  CODE_LABELS = 'CODE_LABELS',
  CONFIDENTIAL = 'CONFIDENTIAL',
  FORM_SELECT = 'FORM_SELECT',
  FORM_DATE_PICKER = 'FORM_DATE_PICKER',
  FORM_FIELD_TEXT_INPUT = 'FORM_FIELD_TEXT_INPUT',
}

const MISSING_INFO = 'MISSING_INFO';

enum ALLOTMENT_TYPE {
  I = 'I',
  P = 'P',
}

enum STFD_TYPE {
  ENTITLEMENT = 'ENTITLEMENT',
  AS_BOOKED = 'AS BOOKED',
}

const AIRPORT_CODE = {
  hongKong: 'HKG',
};

// Can add new type when wanna to reuse the multiple warning component
const MULTIPLE_WARNING_TYPE = {
  DTSecurityReminder: 'DT_SECURITY_REMINDER',
  otherAirline: 'OTHER_AIRLINE',
};

const ADMIN_PERSONALINFO_SELECT_TYPE = {
  paymentConcept: 'paymentConcept',
  paymentMedium: 'paymentMedium',
  paymentStatus: 'paymentStatus',
  benefitDTJobLevel: 'benefitDTJobLevel',
  effectiveFromDateDuty: 'effectiveFromDateDuty',
  effectiveToDateDuty: 'effectiveToDateDuty',
  benefitLTJobLevel: 'benefitLTJobLevel',
  effectiveFromDateLeisure: 'effectiveFromDateLeisure',
  effectiveToDateLeisure: 'effectiveToDateLeisure',
  flownSuspension: 'flownSuspension',
  flownSuspensionStartDate: 'flownSuspensionStartDate',
  flownSuspensionEndDate: 'flownSuspensionEndDate',
  travelHistoryReportFrom: 'travelHistoryReportFrom',
  travelHistoryReportTo: 'travelHistoryReportTo',
};

const ADMIN_PERSONALINFO_INPUT_TYPE = {
  yearOfService: 'yearOfService',
};

const PAYMENT_STATUS_OPTIONS = [
  {
    code: en.common.empty,
    label: en.common.empty, // This 'label' is used to display ui.
    value: null, // This 'value' is used to transform the data of the backend.
  },
  {
    code: en.common.yes,
    label: en.common.yes,
    value: 'Y',
  },
  {
    code: en.common.no,
    label: en.common.no,
    value: 'N',
  },
];

const BENEFIT_JOB_LEVEL_OPTIONS = [
  {
    code: en.common.empty,
    label: en.common.empty,
  },
  {
    code: 'A',
    label: 'A',
  },
  {
    code: 'B',
    label: 'B',
  },
  {
    code: 'C',
    label: 'C',
  },
  {
    code: 'D',
    label: 'D',
  },
  {
    code: 'E',
    label: 'E',
  },
  {
    code: 'Z',
    label: 'Z',
  },
];

enum ADMIN_ACTION_TYPE {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

enum REFUND_TYPE {
  FULL_REFUND = 'FULL_REFUND',
  DOWNGRADE_REFUND = 'DOWNGRADE_REFUND',
}

// [ETP-4379] prepaidAuthFlowType for transit page use
enum PREPAID_AUTH_FLOW_TYPE {
  NORMAL_PREPAID = 'NORMAL_PREPAID',
  RETRIEVE_AND_PAY = 'RETRIEVE_AND_PAY',
}

// [ETP-4622] nomination companion registration flow
const COMPANION_REGISTRATION_FLOW_PAGE_NO = {
  acknowledgePolicy: 0,
  personal: 1,
  passport: 2,
  reviewAndConfirm: 3,
  nominationPolicy: 4,
  completed: 5,
};

// [ETP-3868] initialize edit companion passport
const INIT_EDIT_COMPANION_PASSPORT_CONTEXT = {
  isOpenCancelEditCompanionPassportDialog: false,
  isEditMode: false,
  isPassportInputValid: false,
  dependentId: '',
  initFormData: {
    passportNumber: '',
    passportFirstName: '',
    passportLastName: '',
    passportExpirationDate: '',
    passportNationality: '',
    passportCountry: '',
  },
  // for API used
  requestParams: {
    dependentId: '',
    passportNumber: '',
    passportExpirationDate: '',
    passportNationality: '',
    passportCountry: '',
  },
  // show toast message after edit companion passport success
  isTriggerEditCompanionPassportToast: false,
};

// [ETP-4717] nomination companion registration flow > handle relationship "Others" field
const RELATIONSHIP_OTHERS_CODE = 'OTH';

// Paragraph constants
const PARAGRAPH_TYPE = {
  bulletPoints: 'BULLET_POINTS',
  link: 'LINK',
  email: 'EMAIL',
  bold: 'BOLD',
};

// [ETP-4644] nomination flow
const NOMINATION_FLOW_PAGE_NO = {
  nominationViewMode: 0,
  selectNominationType: 1,
  nominationCart: 2,
  nominationOALEligibility: 3,
  nominationReviewAndConfirm: 4,
  nominationDisclaimer: 5,
};

const NOMINATION_TYPE = {
  plusOne: 'plusOne',
  travelNominees: 'travelNominees',
};

const NOMINATION_TYPE_TO_BACKEND = {
  plusOne: 'PLUS_ONE',
  travelNominees: 'TRAVEL_NOMINEE',
  autoNominated: 'AUTO_NOMINATED',
};

const NOMINATION_SUB_ROUTE_PAGE = {
  nominatedAndToBeAddedDependentsInCart: 'nominatedAndToBeAddedDependentsInCart',
  notNominatedDependentsSelection: 'notNominatedDependentsSelection',
};

// generic type for display cart item
const CART_TYPE = {
  dependent: 'dependent',
  message: 'message',
};
const DIRECT_DEBIT_STATUS = ['Y', 'N'];

const BENEFICIARY_TYPE_CODE = {
  employee: 'EMP',
  spouse: 'SPO',
  children: 'CHD',
  companion: 'FRD',
  iFlyEmployee: 'SEL',
  iFlySeniorChildren: 'SCD',
};

const NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE = [
  BENEFICIARY_TYPE_CODE.spouse,
  BENEFICIARY_TYPE_CODE.companion,
];

// etp-4615 add: for oal eligibility page [spo,frd] sorting: spo first, then frd
const NOMINATION_OAL_ELIGIBILITY_SPO_FRD_SORT = [BENEFICIARY_TYPE_CODE.spouse, BENEFICIARY_TYPE_CODE.companion];

// [ETP-4751] triggering point for the cancellation prompt in the nomination flow
const CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT = {
  cancelButton: 'CANCEL_BUTTON',
};

// [ETP-4646] display allotment status label in Nomination view mode dependent
const NOMINATION_DEPENDENT_REALLOTMENT_STATUS = {
  new: 'NEW',
  error: 'ERROR',
  done: 'DONE',
};

const LOGIN_MODE = {
  key: 'LOGIN_MODE',
  values: {
    assoSubsid: 'assoSubsid',
  },
};

export {
  ETP_CLIENT_VER,
  ETP_CLIENT_ID_KEY,
  CARRIER_OPTION_MAP_TRANSLATION,
  CARRIER_LIST,
  ID_TICKET_TYPE,
  ID_TICKET_TYPE_MAP_TRANSLATION,
  CARRIER_OPTION,
  OWOAL_TYPE,
  UI_STYLES,
  DATE_FORMAT,
  DATE_UNIT,
  DATE_UNIT_CODE,
  SEAT_CLASS_OPTIONS,
  FLIGHT_AVAILABILITY_STATUS,
  TRAVEL_PURPOSE,
  TRAVEL_PURPOSE_TOGGLE,
  TRAVEL_DETAIL_PURPOSE,
  FORM,
  REGEX,
  UI_METRICS_IN_PX,
  MANDATORY_BOOKING_FIELDS,
  SEAT_CLASS_TYPE_MAPPING,
  SEAT_CLASS,
  BOOKING_CLASS,
  LABEL_CATEGORY,
  BOOKING_FLOW_PAGE_NO,
  BOOKING_STATUS,
  BOOKING_UNUC_TYPE,
  USER_ROLE,
  USER_PROFILE_TYPE,
  TRAVELLER_TYPE,
  TRAVELLER_INFO,
  SALUTATION,
  CONCESSION_GROUPING,
  REMOVE_DIALOG_TYPE,
  ADMIN_BOOKING_SEARCH_TYPE,
  USER_PROFILE_DETAILS,
  INFINITE_DATE,
  SAVE_DATA_TO,
  NOT_APPLICABLE,
  ADMIN_CONCESSION,
  ADMIN_HISTORY_REPORT_ALL_TYPE,
  GOOGLE_ANALYTICS_EVENTS,
  NOMINATED_PASSENGER_AGE_TYPE,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  PAYMENT,
  SEAT_CLASS_MAX_LOADING,
  FLIGHT_LOAD_STATUS,
  PAYMENT_CONCEPT,
  PAYMENT_MEDIUM,
  PAYMENT_SETUP_STATUS,
  PAYPAL_STATUS,
  PREPAID_STATUS,
  RETRIEVE_AND_PAY_COMPLETED_ISSUED_TICKETS_STATUS,
  PREPAID_LABELS_STATUS,
  PREPAID_PAYMENT_STATUS,
  HKG,
  TRAVEL_TYPES,
  FARE_TYPES,
  FARE_DISCOUNTS,
  LABEL_VALUE_TYPE,
  DEFAULT_ZONE_CX_CARRIER,
  BOOKING_FOOTER_ACTION,
  CANCEL_BOOKING_ACTION,
  ACKNOWLEDGE_FLOWN_SUSPENSION,
  LT_SEARCH_FLIGHT_OPTION,
  MISSING_INFO,
  ALLOTMENT_TYPE,
  STFD_TYPE,
  AIRPORT_CODE,
  MULTIPLE_WARNING_TYPE,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  ADMIN_PERSONALINFO_INPUT_TYPE,
  PAYMENT_STATUS_OPTIONS,
  BENEFIT_JOB_LEVEL_OPTIONS,
  ADMIN_ACTION_TYPE,
  REFUND_TYPE,
  PREPAID_AUTH_FLOW_TYPE,
  COMPANION_REGISTRATION_FLOW_PAGE_NO,
  INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
  RELATIONSHIP_OTHERS_CODE,
  PARAGRAPH_TYPE,
  NOMINATION_FLOW_PAGE_NO,
  NOMINATION_TYPE,
  NOMINATION_TYPE_TO_BACKEND,
  NOMINATION_SUB_ROUTE_PAGE,
  CART_TYPE,
  DIRECT_DEBIT_STATUS,
  NOT_ALLOW_RETRIEVE_AND_PAY_SEGMENT_STATUS,
  BENEFICIARY_TYPE_CODE,
  NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE,
  NOMINATION_OAL_ELIGIBILITY_SPO_FRD_SORT,
  CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT,
  NOMINATION_DEPENDENT_REALLOTMENT_STATUS,
  LOGIN_MODE,
};
