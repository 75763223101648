/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, useTheme, SxProps, Theme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { USER_PROFILE_PATHS, NOMINATION_PATHS } from '../../constants/paths';

import { IUserProfileList, IUserProfileListProps } from '../../interfaces';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';

import { Div100vhView, ScrollableView } from '../../components';
import { UserProfileNavList } from '../../containers';

import {
  ProfileIcon,
  BasicInfoIcon,
  ContactDetailsIcon,
  PassportIcon,
  DelegationIcon,
  PaymentMethodIcon,
  PaymentHistoryIcon,
  ConcessionIcon,
  BackIcon,
  TravellerIcon,
  NominationIcon,
} from '../../assets/images';

import { usePaymentMethodIndicator } from '../../hooks';
import { PAYMENT_CONCEPT, UI_STYLES } from '../../constants/constants';
import {
  getShortDisplayName,
  handleBackToUserProfile,
  isRetireeWidow,
  isWidow,
  isRetireeSpecialProfile,
} from '../../helpers';

interface IResizedIcon {
  originIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  originIconProps?: React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  };
  resizedIconCustomStyle?: SxProps<Theme>;
}
const ResizedIcon = (props: IResizedIcon) => {
  const { originIcon, originIconProps, resizedIconCustomStyle } = props;
  if (!originIcon) return <></>;

  const OriginIcon = originIcon;
  return (
    <Box
      className="resized_icon"
      sx={{
        display: 'flex',
        ...resizedIconCustomStyle,
      }}
    >
      <OriginIcon {...originIconProps} />
    </Box>
  );
};

const UserProfile = () => {
  const theme = useTheme();
  const history = useHistory();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { profile, allowDelegation } = useAppSelector(selectUser) || {};
  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const textColor = theme.color.secondary.dark_grey.option_1;

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  const userProfileItems: IUserProfileList[] = [
    {
      title: en.userProfile.personal.basicInfo.title,
      url: USER_PROFILE_PATHS.basicInformation,
      icon: (
        <ResizedIcon
          originIcon={BasicInfoIcon}
          resizedIconCustomStyle={{
            marginRight: '8px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={BasicInfoIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '5px 3px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      isShowWarning: false,
      isShowOnWidowProfile: false,
    },
    {
      title: en.userProfile.personal.contactInfo.title,
      url: USER_PROFILE_PATHS.contactDetails,
      icon: (
        <ResizedIcon
          originIcon={ContactDetailsIcon}
          resizedIconCustomStyle={{
            marginRight: '8px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={ContactDetailsIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '5px 3px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      // desktopIcon: <ContactDetailsIcon width={32} height={32} style={{ padding: '6 6' }} />,
      isShowWarning: false,
      isShowOnWidowProfile: true,
    },
  ];
  const defaultEmploymentDetailItems: IUserProfileList[] = [
    {
      title: en.userProfile.employmentDetail.paymentHistory.title,
      url: USER_PROFILE_PATHS.paymentHistory,
      icon: (
        <ResizedIcon
          originIcon={PaymentHistoryIcon}
          resizedIconCustomStyle={{
            marginLeft: '-2px',
            marginRight: '4px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={PaymentHistoryIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: 0,
          }}
          originIconProps={{
            width: 32,
            height: 32,
          }}
        />
      ),
      // desktopIcon: <PaymentHistoryIcon width={32} height={32} />,
      isShowWarning: false,
    },
    {
      title: en.userProfile.employmentDetail.paymentMethod.title,
      url: USER_PROFILE_PATHS.paymentMethod,
      icon: (
        <ResizedIcon
          originIcon={PaymentMethodIcon}
          resizedIconCustomStyle={{
            marginRight: '6px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={PaymentMethodIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '8px 5px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      // desktopIcon: <PaymentMethodIcon width={32} height={32} style={{ padding: '8 5' }} />,
      isShowWarning: false,
    },
  ];

  const defaultDependentDetailItems: IUserProfileList[] = [
    {
      title: en.userProfile.nomination.nomineesDetail.nomineesTitle,
      url: USER_PROFILE_PATHS.dependentDetails,
      icon: (
        <ResizedIcon
          originIcon={TravellerIcon}
          resizedIconCustomStyle={{
            marginLeft: '-2px',
            marginRight: '3px',
          }}
          originIconProps={{
            fill: theme.palette.gray.dark,
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={TravellerIcon}
          resizedIconCustomStyle={{
            width: '38px',
            height: '32px',
            // padding: '5px 3px',
            marginLeft: '-2px',
          }}
          originIconProps={{
            width: 38,
            height: 32,
            fill: theme.palette.gray.dark,
          }}
        />
      ),
      // desktopIcon: <TravellerIcon fill={theme.palette.gray.dark} width={32} height={32} />,
      isShowWarning: false,
      isShowOnWidowProfile: true,
      isShowOnSpecialRetireeProfile: true,
    },
    {
      title: en.userProfile.nomination.nominationFlow.title,
      url: NOMINATION_PATHS.nominationFlow,
      icon: (
        <ResizedIcon
          originIcon={NominationIcon}
          resizedIconCustomStyle={{
            width: '24px',
            height: '24px',
            padding: 0,
            marginLeft: '-4px',
            marginRight: '4px',
          }}
          originIconProps={{
            fill: theme.palette.gray.dark,
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={NominationIcon}
          resizedIconCustomStyle={{
            width: '34px',
            height: '34px',
            padding: 0,
            marginLeft: '-2px',
            marginTop: '1px',
          }}
          originIconProps={{
            width: 34,
            height: 30,
            fill: theme.palette.gray.dark,
          }}
        />
      ),
      isShowWarning: false,
      isShowOnWidowProfile: false,
      isShowOnSpecialRetireeProfile: false,
    },
  ];

  const passportItems: IUserProfileList[] = [
    {
      title: en.userProfile.passport.title,
      url: USER_PROFILE_PATHS.passport,
      icon: (
        <ResizedIcon
          originIcon={PassportIcon}
          resizedIconCustomStyle={{
            marginLeft: '3px',
            marginRight: '9px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={PassportIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '5px 3px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      // desktopIcon: <PassportIcon width={32} height={32} style={{ padding: '6 8' }} />,
      isShowWarning: false,
    },
  ];

  const delegationItem: IUserProfileList[] = [
    {
      title: en.userProfile.delegation.title,
      url: USER_PROFILE_PATHS.delegation,
      icon: (
        <ResizedIcon
          originIcon={DelegationIcon}
          resizedIconCustomStyle={{
            marginRight: '8px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={DelegationIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '5px 3px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      // desktopIcon: <DelegationIcon width={32} height={32} style={{ padding: '8 4' }} />,
      isShowWarning: false,
    },
  ];

  const concessionItem: IUserProfileList[] = [
    {
      title: en.userProfile.concession.title,
      url: USER_PROFILE_PATHS.concessionDetails,
      icon: (
        <ResizedIcon
          originIcon={ConcessionIcon}
          resizedIconCustomStyle={{
            marginRight: ' 8px',
          }}
        />
      ),
      desktopIcon: (
        <ResizedIcon
          originIcon={ConcessionIcon}
          resizedIconCustomStyle={{
            width: '32px',
            height: '32px',
            padding: '5px 3px',
          }}
          originIconProps={{
            width: 22,
            height: 22,
          }}
        />
      ),
      // desktopIcon: <ConcessionIcon width={32} height={32} style={{ padding: '6 6' }} />,
      isShowWarning: false,
    },
  ];

  // set default employment detail items
  const [employmentDetailItems, setEmploymentDetailItems] = useState(defaultEmploymentDetailItems || []);

  const userProfileItemsForWidow = userProfileItems.filter((item) => item.isShowOnWidowProfile);
  const dependentDetailItemsForWidow = defaultDependentDetailItems.filter((item) => item.isShowOnWidowProfile);
  const dependentDetailItemsForSpecialRetireeProfile = defaultDependentDetailItems.filter(
    (item) => item.isShowOnSpecialRetireeProfile,
  );
  const isWidowProfile = isWidow(profile);

  const navList: IUserProfileListProps[] = useMemo(() => {
    return isDesktop
      ? [
          {
            list: isWidowProfile
              ? [...userProfileItemsForWidow, ...dependentDetailItemsForWidow, ...concessionItem]
              : [...userProfileItems, ...passportItems],
          },
          // Checking Widow Profile | Special Retiree Profile | Other type Profile
          {
            list: isWidowProfile
              ? [...employmentDetailItems]
              : profile && isRetireeSpecialProfile(profile)
              ? [...dependentDetailItemsForSpecialRetireeProfile, ...concessionItem]
              : [...defaultDependentDetailItems, ...concessionItem],
          },
          { list: isWidowProfile ? [] : employmentDetailItems },
          {
            list:
              allowDelegation && profile && !isRetireeWidow(profile) && !isRetireeSpecialProfile(profile)
                ? delegationItem
                : [],
          },
        ]
      : [
          {
            list: isWidowProfile ? [...userProfileItemsForWidow] : userProfileItems,
            header: en.userProfile.personal.title,
          },
          // Checking Widow Profile | Special Retiree Profile | Other type Profile
          {
            list: isWidowProfile
              ? dependentDetailItemsForWidow
              : profile && isRetireeSpecialProfile(profile)
              ? dependentDetailItemsForSpecialRetireeProfile
              : defaultDependentDetailItems,
            header: en.userProfile.nomination.nomineesDetail.detailPageTitle,
          },
          {
            list: employmentDetailItems,
            header: en.userProfile.employmentDetail.header,
          },
          {
            list: isWidowProfile ? [] : passportItems,
            header: isWidowProfile ? '' : en.userProfile.passport.header,
          },
          { list: concessionItem, header: en.userProfile.concession.header },
          {
            list:
              allowDelegation && profile && !isRetireeWidow(profile) && !isRetireeSpecialProfile(profile)
                ? delegationItem
                : [],
            header:
              profile && !isRetireeWidow(profile) && !isRetireeSpecialProfile(profile)
                ? en.userProfile.delegation.header
                : '',
          },
        ];
  }, [employmentDetailItems, isDesktop]);

  //TODO integrate the validation function later
  useEffect(() => {
    // ETP-2431
    const isPrepaid = paymentMethodIndicator.paymentConcept === PAYMENT_CONCEPT.PREPAID;

    if (employmentDetailItems?.length) {
      setEmploymentDetailItems((prev) => {
        const tmpArr = prev?.map((prevItem) => {
          return {
            ...prevItem,
            ...(prevItem?.url?.includes('paymentMethod') && {
              isShowWarning: !isPrepaid && paymentMethodIndicator?.isShowWarning,
            }),
          };
        });
        return [...tmpArr];
      });
    }
  }, [profile?.paymentConcept, profile?.paymentValidated, profile?.paymentMedium, paymentMethodIndicator]);

  return (
    <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
      {!isDesktop && (
        <IconButton onClick={() => handleBackToUserProfile(history)} sx={{ py: 3.625, pl: 2, position: 'absolute' }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}
      <Box
        component={ScrollableView}
        sx={{
          mt: 9.875,
          pb: 2,
          px: {
            sm: `calc(50vw - 480px)`,
            xs: 2,
            height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${isDesktop ? theme.spacing(9.875) : 0})`,
          },
        }}
      >
        <ProfileIcon fill={textColor} />
        <Typography variant="title_1_bold" sx={{ color: textColor, mb: isDesktop ? 4 : 3 }}>
          {isWidowProfile ? (
            en.home.helloWidow
          ) : (
            <>
              {en.home.hello}
              {getShortDisplayName({ preferredFirstName, firstName, lastName, middleName })}
            </>
          )}
        </Typography>
        {navList.map(
          (item: IUserProfileListProps, index: number) =>
            item.list.length > 0 && <UserProfileNavList key={index} list={item.list} header={item?.header} />,
        )}
      </Box>
    </Div100vhView>
  );
};

export default UserProfile;
