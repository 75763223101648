import React, { useState, useEffect, useMemo, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Typography, useTheme } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setErrorAlert, initialState as appSliceInitialState } from '../../slice/appSlice';

import en from '../../translations/en';

import { DialogComponent, FormButton, CommonWarning } from '..';

import { AlertIcon } from '../../assets/images';

import { logout } from '../../helpers';

import { API_ERROR_CODE } from '../../constants/api';
import { ROOT_PATHS } from '../../constants/paths';

import { EditSearchBarAction } from '../../context';

const AlertDialog = () => {
  const theme = useTheme();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { errorAlert } = useAppSelector(selectApp, shallowEqual) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { setIsShowEditSearchOverlay } = useContext(EditSearchBarAction) || {};

  const {
    title,
    message,
    requestId,
    applicationId,
    noErrorAlert,
    showErrorIcon = true,
    isEnableMobileEditSearchFlag,
    isShowCommonWarning,
    commonWarningReplaceTarget,
    commonWarningActionFunc,
    dismissCallBackFunc,
    errorStatus,
  } = errorAlert || {};
  const isHandleMobileEditSearch = isEnableMobileEditSearchFlag && !isDesktop;

  // const { instance } = useMsal();

  useEffect(() => {
    setOpen(!!(title && message));
  }, [title, message]);

  useEffect(() => {
    if (!open) {
      dispatch(setErrorAlert(appSliceInitialState.errorAlert));
    }
  }, [open]);

  const commonWarningMessage = useMemo(() => {
    return (
      <CommonWarning
        isShowIcon={false}
        msgText={message}
        replaceTarget={commonWarningReplaceTarget || ''}
        actionFunc={commonWarningActionFunc}
      />
    );
  }, [message, isShowCommonWarning, isDesktop]);

  const errMessage = useMemo(() => {
    return (
      <>
        {applicationId && <Typography>{applicationId}</Typography>}
        {requestId && <Typography>{requestId}</Typography>}
        <Typography>{message === API_ERROR_CODE.olderClientVer ? en.error.oldClientVer : message}</Typography>
      </>
    );
  }, [message, requestId, applicationId]);

  return (
    <DialogComponent
      open={open}
      title={title}
      message={isShowCommonWarning ? commonWarningMessage : errMessage}
      IconComponent={showErrorIcon ? <AlertIcon /> : null}
      noErrorAlert={noErrorAlert}
      buttonComponent={
        <>
          <Button
            onClick={() => {
              setOpen(false);

              if (message === API_ERROR_CODE.olderClientVer) {
                window.location.replace(ROOT_PATHS.landing);
              } else if (
                typeof message === 'string' &&
                message.toLowerCase()?.includes(en.errorAlert.helpYouToLogout.toLowerCase())
              ) {
                // logout(dispatch, instance);
                logout(dispatch);
              } else if (typeof message === 'string' && !!dismissCallBackFunc) {
                // if have dismissCallBackFunc
                dismissCallBackFunc?.({
                  errorMessage: message,
                  errorStatus,
                });
              } else if (
                typeof message === 'string' &&
                message.toLowerCase()?.includes(en.errorAlert.amadeusTimeoutIssueTicket.toLowerCase())
              ) {
                history.push({
                  pathname: ROOT_PATHS.landing,
                });
              }
            }}
            sx={{
              width: '164px',
              height: '44px',
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '16px',
              lineHeight: '24px',
              my: '32px',
              p: 0,
              color: theme.palette.borderColor.light,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              ...(isHandleMobileEditSearch && {
                width: '131px',
                border: `2px solid ${theme.color.utility.link.option_3}`,
                py: 1,
                px: 2.75,
              }),
            }}
          >
            {message === API_ERROR_CODE.olderClientVer ? en.common.refresh : en.common.dismiss}
          </Button>

          {isHandleMobileEditSearch && (
            <FormButton
              theme={theme}
              colour={'green'}
              variant="contained"
              color="success"
              size="large"
              customStyles={{
                borderRadius: 8,
              }}
              onClick={() => {
                // hide AlertDialog
                setOpen(false);

                // open edit search component `DrawerOverlay`
                setIsShowEditSearchOverlay(true);
              }}
              sx={{
                '&:not(:first-of-type), &:first-of-type': {
                  ml: 0,
                },
              }}
            >
              {en.common.editSearch}
            </FormButton>
          )}
        </>
      }
      customDialogActionStyles={{
        ...(isHandleMobileEditSearch && {
          justifyContent: 'space-evenly',
        }),
      }}
    />
  );
};

export default AlertDialog;
