import { Typography, Box } from '@mui/material';

import { AdminBookingIcon, AdminEmployeeDetailsIcon, AdminTravelHistoryIcon } from '../../assets/images';

import en from '../../translations/en';
import { ADMIN_PATHS, BOOKING_PATHS } from '../../constants/paths';

import { IUserProfileList } from '../../interfaces';

import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';

import { AdminNavList } from './';
import { ScrollableView } from '../../components';

const AdminFeatureLayout = () => {
  const { adminRoles, role } = useAppSelector(selectUser) || {};

  const adminRole = adminRoles.find((item) => item.id === role.id);
  const adminFeatureItems: IUserProfileList[] = [];

  // check admin createBooking access
  adminRole?.access?.createBooking &&
    adminFeatureItems.push({
      title: en.admin.feature.adminBooking.title,
      url: BOOKING_PATHS.myBookings,
      icon: <AdminBookingIcon />,
    });
  // check admin maintainEmployeeDetails access
  adminRole?.access?.maintainEmployeeDetails &&
    adminFeatureItems.push({
      title: en.admin.feature.maintainEmployeeDetails.title,
      url: ADMIN_PATHS.adminEmployee,
      icon: <AdminEmployeeDetailsIcon />,
    });
  // check admin travelHistoryReport access
  adminRole?.access?.travelHistoryReport &&
    adminFeatureItems.push({
      title: en.admin.feature.travelHistoryReport.title,
      url: ADMIN_PATHS.adminTravelHistory,
      icon: <AdminTravelHistoryIcon />,
    });

  return (
    <>
      <Box sx={{ mt: 4.5, ml: '14%' }} component={ScrollableView}>
        <Typography variant="headline_medium">
          {adminFeatureItems.length > 0 ? en.admin.feature.header : en.admin.feature.noAdminFeatures}
        </Typography>
        <AdminNavList list={adminFeatureItems} header={en.userProfile.passport.header} />
      </Box>
    </>
  );
};

export default AdminFeatureLayout;
