import { Backdrop, Box, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import en from '../../translations/en';
import { RightArrowIcon } from '../../assets/images';

import { selectUser, setIsShowProfileIndicator, setIsShowSelectProfileDialog } from '../../slice/userSlice';
import { selectApp, setSnackBarData } from '../../slice/appSlice';
import { useAppSelector } from '../../app/hooks';
import { setErn } from '../../slice/authSlice';

const RetireeSwitchProfileDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const retireeSpecialProfileLbl = en.userProfile.retireeSpecialProfile;

  const profileItemData = [
    { label: retireeSpecialProfileLbl.switchProfileDialog.retireeProfileLabel, isSpecialProfile: false },
    { label: retireeSpecialProfileLbl.switchProfileDialog.specialProfileLabel, isSpecialProfile: true },
  ];

  const handleProfileItemClick = (isSpecialProfile: boolean) => {
    dispatch(setIsShowSelectProfileDialog(false));
    dispatch(setIsShowProfileIndicator(true));
    dispatch(
      setSnackBarData({
        message: retireeSpecialProfileLbl.switchProfileDialog.successfulSwitched,
        isShow: true,
        delay: 700,
        customStyles: {
          mb: isDesktop ? 9 : 10.5,
        },
      }),
    );

    if (isSpecialProfile) {
      dispatch(setErn(profile?.retireeSpecialProfile.employeeId || ''));
    }
  };

  return (
    <Backdrop
      sx={{
        color: theme.palette.bgColor.main,
        zIndex: theme.zIndex.modal + 1,
      }}
      open={true}
    >
      <Box
        sx={{
          width: isDesktop ? '478px' : '343px',
          height: isDesktop ? '246px' : '230px',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 2,
        }}
      >
        <Typography
          variant="title_1_bold"
          color={theme.color.secondary.dark_grey.option_1}
          sx={{ mt: 4, mb: 1, textAlign: 'center' }}
        >
          {retireeSpecialProfileLbl.switchProfileDialog.title}
        </Typography>

        {profileItemData.map((item) => {
          return (
            <Box
              key={item.label}
              sx={{
                width: isDesktop ? '269px' : '311px',
                height: '48px',
                background: theme.color.secondary.slate.option_8,
                display: 'flex',
                borderRadius: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                mt: 2,
                cursor: 'pointer',
              }}
              onClick={() => {
                handleProfileItemClick(item.isSpecialProfile);
              }}
            >
              <Typography variant="body_2_regular" color={theme.color.utility.link.option_3}>
                {item.label}
              </Typography>

              <RightArrowIcon style={{ width: '10px', height: '10px' }} fill={theme.color.utility.link.option_3} />
            </Box>
          );
        })}
      </Box>
    </Backdrop>
  );
};

export default RetireeSwitchProfileDialog;
