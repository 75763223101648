import { Field } from 'redux-form';

import { getLocalDate } from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

import { ReduxFormDatePicker } from '.';

const PassportExpiryDatePicker = ({ title, name }: { title: string; name: string }) => {
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Field
      component={ReduxFormDatePicker}
      name={name}
      title={title}
      minDate={getLocalDate()}
      containerStyles={{
        ...(isDesktop && {
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: `rgba(0,0,0,.5)`,
          zIndex: 120,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      }}
      contentStyles={{
        ...(isDesktop
          ? {
              position: 'unset',
            }
          : { top: 0 }),
      }}
    />
  );
};

export default PassportExpiryDatePicker;
