const en = {
  common: {
    hkd: 'HKD',
    empty: '--',
    edit: 'Edit',
    save: 'Save',
    next: 'Next',
    cancel: 'Cancel',
    search: 'Search',
    apply: 'Apply',
    editSearch: 'Edit search',
    update: 'Update',
    noOption: 'No option',
    noMatchResult: 'No matching result',
    createBooking: 'Create booking',
    confirmAndPay: 'Confirm and Pay', // ETP-2431
    applicationName: 'Travel Hub',
    goBack: 'Go back',
    booking: 'Booking',
    bookmarks: 'Bookmarks',
    clear: 'Clear',
    continue: 'Continue',
    hyphen: '-',
    dismiss: 'Dismiss',
    refresh: 'Refresh',
    followingReason: 'Due to the following reason:',
    yes: 'Yes',
    no: 'No',
    yesCancel: 'Yes, cancel',
    yesConfirm: 'Yes, confirm',
    active: 'Active',
    inactive: 'Inactive',
    view: 'View',
    hide: 'Hide',
    backToHome: 'Back to home',
    remove: 'Remove',
    firstNameAndOtherName: 'First name and other name',
    lastName: 'Last name',
    download: 'Download',
    filter: 'Filter',
    from: 'From',
    to: 'To',
    suggestFullVersionView: 'Full version is available on desktop view',
    confirm: 'Confirm',
    confirmAndSubmit: 'Confirm and submit',
    back: 'Back',
    formFields: {
      dateOfBirth: 'Date of Birth',
    },
    viewMore: 'View more',
    viewMoreAndEdit: 'View more / Edit',
    viewLess: 'View less',
  },
  menu: {
    home: 'Home',
    info: 'Info',
    profile: 'About you',
    search: 'Search',
    bookmarks: 'Bookmarks',
    guide: 'Admin guide',
  },
  userMenu: {
    profile: 'About you',
    signOut: 'Sign out',
  },
  accountMenu: {
    profile: 'Profile',
    personalAccount: 'Personal account',
    delegation: 'Delegation',
    admin: 'Admin',
    signOut: 'Sign out',
  },
  home: {
    hi: 'Hello',
    yourTravelExperienceStartsHere: 'Your travel experience starts here',
    whereAreYouFlyingTo: 'Where are you flying to?',
    whenWillYouBeThere: 'When is your departure date?',
    upcomingTrips: 'My planned trips',
    noUpcomingTrips: "You don't have any upcoming trips. Let's start planning now!",
    bookmarks: 'Bookmarks',
    noBookmarks: 'Add your favourite destinations!',
    whereToGoNext: 'Where is your next destination?',
    viewAll: 'View all',
    myBooking: 'My booking',
    adminBooking: 'Admin booking',
    noBooking: 'You don’t have any bookings',
    notFindBooking: 'We cannot find any bookings. Please refine your search criteria and try again.',
    howTravel: 'Which concession is used?',
    hello: 'Hello ',
    helloWidow: 'Hello, welcome to Travel Hub',
    adminStatus: 'Your admin booking starts here',
    resizeBroswer: 'Resize your browser to a bigger size',
    adminFunctionUnavailable: 'Admin functions are not available on mobile view',
  },
  userProfile: {
    title: 'About you',
    backToProfile: 'Back to profile',
    personal: {
      title: 'Personal details',
      yourProfile: 'Your Profile',
      desc: 'Your details, Your contacts and payment info',
      countryOfResidence: 'Country of residence',
      declareCountryOfResidence: 'Declare your country of residence',
      countryOfResidenceWarning:
        'Please ensure that you have completed the payment setup (if necessary) before exiting this process. Failure to do so will require you to set up your country of residence again.',
      personalEmailWarning:
        'Please contact GBS to register your personal email and contact number. Your booking creation will be affected without this information.',
      basicInfo: {
        title: 'Your details',
        lastName: 'Last name',
        firstName: 'First name',
        ern: 'ERN',
        company: 'Company',
        companySeniorityDate: 'Company seniority date',
        benefitLeisureJobLevel: 'Benefit leisure job level',
        benefitDutyJobLevel: 'Benefit duty job level',
        costCentre: 'Cost centre',
        leisureTravelTicketingStatus: 'Leisure travel ticketing status',
        level: 'Level',
        desc: 'Basic info',
        portOfDomicile: 'Port of domicile',
        homePort: 'Home port',
        yearOfService: 'Year of service',
      },
      contactInfo: {
        title: 'Your contacts',
        personalEmail: 'Personal email',
        companyEmail: 'Company email',
        notificationEmail: 'Notification email',
        mobilePhoneNumber: 'Mobile phone number',
        officePhoneNumber: 'Office phone number',
        desc: 'Contact info',
        noticeMessage: 'You may edit your personal email and mobile phone number in People Hub.',
        retireeWidowNoticeMessage:
          'To update your personal email and mobile phone number, please contact GBS for assistance.',
      },
      paymentInfo: {
        title: 'Payment info',
        paymentConcept: 'Payment mechanism',
        paymentMedium: 'Payment medium',
        paymentStatus: 'Payment status',
      },
    },
    dependent: {
      title: 'Dependent list',
      desc: 'Dependent & companion info',
      dependentDetails: 'Dependent details',
      dependentTitle: 'Your dependents',
      otherDependents: 'Not nominated dependents',
      nominated: 'Nominated dependents',
      dependentName: 'Dependent name',
      dependentType: 'Dependent type',
      name: 'Name',
      type: 'Type',
      relationship: 'Relationship',
      nomineeType: 'Nominee Type',
      dateOfBirth: 'Date of birth',
      nominationStatus: 'Nomination status',
      dependentStatus: 'Dependent status',
      nominationDate: 'Nomination date',
      passportDetails: 'Passport details',
      firstOtherNameAsInPassport: 'First, other name in passport',
      lastNameAsInPassport: 'Last name in passport',
      passportNumber: 'Passport number',
      passportExpiryDate: ' Passport expiry date',
      nationalityInPassport: 'Nationality in passport',
      issuedCountry: 'Country of issue',
      currentNominee: 'Current nominee',
      pastNominee: 'Not nominated',
      active: 'Active',
      inActive: 'Inactive',
      isCurrentNominateeStatus: 'isCurrentNominateeStatus',
      isActiveDependentStatus: 'isActiveDependentStatus',
      empty: 'We cannot find any dependents.',
    },
    passport: {
      header: 'Passport details',
      title: 'Your passport',
      desc: 'Passport information',
      defaultPassport: 'Passport',
      secondPassport: '2nd passport',
      lastName: 'Last name',
      firstName: 'First name',
      number: 'Passport number',
      issueDate: 'Issue date',
      expiryDate: 'Expiry date',
      issuedCountry: 'Country of issue',
      nationality: 'Nationality',
      noticeMessage: 'You may edit your passport details in People Hub.',
      updateMessage: 'To update your passport details, please contact GBS for assistance.',
    },
    delegation: {
      header: 'Delegation details',
      title: 'Your delegation',
      desc: 'delegation information',
      noDelegation: 'No existing delegation record,',
      addOneDelegatee: ' please add one.',
      addDelegatee: 'Add delegatee',
      delegateeName: 'Name',
      from: 'From',
      to: 'To',
      noDueDate: 'No due date',
      confirm: 'Confirm',
      removeDelegatee: 'Remove delegatee',
      removeDelegateeDesc: 'Are you sure to remove your delegatee?',
      delegateeRemoved: 'Your delegatee is removed.',
      actingAs: 'You are acting as',
    },
    employmentDetail: {
      header: 'Payment details',
      paymentHistory: {
        title: 'Payment history',
        header: 'Payment History',
        noSearchResult: 'You don’t have any payment history.',
        itinerary: 'Itinerary',
        selectPeriod: 'Select a period',
        fields: {
          concession: 'Concession',
          travellerName: 'Traveller name',
          ticketNumber: 'Ticket number',
          itinerary: 'Itinerary',
          processedDate: 'Processed date',
          currency: 'Currency',
          totalAmount: 'Total',
          paymentMedium: 'Payment medium',
          paypalStatus: 'Paypal status',
          status: 'Status',
        },
        // part of status is related to paypal, the label from API Configuration
        status: {
          paid: 'Paid',
          request: 'Request for refund',
          submitted: 'Submitted for refund',
        },
      },
      paymentMethod: {
        title: 'Payment method',
        paymentConcept: 'Payment mechanism',
        paymentMedium: 'Payment medium',
        paymentSetUpStatus: 'Payment setup status',
      },
      paymentSetUpStatus: {
        notYetSetup: 'Incomplete',
        completed: 'Completed',
        suspended: 'Suspended',
        error: 'Error',
        unableRetrieve: 'Unable to retrieve',
      },
    },
    concession: {
      header: 'Travel concessions',
      title: 'Concession details',
      concessionFor: 'Concessions for',
      self: 'Self',
      as: 'as of',
      viewMore: 'View more',
      viewLess: 'View less',
      boardingPriority: 'Boarding priority',
      travelClass: 'Travel class',
      notFindConcession: 'We cannot find any travel concessions.',
      travelClassDetails: 'Travel class details',
      allotmentDetails: 'Allotment details',
      travelValidity: 'Travel Validity',
      allotmentType: 'Allotment Type',
      allotedTicketSector: 'Alloted Ticket/Sector',
      used: 'Used',
      available: 'Available',
      unlimited: 'Unlimited',
      individual: 'Individual',
    },
    flownSuspension: {
      title: 'Payment mechanism change',
      link: 'link',
      startDate: '{start date}',
      endDate: '{end date}',
      changeAcknowledged: 'Agree and continue',
      contentMessage:
        'Effective {start date} to {end date}, your payment mechanism will change from FLOWN to Pre-pay. Please refer to this link for more details. The existing booking will remain but the FLOWN ticket you have issued previously will indicate “Retrieve and Pay”. You must prepay with a credit card before you can use this booking for travel.',
      consentMessage:
        'I acknowledge during the period from {start date} to {end date}, tickets must be prepaid by credit card​.',
    },
    nomination: {
      nomineesDetail: {
        nomineesTitle: 'Nominee details, Register new travel companion',
        detailPageTitle: 'Nominees details',
        contentTitle: 'Travel companion details',
        nominatedSection: 'Nominated',
        notNominatedSection: 'Not nominated',
        empty: 'We cannot find any nominees.',
        registerTravelCompanionButton: 'Register New Travel Companion',
      },
      companionRegistration: {
        // 3764, 4622 add
        addNewCompanion: 'Add new travel companion for nomination',
        personalDetails: {
          header: 'Personal details',
          fields: {
            dependantName: 'Name', // for review & confirm
            relationship: 'Relationship',
            dependant: 'Dependant',
            dependantType: 'Dependant Type',
            title: 'Title',
            firstName: 'First Name',
            middleName: 'Middle Name',
            surname: 'Surname',
            dateOfBirth: 'Date of Birth',
            gender: 'Gender',
            travelCompanion: 'Travel companion',
            yearKnown: 'No. of Year',
            monthKnown: 'No. of Month',
          },
          descriptionContent: [
            {
              text: 'Please input details of the person you wish to register as Travel Companion. If the person you wish to register is already registered in People Hub, please select the ‘Relationship’ and their name from the ‘Dependant’ drop down box. If you have previously registered the person as your Travel Companion on Travel Hub and he/she appears on your Nominee Details list, you do not need to register again.',
              formatType: 'default',
            },
          ],
          // this description is under the Personal details header
          descriptionContentWhichUnderPersonalDetailHeader: [
            {
              text: "Please put down the number of years and months that you've known this person for. E.g. you've known this person for 12 years, please put 12 years 0 month.",
              formatType: 'default',
            },
          ],
          // [ETP-4847]
          yearMonthKnownErrorMessage: 'This is a mandatory field. Please complete as required.',
          personalFirstNameErrorLabel:
            'This is a mandatory field. If your Travel Companion only has one name, enter the name in both ‘First Name’ and ‘Surname’ fields.',
        },
        passportDetails: {
          heading: 'Passport details',
          fields: {
            passportNumber: 'Passport Number',
            passportFirstName: 'First, Other Name as in Passport',
            surname: 'Surname as in Passport',
            expiryDate: 'Passport Expiry Date',
            nationality: 'Nationality in Passport',
            issuedCountry: 'Issued Country',
          },
          descriptionContent: [
            {
              text: 'Please enter your Travel Companion’s passport details. All fields are mandatory.',
              formatType: 'default',
            },
          ],
          passportFirstNameErrorLabel:
            'This is a mandatory field. If your Travel Companion’s passport only has one name, enter the name in both ‘First, Other Name’ and ‘Surname’ fields.',
        },
        dependantDetails: {
          heading: 'Nominee details',
        },
        disclaimerNote: 'Please see below disclaimer and acknowledge the nomination declaration to proceed.',
        agreeDisclaimer:
          'I wish to nominate the above-mentioned person as my Companion. I understand that if my nominated companion uses any ticket acquired under the Concessional Travel Policy other than for recreation or leisure travel purposes or is found to have paid, or procured the payment of any consideration to me for any such ticket, I and all persons eligible through me may lose all concessional travel benefits permanently; I may have to reimburse the Company with full applicable fare for the journeys and I may be subject to disciplinary action.',
        confirm: 'Confirm',
        dependant: 'Dependant',
        confirmTitle:
          'Please confirm to add below companion for nomination as changes cannot be reversed once confirmed.',
        successAdded: 'Your companion is added.',
        // [ETP-4620]
        acknowledgePolicy: {
          continueButton: 'Acknowledge',
          title: 'Register a Travel Companion',
          content: [
            {
              text: 'Working at an airline has its unique perks. Cathay extends its employee travel benefits to our employees’ eligible Travel Nominees, and each employee may nominate one person as their Travel Companion. ',
              formatType: 'default',
            },
            {
              text: 'Below summarises who you can nominate as a Travel Companion. For details, please refer to the prevailing Cathay Leisure Travel Policy (the “Policy”) which will take precedence in case of discrepancies.',
              formatType: 'default',
            },
            {
              text: '(1) A Travel Companion can be your partner, sibling, other family member or your friend.',
              formatType: 'default',
            },
            {
              text: '(2) As prescribed in the Policy’s "Non-Eligible Persons" section, a Travel Companion cannot be someone:',
              formatType: 'default',
            },
            {
              text: '(2a) who is already a Travel Nominee or Plus One of another employee or retiree;',
              formatType: 'default',
            },
            {
              text: '(2b) who has previously been nominated and whose access to travel benefits have been withdrawn or suspended;',
              formatType: 'default',
            },
            {
              text: '(2c) whose employment with the Company was terminated;',
              formatType: 'default',
            },
            {
              text: '(2d) who, in the Company’s opinion, resigned to avoid termination of their service by the Company; or',
              formatType: 'default',
            },
            {
              text: '(2e) who has left the Company’s employment but has not repaid their debts owed to the Company.',
              formatType: 'default',
            },
          ],
        },
        reviewAndConfirm: {
          title: 'Review and confirm',
          content: [],
          cancelRegistrationDialog: {
            title: 'Cancel travel companion registration',
            description:
              'Confirm to cancel? The registration would not be saved. You would be directed back to your nomination list.',
          },
        },
        completed: {
          title: 'Registration completed',
          content: [
            {
              text: 'You can now make nomination for the registered travel companion.',
              formatType: 'default',
            },
          ],
          back: 'Back to nominees details',
        },
        // [ETP-3868]
        editCompanionPassport: {
          contentTitle: 'Edit companion passport details',
          cancelEditCompanionPassportDialog: {
            title: 'Cancel changes',
            description: 'Confirm to cancel? The changes to the passport details would not be saved.',
          },
          editSuccessToastMessage: 'Your travel companion details is updated.',
          backToDependantDetails: 'Back to Dependant details',
        },
        backToNomineesDetails: 'Back to nominees details',
        backToTravelPolicy: 'Back to travel policy',
        backToTravelCompanionDetails: 'Back to travel companion details',
        backToReviewAndConfirm: 'Back to review and confirm',
      },
      nominationFlow: {
        nominationViewMode: {
          title: 'Current year nomination',
          continueButton: 'Make Nomination',
        },
        title: 'Make Nomination',
        nomineeType: 'Select below to nominate',
        cathayTitle: 'Current year nomination: Cathay Pacific',
        interlineTitle: 'Current year nomination: Interline carriers',
        notNominated: 'Not nominated',
        addNominee: 'Add nominee',
        plusOne: {
          title: 'Plus One',
          description:
            'A Travel Nominee selected by an employee who has access to FOC tickets and Priority 25 and 27 standby zone travel tickets.',
          viewModeHeaderDescription:
            'A Travel Nominee selected by an employee who has access to FOC tickets and Priority 25 and 27 standby zone travel tickets.',
        },
        travelNominees: {
          title: 'Travel nominees',
          description:
            'An eligible nominee selected by an employee who has access to Priority 27 standby zone travel tickets.',
        },
        eligibilityTagName: 'Eligibility',
        eligibilityCarrier: {
          cx: 'CX',
          // aligned with BU, should be all lower case for the oneworld
          oneworld: 'oneworld',
          zed: 'ZED',
        },
        dependentWarning: {
          // [ETP-4646] handle allotment in progress label
          // follow the status from backend
          reallotmentStatus: {
            new: 'Concession allotment in progress',
            // [ETP-4931] handle the error status
            error: 'Concession allotment unsuccessful. Please contact IT via IT Hub.',
          },
        },
        maySelectAs: 'May select as',
        nomineeTypeChange: 'Nominee type change:',
        toTravelNominee: 'Plus One to Travel Nominee',
        toPlusOne: 'Travel Nominee to Plus One',
        ifYouConfirm: 'If you proceed, <b>%s</b>',
        willBeChanged: "'s nominee type will be changed from ",
        changeTypeContent: [
          {
            text: 'Changing Travel Nominee to Plus One: This person will remain as your Plus One until 31 December of the travel year. For married employees who have met all the conditions to change Plus One to Travel Nominee: this means you understand all your Spouse’s unused FOC entitlement will be forfeited.',
            formatType: 'default',
          },
        ],
        selectUpTo: 'Please select up to',
        emptyNotNominatedContent: [
          {
            text: 'Currently you do not have any registered nominees.',
            formatType: 'default',
          },
          {
            text: 'You may nominate up four Travel Nominees and one Plus One to have access to travel benefits. Read more about how to register on the Registration section on Employee Travel Portal.',
            formatType: 'default',
          },
        ],
        nominees: 'nominees:',
        OALEligibility: {
          label: 'OALEligibility',
          title: 'Assign Interline Travel',
          desc: "If you have nominated a Spouse or a Travel Companion, you can only choose one to access Interline Travel during the same travel nomination period. Spouse is eligible for all interline carriers. Travel Companion is eligible for oneworld, HK Express and Air New Zealand. View 'Eligibility' for eligible carriers.",
          assigned: 'Assigned',
          toggleLabel: 'Interline travel access',
          toggleDesc: 'Remark: No changes can be made until the next travel nomination period.',
        },
        travelEligibility: {
          label: 'travelEligibility',
          title: 'Interline Travel',
          desc: "Parents are eligible for oneworld and HK Express. Siblings are eligible for oneworld. All others are not eligible for Interline Travel. View 'Eligibility' for eligible carriers.",
        },
        legends: {
          title: 'Legends',
          remarks: [
            '<b>This icon</b> denotes this person remains as your Travel Nominee / Plus One until 31 December of the travel year.',
          ],
        },
        confirmation: 'Review and confirm',
        toBeAdded: 'Press confirm to finalise nomination',
        oalAssigned: '(with Interline Travel access)',
        fromPlusOneToLabel: 'From Plus One to',
        travelNomineeLabel: ' Travel Nominee',
        fromTravelNomineeLabel: 'From Travel Nominee',
        toPlusOneLabel: 'to Plus One',
        saveNominationSuccessToastMessage: 'Your nomination changes are saved.',
        // map with constant
        stepList: {
          selectNominationType: 'Select',
          nominationCart: 'Cathay',
          nominationOALEligibility: 'Interline',
          nominationReviewAndConfirm: 'Review',
          nominationDisclaimer: 'Consent',
        },
        cancelNominationFlowDialog: {
          title: 'Cancel nomination changes',
          description: 'Confirm to cancel? The changes would not be saved.',
        },
        backToNomination: 'Back to nomination',
        backToSelect: 'Back to select',
        backToCathay: 'Back to Cathay',
        backToInterline: 'Back to Interline',
        backToReview: 'Back to review',
      },
      common: {
        redirectNominationPage: 'Make nomination',
        nominationDependentStatus: {
          registered: 'Registered',
        },
        // [ETP-4621] for companion registration flow disclaimer page
        nominationPolicy: {
          title: 'Employee Declaration',
          descriptionContent: [
            {
              text: 'To complete your registration process, please read and accept the following. You will be required to confirm your understanding and acceptance of the below again when you submit the final nomination.',
              formatType: 'default',
            },
          ],
          policyDetail: {
            title: '',
            descriptionContent: [
              {
                text: '(1) I understand that any travel undertaken by the Travel Companion is for recreation and leisure travel only, and may at no time be used for any other purpose, such as business or commercial travel.',
                formatType: 'default',
              },
              {
                text: '(2) I declare that I have not and will not receive any consideration, financial or otherwise, from my Travel Companion, in respect of any tickets that I may request for them. I also declare that I will only request tickets, on their behalf, which are for the purpose of recreation or leisure travel. ',
                formatType: 'default',
              },
              {
                text: '(3) I understand that if my Travel Companion:',
                formatType: 'default',
              },
              {
                text: '(3a) Uses any travel concessions under the Cathay Leisure Travel Policy (the “Policy”) other than for the purposes of recreation or leisure travel; or',
                formatType: 'default',
              },
              {
                text: '(3b) Is found to have paid, or procured the payment of any consideration (whether financial or otherwise) to me directly or indirectly for any such ticket,',
                formatType: 'default',
              },
              {
                text: 'then the following consequences will apply:',
                formatType: 'default',
              },
              {
                text: '(i) I, and all persons eligible through me may lose all concessional travel benefits permanently;',
                formatType: 'default',
              },
              {
                text: '(ii) I will be required to reimburse Cathay Pacific Airways Limited (the “Company”) the full published applicable fares of tickets for each journey taken in breach of the Policy and indemnify the Company for all its costs and losses arising from the Travel Companion’s use of the travel concessions;',
                formatType: 'default',
              },
              {
                text: '(iii) I may be subject to disciplinary action, including but not limited to summary dismissal by the Company;',
                formatType: 'default',
              },
              {
                text: '(iv) I and the Travel Companion may be subject to criminal investigation under the applicable laws.',
                formatType: 'default',
              },
              {
                text: '(4) I also agree that:',
                formatType: 'default',
              },
              {
                text: '(4a) The Policy and any concessional travel benefits granted are discretionary in nature and the Company has the right to unilaterally vary at any time for any reason (whether to my detriment or benefit) any provision of the Policy or to withdraw the Policy in its entirety; ',
                formatType: 'default',
              },
              {
                text: '(4b) I am responsible for procuring my Travel Companion’s understanding of the above and that any privileges afforded to them whilst travelling as a full fare passenger do not apply whilst travelling on concessional travel tickets; ',
                formatType: 'default',
              },
              {
                text: '(4c) The Company has the right to reject my registration if any improper use is suspected;',
                formatType: 'default',
              },
              {
                text: '(4d) I am responsible for procuring the Travel Companion’s compliance of the conditions of the Policy, failing the consequences at 3i), ii) and iii) may apply.',
                formatType: 'default',
              },
            ],
          },
          secondPolicyDetail: {
            descriptionContent: [
              {
                text: 'I have read, understood and accept the above.',
                formatType: 'default',
              },
              {
                text: 'Personal Information Collection Statement',
                formatType: 'default',
              },
              {
                text: 'By nominating your Travel Companion to have access to Cathay’s Employee Travel Benefits, you acknowledge that you have obtained their consent to the collection and use of their personal data, including name, date of birth, passport details, by Cathay Pacific Airways Limited (“Company”), for the purpose of facilitate ticket issuance and bookings for when he/she is eligible through your nomination to access Cathay’s Employee Travel Benefits.',
                formatType: 'default',
              },
              {
                text: 'The provision of the personal details is voluntary. Personal data may be shared with third parties, including immigration authorities to facilitate the purposes stated above.',
                formatType: 'default',
              },
              {
                text: 'The data will be used by the Company for the purposes stated above. The Company will retain personal data of your companion for 7 years from the employee’s last employment date, after which it will be securely destroyed.',
                formatType: 'default',
              },
              {
                text: 'You have the right to request access to and correct of your personal data. If you have any questions or concerns about how the Company handles your personal data or wish to exercise your rights, please refer to the respective contact points in the Company ‘Personal Information Collection Statement – Employees’. ',
                formatType: 'default',
              },
            ],
          },
        },
        // [ETP-4667] for nomination flow disclaimer page
        // TBC should be different context with companion registration flow disclaimer
        nominationFlowDisclaimer: {
          title: 'Employee Declaration',
          policyDetail: {
            title: 'To complete your nomination process, please read and accept the following:',
            descriptionContent: [
              {
                text: '(1) I understand that any travel undertaken by my nominated individuals is for recreation and leisure travel only, and may at no time be used for any other purpose, such as business or commercial travel.',
                formatType: 'default',
              },
              {
                text: '(2) I declare that I have not and will not receive any consideration, financial or otherwise, from my nominated individuals, in respect of any tickets that I may request for them. I also declare that I will only request tickets, on their behalf, which are for the purpose of recreation or leisure travel. ',
                formatType: 'default',
              },
              {
                text: '(3) I understand that if any of my nominated individuals:',
                formatType: 'default',
              },
              {
                text: '(3a) Uses any travel concessions under the Cathay Leisure Travel Policy (the “Policy”) other than for the purposes of recreation or leisure travel; or',
                formatType: 'default',
              },
              {
                text: '(3b) Is found to have paid, or procured the payment of any consideration (whether financial or otherwise) to me directly or indirectly for any such ticket,',
                formatType: 'default',
              },
              {
                text: 'then the following consequences will apply:',
                formatType: 'default',
              },
              {
                text: '(i) I, and all persons eligible through me may lose all concessional travel benefits permanently;',
                formatType: 'default',
              },
              {
                text: '(ii) I will be required to reimburse Cathay Pacific Airways Limited (the “Company”) the full published applicable fares of tickets for each journey taken in breach of the Policy and indemnify the Company for all its costs and losses arising from the nominated individual’s use of the travel concessions;',
                formatType: 'default',
              },
              {
                text: '(iii) I may be subject to disciplinary action, including but not limited to summary dismissal by the Company;',
                formatType: 'default',
              },
              {
                text: '(iv) I and the nominated individual may be subject to criminal investigation under the applicable laws.',
                formatType: 'default',
              },
              {
                text: '(4) I also agree that:',
                formatType: 'default',
              },
              {
                text: '(4a) The Policy and any concessional travel benefits granted are discretionary in nature and the Company has the right to unilaterally vary at any time for any reason (whether to my detriment or benefit) any provision of the Policy or to withdraw the Policy in its entirety;',
                formatType: 'default',
              },
              {
                text: '(4b) I am responsible for procuring my nominated individuals’ understanding of the above and that any privileges afforded to them whilst travelling as a full fare passenger do not apply whilst travelling on concessional travel tickets;',
                formatType: 'default',
              },
              {
                text: '(4c) The Company has the right to reject my nomination if any improper use is suspected;',
                formatType: 'default',
              },
              {
                text: '(4d) I am responsible for procuring my nominated individuals’ compliance of the conditions of the Policy, failing the consequences at 3i), ii) and iii) may apply.',
                formatType: 'default',
              },
            ],
          },
          secondPolicyDetail: {
            descriptionContent: [
              {
                text: 'I have read, understood and accept the above.',
                formatType: 'default',
              },
            ],
          },
        },
      },
    },
    retireeSpecialProfile: {
      retireeProfileTitle: 'Retiree Profile',
      specialProfileTitle: 'Special Profile',
      notification: "You're currently using ",
      switchProfileDialog: {
        title: 'Select your login profile',
        successfulSwitched: 'You have successfully switched your login profile.',
        retireeProfileLabel: 'Retiree profile',
        specialProfileLabel: 'Special profile (FOC ticket)',
      },
    },
  },
  admin: {
    feature: {
      header: 'Other admin features',
      noAdminFeatures: 'No admin features available for this role.',
      adminBooking: {
        title: 'Admin booking',
        adminBookingPlaceHolder: 'You may view bookings by using search function or viewing all.',
        adminBookingTooltip: 'It may takes time to load all the booking records.',
        noBookingsFound: 'No booking found.',
      },
      maintainEmployeeDetails: {
        title: 'Maintain employee details',
        fullNameLbl: 'Full Name',
        employeeIDLbl: 'Employee ID',
        companyLbl: 'Company',
        ern: 'ERN',
        galaCXyId: 'GalaCXy ID',
        searchValueError: 'Please enter the correct ERN / GalaCXy ID',
        incompleteErn: 'No matching result due to incomplete ERN input.',
        incompleteGalaCXyId: 'No matching result due to incomplete GalaCXy ID input.',
        noPermission: 'No permission',
        fields: {
          regulatoryRegion: 'Regulatory region',
          employeeClassification: 'Employee classification',
          terminationActionReason: 'Termination reason',
          revision: 'Profile version',
          isAccessible: '',
        },
      },
      travelHistoryReport: {
        title: 'Travel History Report',
        travelFrom: 'Travel from (DD/MM/YYYY)',
        travelTo: 'Travel to (DD/MM/YYYY)',
        travelType: 'Travel type',
        reportIsDownloaded: 'Travel history report is downloaded.',
        submit: 'Submit',
        inputValidDateRange: 'Travel date range should be within 1 year. ',
      },
      employeeProfile: 'Employee Profile',
      personalInformation: {
        profileDetails: {
          heading: 'Profile Details',
          title: 'Title',
          firstName: 'First name',
          middleName: 'Middle name',
          surname: 'Surname',
          preferredFirstName: 'Preferred first name',
          preferredLastName: 'Preferred last name',
          gender: 'Gender',
          dateOfBirth: 'Date of Birth',
          employeeID: 'Employee ID',
          company: 'Company',
          countryOfResidence: 'Country of residence',
          maritalStatus: 'Marital status',
          editProfilePopup: {
            title: 'You have unsaved changes',
            body: 'There are unsaved changes, click “Quit without saving” to discard all changes. Click "Stay on the page" to return to previous page.',
            withoutSaving: 'Quit without saving',
            missingInformation: 'Missing mandatory information',
            fillFields: 'Please fill in all mandatory fields',
            changesSaved: 'Changes has been successfully saved',
            inputValidDateRange: 'Please input a valid date range.',
            stayOnThePage: 'Stay on the page',
          },
        },
        employmentDetails: {
          heading: 'Employment Details',
          jobLevel: 'Job level',
          jobTitle: 'Job title',
          department: 'Department',
          contractType: 'Contract type',
          employmentType: 'Employment type',
          poiType: 'POI type',
          officerCode: 'Officer code',
          hrStatus: 'HR status',
          serviceStartDate: 'Service start date',
          serviceEndDate: 'Service end date',
          travelEndDate: 'Travel end date',
          resignationCode: 'Resignation code',
          employeeCategory: 'Employee category',
          fullOrPartTime: 'Full/Part-time',
          regularOrTemp: 'Regular/Temp',
          domicilePort: 'Domicile port',
          recruitmentPort: 'Recruitment port',
          countryOfEmployment: 'Country of employment',
          workingLocation: 'Working location',
          homePort: 'Home port',
          houseStaff: 'House staff',
        },
        paymentDetails: {
          title: 'paymentDetails',
          heading: 'Payment Details',
          paymentConcept: 'Payment mechanism',
          paymentMedium: 'Payment medium',
          billingAgreementSetup: 'Billing agreement setup',
          flownSuspensionStartDate: 'Flown suspension start date',
          flownSuspensionEndDate: 'Flown suspension end date',
          paymentSetUpStatus: 'Payment set up status',
          clearBillingAgreement: {
            title: 'Clear Billing Agreement ID',
            message: 'Are you sure to clear billing agreement ID?',
            notes: 'Please save any modifications made before clearing the billing agreement.',
            yesClear: 'Yes, clear',
            questionMark: '?',
            clearSucceed: 'Billing Agreement ID has been cleared.',
          },
        },
        benefitDetails: {
          heading: 'Benefit Details',
          benefitJobLevelDuty: 'Benefit Job Level - Duty',
          effectiveFromDateDuty: 'Benefit duty job level effective from',
          effectiveToDateDuty: 'Benefit duty job level effective to',
          benefitJobLevelLeisure: 'Benefit Job Level - Leisure',
          effectiveFromDateLeisure: 'Benefit leisure job level effective from',
          effectiveToDateLeisure: 'Benefit leisure job level effective to',
          dateEditFormat: '(DD/MM/YYYY)',
          profileStatus: 'Profile status',
          profileType: 'Profile type',
          age: 'Age',
          yearOfService: 'Year of service',
          companySeniorityDate: 'Company seniority date',
        },
        flownSuspensionDetails: {
          heading: 'Flown Suspension Details',
          flownSuspensionStartDate: 'Flown suspension start date',
          flownSuspensionEndDate: 'Flown suspension end date',
          newFlownSuspensionStartDate: 'New flown suspension start date',
          newFlownSuspensionEndDate: 'New flown suspension end date',
          reason: 'Reason',
          status: 'Status',
          title: 'flownSuspensionDetails',
          editFlownSuspensionRecord: 'Edit flown suspension record',
          adminEdit: 'Admin edit',
          NoFlownSuspensionRecord: 'Currently there is no records to display.',
          addFlownNote: 'Note: Click "Save" to add new flown suspension period.',
        },
        contactDetails: {
          heading: 'Contact Details',
          personalEmail: 'Personal email',
          officeEmail: 'Office email',
          notificationEmail: 'Notification email',
          phoneNumberMobile: 'Phone number (mobile)',
          phoneNumberOffice: 'Phone number (office)',
          addressDetails: 'Address details',
        },
        passportDetails: {
          heading: 'Passport Details',
          passportNumber: 'Passport number',
          firstOtherNameAsInPassport: 'First, other name as in passport',
          surnameAsInPassport: 'Surname as in passport',
          passportExpiryDate: 'Passport expiry date',
          nationalityInPassport: 'Nationality in passport',
          issuedCountry: 'Issued country',
          countryOfResidence: 'Country of residence',
        },
        retireeWidowBadgeInformationDetails: {
          heading: 'Badge Information',
          badgeNumber: 'Badge Number',
          badgeEffectiveDate: 'Badge effective date',
          badgeExpiryDate: 'Badge expiry date',
          title: 'retireeWidowBadgeInformationDetails',
        },
      },
      dependent: {
        name: 'Name',
        relationship: 'Relationship',
        nominationType: 'Nominee Type',
        age: 'Age',
        nominationFrom: 'Nomination From',
        nominationTo: 'Nomination To',
        nominationStatus: 'Status',
        backToDependentList: 'Back to dependent list',
        profileDetails: {
          heading: 'Profile Details',
          dependentType: 'Dependent Type',
          relationship: 'Relationship',
          title: 'Title',
          firstName: 'First Name',
          middleName: 'Middle Name',
          surname: 'Surname',
          gender: 'Gender',
          dateOfBirth: 'Date Of Birth',
          validFrom: 'Valid From',
          validTo: 'Valid To',
          dependentStatus: 'Dependent Status',
        },
        passportDetails: {
          heading: 'Passport Details',
          passportNumber: 'Passport Number',
          firstOtherNameAsInPassport: 'First, other name as in passport',
          surnameAsInPassport: 'Surname as in passport',
          passportExpiryDate: 'Passport expiry date',
          nationalityInPassport: 'Nationality in passport',
          issuedCountry: 'Issued country',
        },
        openUpNomination: {
          buttonLabel: 'Open up Nomination',
          title: 'Open up nomination',
          dependantName: 'Dependant name',
          relationship: 'Relationship',
          verifiedQuotaUsage: 'Verified quota usage',
          remarks: 'Remarks',
          addRemarks: 'Add remarks',
          remarksLimitCharacters: '/200 characters',
          confirmPopupTitle: 'Confirm to open up nomination? ',
          confirmPopupMessage:
            'Open up nomination for the below dependant will be performed immediately. Please confirm to proceed.',
        },
      },
      flownSuspensionStatusOptions: [
        {
          code: 'Active',
          label: 'Active',
        },
        {
          code: 'Inactive',
          label: 'Inactive',
        },
      ],
    },
    adminToggleList: {
      personalInformation: 'Personal Information',
      dependents: 'Dependents',
      note: 'Note',
      nominationHistory: 'Nomination History',
      quotaHistory: 'Quota history',
      concession: 'Concession',
      admin: 'Admin',
      all: 'All',
      profile: 'Profile',
      employment: 'Employment',
      payment: 'Payment',
      benefit: 'Benefit',
      contact: 'Contact',
      passport: 'Passport',
      flownSuspension: 'Flown Suspension',
      retireeWidowBadgeInformation: 'Badge Information',
    },
    concession: {
      removeTitle: 'Remove concession',
      removeDescription: 'Are you sure to remove concession and start over? All fields will be cleared. ',
      yesRemove: 'Yes, remove',
    },
    traveller: {
      yesSwitch: 'Yes, switch',
    },
    guide: {
      noticeMessage1:
        'Prior to creating any admin travel application in the Travel Hub admin site, you must read thoroughly the following terms of use and guidelines.',
      noticeMessage2: '(Published on 25 October 2022)',
      termsOfUse: {
        title: 'Terms of Use',
        content1:
          'You are now entering into a site that contains sensitive and confidential information. By logging in you hereby agree to be bound by the Terms of Use below. If you do not accept these terms of use, you cannot access or use the service.',
        content1List: [
          "The Travel Hub admin site is provided for Cathay Pacific (CX) employees who are appointed & authorized by their department head as a Travel Hub Administrator, and for the purpose of conducting admin booking and ticketing for company's business;",
          'All information shall be treated confidential and shall only be disclosed and used for authorized purpose(s) of the Company;',
          'All information provided in the admin travel application is accurate and complete;',
          'Admin travel application must be made with authorized approval.',
          'Admin travel application is made in line with the eligibility and entitlement of the admin travel concession.',
          'ID travel by employees is governed under the terms of the Concessional Travel Policy. Soft benefit waiver authorization (i.e. BAGW, UPGD, LGAC and FMUG) would be disallowed on all concession travel tickets unless specified in the policy.',
          "In accessing any personal information, you must also follow the Company's policy and guidelines on data privacy and any applicable laws;",
          'You shall not share your User ID and Password to any persons, including your colleagues, to prevent unauthorized access to and use of the facilities and restricted information;',
          'Do not sign on to Travel Hub in any PCs in public areas (e.g. Internet cafes, etc.) or PC whose owner is unknown. This is to avoid the possibility of user ID and Password being captured by hacking tools and used by unauthorized personnel to gain access to Travel Hub;',
          'You must close all browsers after use to prevent exposing your personal or other confidential information;',
        ],
        content2: 'As a user of Travel Hub admin site, I agree and accept the above Travel Hub Terms of Use.',
        readMore: 'Read more',
        readLess: 'Read less',
      },
      adminGuide: {
        title: 'Admin guide',
        content: [
          {
            title: 'What you should prepare prior to making travel arrangement?',
            list: [
              'Offline approval from the respective Cathay Pacific’s Department Head and/or Manager must be obtained.',
              'SAP’s WBS element (Project code) if the air travel is for an approved CX project.',
              'Cost centre and account code to be charged for the cost of the air ticket after flown.',
            ],
          },
          {
            title: 'Who should be the endorser of the admin travel application?',
            list: [
              'In general, Direct appraiser (job level C and above) or indirect appraiser (job level C and above) of the same reporting line (if Direct appraiser is job level A/B) will be defaulted as the endorser of the admin travel application.',
              'Admin travel application for individuals who are not CX employees but who are travelling at Cathay’s expense for the purpose of conducting the CX business (e.g. Consultant, CAD Inspector etc.), the CX Department Head and/or Manager who approved the travel arrangement shall be the endorser. The endorser in this case must be in the same department as the Travel Hub administrator who authorized to make the travel application(s) in the Travel Hub admin site.',
              'The endorser will receive a summary of travel application record(s) at the end of each application date via email.',
            ],
          },
          {
            title: 'What you should keep after the travel application is made?',
            list: [
              'All the documentation to support the offline approval must be kept for 7 years from the last date of travel.',
              'The documentation should indicate clearly of the purpose of travel, project name (if applicable), traveller’s details (Company name and job position, if applicable) and itinerary (e.g. travel date and routing).',
            ],
          },
          {
            title: 'Key notes for ‘Add traveller’',
            list: [
              'Each travel application can add one traveller.',
              'Individuals who are not CX employees but who are travelling at Cathay’s expense for the purpose of conducting the CX business (e.g. Consultant, CAD Inspector) are also required to comply with the nature and intent of the Cathay Duty Travel policy.',
              'Traveller’s salutation (Title), Last name, First and Other name are mandatory, and shall be the same as appeared on travel document (i.e. passport). Check-in may be denied if name in the booking & ticket is different from travel document.',
              'Traveller whose passport name is only one name (e.g. Louisa), please enter the name into both Last name and First and Other name box (i.e. <b>Last name: </b> Louisa, <b>First and other name:</b> Louisa).',
            ],
          },
          {
            title: 'Key notes for ‘Passport, country of residence and destination address’',
            list: [
              'Cathay is required by customs and immigration authorities of various countries to collect passport information, country of residence and destination address for pre-arrival clearance. It’s important that the information you provide is accurate and complete.',
              'The information that added in the travel application in Travel Hub will be pre-populated during online check-in.',
              'For travelling to the United States, traveller’s passport information and the US address must be provided in the travel application.',
              'For travelling to Canada, traveller’s passport information and country of residence must be provided in the travel application.',
              'For travelling from the United States and/or from Canada, traveller’s passport information must be provided in the travel application.',
              'For other itineraries, passport information and country of residence is optional, or for employee travel application, there is an option to clear the pre-populated passport/country of residence information. Traveller may provide the information at check-in online and/or at airport counter.',
            ],
          },
        ],
      },
    },
    notes: {
      fields: {
        createdDate: 'Created Date',
        lastModifiedDate: 'Last Modified Date',
        modifiedByName: 'Modified By',
        type: 'Type',
        subject: 'Subject',
        description: 'Description',
      },
      backToNoteList: 'Back to note list',
      notesDetails: 'Notes Details ',
      createNewNote: 'Create New Note',
      editNote: 'Edit Note',
      typePlaceholder: 'Select type',
      subjectPlaceholder: 'Add subject',
      descriptionPlaceholder: 'Add description',
      subjectLimitCharacters: '/100 characters',
      descriptionLimitCharacters: '/3000 characters',
    },
    dependents: {
      backToDependentList: 'Back to dependent list',
      currentNominee: 'Current Nominee',
      notNominated: 'Not Nominated',
    },
    nominationHistory: {
      name: 'Name',
      relationship: 'Relationship',
      age: 'Age',
      nominationFrom: 'Nomination From',
      nominationTo: 'Nomination To',
      lastUpdatedBy: 'Last Updated By',
      lastUpdateDate: 'Not in Use',
      remark: 'Remarks',
    },
    quotaHistory: {
      name: 'Name',
      dependentType: 'Dependent type',
      concessionName: 'Concession',
      travelFromDate: 'Travel from date',
      travelToDate: 'Travel to date',
      profileRevision: 'Profile revision',
      allottedTicket: 'Allotted ticket',
      used: 'Used',
      allotmentYear: 'Allotment year',
    },
    adminConcession: {
      fields: {
        concession: 'Concession',
        travelFromDate: 'Travel From Date',
        travelToDate: 'Travel To Date',
        allotedTicket: 'Alloted Ticket',
        used: 'Used',
        stfdType: 'STFD Type',
        bookingPriority: 'Booking Priority',
        bookingClass: 'Booking Class',
        regradePriority: 'Regrade Priority',
        regradeClass: 'Regrade Class',
        concessionStatus: 'Concession Status',
        name: 'Name',
        concessionGrouping: 'Concession Grouping',
        relationship: 'Relationship',
        dependantType: 'Dependant Type',
        noOfTicketsAlloted: 'No, of Tickets Alloted',
        unlimited: 'Unlimited',
        balance: 'Balance',
        economy: 'Economy',
        business: 'Business',
        allotmentDate: 'Not in use',
        suspendFromDate: 'Suspend From Date',
        suspendToDate: 'Suspend To Date',
        systemActions: 'System Actions',
        active: 'Active',
        inActive: 'Inactive',
        asBooked: 'As booked',
        action: 'Action',
        remark: 'Remark',
      },
      backToConcessionList: 'Back to concession list',
      concessionDetail: 'Details',
    },
  },
  booking: {
    from: 'Leaving from',
    to: 'Going to',
    travelPeriod: 'Travel period',
    searchBar: {
      date: 'Date',
      concession: 'Travel concession',
      dutyTravelConcession: 'Concession',
      leisureTravelConcession: 'Concession & traveller',
      otherTravelConcession: '',
      select: 'Select',
      cxPort: 'CX port',
    },
    fareType: {
      zone: 'Zone',
      foc: 'FOC',
      id50: 'ID50',
    },
    travelType: {
      type: 'Type',
      leisureTravel: {
        title: 'Leisure travel',
        travellers: 'Travellers',
        eligibleTravellers: 'Eligible travellers',
        nonEligibleTravellers: 'Non-eligible travellers',
        adults: 'Adults',
        aboveTwelve: '(12 or above)',
        children: 'Children',
        twoToEleven: '(2 to 11)',
        infantsWithSeat: 'Infant with seat',
        underTwo: '(under 2)',
        infantsWithoutSeat: 'Infant without seat',
        selectedTraveller: 'Selected traveller',
        accompanyPassenger: 'Please fill in the details of accompanying adult',
        headerTitles: {
          createItinerary: 'Create itinerary',
        },
        infantWithoutSeatOption: 'Infant without seat is required to travel with an adult traveller at all times.',
        ensureSufficient: 'Please ensure sufficient adult travellers are accompanying the infant/child travellers.',
        infantWithSeat: 'Infant with seat',
        infantWithoutSeat: 'Infant without seat',
        consent:
          'I declare that the above accompanying adult traveller is holding the same ticket type as the infant/child (e.g. both are holding zone tickets).',
        travelReminder: 'Choosing your priority',
        travelReminderContent:
          'If you, or your spouse or your children under age 24 must travel under the same booking as your Travel Nominees (siblings/ parents/ companion etc.), please select Priority 27. Otherwise, please issue bookings separately.',
        quotaElement: {
          label: 'Quota',
          unused: 'Unused',
          booked: 'Booked',
          limitQuotaRestriction:
            "The number of booked tickets is more than the traveller's entitle balance. Please note that bookings will be cancelled once the quota limit has been met.",
          emptyQuotaRestriction: 'Traveller do not have available quota.',
        },
        dependentTypeMustTravelWithEmpWarning:
          '{beneficiaryType} must be accompanied by employee (same ticket type as the {beneficiaryType}) when travel on {marketingCompany} flight.',
        childrenUnder16: 'Children under 16 must travel with an adult traveller who is 18 years old or above.',
      },
      dutyTravel: 'Duty travel',
      subLoad: 'SUBLO',
      noSubLoad: 'NOSUB',
      other: 'Other',
      concession: 'Concession',
      boardingPriority: 'Boarding priority',
      dtAdvanceBookingDays: 'Advance booking period',
      advanceBookingDays: 'Advance booking period',
      classNotAvailable: 'Cabin class not available',
      travelClass: 'Travel class',
      ugsaClass: 'UGSA class',
      priority: 'Priority',
      travelConcession: 'Travel concession',
      noTravelConcession: 'Sorry, we can’t find any travel concessions.',
      concessionTraveler: 'Concession & traveller',
      selectConcession: 'Select concession',
      traveller: 'Traveller',
      addTraveller: 'Add traveller',
      searchByConcession: 'Search by concession title',
      searchByErn: 'Search by ERN',
      searchByBooking: 'Search by booking reference',
      searchByErnOrBooking: 'Search by ERN or booking reference',
      removeAndRestart: 'Remove & start over',
      employeeTraveller: 'Employee traveller',
      nonEmployeeTraveller: 'Non-employee traveller',
      switchToNonEmployee: 'Switch to non-employee traveller',
      switchToEmployee: 'Switch to employee traveller',
      switchEmployeeDetails:
        'Are you sure to switch to employee traveller? All currently added non-employee traveller will be removed.',
      switchNonEmployeeDetails:
        'Are you sure to switch to non-employee traveller? All currently added employee traveller will be removed.',
      go: 'Go',
      noErnResult: 'Sorry, we can’t find a matching result.',
      searchErnNotEligible:
        'Selected employee is not eligible for the current concession, please reconfirm and try again.',
      tryAgain: 'Please try searching again.',
      editTraveller: 'Edit traveller',
      samePassportName: 'Same as appeared on passport',
      salutation: 'Salutation',
      preferredCarrier: 'Carrier options',
    },
    flightSearchResultCarrierOptions: {
      cx: 'CX',
      oneWorld: 'Oneworld',
      otherCarrier: 'Other carriers',
    },
    carrierGroupLabels: {
      cx: 'CX',
      otherCarrier: 'Other carriers',
      allCarrier: 'All carriers',
    },
    edit: {
      title: 'Edit your search',
      type: 'Type',
      originAndDestination: 'Origin and destination',
      carrier: 'Carrier',
      period: 'Period',
    },
    searchFlight: {
      filterPlaceholder: 'Search by flight number',
      noSearchResult:
        'Sorry, we are unable to find flights for the date specified. Please try again by selecting alternate dates.',
      noFilterResult: 'Sorry, we are unable to find any matched flights.',
      outOf: 'out of',
      nonStop: 'Non-stop',
      oneStop: '1 stop',
      stops: 'stops',
      embargo: 'Embargo flight - booking is not allowed',
      seatStatus: 'seat status',
      notAvailable: 'is not available',
      noMatch: "Sorry, we can't find a matching destination.",
      seatNotAvail: 'Seat not avail',
      booked: 'Booked',
      flightRestriction: 'Flight restriction',
      embargoFlight: 'Embargo flight',
      bookingNotAllowed: 'booking is not allowed',
    },
    flightClassStatus: {
      waitList: 'Waitlist',
      standby: 'Standby',
      confirm: 'Confirm',
      notAvail: 'Not avail',
    },
    flightClass: {
      title: 'Seat class',
      first: 'First',
      business: 'Business',
      premiumEconomy: 'Premium economy',
      economy: 'Economy',
      select: 'Select your seat class',
      backSearchResult: 'Back to flight search result',
      backSeatOptions: 'Back to select cabin class',
      LTBackSeatOptions: 'Back to concession and cabin class',
      backTravelDetails: 'Back to duty travel details',
      backTravelOptions: 'Back to select traveller',
      backReviewAndConfirm: 'Back to review and confirm',
      seatAvailability: 'Please select from the available cabin class(es)',
    },
    travelDetail: {
      title: 'Duty travel details',
      purpose: 'Duty travel purpose',
      purposeOptions: {
        meeting: 'Meeting',
        training: 'Training',
        familiarization: 'Familiarization',
        conference: 'Conference',
        project: 'Project',
      },
      description: 'Duty travel description',
      descriptionSubTitle: 'E.g 2021 aviation conference, from 2 Aug - 6 Aug 2021',
      endorserGalaCXyId: 'Endorser',
      projectCode: 'WBS element (Project code)',
      costCentre: 'Cost centre',
      accountCode: 'Account code',
      flightDetail: 'Flight details',
    },
    selectFlight: {
      selecting: 'Selecting',
      roundTrip: 'Round-trip',
      oneWay: 'Book one-way',
      to: 'to',
      selected: 'Selected',
      flightBookingCart: 'Flight booking cart',
      clear: 'Clear',
      selectAFlight: 'Select a flight',
      travelRequirements: 'Travel requirements',
      readMore: 'Read more',
      exceedAdvanceBookingPeriod: {
        title: 'Exceeded advance booking period',
        message:
          'The maximum advance booking period for this concession is %s. Please choose another concession or date of travel.',
        unit: {
          day: 'days',
          month: 'months',
          year: 'years',
        },
      },
    },
    flightConfirmation: {
      fareDetails: {
        title: 'Ticket price',
        travellerType: 'Traveller type',
        noOfPassengers: 'No. of travellers',
        baseFare: 'Base fare',
        tax: 'Tax',
        total: 'Total',
        // TODO: hard code in R1 for adult & 1 pax, remove in R2
        adult: 'Adult',
        onePassenger: '1',
      },
      LTFareDetails: {
        title: 'Ticket cost',
        travelerType: 'Traveller type',
        noOfPassengers: 'No. of travellers',
        baseFare: 'Base fare',
        tax: 'Tax',
        total: 'Total',
        grandTotal: 'Grand total',
        totalNoOfPassengers: 'Total no. of travellers',
      },
      travelDetails: {
        title: 'Duty travel (Level C)',
        flownDetails: {
          title: 'Usage/ payment details',
          processDate: 'Ticket processed date',
          actualClass: 'Actual travel class',
          actualDate: 'Actual travel date',
          actualFlight: 'Actual travel flight',
          viewMore: 'View more',
          viewLess: 'View less',
          flown: 'Flown',
        },
        travellerDetails: {
          title: 'Traveller Details',
          info: 'Traveller Info',
          travellerName: 'Traveller Name',
          countryOfResidential: 'Country of residence',
        },
        passportDetails: {
          info: 'Passport info',
          title: 'Passport details',
          subTitle: 'For this booking only',
          passportName: 'Passport name',
          passportNumber: 'Passport number',
          dateOfBirth: 'Date of birth',
          expiryDate: 'Expiry date',
          gender: 'Gender',
          nationality: 'Passport nationality',
          issuingCountry: 'Country of issue',
          warning: 'Please edit your passport details',
          isClearOrFillIn: {
            youMayEither: 'You may either',
            clear: ' clear ',
            or: 'or',
            fillIn: ' fill in ',
            allFields: 'all the details. Any updates will only apply to this booking.',
            toBeTrimmed: 'If your passport name is being ',
            trimmed: 'trimmed',
            dueTo:
              ' due to the name length limitation, please ensure your passport information is updated during check-in.',
          },
        },
        usAddress: {
          title: 'US address',
          street: 'Street',
          city: 'City',
          state: 'State',
          zipCode: 'Zip code',
        },
        contactDetails: {
          title: 'Contact details',
          phoneNumber: 'Mobile phone number',
          type: 'Email type',
          email: 'Email address',
          multipleEmailLabel: 'Email',
          addEmail: 'Add email',
          options: [
            {
              code: 'WORK',
              label: 'Work',
            },
            {
              code: 'PERSONAL',
              label: 'Personal',
            },
            {
              code: 'OTHER',
              label: 'Other',
            },
          ],
        },
        dutyTravelDetails: {
          title: 'Duty travel details',
          endorserGalaCXyId: 'Endorser',
          dutyTravelPurpose: 'Duty travel purpose',
          tellUsMore: 'Tell us more',
          travelDescription: 'Travel description',
        },
        paymentDetails: {
          title: 'Payment details',
          projectCode: 'WBS (Project code)',
          costCentre: 'Cost centre',
          accountCode: 'Account code',
          paymentConcept: 'Payment mechanism',
          paymentMedium: 'Payment medium', // ETP-2431
          paymentStatus: 'Payment status', // ETP-2431
          paymentFailHeader: 'Payment unsuccessful', // ETP-2431
          // ETP-2431
          PaymentFailBody: 'Payment unsuccessful, ticket is not issued. Please try again later.', // ETP-2431
          notPaid: 'Not paid', // [ETP-4562] keep text in frontend side, aligned with backend team before.
        },
      },
      fillInfo: 'Fill in duty travel information',
      reviewBooking: 'Review and create booking',
    },
    confirmation: {
      done: 'Done',
      ticketPurchaseCompleted: 'Your booking is ready',
      ticketWillUpdate:
        'Ticket details will be updated shortly in Travel Hub. You may travel with the e-ticket received by email. Please contact GBS via My Cases for assistance if needed.',
      heading2: 'completed.',
      bookingReference: 'Booking reference',
      bookingReferenceWarning: 'Please enter the 6 characters booking reference',
      bookingRef: 'Booking Ref.',
      concession: 'Concession',
      backHomepage: 'Back to home',
      viewDetails: 'View details',
      ticketNumber: 'Ticket number',
      ticketDetails: {
        title: 'Ticket details',
        couponStatus: 'Coupon status',
        baggageAllowance: 'Baggage allowance',
        baggage: {
          start: '(up to',
          end: 'each bag)',
        },
        dateOfIssue: 'Date of issue',
      },
      cancelBooking: 'Cancel booking',
      retrieveAndPay: 'Retrieve and Pay',
      mandatory: 'This is a mandatory field',
      associatedWith: 'Associated with:',
      checkInStatusWarningMessage:
        'You have already processed Check-in or Standby Registration, please withdraw it prior to cancel this booking or listing. If you cannot board the current flight, you will be roll over to the next flight automatically.',
      confirmCancel: {
        title: 'Review and confirm to cancel',
        flightNo: 'Flight no.',
        flightDate: 'Flight date',
        flightTime: 'Flight time',
        tripType: 'Trip type',
        od: 'From/to',
        cabinClass: 'Cabin class',
        status: 'Status',
      },
      removePax: {
        breadcrumbs: {
          removeTraveller: 'Remove Traveller',
          reviewConfirm: 'Review and Confirm',
        },
        removeTraveller: 'Remove traveller',
        selectToRemove: 'Please select whom to remove:',
        paxIsRemoved: 'This traveller is being removed.',
        warningMessage: {
          infantAssociated: 'Both the infant and the associated adult will be removed.',
          updateInfo: 'Please update the info below',
        },
        confirmRemovePaxDialog: {
          description: 'Confirm to remove traveller(s) below:',
          yes: 'Yes, remove',
          no: 'No',
        },
        removedTravellers: 'Removed traveller(s)',
        removeAllPassengers: 'Removing all travellers will cancel this booking, confirm to proceed?',
        removeAdultPassengers:
          'Removing adult traveller will cancel this booking as insufficient adult accompanying infant/child travellers, confirm to proceed?',
        nonEligibleTravellers: 'Non-eligible for remove travellers',
      },
      cancelActions: {
        moreActions: 'More Actions',
        selectOptions: 'Please select',
        cancelOnly: 'Cancel booking',
        removeTravellers: 'Remove Travellers',
        rebook: {
          title: 'Rebook',
          rebookWithTicket: 'Rebook with existing ticket',
          newFlightDate: 'New flight date',
          editFlightDate: 'Please enter the flight date for your rebook ticket.',
          confirmNote:
            'Please confirm your rebook ticket, and please note that you cannot change your cabin class from original booking.',
          cancelChanges: 'Confirm cancel rebook',
          confirmCancel: 'By confirming this message, all changes made will be cleared.',
          haveRebooked: 'You have rebooked with an existing ticket.',
          backToBookingDetail: 'Back to booking details',
          backToFlightDetail: 'Back to booked flight details',
          backToSearchResult: 'Back to search results',
          backToRebookDetail: 'Back to rebook flight details',
          naTicketClass: 'Ticketed class N/A',
        },
        refund: {
          refundOnly: 'Refund',
          cancelAndRefund: 'Cancel and refund',
          confirmRefund: 'Confirm to refund your tickets?',
          refundWarningMsg: 'Pending for refund',
          prepaidRemovedPaxWarningMsg:
            'Traveller has been removed by the system. If you click “Cancel and refund”, the refund will process for all travellers. ​Note that if you only want to refund for the removed traveller, please submit the refund after the travel has been completed.​',
          refundSnackBarMsg: 'A refund is submitted successfully.',
          refundBookingOverlayTitle: 'Refund',
          cancelAndRefundBookingOverlayTitle: 'Cancel and refund',
          bookingReference: 'Booking Reference',
          fullRefund: 'Full Refund',
          downgradeRefund: 'Downgrade Refund',
          reviewRefund: 'Review and confirm to Refund',
          od: 'From/to',
        },
        notRefund: 'Refund not submitted',
      },
      open: 'OPEN',
      ticketOnly: 'Ticket only',
    },
    cancelBooking: {
      title: 'Cancel booking',
      subTitle: 'Are you sure to cancel this booking?',
      subTitleModified: 'Your booking is being modified outside of Travel Hub. Are you sure to cancel this booking?',
      snackbar: {
        firstPart: 'Your booking ',
        secondPart: ' is cancelled',
      },
    },
    cancel: {
      genericCancel: 'This booking is cancelled by',
      userCancel: 'This booking is cancelled',
      systemCancel: 'This booking is cancelled by system',
      delegateeCancel: 'This booking is cancelled by your delegatee',
      autoCancelBA: 'Please waitlist in BA system',
      existNotRefundWarning:
        'This booking is cancelled. Refund can only be done within 365 days from date of issue, these tickets are no longer valid.',
      existRebookAndRefundWarning: 'This booking is cancelled. The tickets are still valid for rebooking or refund.​',
      onlyRefundWarning: 'This booking is cancelled. The tickets are still valid for refund.',
      onlyRebookWarning:
        'This booking is cancelled. The tickets are valid for rebooking only. Refund can only be done within 365 days from date of issue.',
    },
    create: {
      genericCreate: 'This booking is created by your',
      delegateeCreate: 'delegatee',
    },
    modified: {
      outside: 'Modified outside Travel Hub',
      details: 'Below flight details as of: ',
      hkt: 'HKT',
      errorRetrieve: 'Booking cannot be retrieved',
    },
    breadCrumbs: {
      seatOptions: 'Select Cabin class',
      travelDetails: 'Duty travel details',
      confirm: 'Review and confirm',
    },
    ltBreadCrumbs: {
      travellerOptions: 'Select traveller',
      seatOptions: 'Concession and cabin class',
      confirm: 'Review and confirm',
    },
    unUc: {
      noProtection: {
        message: ' is not operated. This travel application is marked as ‘Cancelled’',
      },
      protected: {
        message: ' is not operated and being protected',
        originalFlight: ' original flight',
      },
      protectedDifferentCarrier: {
        message1: ' is not operated. Please contact GBS via ',
        message2: 'My Cases',
      },
    },
    legends: {
      title: 'Legends',
      desc: {
        laugh: 'Overall booking below 86%',
        smile: 'Overall booking between 86% and 98%',
        sad: 'Overall booking above 98%',
      },
      remarks: [
        'A minus sign <b>(-)</b> denotes this cabin is overbooked by the actual number shown on the display.',
        'An asterisk sign <b>(*)</b> denotes this cabin is overbooked by <b>more than</b> the number shown on the display.',
        'A plus sign <b>(+)</b> denotes the available seats in this cabin is <b>more than</b> the number shown on the display.',
      ],
    },
    cabinClass: {
      first: {
        full: 'First',
        short: 'F',
      },
      business: {
        full: 'Business',
        short: 'J',
      },
      premiumEconomy: {
        full: 'Premium economy',
        short: 'W',
      },
      economy: {
        full: 'Economy',
        short: 'Y',
      },
    },
    filter: {
      travelType: 'Travel Type',
      travelPeriod: 'Travel Period',
    },
    nonEnglishCharacter: {
      errorMes: "Non-English characters detected in traveller's name",
      title: "Non-English characters detected in traveller's name",
      message:
        'Names containing non-English characters may affect the booking creation process. For HKG based employee please contact GBS via MyCases, for outport employee please contact local People team. So they can help to update your information.',
      viewMore: 'View more',
      viewLess: 'View less',
      hkg: 'Names containing non-English characters may affect the booking creation process. If you are based in Hong Kong, contact GBS team via MyCases so they can help to update your information.',
      notHkg:
        'Names containing non-English characters may affect the booking creation process. If you are based in Outports, contact your local People team so they can help to update your information.',
      defaultFirstMsg: 'You have ',
      defaultSecondMsg: ' important messages that may affect your booking.',
    },
    retrieveAndPay: {
      errorMessage:
        'This booking remains but require your action. If you wish to travel, you must prepay with a credit card for a new ticket by selecting “ Retrieve and pay“. Otherwise you should cancel it by selecting “Cancel booking” or “Remove traveller”​',
      travellerDetailDisplay: 'No valid ticket. Please review and pay to issue a new ticket.​',
      confirmAndPaySuccess: 'Your payment is received.',
      confirmAndPayFail: 'Payment failure.',
      confirmAndPayErrorMes: 'The ticket price is updated. Please proceed payment by selecting “Confirm”',
      title: 'Booking details',
      backToBookingDetails: 'Back to booking details',
      loadingText: 'We are processing your payment, please wait for a moment.',
      errorTitle: 'Unable to proceed for payment',
      notAllowRetrieveAndPaySegmentStatusPNRMessage:
        'Your booking segment of %s has been updated. Unable to proceed for payment and ticket is not yet issued. Please contact GBS for assistance.',
      notAllowRetrieveAndPaySegmentStatusWarningMessage:
        '1. Your booking segment has been updated. Unable to proceed for payment and ticket is not yet issued. Please contact GBS for assistance.',
    },
  },
  info: {
    usefulLinks: 'Useful links',
    employeeTravelPortal: 'Employee Travel Portal',
    flyReady: 'Fly Ready',
    travelRequirements: 'Travel requirements',
    onlineCheckIn: 'Online check-in',
    quickReferenceGuide: 'Quick reference guide',
    dTSecurityAdvisory: 'Duty Travel Security Advisory level',
    safetyAndSecurityPolicy: 'Safety & Security Policy - Cathay Duty Travel Arrangement',
  },
  error: {
    required: 'This is a mandatory field',
    invalidFormat: 'Invalid data format', // TODO: remove invalidFormat in common
    invalidEndorser: 'Please input a vaild endoser',
    invalidEmail: 'Please input a vaild email address',
    docaMax61Char: 'Please keep it within 61 characters',
    cancelBooking: 'Unable to cancel this booking. Please try again later or contact GBS via My Cases for assistance.',
    oldClientVer: 'Your Travel Hub version is not up-to-date, please refresh the website.',
    invalidAgeFirst: 'Travellers under',
    invalidAgeSecond: 'are not eligible to travel in',
    userNotMatch: 'User not match',
    networkError: 'Network Error',
    domicileOnlyRestriction: 'Domicile only',
    personalEmailMissing: 'Personal email is missing',
  },
  errorAlert: {
    searchFlight: 'Unable to find any flights',
    getFare: 'Unable to retrieve fare information',
    getFareAndTravellerInfo: 'Unable to retrieve fare/traveller information',
    bookFlights: 'Unable to create booking',
    getBooking: 'Unable to retrieve booking',
    getUpcomingBooking: 'Unable to retrieve upcoming booking',
    getMyBookings: 'Unable to retrieve booking',
    getDocsDoca: 'Unable to retrieve US passport/address details',
    getTravellerName: 'Unable to retrieve traveller name',
    cancelBooking: 'Unable to cancel booking',
    refundBooking: 'Unable to refund booking',
    getAirports: 'Unable to retrieve airport information',
    getConfigurations: 'Unable to retrieve configuration',
    getLabels: 'Unable to retrieve labels',
    getProfile: 'Unable to retrieve profile information',
    getPassport: 'Unable to retrieve passport info',
    getDependent: 'Unable to retrieve dependent info',
    getEmployee: 'Unable to retrieve employee info',
    getSuggestion: 'Unable to retrieve suggestion list',
    getNotes: 'Unable to retrieve note list',
    noteUpsert: 'Unable to update note',
    getConcessions: 'Unable to retrieve concession list',
    employeeUpdate: 'Unable to update employee',
    clearBillingAgreement: 'Unable to clear billing agreement',
    inactiveNomination: 'Unable to open up nomination',
    noteDelete: 'Unable to update note',
    getEndorser: 'Unable to retrieve endorser',
    getConcession: 'Unable to retrieve concession information',
    getConcessionEmpNonEmp: 'Unable to retrieve concession employee or non-employee information',
    getHistoryReport: 'Unable to retrieve admin travel history report',
    validateEndorser: 'Unable to validate endorser ',
    genericTitle: 'Technical issue',
    genericMessage: 'Please try again',
    helpYouToLogout: 'You are now logging out from Travel Hub',
    amadeusTimeoutIssueTicket: 'Connection timeout with Amadeus',
    changeRole: 'Unable to change role',
    getRole: 'Unable to retrieve user role',
    userNotAuthorized: 'User not authorized',
    getErnEmpTraveller: 'Unable to search employee information',
    delegationUnsuccessful: 'Delegation unsuccessful',
    getNominatedPassenger: 'Unable to get nominated travellers',
    getLtConcession: 'Unable to get LT concession list',
    getFareAndTravellerLT: 'Unable to get LT getFareAndTravellerLT details',
    createBillingAgreementToken: 'Unable to create billing agreement token',
    createBillingAgreementId: 'Unable to create billing agreement id',
    paymentHistory: 'Unable to get payment history list',
    removePassenger: 'Unable to remove travellers',
    changeAcknowledged: 'Unable to change acknowledged',
    prepaidAuth: 'Unable to authorise payment',
    getAllotmentDetails: 'Unable to get allotment details',
    addCompanionRegistration: 'Unable to add companion registration',
    editCompanionPassport: 'Unable to edit travel companion passport details',
    nominationViewModeList: 'Unable to get nomination view mode list',
    nominationSelectionList: 'Unable to get nomination selection list',
    saveNomination: 'Unable to save nomination',
    updateSetupCountryOfResidential: 'Not able to update your country of residence',
    domicileOnlyRestriction: {
      msg1: 'Domicile only restriction violated: {Origin} to port of domicile ({Destination}), Port of domicile ({Destination}) to {Origin}.',
      msg2: '\n\nPlease input valid origin and destination as per the domicile only restriction shown.',
    },
  },
  notAuthorized: {
    title: 'Oops, we are sorry...',
    accessForbidden: '403 Access Forbidden',
    message: "You don't have access permission to Travel Hub.",
  },
  systemMaintenance: {
    title: 'Oops, we are under maintenance',
  },
  exampleOfAccountCode: {
    title: 'Example of account codes',
    list: [
      '71604020 (Employee functional training expenses)',
      '71604025 (Employee non-functional training expenses)',
      '71703000 (Consultancy)',
      '71902000 (Conference expenses)',
      '71906000 (Employee travelling expenses)',
    ],
  },
  payment: {
    replaceTarget: 'click here',
    link: 'link', // ETP-2431
    directDebit: {
      warningTitle: 'Direct Debit account is not set up',
      commonWarning: 'You have not set up your direct debit account. Please click here to visit instructions',
      rejectedWarningTitle: 'Direct debit set up unsuccessful',
      rejectedWarning: 'Your direct debit set up is being suspended, please contact GBS via MyCases.',
      myCasesLink: 'MyCases',
    },
    // ETP-2431
    suspension: 'Your payment mechanism changes from flown to prepaid effective %. For more details please refer to ',
    paypal: {
      warningTitle: 'Paypal billing agreement is not created',
      commonWarning:
        'As you have not created a PayPal billing agreement, leisure travel bookings will not be available until this has been set up. Please click here to do this.',
      // [ETP-4266] full message: unableRetrievePaypalDetail + HKGEmployee or outportEmployee
      unableRetrievePaypalDetailTitle: 'Unable to retrieve PayPal billing agreement details',
      unableRetrievePaypalDetailWarning:
        'We are unable to retrieve your PayPal details, please refresh the page or try again later.',
      billingAgreement: {
        title: 'PayPal billing agreement',
        agreement: 'I hereby agree to authorise Cathay Pacific Airways to initiate my future transactions.',
        success: 'Your PayPal billing agreement created successfully.',
      },
      // [ETP-4372]
      notCompletedDialog: {
        title: 'Country of residence update and billing agreement setup are not completed',
        description:
          'Country of residence update and billing agreement setup are not completed. You have to restart the whole process if you quit before completing it.',
        leftButton: 'Quit',
        rightButton: 'Back to setup',
      },
    },
    prepaid: {
      errorDuringRedirectCPP: 'Error during redirect to CPP',
      noCppSignature: 'No cpp signature',
      cppBackReason: 'User click back to Travel Hub', // [ETP-4379] cpp error handling
      unableToProceedPleaseReOpenWebsite:
        'Unable to proceed, please close and re-open the website to retry the process', // [ETP-4379] transit page error handling
    },
    detailsIncomplete: {
      warningTitle: 'Payment details incomplete',
      commonWarning: {
        base: 'Your payment details are incomplete.',
        HKGEmployee: 'For HKG based employee please contact GBS via MyCases.',
        outportEmployee: 'For outport employee please contact local PPL office.',
      },
    },
    // [ETP-4372] setup country of residence
    countryOfResidential: {
      updateCountryOfResidentialButton: 'Update Country of Residence',
      commonWarning:
        'You have not declared your country of residence. Please click here to set up. Your payment medium is set to empty until country of residence is declared, which would affect flight booking.',
      paypalCommonWarning:
        'You have not created billing agreement for PayPal transaction. Please click here to create billing agreement. You can still search for filghts but flight bookings are not allowed',
      successfulUpdateSetup: 'Your country of residence set up is completed successfully.',
      successfulUpdateSetupWithPayPal:
        'Your billing agreement with PayPal and country of residence setup are completed successfully.',
    },
  },
  labels: {
    maritalStatusOptions: ['Married', 'Not Married'],
    employmentStatusOptions: ['Active', 'Inactive'],
  },
  dateUnit: {
    years: 'years',
    months: 'months',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  },
  multipleWarning: {
    noBp: '{no BP}',
    email: 'email',
    link: 'link',
    mailto: 'mailto:',
    open: 'OPEN',
    youHave: 'You have',
    messageLength: ' message regarding your booking',
    OAL: {
      listingInformation: 'listing information',
      OALWarning: {
        AI: {
          title: 'AIR INDIA',
          replaceContent: [
            {
              matchText: '{AI Call Centre}',
              displayText: 'AI Call Centre',
              link: 'https://www.airindia.com/in/en/customer-support.html',
              type: 'link',
            },
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made via the {AI Call Centre} to the scheduled time of departure.',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        PX: {
          title: 'AIR NIUGINI',
          replaceContent: [
            {
              matchText: '{email}',
              displayText: 'email',
              link: 'interline@airniugini.com.pg',
              type: 'email',
            },
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made by sending the below information to PX via {email} during PX business hours Monday to Friday 0900-1700 hrs (POM local time). \n 1. Employee/Retiree name \n 2. Employee/Retiree ID number (ERN) \n 3. Ticket number, traveller name, relationship, date of birth, passport information \n 4. Itinerary (flight number, travel date, routing) \n',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        SU: {
          title: 'AEROFLOT',
          replaceContent: [
            {
              matchText: '{link}',
              displayText: 'link',
              link: 'https://www.ID90TRAVEL.com',
              type: 'link',
            },
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made via {link}',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        AT: {
          title: 'ROYAL AIR MAROC',
          replaceContent: [
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made through the AT sales office during their business hours.',
            '{no BP}During rest days, please contact their international call center at 089 000 0800 or 00 212 5 22 48 97 97',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        BA: {
          title: 'British Airways',
          replaceContent: [
            {
              matchText: '{link}',
              displayText: 'link',
              link: 'https://idtravel.ba.com/',
              type: 'link',
            },
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made via {link}',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        MH: {
          title: 'Malaysian Airlines',
          replaceContent: [
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Travel companion must be accompanied by employee (same ticket type as the travel companion) when travel on MH',
            'Listing is required for all flights, and shall be made via MH call centre by calling 1300 88 3000 (within Malaysia), and +603-7843 3000 (outside Malaysia).',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        QR: {
          title: 'Qatar',
          replaceContent: [
            {
              matchText: '{link}',
              displayText: 'link',
              link: 'https://stafftravel.qatarairways.com.qa',
              type: 'link',
            },
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be made via {link}',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
        UL: {
          title: 'SRILANKAN',
          replaceContent: [
            {
              matchText: '{Employee Travel Portal}',
              displayText: 'Employee Travel Portal',
              link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/SitePage/138016/interline-agreements',
              type: 'link',
            },
          ],
          content: [
            'Listing is required for all flights, and shall be contact UL Call Centre at +94 77 777 1979.',
            'Please follow the proper booking time and listing requirements of each interline, more details on {Employee Travel Portal}',
            'Children under 16 must travel accompanied by an adult holding the same type of ticket.',
          ],
        },
      },
    },
    DT: {
      securityReminder: 'Security reminder for duty travellers',
      securityReminderJson: {
        message1: {
          title: '',
          replaceContent: [
            {
              matchText: '{Safety & Security Policy – Cathay Duty Travel Arrangement}',
              displayText: 'Safety & Security Policy – Cathay Duty Travel Arrangement',
              link: 'https://hub.cathaypacific.com/redir/281304',
              type: 'link',
            },
            {
              matchText: '{Duty Travel Security Advisory level}',
              displayText: 'Duty Travel Security Advisory level',
              link: 'https://hub.cathaypacific.com/redir/281017',
              type: 'link',
            },
          ],
          content: [
            'Please ensure you read the {Safety & Security Policy – Cathay Duty Travel Arrangement} and note the {Duty Travel Security Advisory level} of your destination before making any travel arrangements.',
          ],
        },
      },
    },
  },
};

export default en;
