import { Box, StepIconProps, Typography, TypographyProps, useTheme } from '@mui/material';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

const DefaultStepIcon = (props: StepIconProps & { step: number; stepLabelVariant: TypographyProps['variant'] }) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { active: isActiveStep, completed: isCompletedStep, step, stepLabelVariant } = props;

  const isActiveOrCompleted = isActiveStep || isCompletedStep;
  const activeOrCompletedColor = isActiveOrCompleted
    ? theme.palette.cathayJadeLight.contrastText
    : theme.color.secondary.light_slate.option_3;

  const fontColor = isActiveOrCompleted ? 'white' : theme.color.secondary.dark_grey.option_3;

  return (
    <Box
      className="progressbar_default_step_icon"
      sx={{
        width: isDesktop ? '24px' : '20px',
        height: isDesktop ? '24px' : '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: `1px solid ${activeOrCompletedColor}`,
        color: fontColor,
        backgroundColor: activeOrCompletedColor,
      }}
    >
      <Typography
        variant={stepLabelVariant}
        sx={{
          color: fontColor,
        }}
      >
        {step}
      </Typography>
    </Box>
  );
};

export default DefaultStepIcon;
