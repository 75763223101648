import { ISelectedFlight, IFlightSectors } from '../interfaces';

const constructFlightSectors = (flight: ISelectedFlight | null, customParams?: any) => {
  const {
    departurePort,
    arrivalPort,
    departureDate,
    marketingCompany,
    flightNo,
    departureTime,
    arrivalTime,
    availability,
  } = flight || {};

  const flightSectors: IFlightSectors[] = [
    {
      departurePort: departurePort || '',
      arrivalPort: arrivalPort || '',
      departureDate: departureDate || '',
      marketingCompany: marketingCompany || '',
      flightNo: flightNo || '',
      departureTime: departureTime,
      arrivalTime: arrivalTime,
      availability,
      ...customParams,
    },
  ];

  return flightSectors;
};

const isContainNegativeSign = (text: string) => {
  return text?.includes('-');
};

const isContainPositiveSign = (text: string) => {
  return text?.includes('+');
};

const clearSign = (text: string) => {
  return text.replace('+', '').replace('-', '').replace('*', '');
};

const isEqualToZero = (text: string) => {
  return clearSign(text) === '0';
};

export { constructFlightSectors, isContainNegativeSign, isContainPositiveSign, clearSign, isEqualToZero };
