import { useContext } from 'react';
import { Theme, SxProps } from '@mui/material';
import _ from 'lodash';

import {
  NOMINATION_TYPE,
  NOMINATION_FLOW_PAGE_NO,
  NOMINATION_SUB_ROUTE_PAGE,
} from '../../../../../constants/constants';
import en from '../../../../../translations/en';

import { INominationViewModeItem } from '../../../../../interfaces';
import {
  IContentProps,
  IParagraphVariantProps,
} from '../../../../../containers/Common/Paragraph/ParagraphMasterContainer';

import { getDisplayName } from '../../../../../helpers';

import { NominationAction, NominationState } from '../../../../../context';

import { CartContainer, DependentCartItem, MessageCartItem, ParagraphMasterContainer } from '../../../../../containers';

export interface INominationCartListProps {
  component: React.ComponentType<any>;
  dependentDetail?: {
    dependentId: string;
    dependentName: string;
    relationship: string;
    eligibleCarrier: string[];
    isActive: boolean;
    isLocked: boolean;
    isViewMode: boolean;
    isGreyedOut: boolean;
    isSelected: boolean;
    changeTypeLabel: string;
    handleAction?: ({ selectIndicate }: { selectIndicate?: boolean }) => void;
    warningMessage: string;
  };
  content?: IContentProps[];
}

export interface INominationCartMasterProps {
  list?: INominationViewModeItem[];
  secondCartItemList?: INominationViewModeItem[];
  maxNumberOfPerson?: number;
  maxNumberOfSelectableNominees?: number;
  nominationType: string;
  isViewMode: boolean;
  isAllowAddToCart?: boolean;
  containerCustomProps?: SxProps<Theme>;
  addNomineeToCartAction?: () => void;
}

const NominationCartMasterContainer = ({
  list = [],
  secondCartItemList = [],
  maxNumberOfSelectableNominees,
  maxNumberOfPerson,
  nominationType,
  isViewMode = false,
  isAllowAddToCart = false,
  containerCustomProps,
  addNomineeToCartAction,
}: INominationCartMasterProps) => {
  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const isPlusOne = nominationType === NOMINATION_TYPE.plusOne;

  const headerTitle =
    (nominationFlowLbl?.[nominationType as keyof typeof nominationFlowLbl] as { title?: string })?.title || '';
  const headerDescription = isPlusOne ? nominationFlowLbl.plusOne.viewModeHeaderDescription : '';

  const numberOfNominatedPerson = list.length || 0;

  const isShowStar = isPlusOne;

  const { nominationTransformData, nominationFlow, nominationChangeTypeData } = useContext(NominationState) || {};
  const { setNominationTransformData, setNominationChangeTypeData } = useContext(NominationAction) || {};

  const { step: routeToStep } = nominationFlow?.nominationFlowRouteTo || {};
  const { nominationSubRoutePage } = nominationFlow || {};
  const isNominationCartPage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationCart;
  const isNotNominatedDependentsSelection =
    nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection;
  const isNominationViewModePage = routeToStep === NOMINATION_FLOW_PAGE_NO.nominationViewMode;

  const nominationViewModeCartItemMargin = '-29px';

  const transformNominationViewModeList = (list: INominationViewModeItem[], isShowTypeChangeSection: boolean) => {
    let tmpList = [];

    if (list.length > 0) {
      tmpList = list?.map((item, index) => {
        return {
          component: DependentCartItem,
          dependentDetail: {
            dependentId: item.dependentId,
            dependentName: getDisplayName({
              firstName: item.firstName,
              lastName: item.lastName,
            }),
            relationship: item.beneficiaryType,
            eligibleCarrier: item.eligibleCarrier,
            isActive: item.isActive,
            isLocked: item.isLocked || false, // show lock icon
            isViewMode, // true for view mode display | false for selection mode display
            isGreyedOut: item.isGreyedOut || false, // show greyed out style
            isSelected: item.isSelected,
            changeTypeLabel: isShowTypeChangeSection && isNotNominatedDependentsSelection ? headerTitle : '',
            // [ETP-4646] Nomination View mode page -> display warning message for allotment in progress
            warningMessage:
              (isNominationViewModePage &&
                item?.reallotmentStatus &&
                nominationFlowLbl.dependentWarning.reallotmentStatus?.[
                  item?.reallotmentStatus.toLowerCase() as keyof typeof nominationFlowLbl.dependentWarning.reallotmentStatus
                ]) ||
              '',
            handleAction: ({ selectIndicate }: { selectIndicate?: boolean }) => {
              const transformList = [];

              // check if "change type" data "tick" action
              const isChangeTypeDataToHandle = nominationChangeTypeData.some(
                (data: INominationViewModeItem) => data.dependentId === item.dependentId,
              );

              // check to handle data is from "ChangeType" list or "Transform" list
              const tempData = isChangeTypeDataToHandle ? nominationChangeTypeData : nominationTransformData;

              for (const transformItem of tempData) {
                if (item.dependentId === transformItem.dependentId) {
                  // select and unselect action
                  if (!_.isNil(selectIndicate)) {
                    transformItem.isSelected = !selectIndicate;
                  }
                }
                transformList.push(transformItem);
              }

              if (isChangeTypeDataToHandle) {
                setNominationChangeTypeData(transformList);

                // need to refresh context transform data
                const tempList = _.cloneDeep(nominationTransformData);
                setNominationTransformData(tempList);
              } else {
                setNominationTransformData(transformList);
              }
            },
          },
          dependentCartItemCustomProps: {
            mt:
              isViewMode && index === 0 && nominationType === NOMINATION_TYPE.travelNominees
                ? nominationViewModeCartItemMargin
                : 0,
          },
        };
      });
    } else {
      if (isViewMode && !isNominationCartPage && !isShowTypeChangeSection)
        // checked if empty view mode list means the user has not nominated anyone in that "cart". The "cart" including Plus one and Travel nominees.
        tmpList.push({
          component: MessageCartItem,
          content: [
            {
              text: nominationFlowLbl.notNominated,
              formatType: 'default',
            },
          ],
          messageCartItemCustomProps: {
            mt: nominationType === NOMINATION_TYPE.travelNominees ? nominationViewModeCartItemMargin : 0,
          },
        });
    }

    return tmpList;
  };

  const nominationCartList = transformNominationViewModeList(list, false);
  const nominationChangeTypeList = transformNominationViewModeList(secondCartItemList, true);

  let cartContentDescriptionProps;

  // condition: empty tick not nominated dependents + empty type change dependents
  const isShowEmptyNotNominatedDependentsDescription =
    isNotNominatedDependentsSelection && list.length === 0 && secondCartItemList.length === 0;

  // [ETP-4871] display message for empty not nominated dependents + empty type change dependents
  if (isShowEmptyNotNominatedDependentsDescription) {
    cartContentDescriptionProps = {
      component: ParagraphMasterContainer,
      componentProps: {
        content: nominationFlowLbl.emptyNotNominatedContent,
        paragraphVariant: 'body_2_medium' as IParagraphVariantProps['paragraphVariant'],
        paragraphSxProps: {
          pb: '6px',
          lineHeight: '18px',
        },
        multiParagraphContainerSxProps: {
          m: 0,
          mt: isPlusOne ? '18px' : 0,
          pl: 0,
        },
      },
    };
  }

  // display message for select up to *number* nominees
  const isShowSelectUpToNomineesDescription =
    !isShowEmptyNotNominatedDependentsDescription && !_.isUndefined(maxNumberOfSelectableNominees);
  if (isShowSelectUpToNomineesDescription) {
    cartContentDescriptionProps = {
      component: ParagraphMasterContainer,
      componentProps: {
        content: [
          {
            text: `${nominationFlowLbl.selectUpTo} ${maxNumberOfSelectableNominees} ${nominationFlowLbl.nominees}`,
            formatType: 'default',
          },
        ],
        paragraphVariant: 'body_2_medium' as IParagraphVariantProps['paragraphVariant'],
        paragraphSxProps: {
          pb: 0,
          m: 0,
          mt: headerDescription ? 2 : -1,
        },
      },
    };
  }

  return (
    <CartContainer<INominationCartListProps>
      {...{
        isShowStar,
        currentCartItemQuantity: numberOfNominatedPerson,
        maxCartItemQuantity: maxNumberOfPerson,
        cartLabel: {
          headerTitle,
          headerDescription,
          headerSubTitle: secondCartItemList.length > 0 ? nominationFlowLbl.notNominated : '',
          secondCartItemListTitle: isPlusOne ? nominationFlowLbl.travelNominees.title : nominationFlowLbl.plusOne.title,
        },
        containerCustomProps,
        cartItemList: nominationCartList,
        secondCartItemList: nominationChangeTypeList,
        isViewMode,
        isAllowAddToCart,
        addToCartTitle: nominationFlowLbl.addNominee,
        addNomineeToCartAction,
        cartContentDescriptionProps,
      }}
    />
  );
};

export default NominationCartMasterContainer;
