import dayjs, { ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import { DATE_FORMAT } from '../constants/constants';

import en from '../translations/en';
import { Employee } from 'employee';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

export function checkIsDateValid(date?: string | Date | null, format?: string) {
  return dayjs(date, format).isValid();
}

export function formatStringToDate(date: string | null | undefined, format: string): Date {
  if (checkIsDateValid(date, format)) {
    return dayjs(date, format).toDate();
  }

  return new Date();
}

export function formatDateAsString(
  date: string | Date | null | undefined,
  outputFormat?: string,
  initialFormat?: string,
) {
  if (checkIsDateValid(date, initialFormat)) {
    return dayjs(date, initialFormat).format(outputFormat);
  }
  return '';
}

export function compareDate(
  date1: string | Date,
  date2: string | Date,
  date1Format?: string,
  date2Format?: string,
  unit: QUnitType | OpUnitType = 'day',
): number {
  if (checkIsDateValid(date1, date1Format) && checkIsDateValid(date2, date2Format)) {
    return dayjs(date1, date1Format).diff(dayjs(date2, date2Format), unit);
  }
  return 0; // TODO: what should be the default fallback value when either date is invalid and comparison is done
}

export function getLocalDateAdding(quantity: number, unit: ManipulateType = 'day'): Date {
  return dayjs().add(quantity, unit).toDate();
}

export function getInputDateAdding(inputDate: string | Date, quantity: number, unit: ManipulateType = 'day'): Date {
  return dayjs(inputDate).add(quantity, unit).toDate();
}

export function getLocalDate(): Date {
  return dayjs().toDate();
}

export function secondsToDuration(seconds: number) {
  return dayjs.duration(seconds, 'seconds').format('H[h] m[m] s[s]');
}

export function checkValidDateRange(from?: string, to?: string) {
  if (!from || !to) {
    return false;
  }
  return dayjs(from).isBefore(to) || compareDate(from, to) === 0;
}

export function formatDMYDateWithSlash(date: string | Date | null | undefined) {
  if (!checkIsDateValid(date)) {
    return '';
  }
  return formatDateAsString(date, DATE_FORMAT.ddmmyyyy);
}

export function formatDMYDateWithSpace(date: string | Date | null | undefined) {
  if (!checkIsDateValid(date)) {
    return '';
  }
  return formatDateAsString(date, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);
}

export function getDepartureMaxDate(departureDate: Date) {
  // TODO: for future rebook refinement, need to aware /Rebook/RebookDateFieldContainer.tsx file -> get maxDate
  const blockedDate = window.config?.blockedBookingDepartureDateOnOrAfter;
  const formatDepartureDate = formatDateAsString(departureDate, DATE_FORMAT.date);
  const theDayBeforeBlockedDate = getInputDateAdding(blockedDate, -1);

  const isPassedBlockedDate = formatDepartureDate >= blockedDate;

  return {
    isPassedBlockedDate,
    // return "Date" format
    maxDate: isPassedBlockedDate ? theDayBeforeBlockedDate : departureDate,
  };
}

export const formatProfileYOS = (yearOfService: Employee.Profile['yearOfService'] | null | undefined) => {
  if (!yearOfService) {
    return `0 ${en.dateUnit.years} 0 ${en.dateUnit.months} 0 ${en.dateUnit.days}`;
  }
  return `${yearOfService.years} ${en.dateUnit.years} ${yearOfService.months} ${en.dateUnit.months} ${yearOfService.days} ${en.dateUnit.days}`;
};
