import { FunctionComponent, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PAYMENT_MEDIUM } from '../../../constants/constants';
import { usePaymentMethodIndicator } from '../../../hooks';
import { selectAuth } from '../../../slice/authSlice';
import { setIsOpenBillingAgreementDialog, setIsOpenBillingAgreementInterruptDialog } from '../../../slice/paypalSlice';
import { getConcessionThunk, getProfileThunk, selectUser, setProfile } from '../../../slice/userSlice';
import BillingAgreementDialog from '../../Common/BillingAgreementDialog';
import BillingAgreementDialogInterruptDialog from '../../Common/BillingAgreementDialogInterruptDialog';
import CountryOfResidenceDialog from '../../Common/CountryOfResidenceDialog';
import { updateSetupCountryOfResidential } from '../../../services/user';
import { createBillingAgreementTokenAction } from '../../../helpers';
import { SnackbarComponent } from '../../Common';
import { CheckMarkIcon } from '../../../assets/images';
import en from '../../../translations/en';

const UpdateSetupCountryOfResidenceFlowContainer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUser) || {};
  const { ern } = useAppSelector(selectAuth) || {};

  const selectCountryOfResidential = (countryOfResidential: string) => {
    setCountryOfResidential(countryOfResidential);
  };

  const [countryOfResidential, setCountryOfResidential] = useState<string | undefined>();
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  const [openBillingAgreementDialog, setOpenBillingAgreementDialog] = useState<boolean>(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    if (
      profile &&
      profile.retireeWidow &&
      profile.retireeWidow.backendCalculatePaymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT
    ) {
      setOpenBillingAgreementDialog(true);
    } else {
      setOpenBillingAgreementDialog(false);
    }
  }, [profile]);

  return (
    <>
      <BillingAgreementDialog
        openBillingAgreementDialog={openBillingAgreementDialog}
        handleCloseClick={() => {
          dispatch(setIsOpenBillingAgreementDialog(false));
          dispatch(setIsOpenBillingAgreementInterruptDialog(true));
        }}
        handleConfirmClick={() => {
          createBillingAgreementTokenAction({
            ern: ern,
            dispatch,
          });
          dispatch(setIsOpenBillingAgreementInterruptDialog(false));
        }}
      />
      <BillingAgreementDialogInterruptDialog
        handleCloseClick={() => {
          dispatch(setIsOpenBillingAgreementInterruptDialog(false));
          // [ETP-4372] after quit the setup of billing agreement, get the profile again
          dispatch(getProfileThunk(ern));
        }}
        handleConfirmClick={() => {
          dispatch(setIsOpenBillingAgreementInterruptDialog(false));
          dispatch(setIsOpenBillingAgreementDialog(true));
        }}
      />
      <CountryOfResidenceDialog
        selectCountryOfResidential={selectCountryOfResidential}
        handleConfirmAction={async () => {
          const result = await updateSetupCountryOfResidential(ern, countryOfResidential ? countryOfResidential : '');
          if (result) {
            // close dialog
            // show succeed snackBar
            dispatch(setProfile(result));
            if (
              result.retireeWidow &&
              (result.retireeWidow.backendCalculatePaymentMedium
                ? result.retireeWidow.backendCalculatePaymentMedium !== PAYMENT_MEDIUM.PAYPAL_PAYMENT
                : true)
            ) {
              paymentMethodIndicator.isShowWarning = false;
              dispatch(getConcessionThunk(ern));
              setIsOpenSnackbar(true);
              setTimeout(() => {
                setIsOpenSnackbar(false);
              }, 3000);
            }
          }
        }}
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        toastNotification={
          profile?.isPayPalBillingAgreementSetup
            ? en.payment.countryOfResidential.successfulUpdateSetupWithPayPal
            : en.payment.countryOfResidential.successfulUpdateSetup
        }
        IconComponent={<CheckMarkIcon />}
        handleClose={() => {}}
      />
    </>
  );
};

export default UpdateSetupCountryOfResidenceFlowContainer;
