import { useContext } from 'react';
import { Box, useTheme, IconButton, Grid } from '@mui/material';

import en from '../../../../translations/en';
import { DATE_FORMAT, LABEL_VALUE_TYPE, COMPANION_REGISTRATION_FLOW_PAGE_NO } from '../../../../constants/constants';
import { BackIcon, AlertIcon } from '../../../../assets/images';

import { formatDateAsString, getPHubDependentDisplayName, formatDMYDateWithSpace } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { NominationAction, NominationState } from '../../../../context';

import { DetailsContainer, IDisplayDetails, LabelValueContainer } from '../../../AdminMaintainEmployeeDetail';
import { CancelDialog } from '../../../../components';
import { NominationPageTitle, ParagraphMasterContainer } from '../../../../containers';

interface IMassagedPersonalInfoData {
  [key: string]: IDisplayDetails[];
}

const CompanionReviewContainer = ({
  cancelRegistrationDialogConfirmOnClick,
}: {
  cancelRegistrationDialogConfirmOnClick: () => void;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};
  const { routeTo, companionRegistrationData, isSelectDependentRelationship } = companionRegistrationFlow || {};
  const { step: routeToStep } = routeTo || {};
  const { companionInfo, companionPassport } = companionRegistrationData || {};

  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const personalDataLbl = companionRegistrationLbl.personalDetails;
  const passportDetailsLbl = companionRegistrationLbl.passportDetails;
  const reviewAndConfirmLbl = companionRegistrationLbl.reviewAndConfirm;

  const fieldLabel = {
    dependantDetails: {
      heading: companionRegistrationLbl.dependantDetails.heading,
    },
    passportDetails: {
      heading: companionRegistrationLbl.passportDetails.heading,
    },
  };

  const defaultInfoData = {
    type: LABEL_VALUE_TYPE.STRING,
    confidential: false,
    column: 6,
    valueVariant: 'body_1_medium',
    labelVariant: 'navigation_regular',
    labelTypoSxProps: {
      color: theme.color.secondary.grey.option_2,
    },
  };

  const massagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.dependantName,
        value: getPHubDependentDisplayName({
          passportInfo: companionPassport,
          personalInfo: companionInfo,
        }).toUpperCase(),
      },
    ],
    dependantDetails: [
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.relationship,
        value: companionInfo?.relationship?.label,
        customStyles: {
          'max-width': '100%',
          'flex-basis': '100%',
        },
      },
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.dependantType,
        value: companionInfo?.dependantType,
        customStyles: {
          'max-width': '100%',
          'flex-basis': '100%',
        },
      },
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.title,
        value: companionInfo?.title?.label,
      },
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.gender,
        value: companionInfo?.gender?.label,
      },
      {
        ...defaultInfoData,
        label: personalDataLbl.fields.dateOfBirth,
        value: formatDMYDateWithSpace(companionInfo?.dateOfBirth),
      },
    ],
    passportDetails: [
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.passportFirstName,
        value: companionPassport?.passportFirstName.toUpperCase(),
        column: 12,
      },
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.surname,
        value: companionPassport?.passportLastName.toUpperCase(),
        column: 12,
      },
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.passportNumber,
        value: companionPassport?.passportNumber,
      },
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.expiryDate,
        value: formatDateAsString(companionPassport?.passportExpirationDate, DATE_FORMAT.ddmmmyyyy),
      },
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.nationality,
        value: companionPassport?.passportNationality?.label,
      },
      {
        ...defaultInfoData,
        label: passportDetailsLbl.fields.issuedCountry,
        value: companionPassport?.passportCountry?.label,
      },
    ],
  };

  return (
    <>
      <Box className="companion_review_container">
        {!isDesktop && (
          <IconButton
            onClick={() => {
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                routeTo: {
                  step:
                    // Route step range use '2' because skip passport page when register dependent companion(when `isSelectDependentRelationship` is true)
                    routeToStep -
                    (isSelectDependentRelationship &&
                    routeToStep === COMPANION_REGISTRATION_FLOW_PAGE_NO.reviewAndConfirm
                      ? 2
                      : 1),
                },
              });
            }}
            sx={{ py: 1.625, pl: 0 }}
          >
            <BackIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        )}

        <Box
          sx={{
            width: isDesktop ? '488px' : '100%',
            height: '100%',
            ...(isDesktop
              ? {
                  mt: 2,
                  mb: 3,
                  mx: 'auto',
                }
              : {
                  mt: 2.25,
                }),
          }}
        >
          <NominationPageTitle title={reviewAndConfirmLbl.title} />

          <ParagraphMasterContainer
            content={reviewAndConfirmLbl.content}
            paragraphVariant="body_1_regular"
            multiParagraphContainerSxProps={{ p: 0 }}
            paragraphSxProps={{
              mt: 1.5,
            }}
          />

          <Box
            sx={{
              px: 2,
              pb: 2,
              width: '100%',
              mx: 'auto',
              background: 'white',
              boxShadow: theme.boxShadow.important,
              borderRadius: '8px',
            }}
          >
            {Object.entries(massagedPersonalInfoData).map(([key, value]: [string, IDisplayDetails[]]) => {
              return (
                <>
                  <DetailsContainer
                    key={key}
                    title={
                      (
                        fieldLabel[key as keyof typeof fieldLabel] as {
                          heading: string;
                        }
                      )?.heading
                    }
                    customStyles={{
                      ...(key === 'profileDetails' && {
                        mt: 0,
                      }),
                      ...(key !== 'profileDetails' && {
                        mt: 3,
                      }),
                    }}
                    titleVariant="body_2_bold"
                    titleTypoSxProps={{
                      mb: 2,
                      color: theme.color.secondary.dark_grey.option_3,
                    }}
                  >
                    <Grid container spacing={2}>
                      {value.map((data: IDisplayDetails, index: number) => {
                        return <LabelValueContainer key={index} {...data} />;
                      })}
                    </Grid>
                  </DetailsContainer>
                </>
              );
            })}
          </Box>
        </Box>
      </Box>

      <CancelDialog
        open={companionRegistrationFlow.isOpenCancelRegistrationDialog}
        showCancelIcon={false}
        handleConfirmAction={cancelRegistrationDialogConfirmOnClick}
        handleCloseDialog={() => {
          setCompanionRegistrationFlow({
            ...companionRegistrationFlow,
            isOpenCancelRegistrationDialog: false,
          });
        }}
        confirmToClose={false}
        IconComponent={<AlertIcon />}
        dialogContent={{
          title: reviewAndConfirmLbl.cancelRegistrationDialog.title,
          message: reviewAndConfirmLbl.cancelRegistrationDialog.description,
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.back,
        }}
      />
    </>
  );
};

export default CompanionReviewContainer;
