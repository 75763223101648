import React from 'react';
import { Box, IconButton, SxProps, Theme } from '@mui/material';

import { BackIcon, CloseIcon } from '../../assets/images';

const Header = ({
  leftChild,
  rightChild,
  centerChild,
  bottomBorder,
  isHideBackBtn,
  handleOnBack,
  handleOnClose,
  customStyles,
  leftChildBoxCustomStyles,
  centerChildBoxCustomStyles,
  rightChildBoxCustomStyles,
  iconColor = '#116F9A',
}: {
  leftChild?: React.ReactNode;
  rightChild?: React.ReactNode;
  centerChild?: React.ReactNode;
  bottomBorder?: boolean;
  isHideBackBtn?: boolean;
  handleOnBack?: () => void;
  handleOnClose?: () => void;
  customStyles?: SxProps<Theme>;
  leftChildBoxCustomStyles?: SxProps<Theme>;
  centerChildBoxCustomStyles?: SxProps<Theme>;
  rightChildBoxCustomStyles?: SxProps<Theme>;
  iconColor?: string;
}) => {
  const defaultHeaderStyle = {
    width: '100%',
    justifyContent: 'space-between',
  };

  const bottomBorderStyle = bottomBorder
    ? {
        mt: 0.25,
        borderBottom: 1,
        borderColor: 'secondary.main',
        background: 'white',
      }
    : {};

  const headerStyle = {
    ...defaultHeaderStyle,
    ...bottomBorderStyle,
    ...customStyles,
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="row" sx={headerStyle}>
      <Box
        className="header_left_child_item"
        display="flex"
        alignItems="center"
        flexGrow={1}
        sx={leftChildBoxCustomStyles}
      >
        {!isHideBackBtn && (
          <IconButton
            size="medium"
            onClick={() => {
              handleOnBack?.();
            }}
          >
            <BackIcon fill={iconColor} />
          </IconButton>
        )}

        {leftChild}
      </Box>

      {centerChild && (
        <Box
          className="header_center_child_item"
          display="flex"
          alignItems="center"
          flexGrow={1}
          sx={centerChildBoxCustomStyles}
        >
          {centerChild}
        </Box>
      )}

      {handleOnClose ? (
        <IconButton onClick={handleOnClose}>
          <CloseIcon fill={iconColor} />
        </IconButton>
      ) : (
        <Box className="header_right_child_item" sx={rightChildBoxCustomStyles}>
          {rightChild}
        </Box>
      )}
    </Box>
  );
};

Header.defaultProps = {};
export default Header;
