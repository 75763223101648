import { SxProps, Theme, useTheme } from '@mui/material';
import en from '../../../translations/en';

import { ISearchBtnProps } from '../../../components/View/Footer';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { Footer } from '../../../components';

interface INominationFooterProps {
  primaryBtn?: ISearchBtnProps['primaryBtn'];
  secondaryBtn?: ISearchBtnProps['secondaryBtn'];
  leftChild?: ISearchBtnProps['leftChild'];
  leftColumnSx?: ISearchBtnProps['leftColumnSx'];
  customStyles?: SxProps<Theme>;
}

const NomineeFooterComponent = ({
  customStyles,
  primaryBtn,
  secondaryBtn,
  leftChild,
  leftColumnSx,
}: INominationFooterProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Footer
      {...(leftChild && {
        leftChild,
      })}
      {...(leftColumnSx && {
        leftColumnSx,
      })}
      customStyles={{
        ...(isDesktop
          ? { px: 22, borderRadius: '24px 24px 0px 0px', boxShadow: theme.boxShadow.important, mb: 9 }
          : {
              px: 2,
            }),
        ...customStyles,
      }}
      {...(primaryBtn && {
        primaryBtn: {
          isFullWidth: primaryBtn?.isFullWidth || false,
          text: primaryBtn?.text || en.common.continue,
          customOnClick: primaryBtn?.customOnClick,
          colour: primaryBtn?.colour,
          customButtonStyles: primaryBtn?.customButtonStyles,
          restProps: primaryBtn?.restProps,
        },
      })}
      {...(secondaryBtn && {
        secondaryBtn: {
          isFullWidth: secondaryBtn?.isFullWidth || false,
          text: secondaryBtn?.text || en.common.cancel,
          customOnClick: secondaryBtn?.customOnClick,
          customButtonStyles: secondaryBtn?.customButtonStyles,
        },
      })}
    />
  );
};

export default NomineeFooterComponent;
