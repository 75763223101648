import React, { createContext, useState } from 'react';

interface IActionType {
  setOpen: any;
}

const CountryOfResidenceState = createContext<{ isOpen: boolean }>({
  isOpen: false,
});

const CountryOfResidenceAction = createContext<IActionType>({
  setOpen: null,
});

const CountryOfResidenceContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <CountryOfResidenceState.Provider value={{ isOpen }}>
      <CountryOfResidenceAction.Provider value={{ setOpen }}>{children}</CountryOfResidenceAction.Provider>
    </CountryOfResidenceState.Provider>
  );
};

export { CountryOfResidenceContextProvider, CountryOfResidenceState, CountryOfResidenceAction };
