import React, { useEffect, useContext, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { FORM, REGEX } from '../../../../../constants/constants';
import en from '../../../../../translations/en';

import { isRetireeWidow, validateRegex, validateRequired } from '../../../../../helpers';

import { Configuration } from 'configuration';
import { ILtEditContactDetails } from '../../../../../interfaces';

import {
  EditContactDetailsAction,
  EditContactDetailsState,
  EditLtContactDetailContextProvider as ContextProvider,
  initialEmailObj,
} from '../../../../../context/EditLtContactDetailContext';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectApp } from '../../../../../slice/appSlice';
import { selectBooking, setContactDetails } from '../../../../../slice/bookingSlice';
import { selectUser } from '../../../../../slice/userSlice';

import {
  Header,
  Footer,
  FormButton,
  ReduxFormTextInput,
  ReduxFormSelectInput,
  ScrollableView,
  FormAlert,
} from '../../../../../components';

const { travelDetails } = en.booking.flightConfirmation;
const { contactDetails: contactDetailsLbl } = travelDetails || {};

/**
 *
 * contactDetailsLbl.options:
 * [0]: work
 * [1]: personal
 * [2]: other
 */

const EditForm = ({ handleClose }: { handleClose?: () => void }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const { contactDetails: ltContactDetails } = leisureTravelDetails || {};
  const { businessEmail, personalEmail } = ltContactDetails || {};
  const { profile } = useAppSelector(selectUser);
  const { contactDetails }: { contactDetails: ILtEditContactDetails | null } = useAppSelector(selectBooking) || {};

  const { contactDetails: editContactDetail } = useContext(EditContactDetailsState) || {};
  const { setEditContactDetails } = useContext(EditContactDetailsAction) || {};
  const { phoneNumber, emailList } = editContactDetail || {};
  const { options } = contactDetailsLbl || {};

  const [isFocus, setIsFocus] = useState(false);

  const isValid =
    !!phoneNumber &&
    !!emailList?.length &&
    emailList
      .map((item: any) => !!item?.type?.code && !!item?.email && validateRegex(item.email, REGEX.email))
      .every((item: boolean) => item === true);

  useEffect(() => {
    // First load the page
    if (contactDetails && contactDetails.emailList) {
      // Has contactDetails data: Get redux data
      setEditContactDetails(contactDetails);
    } else {
      // No contactDetails data: Load Default Work email
      setEditContactDetails({
        phoneNumber: contactDetails?.phoneNumber || '',
        emailList: [
          {
            type: contactDetailsLbl.options[0],
            email: businessEmail,
          },
        ],
      });
    }
  }, []);

  return (
    <>
      <Box sx={{ px: 2, pb: 2, flex: 1, minHeight: 0 }}>
        <Header
          isHideBackBtn={true}
          leftChild={
            <Typography variant={isDesktop ? 'headline_bold' : 'headline_medium'} color="gray.dark">
              {contactDetailsLbl.title}
            </Typography>
          }
          handleOnClose={handleClose}
          customStyles={{ my: 3, height: '24px' }}
        />

        <Box
          component={ScrollableView}
          sx={{
            maxHeight: `calc(100% - 64px)`,
            pb: 4,
          }}
        >
          <FormAlert
            severity="info"
            component={
              <Typography
                variant="body_2_regular"
                sx={{
                  ml: 1,
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                {en.booking.confirmation.mandatory}
              </Typography>
            }
          />

          {/* Phone number */}
          <ReduxFormTextInput
            input={{
              value: phoneNumber,
              onChange: (input: any) => {
                setEditContactDetails({
                  ...editContactDetail,
                  phoneNumber: input,
                });
              },
            }}
            title={contactDetailsLbl.phoneNumber}
            isPhoneNum={true}
            meta={{
              touched: !!validateRequired(phoneNumber),
              error: validateRequired(phoneNumber),
            }}
          />

          {/* Email list */}
          {(emailList || []).map(({ type, email }: any, index: number) => {
            const firstSelectedType = emailList[0]?.type?.code || '';

            const conditions = [
              {
                condition: profile && isRetireeWidow(profile),
                code: contactDetailsLbl.options[0].code,
              },
              {
                condition: !personalEmail,
                code: contactDetailsLbl.options[1].code,
              },
              {
                condition: index === 0,
                code: contactDetailsLbl.options[2].code,
              },
              {
                condition: index === 0 && emailList?.[1]?.type?.code,
                code: emailList?.[1]?.type?.code,
              },
              { condition: index > 0, code: firstSelectedType },
            ];

            const filteredOption = options.filter(
              (option: any) => !conditions.some((cond) => cond.condition && option.code === cond.code),
            );

            return (
              <Box
                key={index}
                sx={{
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                  borderRadius: '8px',
                  p: 2,
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_2}>
                    {contactDetailsLbl.multipleEmailLabel}
                  </Typography>

                  {/* Remove Button */}
                  {index !== 0 && (
                    <FormButton
                      theme={theme}
                      colour="transparent"
                      type="button"
                      customStyles={{
                        height: '18px',
                        fontSize: '0.875rem',
                        lineHeight: '18px',
                        paddingRight: '0px',
                      }}
                      onClick={() => {
                        const newEmailList = [...emailList];
                        newEmailList.pop();
                        setEditContactDetails({
                          ...editContactDetail,
                          emailList: newEmailList,
                        });
                      }}
                    >
                      {en.common.remove}
                    </FormButton>
                  )}
                </Box>

                {/* Email type drop down */}
                <ReduxFormSelectInput
                  input={{
                    value: type,
                    onChange: (input: Configuration.CodeLabel) => {
                      const newEmailList = [...emailList];
                      const emailObj: any = {};
                      if (input.code === options[0].code) {
                        emailObj.email = businessEmail;
                      } else if (input.code === options[1].code) {
                        emailObj.email = personalEmail;
                      } else {
                        /**
                         * Special handling for second email:
                         * 1. do not clear the email when email is filled in but type is not selected
                         * 2. clear the email when re-selected e.g personal -> other
                         */
                        if (index === 1 && !newEmailList?.[index]?.type?.code && input?.code === options[2]?.code) {
                          emailObj.email = newEmailList?.[index]?.email || '';
                        } else {
                          emailObj.email = '';
                        }
                      }
                      emailObj.type = input;
                      newEmailList[index] = emailObj;
                      setEditContactDetails({
                        ...editContactDetail,
                        emailList: newEmailList,
                      });
                    },
                  }}
                  title={contactDetailsLbl.type}
                  options={filteredOption}
                />

                {/* Email address text input */}
                <ReduxFormTextInput
                  input={{
                    value: email,
                    onChange: (input: any) => {
                      const newEmailList = [...emailList];
                      const emailObj: any = {};
                      emailObj.type = newEmailList[index].type;
                      emailObj.email = input;
                      newEmailList[index] = emailObj;
                      setEditContactDetails({
                        ...editContactDetail,
                        emailList: newEmailList,
                      });
                    },
                    onFocus: () => {
                      setIsFocus(true);
                    },
                    onBlur: () => {
                      if (validateRegex(email, REGEX.email)) {
                        setIsFocus(false);
                      }
                    },
                  }}
                  title={contactDetailsLbl.email}
                  isReadOnly={type && (type.code === options[0].code || type.code === options[1].code)}
                  meta={{
                    touched: isFocus && !validateRegex(email, REGEX.email),
                    error: isFocus ? !validateRegex(email, REGEX.email) && en.error.invalidEmail : null,
                  }}
                />
              </Box>
            );
          })}

          {/* Add Email Button */}
          {emailList && emailList.length < 2 && (
            <FormButton
              theme={theme}
              colour="transparent"
              type="button"
              onClick={() => {
                const newEmailList = [...emailList];
                newEmailList.push(initialEmailObj);
                setEditContactDetails({
                  ...editContactDetail,
                  emailList: newEmailList,
                });
              }}
            >
              {en.booking.flightConfirmation.travelDetails.contactDetails.addEmail}
            </FormButton>
          )}
        </Box>
      </Box>

      {/* "Update" footer */}
      {isValid && (
        <Footer
          customStyles={{
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          primaryBtn={{
            isFullWidth: true,
            text: en.common.update,
            customOnClick: () => {
              dispatch(setContactDetails(editContactDetail));
              handleClose && handleClose();
            },
          }}
        />
      )}
    </>
  );
};

const EditLtContactDetails = ({ handleClose }: { handleClose?: () => void }) => {
  return (
    <ContextProvider>
      <EditForm handleClose={handleClose} />
    </ContextProvider>
  );
};

EditLtContactDetails.displayName = FORM.contactDetails;

export default EditLtContactDetails;
