import { Field } from 'redux-form';

import { ReduxFormTextInput } from '.';

const PersonalOrPassportNameInput = ({
  name,
  title,
  isReadOnly = false,
}: {
  name: string;
  title: string;
  isReadOnly?: boolean;
}) => {
  return (
    <Field
      component={ReduxFormTextInput}
      name={name}
      isAlphaOrSpace
      isUpperCase
      title={title}
      isReadOnly={isReadOnly}
    />
  );
};

export default PersonalOrPassportNameInput;
