import { getCurrentHostNameAndUrl } from './common';
import { PREPAID_API } from '../constants/api';

import en from '../translations/en';

import { showErrorAlert } from '../services/api';
import { prepaidAuth, retrieveAndPayAuth } from '../services/prepaid';

import { IBookFlightLtParams, IPrepaidRetrieveAndPaytBookFlightLtParams } from '../interfaces';

/* const handleResponseAction = async (response: any) => {
  if (response) {
    const { cpp_signature: cppSignature } = response;

    if (cppSignature) {
      try {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${window.config.cppBaseURL}${PREPAID_API.auth}`;

        const responseEntries = Object.entries(response) || {};

        responseEntries.forEach(([key, value]) => {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = `${value}`;
          form.appendChild(hiddenField);
        });

        document.body.appendChild(form);

        form.submit();
      } catch (err) {
        showErrorAlert({
          title: en.errorAlert.prepaidAuth,
          message: en.payment.prepaid.errorDuringRedirectCPP,
        });
      }
    } else {
      showErrorAlert({
        title: en.errorAlert.prepaidAuth,
        message: en.payment.prepaid.noCppSignature,
      });
    }
  }
}; */

const processPrepaidAuthResponse = async (response: any) => {
  const { cpp_signature: cppSignature } = response;

  if (cppSignature) {
    try {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${window.config.cppBaseURL}${PREPAID_API.auth}`;

      const responseEntries = Object.entries(response) || {};

      responseEntries.forEach(([key, value]) => {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = `${value}`;
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      showErrorAlert({
        title: `${en.errorAlert.prepaidAuth}`,
        message: en.payment.prepaid.errorDuringRedirectCPP,
      });
    }
  } else {
    showErrorAlert({
      title: `${en.errorAlert.prepaidAuth}`,
      message: en.payment.prepaid.noCppSignature,
    });
  }
};

const prepaidAuthAction = async (param: IBookFlightLtParams) => {
  const { currentHostName } = getCurrentHostNameAndUrl();

  const response = await prepaidAuth({ ...param, hostname: currentHostName });

  // comment first to testing
  // handleResponseAction(response);

  // [ETP-4379]
  return response;
};

const retrieveAndPayAuthAction = async ({
  param,
  dismissCallBackFunc,
}: {
  param: Omit<IPrepaidRetrieveAndPaytBookFlightLtParams, 'hostname'>;
  dismissCallBackFunc?: (dismissCallbackPayload: any) => void;
}) => {
  const { currentHostName } = getCurrentHostNameAndUrl();

  const response = await retrieveAndPayAuth({
    ...param,
    hostname: currentHostName,
    ...(dismissCallBackFunc && {
      dismissCallBackFunc,
    }),
  });

  // handleResponseAction(response);

  return response;
};

export { prepaidAuthAction, retrieveAndPayAuthAction, processPrepaidAuthResponse };
