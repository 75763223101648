import { useState, useMemo, useContext } from 'react';

import {
  SAVE_DATA_TO,
  NOMINATED_PASSENGER_AGE_TYPE,
  DATE_UNIT,
  FLIGHT_AVAILABILITY_STATUS,
} from '../constants/constants';

import en from '../translations/en';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  ILeisureTravelDetail,
  ILtConcession,
  ILtTravellerInfo,
  INominatedPassenger,
  IRestrictionBeneficiaryType,
  ISelectedFlight,
  ITravellersOptions,
} from '../interfaces';

import { selectBooking, setLeisureTravelDetails } from '../slice/bookingSlice';

import { EditSearchBarState, EditSearchBarAction } from '../context';
import {
  checkNominatedPassengersQuotaRestriction,
  getCanPreselectAvailableSeatOption,
  checkNominatedPassengers,
  checkLtIsExceedAdvanceBookingDay,
  getInvalidFJClassTravellerWarningMessage,
} from '../helpers';
import _ from 'lodash';
import { FlightSearch } from 'flightSearch';

interface ILtTravellerCountHook {
  adultCount: number;
  childrenCount: number;
  infantsCount: number;
  infantsWithoutSeatCount: number;
}

/*
  Mainly to set LT traveller count
*/
export const useLtTravellerCount = ({
  isSearchResultPage = false,
  isMobile = false,
  isAllowSeatForInfant = true,
}: {
  isSearchResultPage: boolean;
  isMobile: boolean;
  isAllowSeatForInfant: boolean;
}) => {
  const dispatch = useAppDispatch();

  const editSearchData = useContext(EditSearchBarState) || {};
  const { setEditLeisureTravelDetails } = useContext(EditSearchBarAction) || {};
  const { leisureTravelDetails } = isSearchResultPage ? editSearchData : useAppSelector(selectBooking) || {};
  const {
    adultCount: adultCountFromStore,
    childrenCount: childrenCountFromStore,
    infantsCount: infantsCountFromStore,
    infantsWithoutSeatCount: infantsWithoutSeatCountFromStore,
  } = leisureTravelDetails || {};

  const [ltTravellerCountHook, setLtTravellerCountHook] = useState<ILtTravellerCountHook>({
    adultCount: adultCountFromStore + childrenCountFromStore + infantsCountFromStore !== 0 ? adultCountFromStore : 1,
    childrenCount: childrenCountFromStore,
    infantsCount: infantsCountFromStore,
    infantsWithoutSeatCount: infantsWithoutSeatCountFromStore,
  });

  const enLtLabel = en.booking.travelType.leisureTravel;

  const totalTravellerCount = useMemo(() => {
    // TBC
    const calcInfantsCount = isAllowSeatForInfant ? ltTravellerCountHook.infantsCount : 0;

    return isMobile
      ? ltTravellerCountHook.adultCount + ltTravellerCountHook.childrenCount + calcInfantsCount
      : adultCountFromStore + childrenCountFromStore + infantsCountFromStore;
  }, [isMobile, ltTravellerCountHook, adultCountFromStore, childrenCountFromStore, infantsCountFromStore]);

  const saveTravellerCount = ({
    targetTraveller,
    saveTo,
    isMinus = false,
  }: {
    targetTraveller: string;
    saveTo: string;
    isMinus?: boolean;
  }) => {
    // Mapping traveller key with targetTraveller
    let travellerKey: string | any;
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.adult) travellerKey = 'adultCount';
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.child) travellerKey = 'childrenCount';
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat) travellerKey = 'infantsCount';
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.infant) travellerKey = 'infantsWithoutSeatCount';

    // handle save into local state
    if (saveTo === SAVE_DATA_TO.useState) {
      setLtTravellerCountHook((prevState) => {
        const prevKey = prevState?.[travellerKey as keyof ILtTravellerCountHook];
        return {
          ...prevState,
          [travellerKey]: isMinus ? prevKey - 1 : prevKey + 1,
        };
      });
    }

    // handle save into Redux or Context
    if (saveTo === SAVE_DATA_TO.redux || saveTo === SAVE_DATA_TO.context) {
      const prevKey = leisureTravelDetails?.[travellerKey];
      const updateObj = {
        ...leisureTravelDetails,
        [travellerKey]: isMinus ? prevKey - 1 : prevKey + 1,
      };
      if (saveTo === SAVE_DATA_TO.redux) {
        dispatch(setLeisureTravelDetails(updateObj));
      }
      if (saveTo === SAVE_DATA_TO.context) {
        setEditLeisureTravelDetails(updateObj);
      }
    }
  };

  const checkIsLeftDisabled = ({ targetTraveller }: { targetTraveller: string }) => {
    let targetCount;
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.adult) {
      targetCount = isMobile ? ltTravellerCountHook.adultCount : adultCountFromStore;
    }

    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.child) {
      targetCount = isMobile ? ltTravellerCountHook.childrenCount : childrenCountFromStore;
    }

    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat) {
      targetCount = isMobile ? ltTravellerCountHook.infantsCount : infantsCountFromStore;
    }

    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.infant) {
      targetCount = isMobile ? ltTravellerCountHook.infantsWithoutSeatCount : infantsWithoutSeatCountFromStore;
    }

    return totalTravellerCount < 1 || targetCount < 1;
  };

  const checkIsRightDisabled = ({ targetTraveller }: { targetTraveller?: string }) => {
    let count = totalTravellerCount;

    // Only special on infant without seat traveller
    if (targetTraveller === NOMINATED_PASSENGER_AGE_TYPE.infant) {
      count = isMobile ? ltTravellerCountHook.infantsWithoutSeatCount : infantsWithoutSeatCountFromStore;
    }

    return count > 8;
  };

  const travellersOptions: ITravellersOptions[] = [
    {
      title: enLtLabel.adults,
      subTitle: enLtLabel.aboveTwelve,
      isLeftDisabled: checkIsLeftDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
      }),
      isRightDisabled: checkIsRightDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
      }),
      count: isMobile ? ltTravellerCountHook.adultCount : adultCountFromStore,
      handleLeftClick: () => {
        responsiveHandler({
          mobileHandler: () => {
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              isMinus: true,
              saveTo: SAVE_DATA_TO.useState,
            });
          },
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              isMinus: true,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              isMinus: true,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
      handleRightClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.adult,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
    },
    {
      title: enLtLabel.children,
      subTitle: enLtLabel.twoToEleven,
      isLeftDisabled: checkIsLeftDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
      }),
      isRightDisabled: checkIsRightDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
      }),
      count: isMobile ? ltTravellerCountHook.childrenCount : childrenCountFromStore,
      handleLeftClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              isMinus: true,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              isMinus: true,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              isMinus: true,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
      handleRightClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.child,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
    },
    {
      title: enLtLabel.infantsWithSeat,
      subTitle: enLtLabel.underTwo,
      isLeftDisabled: checkIsLeftDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
      }),
      isRightDisabled: checkIsRightDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
      }),
      count: isMobile ? ltTravellerCountHook.infantsCount : infantsCountFromStore,
      handleLeftClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              isMinus: true,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              isMinus: true,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              isMinus: true,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
      handleRightClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
    },

    {
      title: enLtLabel.infantsWithoutSeat,
      subTitle: enLtLabel.underTwo,
      isLeftDisabled: checkIsLeftDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
      }),
      isRightDisabled: checkIsRightDisabled({
        targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
      }),
      count: isMobile ? ltTravellerCountHook.infantsWithoutSeatCount : infantsWithoutSeatCountFromStore,
      handleLeftClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              isMinus: true,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              isMinus: true,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              isMinus: true,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
      handleRightClick: () => {
        responsiveHandler({
          mobileHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              saveTo: SAVE_DATA_TO.useState,
            }),
          desktopHomeHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              saveTo: SAVE_DATA_TO.redux,
            }),
          desktopEditHandler: () =>
            saveTravellerCount({
              targetTraveller: NOMINATED_PASSENGER_AGE_TYPE.infant,
              saveTo: SAVE_DATA_TO.context,
            }),
        });
      },
    },
  ];

  const responsiveHandler = ({
    mobileHandler,
    desktopHomeHandler,
    desktopEditHandler,
  }: {
    mobileHandler?: () => void;
    desktopHomeHandler?: () => void;
    desktopEditHandler?: () => void;
  }) => {
    if (isMobile) {
      mobileHandler?.();
    } else {
      if (isSearchResultPage) {
        desktopEditHandler?.();
      } else {
        desktopHomeHandler?.();
      }
    }
  };

  return {
    ltTravellerCountHook,
    setLtTravellerCountHook,
    travellersOptions,
  } as const;
};

export const getTravellerByBeneficiaryAndDependentId = ({
  travellerList,
  traveller,
}: {
  travellerList: ILtTravellerInfo[];
  traveller: INominatedPassenger;
}) => {
  return travellerList.find(
    (info: ILtTravellerInfo) =>
      traveller?.beneficiaryTypeCode === info?.beneficiaryTypeCode && traveller?.dependentId === info?.dependentId,
  );
};

export const getValidSeatConcession = ({
  ltConcessions,
  outwardFlight,
  startDate,
}: {
  ltConcessions: ILtConcession[];
  outwardFlight: ISelectedFlight | null;
  startDate: string;
}) => {
  return ltConcessions?.map((item: ILtConcession) => {
    const currentItemAdvanceDay = item.concessionDef.advanceBookingDay;
    const LT_ADVANCE_BOOKING_DAYS = currentItemAdvanceDay.value;
    const LT_ADVANCE_BOOKING_DAY_UNIT = currentItemAdvanceDay.unit;

    const isClassEligible = item.bookingClass.every(
      (classItem) => !Object.keys(outwardFlight?.availability || {})?.includes(classItem),
    );
    const isDisabled = checkLtIsExceedAdvanceBookingDay({
      startDate,
      advanceBookingDays: LT_ADVANCE_BOOKING_DAYS,
      advanceBookingDayUnitCode: LT_ADVANCE_BOOKING_DAY_UNIT,
    });

    // set label for advance booking
    const prefixAdvanceBookingDaysLbl = en.booking.travelType.advanceBookingDays;
    const unitGroupLbl = en.booking.selectFlight.exceedAdvanceBookingPeriod.unit;
    let advanceBookingDayUnitLbl = `${prefixAdvanceBookingDaysLbl} - ${LT_ADVANCE_BOOKING_DAYS}`;
    if (LT_ADVANCE_BOOKING_DAY_UNIT) {
      const unitKey = DATE_UNIT?.[LT_ADVANCE_BOOKING_DAY_UNIT as keyof typeof DATE_UNIT];
      const unitLbl = unitGroupLbl?.[unitKey as keyof typeof unitGroupLbl];
      if (unitLbl) {
        advanceBookingDayUnitLbl = `${prefixAdvanceBookingDaysLbl} - ${LT_ADVANCE_BOOKING_DAYS} ${unitLbl}`;
      }
    }

    const warningTitle =
      isDisabled && isClassEligible
        ? `${item.displayName} (${advanceBookingDayUnitLbl}) (${en.booking.travelType.classNotAvailable})`
        : isDisabled
        ? `${item.displayName} (${advanceBookingDayUnitLbl})`
        : isClassEligible
        ? `${item.displayName} (${en.booking.travelType.classNotAvailable})`
        : item.displayName;
    return {
      code: item._id,
      label: warningTitle,
      disabled: isDisabled || isClassEligible,
      boardingPriority: item.boardingPriority,
    };
  });
};

export const selectOrPrefillConcession = ({
  leisureTravelDetails,
  ltConcessions,
  ltSeatConcessionTravellerList,
  outwardFlight,
  restrictionBeneficiaryType,
  selectedConcessionCode,
  startDate,
}: {
  leisureTravelDetails: ILeisureTravelDetail;
  ltConcessions: ILtConcession[];
  ltSeatConcessionTravellerList: INominatedPassenger[];
  outwardFlight: ISelectedFlight;
  restrictionBeneficiaryType: IRestrictionBeneficiaryType[];
  selectedConcessionCode?: string;
  startDate: string;
}) => {
  let concessionSelected: ILtConcession | undefined;
  let updatedDetails;
  let isInvalidAdvanceBookingDay = false;
  let isNoClassEligible = false;

  if (selectedConcessionCode) {
    // selected Concession
    concessionSelected = ltConcessions.find((element: ILtConcession) => element._id === selectedConcessionCode);
  } else if (ltConcessions.length === 1) {
    const currentItemAdvanceDay = ltConcessions?.[0]?.concessionDef?.advanceBookingDay;
    const LT_ADVANCE_BOOKING_DAYS = currentItemAdvanceDay?.value;
    const LT_ADVANCE_BOOKING_DAY_UNIT = currentItemAdvanceDay?.unit;
    isInvalidAdvanceBookingDay = checkLtIsExceedAdvanceBookingDay({
      startDate,
      advanceBookingDays: LT_ADVANCE_BOOKING_DAYS,
      advanceBookingDayUnitCode: LT_ADVANCE_BOOKING_DAY_UNIT,
    });

    isNoClassEligible = ltConcessions?.[0]?.bookingClass.every(
      (classItem: string) => !Object.keys(outwardFlight?.availability || {})?.includes(classItem),
    );

    if (!isInvalidAdvanceBookingDay && !isNoClassEligible) {
      // Updating here
      concessionSelected = ltConcessions?.[0];
    }
  }

  // escape if no valid concession
  if (!concessionSelected) {
    updatedDetails = {
      ...leisureTravelDetails,
      isAccompanyFormChecked: false,
      infantAdultMapFromRedux: {},
      ltConcessions: ltConcessions,
      ltSeatConcessionTravellerList,
      ltSelectTravellerList: ltSeatConcessionTravellerList,
      restrictionBeneficiaryType,
      seatConcession: '',
      seatOption: '',
    };
    return updatedDetails;
  }

  const travellersWithRestriction = ltSeatConcessionTravellerList.map((traveller: INominatedPassenger) => {
    // check matched traveller with beneficiaryTypeCode and dependentId
    const matchedTraveller = getTravellerByBeneficiaryAndDependentId({
      travellerList: concessionSelected?.travellerInfo || [],
      traveller,
    });

    // add restriction data and remove the object in the travellerInfo
    const clearedTravellerTemp = {
      accompanyAdult: undefined,
      accompanyFormData: undefined,
    };

    if (matchedTraveller) {
      const appendRestrictions = matchedTraveller?.restrictions || [];
      const updatedValidationInfo = {
        ...traveller.validationInfo,
        ...clearedTravellerTemp,
        restriction: checkNominatedPassengersQuotaRestriction({
          originalRestriction: appendRestrictions,
          existingRestriction: appendRestrictions,
          quotaInfo: matchedTraveller?.quotaInfo,
        }),
      };

      return {
        ...traveller,
        ...clearedTravellerTemp,
        validationInfo: updatedValidationInfo,
        quotaInfo: matchedTraveller?.quotaInfo,
      };
    } else {
      /**
       * case: selected traveller and backend response not match
       * special handle: append "User not Match" message to restrictions
       */
      const updatedValidationInfo = {
        ...traveller.validationInfo,
        restriction: [en.error.userNotMatch],
      };
      return {
        ...traveller,
        validationInfo: updatedValidationInfo,
      };
    }
  });

  const {
    canProceed: isValidTravellerCombination,
    eligibleTravellers: currentEligibleTravellers,
    nonEligibleTravellers: currentNonEligibleTravellers,
  } = checkNominatedPassengers(travellersWithRestriction);

  let eligibleTravellersWithRestriction;
  if (!isValidTravellerCombination) {
    eligibleTravellersWithRestriction = currentEligibleTravellers.map((traveller: INominatedPassenger) => {
      const currentRestriction = [
        ...traveller.validationInfo.restriction,
        en.booking.travelType.leisureTravel.ensureSufficient,
        ...(traveller.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant &&
        traveller.infantWithSeat === false
          ? [en.booking.travelType.leisureTravel.infantWithoutSeatOption]
          : []),
      ];
      const newRestriction = checkNominatedPassengersQuotaRestriction({
        originalRestriction: currentRestriction,
        existingRestriction: currentRestriction,
        quotaInfo: traveller?.quotaInfo,
      });
      return {
        ...traveller,
        validationInfo: {
          ...traveller.validationInfo,
          restriction: newRestriction,
        },
      };
    });
  } else {
    // handle quota restriction for empty case
    eligibleTravellersWithRestriction = currentEligibleTravellers.map((traveller: INominatedPassenger) => {
      const newRestriction = checkNominatedPassengersQuotaRestriction({
        originalRestriction: traveller?.validationInfo.restriction,
        existingRestriction: traveller?.validationInfo.restriction,
        quotaInfo: traveller?.quotaInfo,
      });

      const validationInfo = {
        ageType: traveller?.validationInfo?.ageType,
        allowAccompany: traveller?.validationInfo?.allowAccompany,
        restriction: newRestriction,
        isAddedRestrictionFlag: false,
        mustTravelWithBeneficiaryTypeCode: traveller?.validationInfo?.mustTravelWithBeneficiaryTypeCode,
      };

      return {
        ...traveller,
        validationInfo,
      };
    });
  }

  if (concessionSelected && !isInvalidAdvanceBookingDay && !isNoClassEligible) {
    updatedDetails = selectOrPrefillSeatOption({
      availability: outwardFlight.availability,
      leisureTravelDetails,
      ltConcessions,
      ltSeatConcessionTravellerList,
      restrictionBeneficiaryType,
      selectedConcession: concessionSelected,
      selectedSeatOption: getCanPreselectAvailableSeatOption({
        bookingClassInConcession: concessionSelected?.bookingClass?.slice(),
        outwardFlight,
      }),
    });
    return updatedDetails;
  }

  updatedDetails = {
    ...leisureTravelDetails,
    ltConcessions,
    seatConcession: concessionSelected._id,
    infantAdultMapFromRedux: {},
    ltSeatConcessionTravellerList: [...eligibleTravellersWithRestriction, ...currentNonEligibleTravellers],
  };
  return updatedDetails;
};

export const selectOrPrefillSeatOption = ({
  availability,
  leisureTravelDetails,
  ltConcessions,
  ltSeatConcessionTravellerList,
  restrictionBeneficiaryType,
  selectedConcession,
  selectedSeatOption,
}: {
  availability: FlightSearch.IntegratedFlightAvailability | undefined;
  leisureTravelDetails: ILeisureTravelDetail;
  ltConcessions: ILtConcession[];
  ltSeatConcessionTravellerList: INominatedPassenger[];
  restrictionBeneficiaryType: IRestrictionBeneficiaryType[];
  selectedConcession: ILtConcession;
  selectedSeatOption: string;
}) => {
  const enLtLabel = en.booking.travelType.leisureTravel;

  let updateDetails;
  if (selectedConcession && restrictionBeneficiaryType) {
    const clonedConcessionRule = _.cloneDeep(ltConcessions || []);
    const clonedLtSeatConcessionTravellerList = _.cloneDeep(ltSeatConcessionTravellerList || []);

    // clear restriction message first and re-calculate with following logic
    const travellerListWithClearRestriction = clonedLtSeatConcessionTravellerList.map(
      (traveller: INominatedPassenger) => {
        return {
          ...traveller,
          validationInfo: {
            ...traveller.validationInfo,
            restriction: [],
            isAddedRestrictionFlag: false,
          },
        };
      },
    );

    // [ETP-3340] Create a map of dependentId to restrictConfirmStatusBookingMessage for faster lookup
    const infoMap = new Map();
    clonedConcessionRule.forEach((rule: ILtConcession) => {
      rule?.travellerInfo.forEach((info: ILtTravellerInfo) => {
        if (info?.restrictConfirmStatusBookingMessage) {
          const { dependentId, restrictConfirmStatusBookingMessage } = info;
          infoMap.set(String(dependentId), restrictConfirmStatusBookingMessage);
        }
      });
    });

    // Iterate through the travellerListWithClearRestriction
    travellerListWithClearRestriction.forEach((traveller: INominatedPassenger) => {
      const { dependentId } = traveller;

      // [ETP-3340] refer to `restrictConfirmStatusBookingMessage`
      const restrictMessage = infoMap.get(String(dependentId));

      // 1.same user
      // 2.has restrictConfirmStatusBookingMessage
      // 3.is confirm status
      if (restrictMessage) {
        if (_.get(availability, selectedSeatOption) === FLIGHT_AVAILABILITY_STATUS.confirm) {
          traveller.validationInfo.restriction.push(restrictMessage);
        }
      }
    });

    const travellersWithRestriction: INominatedPassenger[] = travellerListWithClearRestriction.filter(
      (traveller: INominatedPassenger) => traveller.isSelected === true,
    );

    // 1. loop through selected traveller list
    travellersWithRestriction.forEach((traveller: INominatedPassenger) => {
      // 2. loop restrictionBeneficiaryType for each traveller
      restrictionBeneficiaryType.forEach((item: IRestrictionBeneficiaryType) => {
        // 3. find traveller that contains the restriction
        if (selectedConcession?.restriction?.beneficiaryType?.includes(item._id)) {
          const { ageToYear: age, ageFromYear, beneficiaryType, bookingClass } = item || {};

          // 4. inject the restriction warning into
          const errorMes = getInvalidFJClassTravellerWarningMessage(
            en.error.invalidAgeFirst,
            age,
            en.error.invalidAgeSecond,
            bookingClass,
          );

          // Same booking class
          if (bookingClass === selectedSeatOption) {
            if (
              traveller.beneficiaryTypeCode === beneficiaryType &&
              traveller?.age < age &&
              traveller?.age >= ageFromYear
            ) {
              if (!traveller.validationInfo.isAddedRestrictionFlag) {
                // Inject F, J class restriction
                traveller.validationInfo.restriction.push(errorMes);
                traveller.validationInfo.isAddedRestrictionFlag = true;
              }
            }
          }
        }
      });

      // inject the restriction from the concession
      // intersection will check if restriction includes same restriction_rule
      const travellerRegistrationInConcession = selectedConcession?.travellerInfo.find(
        (info: ILtTravellerInfo) =>
          traveller?.beneficiaryTypeCode === info?.beneficiaryTypeCode && traveller?.dependentId === info?.dependentId,
      );

      if (
        _.intersection(traveller.validationInfo.restriction, travellerRegistrationInConcession?.restrictions).length ===
        0
      ) {
        traveller.validationInfo.restriction.push(...(travellerRegistrationInConcession?.restrictions || []));
      }

      // check matched traveller with beneficiaryTypeCode and dependentId
      const matchedTraveller = getTravellerByBeneficiaryAndDependentId({
        travellerList: selectedConcession?.travellerInfo || [],
        traveller,
      });
      const appendRestrictions = traveller?.validationInfo.restriction || [];

      const newRestriction = checkNominatedPassengersQuotaRestriction({
        originalRestriction: appendRestrictions,
        existingRestriction: appendRestrictions,
        quotaInfo: matchedTraveller?.quotaInfo,
      });

      const updatedValidationInfo = {
        ...traveller.validationInfo,
        restriction: newRestriction,
      };

      // set updated data
      traveller.validationInfo = updatedValidationInfo;
      traveller.quotaInfo = matchedTraveller?.quotaInfo;
    });

    const {
      canProceed: isValidTravellerCombination,
      eligibleTravellers: currentEligibleTravellers,
      nonEligibleTravellers: currentNonEligibleTravellers,
    } = checkNominatedPassengers(travellersWithRestriction);

    let eligibleTravellersWithRestriction;

    if (!isValidTravellerCombination) {
      eligibleTravellersWithRestriction = currentEligibleTravellers.map((traveller: INominatedPassenger) => {
        if (traveller?.validationInfo?.restriction?.length === 0) {
          return {
            ...traveller,
            validationInfo: {
              ...traveller.validationInfo,
              restriction: [
                ...traveller.validationInfo.restriction,
                enLtLabel.ensureSufficient,
                ...(traveller.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant &&
                traveller.infantWithSeat === false
                  ? [enLtLabel.infantWithoutSeatOption]
                  : []),
              ],
            },
          };
        }

        return {
          ...traveller,
        };
      });
    } else {
      eligibleTravellersWithRestriction = currentEligibleTravellers;
    }

    // merge the 2 array list - `eligibleTravellersWithRestriction` and `currentNonEligibleTravellers` and unify the restriction message in each non-eligible traveller
    const unifiedTravellerArray = [...eligibleTravellersWithRestriction, ...currentNonEligibleTravellers].map(
      (traveller: INominatedPassenger) => ({
        ...traveller,
        validationInfo: {
          ...traveller.validationInfo,
          ...(traveller.validationInfo.restriction?.length > 0 && {
            restriction: Array.from(new Set(traveller.validationInfo.restriction)),
          }),
        },
      }),
    );

    // extract latest eligible traveller list and latest non-eligible traveller list and sort by `keyIndex` ASC
    const { tmpLatestEligibleList, tmpLatestNonEligibleList } = _.chain(unifiedTravellerArray)
      .partition((passenger: INominatedPassenger) => {
        return passenger.validationInfo.restriction.length === 0;
      })
      .thru(([tmpLatestEligibleList, tmpLatestNonEligibleList]) => ({
        // sort by index for eligible traveller list
        tmpLatestEligibleList: _.sortBy(tmpLatestEligibleList, 'keyIndex'),

        // sort by index for non-eligible traveller list
        tmpLatestNonEligibleList: _.sortBy(tmpLatestNonEligibleList, 'keyIndex'),
      }))
      .value();

    const massagedTravellerList = [...tmpLatestEligibleList, ...tmpLatestNonEligibleList];

    updateDetails = {
      ...leisureTravelDetails,
      infantAdultMapFromRedux: {},
      ltConcessions,
      ltSeatConcessionTravellerList: massagedTravellerList,
      seatConcession: selectedConcession._id,
      seatOption: selectedSeatOption,
      restrictionBeneficiaryType,
    };
    return updateDetails;
  }
};
