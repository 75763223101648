import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useHistory, useLocation } from 'react-router-dom';

import { Employee } from 'employee';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { BENEFICIARY_TYPE_CODE, INIT_EDIT_COMPANION_PASSPORT_CONTEXT, UI_STYLES } from '../../constants/constants';
import { NOMINATION_PATHS, USER_PROFILE_PATHS } from '../../constants/paths';
import { EditPaymentHistoryContextProvider } from '../../context/PaymentHistoryContext';
import en from '../../translations/en';

import { IDependent, IRouteConfigProps } from '../../interfaces';

import {
  handleBackToUserProfile,
  isRetireeSpecialProfile,
  isRetireeWidow,
  isWidow,
  validateForRegisterMaxNumberOfTravelCompanion,
} from '../../helpers';

import { selectApp } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import { selectUser, setIsLoadedDependent, setIsShowPaymentHistoryDialog } from '../../slice/userSlice';

import { editCompanionPassport } from '../../services/user';

import { NominationAction, NominationState } from '../../context';

import { DesktopView, Div100vhView, FormButton, Header, RouteSwitch, ScrollableView } from '../../components';
import { ConcessionWithTraveller, NomineeFooterComponent } from '../../containers';
import UpdateCountryOfResidentialFooterContainer from '../../containers/UserProfile/PaymentMethodDetails/UpdateCountryOfResidentialFooterComponent';
import { CountryOfResidenceAction } from '../../context/CountryOfResidenceContext';
import { usePaymentMethodIndicator } from '../../hooks';
import { validateEligibleUpdateCountryOfResidential } from '../../services/user';

const UserProfileDetails = ({ routes }: IRouteConfigProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const screenHeight = use100vh();

  const { ern } = useAppSelector(selectAuth) || {};

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser);

  const { dependent, isLoadedDependent } = useAppSelector(selectUser) || [];

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};

  const location = useLocation();

  const isPaymentHistoryPage = location?.pathname === USER_PROFILE_PATHS.paymentHistory;

  const isConcessionPage = location?.pathname === USER_PROFILE_PATHS.concessionDetails;

  const isNomineesDetailPage = location?.pathname === USER_PROFILE_PATHS.dependentDetails;

  const { isEditMode: isEditCompanionPassportMode, isPassportInputValid: isEditCompanionPassportInputValid } =
    companionRegistrationFlow?.editPassport || {};

  //TODO can enhance more pages if need empty padding config
  const isEmptyPaddingPage = [USER_PROFILE_PATHS.paymentHistory].includes(location?.pathname);

  const travelCompanionList = dependent.filter(
    (item: IDependent) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion,
  );

  const [isShowRegisterNomineeFooter, setIsShowRegisterNomineeFooter] = useState(false);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  // Edit companion passport > trigger to open dialog
  const triggerCancelEditCompanionPassportDialog = () => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: {
        ...companionRegistrationFlow.editPassport,
        isOpenCancelEditCompanionPassportDialog: true,
      },
    });
  };

  // Companion registration footer primary button text
  const footerPrimaryButtonText = () => {
    let text = en.userProfile.nomination.nomineesDetail.registerTravelCompanionButton;

    if (isEditCompanionPassportMode) {
      text = en.common.confirm;
    }
    return text;
  };

  // [ETP-3868] edit companion passport action
  const editCompanionPassportAction = async () => {
    const requestParams = companionRegistrationFlow.editPassport.requestParams;

    const result = await editCompanionPassport(ern, requestParams);
    if (result) {
      // after API call success, trigger to refresh nominee detail page, then show toast message
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        editPassport: { ...INIT_EDIT_COMPANION_PASSPORT_CONTEXT, isTriggerEditCompanionPassportToast: true },
      });
    }
  };

  const footerPrimaryBtnOnClick = async () => {
    if (isNomineesDetailPage) {
      if (isEditCompanionPassportMode) {
        // prevent user click confirm button if input is invalid
        if (!isEditCompanionPassportInputValid) return;

        editCompanionPassportAction();
      } else {
        // primary btn click
        history.push(NOMINATION_PATHS.companionRegistration);
      }
    }
  };

  const checkIsEnableFooterPrimaryBtn = (profile?: Employee.Profile | null) => {
    // [ETP-5012] Disable Companion registration footer primary button by:
    // profile is Widow or Retiree special profile
    if (isNomineesDetailPage && profile && (isWidow(profile) || isRetireeSpecialProfile(profile))) {
      return false;
    }

    if (isNomineesDetailPage && isEditCompanionPassportMode) {
      return isEditCompanionPassportInputValid;
    }

    // [ETP-4627] Limit number of travel companions
    if (isNomineesDetailPage && !isEditCompanionPassportMode) {
      return validateForRegisterMaxNumberOfTravelCompanion<IDependent>(travelCompanionList);
    }
    return true;
  };

  // [ETP-3868] edit companion passport
  const clearEditCompanionPassportHandler = () => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
    });
  };

  useEffect(() => {
    // for Edit Companion passport
    if (isNomineesDetailPage && isEditCompanionPassportMode) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }
    }
  }, [isNomineesDetailPage, isEditCompanionPassportMode]);

  // Nominee detail (non edit companion passport mode) > handle display register companion registration button
  useEffect(() => {
    if (isNomineesDetailPage && !isEditCompanionPassportMode && isLoadedDependent) {
      const isShowFooterInNomineeDetailPage =
        validateForRegisterMaxNumberOfTravelCompanion<IDependent>(travelCompanionList);

      setIsShowRegisterNomineeFooter(isShowFooterInNomineeDetailPage);
    }
  }, [isNomineesDetailPage, isEditCompanionPassportMode, travelCompanionList, isLoadedDependent]);

  const isBasicInfoPage = location?.pathname === USER_PROFILE_PATHS.basicInformation;

  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  return (
    <EditPaymentHistoryContextProvider>
      <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
        <DesktopView component={Grid}>
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              px: 21,
              ...(isPaymentHistoryPage && {
                pr: 21,
              }),
              boxShadow: theme.boxShadow.important,
              height: UI_STYLES.homepageNavBarHeight,
              my: 'auto',
            }}
          >
            {isEditCompanionPassportMode ? (
              <Header
                iconColor={theme.color.utility.link.option_3}
                leftChild={
                  <Typography
                    variant="headline_bold"
                    color={theme.color.utility.link.option_3}
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => {
                      clearEditCompanionPassportHandler();
                    }}
                  >
                    {en.userProfile.nomination.companionRegistration.editCompanionPassport.backToDependantDetails}
                  </Typography>
                }
                handleOnBack={() => {
                  dispatch(setIsShowPaymentHistoryDialog(false));
                  clearEditCompanionPassportHandler();
                }}
              />
            ) : (
              <Header
                iconColor={theme.color.utility.link.option_3}
                leftChild={
                  <Typography
                    variant="headline_bold"
                    color={theme.color.utility.link.option_3}
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => {
                      handleBackToUserProfile(history);
                    }}
                  >
                    {en.userProfile.backToProfile}
                  </Typography>
                }
                rightChild={isDesktop && isConcessionPage && <ConcessionWithTraveller />}
                handleOnBack={() => {
                  dispatch(setIsShowPaymentHistoryDialog(false));
                  handleBackToUserProfile(history);

                  // clear isLoadedDependent flag in redux when back to other page
                  dispatch(setIsLoadedDependent(false));
                }}
              />
            )}
          </Box>
        </DesktopView>
        <Grid
          component={ScrollableView}
          ref={scrollViewRef}
          sx={{
            height: isDesktop
              ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.desktopHeaderHeight} )`
              : undefined,
          }}
          container
          pt={!isEmptyPaddingPage && isDesktop ? 2 : 0}
          px={{
            xs: !isEmptyPaddingPage ? 2 : 0,
            sm: 0,
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              py={isEmptyPaddingPage ? 0 : 2}
              sx={{
                ...(isDesktop && {
                  display: 'flex',
                  justifyContent: 'center',
                }),
              }}
            >
              <RouteSwitch routes={routes} />
            </Grid>
          </Grid>
        </Grid>

        {/* // Nominees Detail Page */}
        {isNomineesDetailPage && isShowRegisterNomineeFooter && (
          <NomineeFooterComponent
            // [ETP-3868] edit companion passport
            {...(isEditCompanionPassportMode && {
              leftChild: (
                <FormButton
                  theme={theme}
                  colour="transparent"
                  size="large"
                  sx={{
                    height: '44px',
                    display: 'flex',
                    justifyContent: 'center',
                    ...{ minWidth: 0 },
                  }}
                  onClick={triggerCancelEditCompanionPassportDialog}
                >
                  {en.common.cancel}
                </FormButton>
              ),
            })}
            {...(checkIsEnableFooterPrimaryBtn(profile) && {
              primaryBtn: {
                text: footerPrimaryButtonText(),
                customOnClick: footerPrimaryBtnOnClick,
                ...(!isEditCompanionPassportMode && {
                  isFullWidth: !isDesktop,
                  restProps: {
                    colour: 'outline',
                  },
                  customButtonStyles: {
                    ...(isDesktop && {
                      width: '280px',
                      padding: '8px 16px',
                    }),
                    '&:hover': {
                      boxShadow: 'none',
                      background: 'inherit',
                    },
                  },
                }),
              },
            })}
          />
        )}
        {isBasicInfoPage &&
        profile &&
        isRetireeWidow(profile) &&
        profile.retireeWidow?.countryOfResidential &&
        !paymentMethodIndicator.isShowWarning ? (
          <UpdateCountryOfResidentialFooterContainer
            primaryBtn={{
              text: en.payment.countryOfResidential.updateCountryOfResidentialButton,
              customOnClick: async () => {
                const result = await validateEligibleUpdateCountryOfResidential(profile.employeeId);
                if (result) {
                  setOpenCountryOfResidenceDialog(true);
                }
              },
              isFullWidth: !isDesktop,
              restProps: {
                colour: 'outline',
              },
              customButtonStyles: {
                ...(isDesktop && {
                  width: '280px',
                  padding: '8px 16px',
                }),
                '&:hover': {
                  boxShadow: 'none',
                  background: 'inherit',
                },
              },
            }}
          />
        ) : (
          <></>
        )}
      </Div100vhView>
    </EditPaymentHistoryContextProvider>
  );
};

export default UserProfileDetails;
