import React from 'react';
import { SxProps, Theme } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';

import _ from 'lodash';

import { SingleParagraph, MultipleParagraph } from '..';

export interface IParagraphSxProps {
  paragraphSxProps?: SxProps<Theme>;
}

export interface IParagraphVariantProps {
  paragraphVariant?: TypographyProps['variant'];
}

export interface IContentProps {
  text: string;
  formatType: string;
}

export interface IReplaceContentItemProps {
  matchText: string;
  displayText: string;
  type: string;
  link?: string;
}

export interface IReplaceContentProps {
  replaceContent: IReplaceContentItemProps[];
}

export interface IParagraphMasterContainerProps
  extends IParagraphSxProps,
    IParagraphVariantProps,
    Partial<IReplaceContentProps> {
  content: IContentProps[];
  paragraphTypoSxProps?: SxProps<Theme>;
  multiParagraphContainerSxProps?: SxProps<Theme>;
}

const ParagraphMasterContainer = ({
  replaceContent = [], // default empty []
  content = [], // default empty []
  paragraphSxProps,
  paragraphVariant,
  paragraphTypoSxProps,
  multiParagraphContainerSxProps,
}: IParagraphMasterContainerProps) => {
  // identify if multiple or single paragraph
  // set default is single paragraph
  let isSingleParagraph = true;
  if (content.length > 1) {
    isSingleParagraph = false;
  }

  const baseProps = {
    replaceContent,
    paragraphSxProps,
    paragraphVariant,
    paragraphTypoSxProps,
    multiParagraphContainerSxProps,
  };

  if (isSingleParagraph) {
    const firstContent = _.first(content);
    return <SingleParagraph {...baseProps} singleContent={firstContent} />;
  } else {
    return <MultipleParagraph {...baseProps} multiContent={content} />;
  }
};

export default ParagraphMasterContainer;
