import { Box, Button, Typography, useTheme } from '@mui/material';

import {
  employeeTravelPortal,
  travelRequirements,
  onlineCheckIn,
  quickReferenceGuide,
  safetyAndSecurityPolicy,
  DTSecurityAdvisory,
} from '../../assets/images';
import { USER_ROLE } from '../../constants/constants';
import en from '../../translations/en';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';

import { BottomNavBar, Div100vhView, ScrollableView } from '../../components';
import { RoleIndicatorBar } from '../../containers';

const InfoUtility = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(role.type);
  const infoList = [
    {
      label: en.info.employeeTravelPortal,
      background: employeeTravelPortal,
      link: 'https://hub.cathaypacific.com/sites/employee-travel-portal',
    },
    {
      label: en.info.travelRequirements,
      background: travelRequirements,
      link: 'https://flights.cathaypacific.com/en_HK/travel-information/travel-requirements.html?cxsource=TOP-NAV_FLIGHTS_6_3',
    },
    {
      label: en.info.onlineCheckIn,
      background: onlineCheckIn,
      link: 'https://www.cathaypacific.com/mb/#/en_HK/olci/login',
    },
    {
      label: en.info.quickReferenceGuide,
      background: quickReferenceGuide,
      link: 'https://hub.cathaypacific.com/sites/employee-travel-portal/documents/preview/265060/Travel-Hub-Guide',
    },
    {
      label: en.info.dTSecurityAdvisory,
      background: DTSecurityAdvisory,
      link: 'https://hub.cathaypacific.com/redir/281017',
    },
    {
      label: en.info.safetyAndSecurityPolicy,
      background: safetyAndSecurityPolicy,
      link: 'https://hub.cathaypacific.com/redir/281304',
    },
  ];

  return (
    <Div100vhView
      sx={{
        backgroundColor: theme.color.secondary.light_slate.option_7,
      }}
    >
      {!isDesktop && isAdminDelegation && (
        <RoleIndicatorBar
          customStyles={{
            px: 2.5,
            background: 'rgba(237, 237, 237, 0.7)',
          }}
        />
      )}

      <Typography
        variant={isDesktop ? 'title_1_medium' : 'title_2_medium'}
        sx={{
          mt: isDesktop ? 5 : 3,
          display: 'block',
          width: isDesktop ? '834px' : 'calc(100% - 32px)',
          mx: 'auto',
          height: '40px',
          lineHeight: '40px',
          color: theme.color.secondary.dark_grey.option_1,
        }}
      >
        {en.info.usefulLinks}
      </Typography>
      <Box
        component={ScrollableView}
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: isDesktop ? 'row' : 'column',
          flexWrap: isDesktop ? 'wrap' : 'nowrap',
          justifyContent: isDesktop ? 'space-between' : 'flex-start',
          alignContent: 'baseline',
          mt: isDesktop ? 3 : 2,
          width: isDesktop ? '850px' : '100%',
          mx: 'auto',
          px: isDesktop ? 1 : 2,
        }}
      >
        {infoList.map((item: { label: string; background: string; link: string }) => (
          <Button
            key={item.label}
            sx={{
              width: isDesktop ? '411px' : '100%',
              height: '99px',
              flexShrink: 0,
              background: 'white',
              backgroundImage: `url(${item.background})`,
              backgroundSize: '104px 100%',
              backgroundRepeat: 'no-repeat',
              boxShadow: theme.boxShadow.important,
              borderRadius: 1,
              mb: 1.5,
              pl: 16,
              pr: 2,
              overflow: 'hidden',
              color: theme.color.secondary.dark_grey.option_1,
              justifyContent: 'left',
            }}
            onClick={() => window.open(item.link)}
          >
            <Typography sx={{ textAlign: 'start' }} variant="headline_medium">
              {item.label}
            </Typography>
          </Button>
        ))}
      </Box>
      {!isDesktop && <BottomNavBar />}
    </Div100vhView>
  );
};

export default InfoUtility;
