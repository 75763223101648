import React from 'react';
import {
  Box,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  SxProps,
  Theme,
} from '@mui/material';

import { ICommonProgressbarStepList } from '../../interfaces';

import { DefaultStepIcon } from '.';

const StyledStepConnector = () => {
  const theme = useTheme();
  return (
    <StepConnector
      className="progressbar_step_connector"
      sx={{
        top: '8px',
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 14px)',
        [`& .${stepConnectorClasses.line}`]: {
          height: '4px',
          border: 0,
          backgroundColor: theme.color.secondary.light_slate.option_3,
          borderRadius: '4px',
        },
        [`&.${stepConnectorClasses.completed}, &.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.cathayJadeLight.contrastText,
          },
        },
      }}
    />
  );
};

const Progressbar = ({
  stepList,
  currentStep = 0,
  customStyles,
}: {
  stepList: ICommonProgressbarStepList[]; // object array
  currentStep: number; // number.
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      className="progressbar"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mt: '12px',
        '& .MuiStepper-root': {
          width: '90%',
        },
        ...customStyles,
      }}
    >
      {stepList.length > 0 && (
        <Stepper activeStep={currentStep - 1} alternativeLabel connector={<StyledStepConnector />}>
          {stepList.map((stepItem: ICommonProgressbarStepList) => {
            const isActiveStep = currentStep === stepItem.step;
            const isCompletedStep = currentStep > stepItem.step;

            const stepProps: { completed?: boolean } = {};

            const baseLabelSxProps = {
              color:
                isActiveStep || isCompletedStep
                  ? theme.palette.cathayJadeLight.contrastText
                  : theme.color.secondary.dark_grey.option_3,
              fontSize: '11px',
              fontWeight: '400',
              lineHeight: '12px',
            };

            const labelProps = {
              sx: {
                // default sx props
                '& .MuiStepLabel-label': {
                  ...baseLabelSxProps,
                },
                '& .MuiStepLabel-label.Mui-active': {
                  ...baseLabelSxProps,
                  fontWeight: '500',
                },
                '& .MuiStepLabel-label.Mui-completed': {
                  ...baseLabelSxProps,
                },
                '& .MuiStepLabel-labelContainer ': {
                  width: '126%',
                },
                '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
                  mt: '4px',
                },
                '& .MuiStep-root': {
                  px: 0.5,
                  pr: '2px',
                },
                // parent sx props
                ...stepItem?.labelSxProps,
              },
              active: isActiveStep,
              completed: isCompletedStep,
            };

            return (
              <Step
                key={stepItem.key}
                {...stepProps}
                sx={{
                  '& .MuiStepConnector-root': {
                    left: 'calc(-50% + 20px)',
                    right: 'calc(50% + 20px)',
                  },
                }}
              >
                <StepLabel
                  StepIconComponent={() => {
                    return DefaultStepIcon({
                      active: isActiveStep,
                      completed: isCompletedStep,
                      icon: null,
                      step: stepItem.step,
                      stepLabelVariant: isActiveStep ? 'body_2_bold' : 'body_2_regular',
                    });
                  }}
                  {...labelProps}
                >
                  {stepItem.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Box>
  );
};

export default Progressbar;
