import _ from 'lodash';
import { Configuration } from 'configuration';
import {
  ADMIN_CONCESSION,
  INFINITE_DATE,
  LABEL_VALUE_TYPE,
  OWOAL_TYPE,
  PAYMENT_MEDIUM,
  ALLOTMENT_TYPE,
  DIRECT_DEBIT_STATUS,
  PAYMENT,
} from '../constants/constants';
import {
  IAdminConcession,
  IAdminQuotaList,
  IAdminConcessionFilterDependent,
  IEligibilityDependent,
} from '../interfaces';
import en from '../translations/en';
import { checkValidDateRange, isEmptyField, checkIsFocFare, checkIsId50Fare, checkIsZoneFare } from '.';

const filterConcessionListByCarrierGroup = (list: IAdminConcession[] = [], carrierGroupName: string) => {
  if (!carrierGroupName) return [];
  return _.filter(list, (item: IAdminConcession) => item?.concessionDef?.carrierGroup === carrierGroupName);
};

const transformedConcessionList = (list: IAdminConcession[] = []) => {
  if (list.length === 0) return [];
  const transformedFocConcessionList = _.chain(list)
    .orderBy([ADMIN_CONCESSION.DISPLAY_NAME])
    .groupBy((item: IAdminConcession) => item?.concessionDef?.displayName)
    .map((item: IAdminConcession[]) => {
      return {
        title: _.head(item)?.concessionDef?.displayName,
        data: item,
      };
    })
    .value();
  return transformedFocConcessionList;
};

const checkAgeInEligibilityDependent = (
  eligibility: IEligibilityDependent,
  dependent: IAdminConcessionFilterDependent,
) => {
  // check ageFrom or ageTo exist, then proceed to validate the age.
  if (
    eligibility.beneficiaryType === dependent.beneficiaryTypeCode &&
    eligibility.ageFrom !== undefined &&
    eligibility.ageTo !== undefined &&
    !_.isNil(dependent?.age)
  ) {
    // validate the age in the age period
    return dependent?.age >= eligibility?.ageFrom && dependent?.age < eligibility?.ageTo;
  }
  return true;
};

// [ETP-4887] validate OAL eligibility
const checkEligibilityWithOALEligibility = (
  isWithOALEligibility: Configuration.OALEligibilityLabel['withOALEligibility'],
  dependent: IAdminConcessionFilterDependent,
) => {
  if (isWithOALEligibility && dependent.isAssignedOAL !== undefined) {
    return dependent.isAssignedOAL;
  }

  return true;
};

// [ETP-4887] validate nomination type
const checkEligibilityNominationType = (
  eligibilityNominationTypeList: string[],
  dependent: IAdminConcessionFilterDependent,
) => {
  // bypass empty nomination type OR Employee self
  return (
    eligibilityNominationTypeList.length === 0 ||
    dependent.dependentId === null ||
    (dependent.nominationType && eligibilityNominationTypeList.includes(dependent.nominationType))
  );
};

const adminConcessionCarrierGroupSort = (
  adminConcessionData: IAdminConcession[] = [],
  dependent: IAdminConcessionFilterDependent,
) => {
  // cx group (include foc, zone, id50)
  const cxCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, 'CX');

  // ow group
  const owCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, OWOAL_TYPE.OW);

  // oal group
  const oalCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, OWOAL_TYPE.OAL);

  const focGroupList: IAdminConcession[] = [];
  const zoneGroupList: IAdminConcession[] = [];
  const id50GroupList: IAdminConcession[] = [];

  // cx carrier spilt into foc, zone, id50 groups
  cxCarrierGroupList?.map((cxConcessions: IAdminConcession) => {
    const { fareType, fareDiscount } = cxConcessions?.concessionDef || {};

    if (cxConcessions?.numberOfAllotmentSectors === -1) {
      if (
        checkIsFocFare({
          fareType,
          fareDiscount,
        })
      ) {
        focGroupList.push(cxConcessions);
      } else if (
        checkIsZoneFare({
          fareType,
          fareDiscount,
        })
      ) {
        zoneGroupList.push(cxConcessions);
      } else if (
        checkIsId50Fare({
          fareType,
          fareDiscount,
        })
      ) {
        id50GroupList.push(cxConcessions);
      }
    } else {
      // if numberOfAllotmentSectors != -1 which means quotaList is not empty,
      // check all quotaList if equals filter user's dependentId
      cxConcessions?.quotaList?.map((quota: IAdminQuotaList) => {
        if (
          quota?.dependentId === dependent?.dependentId ||
          (cxConcessions?.allotmentType === ALLOTMENT_TYPE.P && quota.quotaType === ALLOTMENT_TYPE.P)
        ) {
          if (
            checkIsFocFare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            focGroupList.push(item);
          } else if (
            checkIsZoneFare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            zoneGroupList.push(item);
          } else if (
            checkIsId50Fare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            id50GroupList.push(item);
          }
        }
      });
    }
  });

  const transformedFocConcessionList = transformedConcessionList(focGroupList);
  const transformedId50ConcessionList = transformedConcessionList(id50GroupList);
  const transformedZoneConcessionList = transformedConcessionList(zoneGroupList);
  const transformedOwConcessionList = transformedConcessionList(owCarrierGroupList);
  const transformedOALConcessionList = transformedConcessionList(oalCarrierGroupList);

  return [
    ...transformedFocConcessionList,
    ...transformedZoneConcessionList,
    ...transformedId50ConcessionList,
    ...transformedOwConcessionList,
    ...transformedOALConcessionList,
  ];
};

const validAdminEditFromToDateParams = (jobLevel?: string, from?: string, to?: string, mayKey?: string) => {
  const errorInfo = new Map();
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;

  if (_.isUndefined(jobLevel)) {
    errorInfo.set(mayKey, {});
  } else {
    if (!isEmptyField(jobLevel)) {
      if (!from) {
        errorInfo.set(mayKey, { from: en.error.required });
      } else if (!checkValidDateRange(from, to)) {
        errorInfo.set(mayKey, {
          from: editProfilePopup.inputValidDateRange,
          to: editProfilePopup.inputValidDateRange,
        });
      }
    }
  }

  return {
    errorObject: errorInfo.get(mayKey),
  };
};

const constructAdminEditJobLevelParams = (jobLevel?: string, from?: string, to?: string, mayKey?: string) => {
  const jobLevelInfo = new Map();

  if (jobLevel === null || isEmptyField(jobLevel)) {
    // null or "--"
    jobLevelInfo.set(mayKey, { jobLevel: null });
  } else if (!_.isUndefined(jobLevel)) {
    jobLevelInfo.set(mayKey, {
      jobLevel: jobLevel,
      from: _.isEmpty(from) ? null : from,
      to: _.isEmpty(to) ? null : to,
    });
  } else {
    jobLevelInfo.set(mayKey, {});
  }

  return {
    jobLevelObject: jobLevelInfo.get(mayKey),
  };
};

const constructDefaultJobLevelValue = (editJobLevel?: string, profileJobLevel?: string): string => {
  if (editJobLevel) {
    return editJobLevel;
  } else {
    return profileJobLevel === null ? en.common.empty : profileJobLevel || '';
  }
};

const validFlownSuspensionParams = (suspensionStartDate?: string, suspensionEndDate?: string, mayKey?: string) => {
  const errorInfo = new Map();
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;

  if (_.isEmpty(suspensionStartDate) && !_.isEmpty(suspensionEndDate)) {
    // has end date, but no start date
    errorInfo.set(mayKey, { from: en.error.required });
  } else if (
    !_.isEmpty(suspensionStartDate) &&
    !_.isEmpty(suspensionEndDate) &&
    !checkValidDateRange(suspensionStartDate, suspensionEndDate)
  ) {
    errorInfo.set(mayKey, {
      from: editProfilePopup.inputValidDateRange,
      to: editProfilePopup.inputValidDateRange,
    });
  }

  return {
    errorObject: errorInfo.get(mayKey),
  };
};

const constructAdminFlownSuspensionParams = (
  suspensionStartDate?: string,
  suspensionEndDate?: string,
  mayKey?: string,
) => {
  const suspensionInfo = new Map();

  if (!_.isEmpty(suspensionStartDate)) {
    suspensionInfo.set(mayKey, {
      flownSuspensionStartDate: suspensionStartDate,
      flownSuspensionEndDate: suspensionEndDate ?? INFINITE_DATE,
    });
  } else {
    suspensionInfo.set(mayKey, {});
  }

  return {
    suspensionObject: suspensionInfo.get(mayKey),
  };
};

const checkTargetPaymentMedium = ({
  targetPaymentMedium,
  profileData,
  editEmployeeDetail,
}: {
  targetPaymentMedium: string;
  profileData?: any;
  editEmployeeDetail?: any;
}) => {
  if (profileData && editEmployeeDetail) {
    return (
      editEmployeeDetail?.payment?.paymentMedium === targetPaymentMedium ||
      profileData.paymentMedium === targetPaymentMedium
    );
  } else if (profileData) {
    return profileData.paymentMedium === targetPaymentMedium;
  } else if (editEmployeeDetail) {
    return editEmployeeDetail?.payment?.paymentMedium === targetPaymentMedium;
  }
};

const isPaymentMediumPaypal = ({ profileData, editEmployeeDetail }: { profileData?: any; editEmployeeDetail?: any }) =>
  checkTargetPaymentMedium({
    targetPaymentMedium: PAYMENT_MEDIUM.PAYPAL_PAYMENT,
    profileData,
    editEmployeeDetail,
  });

const isPaymentMediumDirectDebit = ({
  profileData,
  editEmployeeDetail,
}: {
  profileData?: any;
  editEmployeeDetail?: any;
}) =>
  checkTargetPaymentMedium({
    targetPaymentMedium: PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT,
    profileData,
    editEmployeeDetail,
  });

const getPaymentStatusLabelInfo = ({
  profileData,
  editEmployeeDetail,
  isEditingAdminProfile = false,
}: {
  profileData?: any;
  editEmployeeDetail?: any;
  isEditingAdminProfile?: boolean;
}) => {
  const isEmptyEditPaymentMedium = _.isUndefined(editEmployeeDetail?.payment?.paymentMedium);

  const isSelectedDirectDebit = isPaymentMediumDirectDebit({
    ...(isEmptyEditPaymentMedium && { profileData }),
    ...(!isEmptyEditPaymentMedium && { editEmployeeDetail }),
  });

  const isPaypal = isPaymentMediumPaypal({
    // select paypal
    ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData }),
    ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
  });

  let labelValue: any;
  let labelType: LABEL_VALUE_TYPE = LABEL_VALUE_TYPE.BOOLEAN;
  if (isSelectedDirectDebit) {
    // for direct debit status, use backend "directDebitStatus"
    // labelValue is for view mode (edit mode use context data)
    labelValue = profileData.directDebitStatus === PAYMENT.paymentStatusValue.yes;
    if (isEditingAdminProfile) {
      // edit mode, label type "drop select"
      labelType = LABEL_VALUE_TYPE.FORM_SELECT;
    } else {
      // view mode: backend directDebitStatus will return "Y"/"N"/null these 3 values
      // use ["Y", "N"].includes(directDebitStatus) to distinguish "boolean" and "string" (LabelValueContainer Type)
      // 1. if "Y", "N" -> label type is "boolean" , use "labelValue(Y->true, N->false)" AND options: ["Yes", "No"]" to convert "Yes"/ "No"
      // 2. if null -> label type is "string", use "getDisplayVal(null)" function convert to "--"
      labelType = DIRECT_DEBIT_STATUS.includes(profileData.directDebitStatus)
        ? LABEL_VALUE_TYPE.BOOLEAN
        : LABEL_VALUE_TYPE.STRING;
    }
  }

  if (isPaypal) {
    // for paypal, use backend "billingAgreements" (true/false)
    labelValue = profileData.billingAgreements;
    // view & edit mode both show boolean type
    labelType = LABEL_VALUE_TYPE.BOOLEAN;
  }

  return { labelValue, labelType };
};

export {
  adminConcessionCarrierGroupSort,
  validAdminEditFromToDateParams,
  constructAdminEditJobLevelParams,
  constructDefaultJobLevelValue,
  validFlownSuspensionParams,
  constructAdminFlownSuspensionParams,
  isPaymentMediumPaypal,
  isPaymentMediumDirectDebit,
  checkAgeInEligibilityDependent,
  checkEligibilityWithOALEligibility,
  checkEligibilityNominationType,
  getPaymentStatusLabelInfo,
};
