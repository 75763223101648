import { Box, Typography, useTheme } from '@mui/material';

import { USER_ROLE } from '../../constants/constants';
import { OverlayBackground } from '../../assets/images';
import en from '../../translations/en';

import { getShortDisplayName, isWidow } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setErn } from '../../slice/authSlice';
import { selectApp } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';

import { RetireeIndicatorBar, RoleIndicatorBar } from '..';

const MobileHeader = ({
  isReachScrollOffset,
  isRetireeProfileWithRetireeSpecial,
  isSpecialRetireeProfile,
}: {
  isReachScrollOffset: boolean;
  isRetireeProfileWithRetireeSpecial: boolean;
  isSpecialRetireeProfile: boolean;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const hiddenSx = isReachScrollOffset ? { opacity: 0 } : {};

  const { isLoading } = useAppSelector(selectApp) || {};
  const { profile, role, isShowProfileIndicator, isShowSelectProfileDialog } = useAppSelector(selectUser) || {};

  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const { type: roleType, delegator = {} } = role || {};
  const isWidowProfile = isWidow(profile);

  const isShowRetireeIndicatorBar = isRetireeProfileWithRetireeSpecial || isSpecialRetireeProfile;

  return (
    <Box
      className="mobile_header"
      sx={{
        '&::before': {
          content: '""',
          backgroundImage: `url(${OverlayBackground})`,
          backgroundPositionY: '15%',
          backgroundSize: 'cover',
          filter: 'brightness(80%)',
          borderRadius: '0px 0px 8px 8px',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 160,
          mt: isShowRetireeIndicatorBar && !isShowSelectProfileDialog ? 3 : 0,
        },
        position: 'relative',
        color: 'white',
        pt: 5,
        px: 2,
        transition: (theme) => theme.transitions.create(['all', 'transform']),
        ...hiddenSx,
      }}
    >
      {roleType === USER_ROLE.delegation && (
        <RoleIndicatorBar
          customStyles={{
            position: 'absolute',
            top: 0,
            left: 0,
            px: 2.5,
            background: 'rgba(252, 252, 252, 0.7)',
          }}
        />
      )}

      {/* if selected retiree or special profile + loading animation dismissed, homepage top will display `RetireeIndicatorBar` after loading */}
      {isShowRetireeIndicatorBar && isShowProfileIndicator && !isLoading && (
        <RetireeIndicatorBar
          customStyles={{
            position: 'absolute',
            top: 0,
            left: 0,
            px: 2.5,
            background: theme.color.cabin_class.economy.option_7,
          }}
          isSpecialRetireeProfile={isSpecialRetireeProfile}
        />
      )}

      <Box
        position="relative"
        sx={{
          mt: isShowRetireeIndicatorBar && !isShowSelectProfileDialog ? 3 : 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body_1_bold"
            sx={{ alignSelf: 'flex-start' }}
            onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              if (window.mockUser) {
                window.mockUser((ern) => {
                  dispatch(setErn(ern.toUpperCase()));
                });
              }
            }}
          >
            {isWidowProfile ? (
              en.home.helloWidow
            ) : (
              <>
                {en.home.hello}
                {getShortDisplayName(
                  roleType === USER_ROLE.delegation
                    ? delegator
                    : { preferredFirstName, firstName, lastName, middleName },
                )}
              </>
            )}
          </Typography>
          <Typography variant="body_2_medium">
            {roleType === USER_ROLE.admin ? en.home.adminStatus : en.home.yourTravelExperienceStartsHere}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileHeader;
