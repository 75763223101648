import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  useTheme,
  Typography,
  SxProps,
  Theme,
  InputAdornment,
} from '@mui/material';

import { Configuration } from 'configuration';

import { FormFieldTextInput, FormFieldLabel } from '..';

import en from '../../translations/en';

import { DownArrowIcon, LockedIcon, BlankBoxIcon } from '../../assets/images';

const SelectInput = ({
  title,
  subTitle,
  value,
  options,
  autocomplete,
  isReadOnly,
  onChange,
  onItemFocus,
  meta,
  customInputStyle,
  customMenuStyle,
  noOptionsText,
  ...props
}: {
  title?: string;
  subTitle?: string;
  value?: Configuration.DropdownOptionClient | null;
  options: Configuration.DropdownOptionClient[];
  autocomplete?: {
    showTypedNoOfChar?: number;
    matchFrom: 'any' | 'start';
    customOpen: boolean;
  };
  isReadOnly?: boolean;
  onChange: (value?: Configuration.DropdownOptionClient | null) => void;
  onItemFocus?: (value?: Configuration.DropdownOptionClient | null) => void;
  meta?: any;
  noOptionsText?: string;
  customInputStyle?: SxProps<Theme>;
  customMenuStyle?: SxProps<Theme>;
  [x: string]: any;
}) => {
  const [inputValue, setInputValue] = useState('');
  const customOpen = autocomplete?.customOpen ?? false;

  const isError = meta?.touched && meta?.error;

  const theme = useTheme();

  const filterOptions = createFilterOptions({
    matchFrom: autocomplete?.matchFrom,
    ignoreCase: true,
    stringify: (option: Configuration.DropdownOptionClient) => `${option.label} ${option.code}`,
    limit: 500,
  });

  useEffect(() => {
    setInputValue('');
  }, [options]);

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {autocomplete ? (
          <Autocomplete
            open={customOpen ? undefined : !!(inputValue && inputValue.length >= (autocomplete.showTypedNoOfChar || 2))}
            options={options}
            filterOptions={filterOptions}
            value={value}
            noOptionsText={noOptionsText ?? en.common.noOption}
            disabled={isReadOnly}
            clearIcon={null}
            blurOnSelect={true}
            onChange={(_, value) => {
              onChange(value);
              setInputValue('');
            }}
            onInputChange={(_, inputValue, reason) => {
              if (inputValue && value?.label !== inputValue && reason === 'input') {
                setInputValue(inputValue);
              }
            }}
            popupIcon={null}
            sx={{
              flex: 1,
              ...(customOpen && { display: 'flex' }),
            }}
            renderInput={(params) => (
              <FormFieldTextInput
                label={title}
                variant="filled"
                color="info"
                error={isError}
                {...params}
                onBlur={() => {
                  setInputValue('');

                  setTimeout(() => {
                    setInputValue('');
                  }, 200);
                }}
                sx={{
                  flex: 1,
                  '& .MuiFilledInput-root': {
                    ...(customOpen && { paddingRight: '0 !important' }),
                    '& .MuiFilledInput-input': {
                      paddingTop: '20px',
                    },
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 0.75em)',
                      right: '10px',
                    },
                  },
                  ...customInputStyle,
                }}
                select={customOpen}
                InputProps={{
                  ...(customOpen ? params : params.InputProps),
                  endAdornment: isReadOnly && (
                    <InputAdornment position="end">
                      <LockedIcon style={{ marginRight: '16px' }} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onMouseDownCapture={(e) => !customOpen && e.stopPropagation()}
                SelectProps={{
                  IconComponent: isReadOnly ? BlankBoxIcon : DownArrowIcon,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  customOpen && onChange(options.find((option) => option.code === event.target.value));
                }}
              >
                {customOpen &&
                  options.map((item: Configuration.DropdownOptionClient) => (
                    <MenuItem
                      key={item.code}
                      value={item.code}
                      disabled={item.disabled}
                      sx={{
                        whiteSpace: 'pre-line',
                        ...customMenuStyle,
                      }}
                      onFocus={() => {
                        onItemFocus?.();
                      }}
                    >
                      <Typography variant="body_1_medium">{item.label}</Typography>
                    </MenuItem>
                  ))}
              </FormFieldTextInput>
            )}
          />
        ) : (
          <FormFieldTextInput
            autoFocus={false}
            label={title}
            variant="filled"
            color="info"
            sx={{ flex: 1, ...customInputStyle }}
            value={value?.code}
            disabled={isReadOnly}
            error={isError}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(options.find((option) => option.code === event.target.value));
            }}
            select
            {...props}
            SelectProps={{
              IconComponent: DownArrowIcon,
            }}
          >
            {options.map((item: Configuration.DropdownOptionClient) => (
              <MenuItem key={item.code} value={item.code}>
                <Typography variant="body_1_medium">{item.label}</Typography>
              </MenuItem>
            ))}
          </FormFieldTextInput>
        )}
      </Box>

      {subTitle && <FormFieldLabel style={theme.typography.fine_print_regular} title={subTitle} />}

      {isError && <FormFieldLabel style={theme.typography.fine_print_regular} title={meta.error} isError />}
    </Box>
  );
};

const ReduxFormSelectInput = ({ input: { name, value, onChange, onFocus }, ...props }: any) => {
  return <SelectInput name={name} value={value} onChange={onChange} onFocus={onFocus} {...props} />;
};

export default ReduxFormSelectInput;
