import React, { useEffect, useState } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import {
  UI_STYLES,
  FORM,
  NOMINATED_PASSENGER_AGE_TYPE,
  BOOKING_FLOW_PAGE_NO,
  PAYMENT_CONCEPT,
  PREPAID_AUTH_FLOW_TYPE,
  LABEL_CATEGORY,
  CARRIER_OPTION,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { Configuration } from 'configuration';

import { constructFlightSectors, getDisplayVal, checkNominatedPassengers, isRetireeWidow } from '../../../helpers';
import { prepaidAuthAction, processPrepaidAuthResponse } from '../../../helpers/prepaid';

import {
  IBookFlightLtParams,
  IBookingFlowOverlayData,
  ILtConcession,
  ILtEditContactDetails,
  ILtFare,
  INominatedPassenger,
  ITravellerDetailsItem,
  ILtTravellerInfo,
  IPassport,
} from '../../../interfaces';
import { OVERLAY_VARIANT } from '../../../containers/BookingFlow/Common/BookingFlowOverlay';

import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectBooking,
  setContactDetails,
  cleanUpLTFareDetail,
  setBookingStep,
  setTpAppRef,
  setIsCalledPrepaidAuth,
  setPrepaidAuthFlowType,
  setLeisureTravelDetails,
  setIsTriggerReviewAndConfirmByNormalPrepaid,
  setDocsDoca,
  setIsTriggerGetFareByNormalPrepaid,
} from '../../../slice/bookingSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { bookFlightsLT, getFareAndTravellerDetailsLT, getDocsDoca } from '../../../services/booking';

import {
  EditTravellerDetails,
  EditPassportDetails,
  EditUsAddressDetails,
  EditLtContactDetails,
  ViewContactDetails,
  ViewLtPaymentDetails,
  ViewTicketCostDetails,
  ViewUsAddressDetails,
  ViewTravellerDetails,
  SegmentInfo,
  BaseConcessionInfo,
  BookingFlowOverlay,
} from '../../../containers';
import { Footer, Header, FormButton, ScrollableView, DesktopBreadcrumb } from '../../../components';

const { travelDetails } = en.booking.flightConfirmation;
const { contactDetails: contactDetailsLbl } = travelDetails || {};

const LtConfirmation = ({ goToRouteStep, routeToStep, handleBackClick }: IBookingFlowOverlayData) => {
  /**
   * TODO:
   * 1. initialize docs, docsResidence, docaAddress
   * 2. Mandatory & Please edit warning (need to chat with David Li)
   * */

  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const screenHeight = use100vh();
  const theme = useTheme();
  const history = useHistory();

  const [editForm, setEditForm] = useState('');
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);
  const [isRestrictTravelDetails, setIsRestrictTravelDetails] = useState(false);
  const [isRestrictUsAddress, setIsRestrictUsAddress] = useState(false);
  const [isRestrictContactDetails, setIsRestrictContactDetails] = useState(false);

  const openOverlayOfType = (type: string) => {
    setEditForm(type);
    setIsOpenOverlay(true);
  };

  const {
    outwardFlight,
    contactDetails,
    docsDoca,
    leisureTravelDetails,
    usAddress,
    isTriggerReviewAndConfirmByNormalPrepaid,
    isTriggerGetFareByNormalPrepaid,
  } = useAppSelector(selectBooking) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { ltSeatConcessionTravellerList, seatConcession, seatOption, ltConcessions, fareDetail } =
    leisureTravelDetails || {};
  const { marketingCompany } = outwardFlight || {};

  // etp-2974: if not carrier means owoal
  const isCXCarrier = marketingCompany === CARRIER_OPTION.CX;

  const { profile } = useAppSelector(selectUser);
  const { paymentConcept, paymentMedium } = profile || {};

  const concessionRule = ltConcessions && ltConcessions?.find((item: ILtConcession) => item._id === seatConcession);

  const { ern } = useAppSelector(selectAuth) || {};
  const { emailList, phoneNumber } = (contactDetails as ILtEditContactDetails) || {};
  const {
    docaAddress: isUsAddressMandatory,
    docaResidence: isTravellerInfoMandatory,
    docs: isPassportInfoMandatory,
  } = docsDoca || {};

  const transformContact = () => {
    let updatedContactDetails: ILtEditContactDetails | null = null;

    if (leisureTravelDetails?.contactDetails) {
      // Prefill phoneNumber and email from get fare API
      const { mobilePhone, businessEmail, personalEmail } = leisureTravelDetails?.contactDetails || {};
      const prefillPersonalEmail = {
        phoneNumber: mobilePhone,
        emailList: [
          {
            type: contactDetailsLbl.options[1],
            email: personalEmail,
          },
        ],
      };
      if (profile && isRetireeWidow(profile)) {
        updatedContactDetails = prefillPersonalEmail;
      } else {
        if (businessEmail) {
          updatedContactDetails = {
            phoneNumber: mobilePhone,
            emailList: [
              {
                type: contactDetailsLbl.options[0],
                email: businessEmail,
              },
            ],
          };
        } else if (personalEmail) {
          updatedContactDetails = prefillPersonalEmail;
        } else {
          //no business email/personal email
          updatedContactDetails = { phoneNumber: mobilePhone };
        }
      }
    }

    return updatedContactDetails;
  };

  // set default contact detail data from Redux
  useEffect(() => {
    if (!contactDetails && leisureTravelDetails?.contactDetails) {
      const updatedContactDetails = transformContact();
      dispatch(setContactDetails(updatedContactDetails));
    }
  }, [contactDetails, leisureTravelDetails?.contactDetails]);

  const editComponents = {
    [FORM.travellerDetails]: EditTravellerDetails,
    [FORM.passportDetails]: EditPassportDetails,
    [FORM.contactDetails]: EditLtContactDetails,
    [FORM.usAddress]: EditUsAddressDetails,
  };

  const { selectedPassengers: selectedTraveller } = checkNominatedPassengers(ltSeatConcessionTravellerList) || {};

  const { adultPassengers: adultTravellers } = checkNominatedPassengers(selectedTraveller) || {};

  const travellerInfo = (forCreateBooking: boolean) =>
    selectedTraveller.map((item: INominatedPassenger) => {
      const { passportDetail } = item || {};
      const { dateOfBirth, passportExpirationDate, gender, passportCountry, passportNationality, passportName } =
        passportDetail || {};
      const isPassportDataExist =
        !!dateOfBirth &&
        !!passportExpirationDate &&
        !!gender &&
        !!passportCountry &&
        !!passportNationality &&
        !!passportName;
      const baseData: ITravellerDetailsItem = {
        dependentId: item.dependentId,
        beneficiaryTypeCode: item.beneficiaryTypeCode,
        firstName: item.firstName,
        lastName: item.lastName,
        ...(forCreateBooking && {
          title: item.passportDetail?.title,
          countryOfResidential:
            profile && isRetireeWidow(profile)
              ? (item?.retireeCountryOfResidential as Configuration.DropdownOptionClient)?.code
              : (item?.countryOfResidential as Configuration.DropdownOptionClient)?.code,
        }),
      };

      return {
        ...baseData,
        ...(isPassportDataExist && {
          passportInput: {
            dateOfBirth: item.passportDetail?.dateOfBirth,
            expiryDate: item.passportDetail?.passportExpirationDate,
            gender: (item.passportDetail?.gender as Configuration.DropdownOptionClient)?.code,
            issuingCountry: (item.passportDetail?.passportCountry as Configuration.DropdownOptionClient)?.code,
            nationality: (item.passportDetail?.passportNationality as Configuration.DropdownOptionClient)?.code,
            passportName: item.passportDetail?.passportName,
            passportNumber: item.passportDetail?.passportNumber,
          },
        }),
        ...(isUsAddressMandatory && forCreateBooking && { usAddress }),
        ...(item.infantWithSeat === false &&
          item.validationInfo.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && {
            attachedTo: {
              dependentId:
                adultTravellers.length >= 2 ? item.accompanyAdult?.dependentId || null : adultTravellers[0].dependentId,
              beneficiaryTypeCode:
                adultTravellers.length >= 2
                  ? item.accompanyAdult?.beneficiaryTypeCode || ''
                  : adultTravellers[0].beneficiaryTypeCode,
            },
          }),
        ...(forCreateBooking &&
          item.accompanyFormData && {
            travelWith: {
              firstName: item.accompanyFormData.firstName,
              lastName: item.accompanyFormData.lastName,
              title: item.accompanyFormData.salutation?.code,
              recLoc: item.accompanyFormData.bookingReference,
            },
          }),
      };
    });

  const constructBookFlightLTParams = (): IBookFlightLtParams => {
    const { departureTime, arrivalDate, arrivalTime } = outwardFlight || {};
    const flightSectors = constructFlightSectors(outwardFlight, {
      departureTime,
      arrivalDate,
      arrivalTime,
      bookingClass: seatOption || '',
    });

    const mainEmailData = emailList?.[0];
    const altEmailData = emailList?.[1];
    const baseParams: IBookFlightLtParams = {
      userId: ern,
      flightSectors,
      phoneNo: phoneNumber || '',
      concessionRuleId: concessionRule?._id || '',
      travellerInfo: travellerInfo(true),
      // [ETP-4316] - handle email & emailType
      email: mainEmailData?.email || '',
      emailType: mainEmailData?.type?.code?.toLowerCase(),
      // [ETP-4316] - handle altEmail & altEmailType
      ...(altEmailData?.email && {
        altEmail: altEmailData?.email,
        altEmailType: altEmailData?.type?.code?.toLowerCase(),
      }),
    };
    return baseParams;
  };

  const cleanUpCurrentStepData = () => {
    // including: `contactDetails`, `leisureTravelDetails.fareDetail`
    dispatch(cleanUpLTFareDetail());
    dispatch(setContactDetails(null));

    // [ETP-4379] clear prepaid auth flag when redirect to home page/index.tsx
    dispatch(setIsCalledPrepaidAuth(false));
    dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(false));
    dispatch(setIsTriggerGetFareByNormalPrepaid(false));
  };

  // [ETP-4379] Below content is reference on other file - `SelectConcessionSeatClass/LtSeatClassSelection.tsx`
  const selectedTravellerInfo: ILtTravellerInfo[] = selectedTraveller.map((item: INominatedPassenger) => {
    const isPassportDataExist = !!item?.passportDetail;
    const baseData: ITravellerDetailsItem = {
      dependentId: item.dependentId,
      beneficiaryTypeCode: item.beneficiaryTypeCode,
      firstName: item.firstName,
      lastName: item.lastName,
      mustTravelWithBeneficiaryTypeCode: item.mustTravelWithBeneficiaryTypeCode,
    };

    return {
      ...baseData,
      ...(isPassportDataExist && {
        passportInput: {
          dateOfBirth: item.passportDetail?.dateOfBirth,
          expiryDate: item.passportDetail?.passportExpirationDate,
          gender: (item.passportDetail?.gender as Configuration.DropdownOptionClient)?.code,
          issuingCountry: (item.passportDetail?.passportCountry as Configuration.DropdownOptionClient)?.code,
          nationality: (item.passportDetail?.passportNationality as Configuration.DropdownOptionClient)?.code,
          passportName: item.passportDetail?.passportName,
          passportNumber: item.passportDetail?.passportNumber,
        },
      }),
      ...(item.infantWithSeat === false &&
        item.validationInfo.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && {
          attachedTo: {
            dependentId:
              adultTravellers.length >= 2 ? item.accompanyAdult?.dependentId || null : adultTravellers[0]?.dependentId,
            beneficiaryTypeCode:
              adultTravellers.length >= 2
                ? item.accompanyAdult?.beneficiaryTypeCode || ''
                : adultTravellers[0]?.beneficiaryTypeCode,
          },
        }),
    };
  });

  // [ETP-4379] Below content is reference on other file - `SelectConcessionSeatClass/LtSeatClassSelection.tsx`
  const getFareAndTravellerDetails = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = seatOption || {};
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const results =
      (await getFareAndTravellerDetailsLT(
        ern,
        concessionRule?._id || '',
        flightSectors,
        selectedTravellerInfo,
        en.errorAlert.getFareAndTravellerInfo,
      )) || null;

    if (results) {
      // 1. Extract the travellerDetail array
      const travellerDetails: ITravellerDetailsItem[] = results.travellerDetails;

      const returnedTravellerDependentId: Array<string | null> = travellerDetails.map(
        (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId,
      );

      let updatedltTravellerList = _.cloneDeep(ltSeatConcessionTravellerList);

      // 2. Set travelDetail and passportDetail to ltTraveller
      updatedltTravellerList = updatedltTravellerList.map((traveller: INominatedPassenger) => {
        // Get the travellerDetail from the getFare API
        if (returnedTravellerDependentId?.includes(traveller.dependentId)) {
          // from api resp data
          const matchedTravellerDetails = travellerDetails.find(
            (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId === traveller.dependentId,
          );
          // 3. Process the countryOfResidential into Configuration.CodeLabel
          let countryOfResidentialOption;
          if (traveller.isUpdated && traveller?.countryOfResidential) {
            // get lastest data from Redux
            countryOfResidentialOption = traveller.countryOfResidential;
          } else {
            countryOfResidentialOption = configurations?.countries?.find(
              (option: Configuration.DropdownOptionClient) =>
                option.code === matchedTravellerDetails?.countryOfResidential,
            );
          }

          //4. Process passport Detail (passportCountry, passportNationality and gender)
          let passportDetail: IPassport | undefined;
          let accompanyAdult: any;

          if (traveller.isUpdated && traveller?.passportDetail) {
            passportDetail = traveller?.passportDetail;
          } else {
            passportDetail = _.cloneDeep(matchedTravellerDetails?.passport);

            // update passport data from fare API
            if (passportDetail) {
              passportDetail.gender = configurations?.labels?.find(
                (option: Configuration.CodeLabel) =>
                  option.category === LABEL_CATEGORY.gender && option.code === passportDetail?.gender,
              );
              passportDetail.passportCountry = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportCountry,
              );
              passportDetail.passportNationality = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportNationality,
              );
            }
          }
          if (matchedTravellerDetails?.attachedTo) {
            accompanyAdult = travellerDetails.find(
              (travellerDetail: ITravellerDetailsItem) =>
                travellerDetail.dependentId === matchedTravellerDetails?.attachedTo.dependentId,
            );
          }

          return {
            ...traveller,
            accompanyAdult: accompanyAdult,
            countryOfResidential: countryOfResidentialOption,
            passportDetail: passportDetail,
          };
        } else {
          return traveller;
        }
      });

      // Set contactDetails
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          ltSeatConcessionTravellerList: updatedltTravellerList,
          contactDetails: results.contactDetails || {},
          fareDetail: results.fare,
        }),
      );
    }

    return results;
  };

  // [ETP-4379}] Below content is reference on other file - `SelectConcessionSeatClass/LtSeatClassSelection.tsx`
  const getDocsDocaAction = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = seatOption;
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const docsDocaResult = await getDocsDoca(flightSectors);

    dispatch(setDocsDoca(docsDocaResult));

    return docsDocaResult;
  };

  // [ETP-4379] trigger call get fare API on Scenario 1b,2,3
  const handleNormalPrepaidGetFareFlow = async () => {
    // Below content is reference on other file - `SelectConcessionSeatClass/LtSeatClassSelection.tsx`

    // ow/oal no need to call docs/doca api
    const docsDocaResult = isCXCarrier ? await getDocsDocaAction() : undefined;
    if (!_.isNil(docsDocaResult) || !isCXCarrier) {
      await getFareAndTravellerDetails();
    }

    dispatch(setIsTriggerGetFareByNormalPrepaid(true));
  };

  const handleDesktopHeaderOnBack = () => {
    // [ETP-4379] clear prepaid auth flag when redirect to home page/index.tsx
    dispatch(setIsCalledPrepaidAuth(false));
    dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(false));
    dispatch(setIsTriggerGetFareByNormalPrepaid(false));
    handleBackClick?.();
  };

  // [ETP-4379] [normal prepaid] trigger get fare API
  useEffect(() => {
    /* 
      Remark: 
      When isTriggerReviewAndConfirmByNormalPrepaid is true,
      means some unhappy flow will redirect to Review and Confirm page

      When isTriggerGetFareByNormalPrepaid is false,
      means have not called get fare API on Normal prepaid flow (prevent duplicated call)
    */
    if (isTriggerReviewAndConfirmByNormalPrepaid && !isTriggerGetFareByNormalPrepaid) {
      handleNormalPrepaidGetFareFlow();
    }
  }, []);

  return (
    <Box
      sx={{
        background: isDesktop ? theme.color.secondary.light_slate.option_7 : 'transparent',
      }}
    >
      <Box
        component={ScrollableView}
        sx={{
          height: isDesktop
            ? `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight}  - ${UI_STYLES.desktopFooterHeight})`
            : `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${UI_STYLES.footerHeight} + ${UI_STYLES.mobileHeaderMarginTopBookingCompleted})`,
          mt: isDesktop ? 0 : `-${UI_STYLES.mobileHeaderMarginTopBookingCompleted}`,
          px: isDesktop ? 0 : 2,
        }}
      >
        <DesktopBreadcrumb
          step={routeToStep}
          customStyles={{ height: UI_STYLES.overlayHeaderHeightSearchCriteria }}
          customCrumbs={en.booking.ltBreadCrumbs}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            background: isDesktop ? 'none' : theme.color.secondary.light_slate.option_7,
            width: isDesktop ? UI_STYLES.desktopHeaderNavWidth : '100%',
            mx: 'auto',
            borderRadius: isDesktop ? 0 : 1,
          }}
        >
          {isDesktop && (
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDesktopHeaderOnBack();
                  }}
                >
                  {en.booking.flightClass.LTBackSeatOptions}
                </Typography>
              }
              customStyles={{
                mb: 2,
              }}
              handleOnBack={handleDesktopHeaderOnBack}
            />
          )}

          <Box
            sx={{
              mb: 1,
              borderRadius: 1,
              mx: isDesktop ? 1 : 0,
              backgroundColor: 'white',
            }}
          >
            {outwardFlight && <SegmentInfo flightInfo={outwardFlight} isShowSegmentStatus={false} />}

            <ViewTicketCostDetails
              currency={fareDetail ? Object.values(fareDetail as ILtFare)?.[0]?.currency : ''}
              fareDetails={(fareDetail as ILtFare) || {}}
            />
            <BaseConcessionInfo data={getDisplayVal(concessionRule?.displayName)} customStyles={{ p: 2 }} />

            <ViewTravellerDetails
              isTravellerInfoMandatory={isTravellerInfoMandatory}
              isPassportInfoMandatory={isPassportInfoMandatory}
              handleEditClick={openOverlayOfType}
              requestedTravellers={selectedTraveller || []}
              setIsRestrictBooking={(value: boolean) => {
                setIsRestrictTravelDetails(value);
              }}
              profile={profile}
            />

            {isUsAddressMandatory && (
              <ViewUsAddressDetails
                handleEditClick={openOverlayOfType}
                setIsRestrictBooking={(value: boolean) => {
                  setIsRestrictUsAddress(value);
                }}
                data={usAddress}
              />
            )}

            <ViewContactDetails
              handleEditClick={openOverlayOfType}
              setIsRestrictBooking={(value: boolean) => {
                setIsRestrictContactDetails(value);
              }}
              data={contactDetails as ILtEditContactDetails}
            />

            <ViewLtPaymentDetails paymentConcept={paymentConcept || ''} paymentMedium={paymentMedium || ''} />
          </Box>
        </Box>

        {editComponents[editForm] && (
          <BookingFlowOverlay
            component={editComponents[editForm]}
            title={en.booking.flightConfirmation.reviewBooking}
            open={isOpenOverlay}
            variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
            isDrawer={true}
            handleBackClick={() => {
              setIsOpenOverlay(false);

              // [ETP-4379] clear prepaid auth flag when redirect to home page/index.tsx
              dispatch(setIsCalledPrepaidAuth(false));
              dispatch(setIsTriggerReviewAndConfirmByNormalPrepaid(false));
              dispatch(setIsTriggerGetFareByNormalPrepaid(false));

              setTimeout(() => {
                dispatch(reset(editForm));
                setEditForm('');
              }, 500);
            }}
            data={{
              handleClose: () => {
                setIsOpenOverlay(false);
                setTimeout(() => {
                  dispatch(reset(editForm));
                  setEditForm('');
                }, 500);
              },
            }}
          />
        )}
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={async () => {
              await cleanUpCurrentStepData();
              dispatch(setBookingStep(null));
              sessionStorage.removeItem('searchResultCache');
              await history.go(0);
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={
          isRestrictTravelDetails ||
          isRestrictUsAddress ||
          isRestrictContactDetails ||
          (profile && isRetireeWidow(profile) && !profile?.personalInfo?.personalEmail)
            ? undefined
            : {
                text:
                  paymentConcept === PAYMENT_CONCEPT.PREPAID // ETP-2431
                    ? en.common.confirmAndPay // ETP-2431
                    : en.common.createBooking, // ETP-2431
                customOnClick: async () => {
                  const bookFlightLTParams = constructBookFlightLTParams();
                  const bookingCallback = (bookingResult: any) => {
                    goToRouteStep?.({
                      step: BOOKING_FLOW_PAGE_NO.completed,
                      data: {
                        bookingResult,
                        recLoc: bookingResult.booking.recLoc,
                      },
                    });
                  };
                  // ETP-2431
                  if (paymentConcept === PAYMENT_CONCEPT.PREPAID) {
                    // ETP-3370 Stop home screen redirect when CPP, paypal and retrieve and pay

                    // useless maybe
                    // dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.confirmation));

                    dispatch(setPrepaidAuthFlowType(PREPAID_AUTH_FLOW_TYPE.NORMAL_PREPAID));

                    const resp = await prepaidAuthAction(bookFlightLTParams);
                    if (resp) {
                      const { tp_app_ref: tpAppRefFromResp, booking } = resp;

                      // prepaid 0 amount: Because 'CPP' does not allow 0 amount, created booking
                      if (booking) {
                        bookingCallback(resp);
                        return;
                      }

                      // redux
                      dispatch(setIsCalledPrepaidAuth(true));
                      dispatch(setTpAppRef(tpAppRefFromResp));

                      processPrepaidAuthResponse(resp);
                    }
                    return;
                  }
                  const result = await bookFlightsLT(bookFlightLTParams);
                  result && bookingCallback(result);
                },
                customButtonStyles: {
                  marginRight: 0,
                },
              }
        }
        customStyles={
          isDesktop
            ? {
                height: UI_STYLES.desktopFooterHeight,
                px: 22,
                boxShadow: theme.palette.boxShadow.dark,
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                px: 2,
              }
        }
      />
    </Box>
  );
};

export default connect((state: RootState) => ({
  travelDetailsFormValues: getFormValues(FORM.travelDetails)(state),
}))(LtConfirmation);
