import React, { useEffect, useContext } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { USER_PROFILE_PATHS } from '../../../constants/paths';
import { INIT_EDIT_COMPANION_PASSPORT_CONTEXT } from '../../../constants/constants';
import { BackIcon, NominatedIcon, AlertIcon } from '../../../assets/images';

import en from '../../../translations/en';

import { IDependent } from '../../../interfaces';

import { selectApp, setSnackBarData } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { getDependentThunk, selectUser, setIsLoadedDependent } from '../../../slice/userSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { NominationAction, NominationState } from '../../../context';

import { CompanionEditPassportContainer, DependentDetailsCard } from '../../../containers';
import { CancelDialog } from '../../../components';

const DependentDetailsContainer = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { dependent } = useAppSelector(selectUser) || [];

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};

  const nomineesLbl = en.userProfile.nomination.nomineesDetail;
  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const cancelEditCompanionPassportLbl = companionRegistrationLbl.editCompanionPassport;

  const {
    isEditMode,
    isOpenCancelEditCompanionPassportDialog,
    initFormData: selectedInitCompanionPassportData,
  } = companionRegistrationFlow?.editPassport || {};

  const refreshNomineeDetailHandler = async () => {
    await dispatch(setIsLoadedDependent(false));
    await dispatch(getDependentThunk({ userId: ern }));

    // display toast message
    dispatch(
      setSnackBarData({
        message: companionRegistrationLbl.editCompanionPassport.editSuccessToastMessage,
        isShow: true,
        delay: 700,
        customStyles: {
          // TMP support desktop or mobile view.
          mb: isDesktop ? 9 : 10.5,
        },
      }),
    );

    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: {
        ...companionRegistrationFlow.editPassport,
        isTriggerEditCompanionPassportToast: false,
      },
    });
  };

  useEffect(() => {
    dispatch(setIsLoadedDependent(false));
    dispatch(getDependentThunk({ userId: ern }));
    return () => {};
  }, [dispatch]);

  // [ETP-4868] refresh nominee detail page after edit passport success
  useEffect(() => {
    if (companionRegistrationFlow.editPassport.isTriggerEditCompanionPassportToast) {
      refreshNomineeDetailHandler();
    }
  }, [companionRegistrationFlow]);

  const [nominatedList, otherDependentsList] = _.partition(dependent, (item: IDependent) => item.isNominationActive);

  const RenderDependentView = ({
    list,
    name,
    isNominatedList,
  }: {
    list: IDependent[];
    name: string;
    isNominatedList: boolean;
  }) => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: isNominatedList ? 0 : 2,
          }}
        >
          {isNominatedList && <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />}
          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
              ml: 0.5,
            }}
          >
            {name}
          </Typography>
        </Box>
        {list?.map((item: IDependent, index: number) => {
          return <DependentDetailsCard key={index} data={item} isNominatedList={isNominatedList} />;
        })}
      </>
    );
  };

  return (
    <Box className="dependent_details_container" sx={{ ...(isDesktop && { width: '488px' }) }}>
      {isEditMode && (
        <Box className="edit_passport_container">
          {selectedInitCompanionPassportData && (
            <CompanionEditPassportContainer passportData={selectedInitCompanionPassportData} isEditMode={isEditMode} />
          )}

          <CancelDialog
            open={isOpenCancelEditCompanionPassportDialog}
            showCancelIcon={false}
            handleConfirmAction={() => {
              // confirm to reset and back to nominee detail page
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
              });
            }}
            handleCloseDialog={() => {
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: {
                  ...companionRegistrationFlow.editPassport,
                  isOpenCancelEditCompanionPassportDialog: false,
                },
              });
            }}
            confirmToClose={false}
            IconComponent={<AlertIcon />}
            dialogContent={{
              title: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.title,
              message: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.description,
              yesTitle: en.common.yesConfirm,
              noTitle: en.common.back,
            }}
          />
        </Box>
      )}
      {!isEditMode && (
        <>
          {!isDesktop && (
            <IconButton
              onClick={() => {
                history.push(USER_PROFILE_PATHS.menu);
                dispatch(setIsLoadedDependent(false));
              }}
              sx={{ py: 1.625, pl: 0 }}
            >
              <BackIcon fill={theme.color.utility.link.option_3} />
            </IconButton>
          )}
          <Box>
            <Typography
              variant="headline_medium"
              sx={{
                color: theme.color.secondary.dark_grey.option_1,
                mb: 3,
                mt: 2,
              }}
            >
              {nomineesLbl.detailPageTitle}
            </Typography>
          </Box>
          {!isLoading && dependent?.length === 0 && (
            <Typography
              variant="body_1_medium"
              sx={{
                color: theme.palette.gray.main,
                ml: 0.5,
                mt: 3,
                textAlign: 'center',
              }}
            >
              {nomineesLbl.empty}
            </Typography>
          )}

          {!isLoading && dependent?.length > 0 && (
            <>
              {nominatedList?.length > 0 && (
                <RenderDependentView list={nominatedList} name={nomineesLbl.nominatedSection} isNominatedList={true} />
              )}

              {otherDependentsList?.length > 0 && (
                <RenderDependentView
                  list={otherDependentsList}
                  name={nomineesLbl.notNominatedSection}
                  isNominatedList={false}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default DependentDetailsContainer;
