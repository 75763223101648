import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import en from '../../../../translations/en';

const NominationOALEligibilitySwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => {
  const contentFontStyle = {
    fontSize: theme.typography.body_1_regular.fontSize,
    top: '12px',
  };

  return {
    width: 66,
    height: 32,
    padding: 0,
    '& .MuiSwitch-track': {
      borderRadius: 16,
      backgroundColor: theme.backgroundColor.trackColor,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '26px',
        height: 16,
      },
      '&:before': {
        content: `"${en.common.yes}"`,
        color: theme.palette.primary.light,
        left: '4px',
        ...contentFontStyle,
      },
      '&:after': {
        content: `"${en.common.no}"`,
        color: theme.palette.gray.dark,
        right: '6px',
        ...contentFontStyle,
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(34px)',
        color: 'white',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.color.secondary.dark_grey.option_3,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
          cursor: 'default',
        },
        '+ .MuiSwitch-track': {
          '&:before': {
            left: '7px',
          },
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        cursor: 'default',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 28,
      height: 28,
    },
  };
});

export default NominationOALEligibilitySwitch;
