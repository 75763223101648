import { SxProps, Theme } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { IErrorAlert } from '../interfaces';

export interface ICancelBookingSuccessData {
  flightNumber: string;
  isShow: boolean;
  removePaxLabel?: string; // for remove pax use, control not to show snackBar
}
export interface IRefundBookingSuccessData {
  applicationId: string;
}
export interface IRetrieveAndPayBookingSuccessData {
  bookingResult: any;
  recLoc?: string;
}
export interface INormalPrepaidBookingSuccessData {
  bookingResult: any;
  recLoc?: string;
}

export interface ISnackBarData {
  message: string;
  isShow: boolean;
  delay?: number;
  customStyles?: (Readonly<SxProps<Theme>> & SxProps<Theme>) | null;
}

interface IAppSlice {
  isLoading: boolean;
  isQuitCppFlow: boolean;
  loadingMessage?: string;
  isDesktop: boolean;
  errorAlert: IErrorAlert;
  cancelBookingSuccessData: ICancelBookingSuccessData | null;
  refundBookingSuccessData: IRefundBookingSuccessData | null;
  isCreatingBooking: boolean;
  retrieveAndPaySuccessData: IRetrieveAndPayBookingSuccessData | null;
  normalPrepaidSuccessData: INormalPrepaidBookingSuccessData | null;
  snackBarData: ISnackBarData | null;
  searchBarInputWidth: number;
}

const initialState: IAppSlice = {
  isLoading: false,
  isQuitCppFlow: false,
  loadingMessage: '',
  isDesktop: false, // default is mobile view
  errorAlert: {
    title: '',
    message: '',
    applicationId: '',
    requestId: '',
    noErrorAlert: true,
    isEnableMobileEditSearchFlag: false,
    isShowCommonWarning: false,
    dismissCallBackFunc: null, // Store a callback function as null initially
    showErrorIcon: false,
    errorStatus: '',
  },
  cancelBookingSuccessData: null,
  refundBookingSuccessData: null,
  isCreatingBooking: false,
  retrieveAndPaySuccessData: null,
  normalPrepaidSuccessData: null,
  snackBarData: null,
  searchBarInputWidth: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsQuitCppFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isQuitCppFlow = payload;
    },
    setLoadingMessage: (state, { payload }: PayloadAction<string>) => {
      state.loadingMessage = payload;
    },
    setIsDesktop: (state, { payload }: PayloadAction<boolean>) => {
      state.isDesktop = payload;
    },
    setErrorAlert: (state, { payload }: PayloadAction<IErrorAlert>) => {
      state.errorAlert = payload;
    },
    setCancelBookingSuccessData: (state, { payload }: PayloadAction<ICancelBookingSuccessData | null>) => {
      state.cancelBookingSuccessData = payload;
    },
    setRefundBookingSuccessData: (state, { payload }: PayloadAction<IRefundBookingSuccessData | null>) => {
      state.refundBookingSuccessData = payload;
    },
    setRetrieveAndPaySuccessData: (state, { payload }: PayloadAction<IRetrieveAndPayBookingSuccessData | null>) => {
      state.retrieveAndPaySuccessData = payload;
    },
    setNormalPrepaidSuccessData: (state, { payload }: PayloadAction<INormalPrepaidBookingSuccessData | null>) => {
      state.normalPrepaidSuccessData = payload;
    },
    setSnackBarData: (state, { payload }: PayloadAction<ISnackBarData | null>) => {
      state.snackBarData = payload;
    },
    setSearchBarInputWidth: (state, { payload }: PayloadAction<number>) => {
      state.searchBarInputWidth = payload;
    },
    setIsCreatingBooking: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingBooking = payload;
    },
    setDismissCallBackFunc: (state, { payload }: PayloadAction<(() => void) | null>) => {
      state.errorAlert.dismissCallBackFunc = payload;
    },
    reset: () => initialState,
  },
});

const selectApp = (state: RootState) => state.app;
const {
  setLoading,
  setIsQuitCppFlow,
  setLoadingMessage,
  setIsDesktop,
  setErrorAlert,
  setCancelBookingSuccessData,
  setRefundBookingSuccessData,
  setRetrieveAndPaySuccessData,
  setNormalPrepaidSuccessData,
  setSnackBarData,
  setSearchBarInputWidth,
  setIsCreatingBooking,
  reset,
} = appSlice.actions;

export {
  appSlice,
  selectApp,
  setLoading,
  setIsQuitCppFlow,
  setLoadingMessage,
  setIsDesktop,
  setErrorAlert,
  setCancelBookingSuccessData,
  setRefundBookingSuccessData,
  setRetrieveAndPaySuccessData,
  setNormalPrepaidSuccessData,
  setSnackBarData,
  setSearchBarInputWidth,
  setIsCreatingBooking,
  reset,
  initialState,
};
export type { IAppSlice };

export default appSlice.reducer;
