import { useEffect, useState } from 'react';

import en from '../translations/en';

import { isRetireeWidow } from '../helpers';

import { useAppSelector } from '../app/hooks';

import { selectUser } from '../slice/userSlice';

export interface IEmailRegisterIndicator {
  isShowWarning: boolean;
  emailRegisterMessage: string;
}

/*
  Mainly to check email has setup or not
*/
export const useEmailRegisterIndicator = () => {
  const { profile } = useAppSelector(selectUser) || {};

  const [emailRegisterMethodIndicator, setEmailRegisterMethodIndicator] = useState<IEmailRegisterIndicator>({
    isShowWarning: false,
    emailRegisterMessage: '',
  });

  const isRetireeWidowRole = profile && isRetireeWidow(profile);
  const isNotYetRegisterPersonalEmail = !!(isRetireeWidowRole && !profile?.personalInfo?.personalEmail);

  useEffect(() => {
    if (isRetireeWidowRole) {
      setEmailRegisterMethodIndicator({
        isShowWarning: isNotYetRegisterPersonalEmail,
        emailRegisterMessage: isNotYetRegisterPersonalEmail ? en.userProfile.personal.personalEmailWarning : '',
      });
      return;
    }
  }, [profile, isRetireeWidowRole]);
  return [emailRegisterMethodIndicator, setEmailRegisterMethodIndicator] as const;
};
