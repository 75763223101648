import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import en from '../../translations/en';
import { BOOKING_PATHS } from '../../constants/paths';
import { USER_ROLE, GOOGLE_ANALYTICS_EVENTS, BOOKING_STATUS } from '../../constants/constants';

import { BOOKING_LAYOUT_VARIANT } from '../MyBooking/BookingLayout';
import { Frontend } from 'booking';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setRefundBookingSuccessData } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import { selectUser } from '../../slice/userSlice';

import { BookingSummaryState } from '../../context';

import { getUpcomingBooking } from '../../services/booking';
import { getUpcomingBooking as getUpcomingBookingDelegation } from '../../services/delegation/booking';

import { BookingLayout, BookingSummaryOverlay } from '..';

const UpComingBookingLayout = ({ scrollViewRef }: { scrollViewRef: any }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const theme = useTheme();

  const { refundBookingSuccessData } = useAppSelector(selectApp) || {};

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType, id: roleId } = role || {};

  const [upcomingBookings, setUpcomingBookings] = useState<Frontend.ParsedBooking[]>([]);
  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);

  const { bookingSummaryData } = useContext(BookingSummaryState) || {};

  const getUpcomingBookingAction = async () => {
    let results;

    if (roleType === USER_ROLE.delegation) {
      results = await getUpcomingBookingDelegation(roleId);
    } else {
      results = await getUpcomingBooking(ern);
    }

    if (results) {
      setUpcomingBookings(results);
    }
  };

  useEffect(() => {
    setUpcomingBookings([]);
    getUpcomingBookingAction();
  }, [ern]);

  useEffect(() => {
    if (bookingSummaryData) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }

      // update upComingBookings (cancel、removePax、cancelAndRefund), not request API
      // filter func for cancel booking
      const updateUpComingBookings = upcomingBookings
        .map((booking) => (booking.applicationId === bookingSummaryData.applicationId ? bookingSummaryData : booking))
        .filter(
          (booking) =>
            ![
              BOOKING_STATUS.flown,
              BOOKING_STATUS.cancelled,
              BOOKING_STATUS.pending,
              BOOKING_STATUS.unsuccessful,
            ].includes(booking.status),
        );
      setUpcomingBookings(updateUpComingBookings);
    }
  }, [bookingSummaryData]);

  const upcomingBookingCards = () => {
    // TODO: when ticketsBySegment have multi segments,
    // need to consider only show 4 bookingCards.
    // coz one segment is show as a booking card
    const bookingForDisplay = isDesktop ? upcomingBookings.slice(0, 9) : upcomingBookings.slice(0, 4);
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          px: { sm: 13, xs: 2 },
        }}
      >
        {/* // ETP-2445 => ETP-2473 */}
        <BookingLayout
          bookings={bookingForDisplay}
          emptyTitle={en.home.noUpcomingTrips}
          handleClick={(selectedItem: Frontend.ParsedBooking) => {
            setBookingSelected(selectedItem);
          }}
          variant={BOOKING_LAYOUT_VARIANT.HOME_PAGE}
        />
      </Box>
    );
  };
  const UpcomingBookingCardsComponent = upcomingBookingCards();

  const handleBookingSummaryClose = useCallback(() => {
    setBookingSelected(null);
    if (refundBookingSuccessData?.applicationId) {
      dispatch(setRefundBookingSuccessData({ applicationId: '' }));
    }
  }, [refundBookingSuccessData?.applicationId]);

  return (
    <>
      <Grid
        className="upcoming_booking_layout"
        item
        xs={12}
        px={{ sm: 13, xs: 2 }}
        pt={isDesktop ? 3.75 : 2}
        pb={isDesktop ? 3 : 2}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="headline_medium" color={theme.color.secondary.dark_grey.option_3}>
            {en.home.upcomingTrips}
          </Typography>

          <Button
            sx={{ p: 0, lineHeight: 'normal' }}
            onClick={() => {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.manageBookingFlow,
                action: GOOGLE_ANALYTICS_EVENTS.action.viewAll,
              });

              history.push(BOOKING_PATHS.myBookings);
            }}
          >
            <Typography variant="body_1_bold" color={theme.color.utility.link.option_3}>
              {en.home.viewAll}
            </Typography>
          </Button>
        </Box>
      </Grid>

      {UpcomingBookingCardsComponent}

      <BookingSummaryOverlay
        bookingSelected={bookingSelected}
        handleClose={handleBookingSummaryClose}
        data={{
          applicationId: bookingSelected?.applicationId,
          type: bookingSelected?.type,
        }}
      />
    </>
  );
};

export default UpComingBookingLayout;
