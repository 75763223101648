import { useContext } from 'react';
import { Box } from '@mui/material';
import { NOMINATION_TYPE } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { INominationViewModeList } from '../../../../interfaces';
import { handleNominatedEligibleCarrier } from '../../../../helpers';
import { NominationState } from '../../../../context';

import { selectApp } from '../../../../slice/appSlice';
import { useAppSelector } from '../../../../app/hooks';
import { selectConfiguration } from '../../../../slice/configurationSlice';

import { NominationPageTitle, NominationCartMasterContainer } from '../../..';

const NominationViewModeContainer = () => {
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  const { nominationFlow } = useContext(NominationState) || {};

  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  return (
    <Box className="nomination_view_mode_container">
      <Box
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
            mt: 2,
          }),
        }}
      >
        <NominationPageTitle
          title={nominationFlowLbl.nominationViewMode.title}
          sxProps={{ ml: 0.5, mt: isDesktop ? 0 : -1.25, mb: 0.5 }}
        />

        {!isLoading &&
          Object.values(NOMINATION_TYPE).map((nominationType: string) => {
            const mappingKey = NOMINATION_TYPE?.[nominationType as keyof typeof NOMINATION_TYPE];
            const matchedTypeObj =
              nominationFlow?.nominationViewModeData?.[mappingKey as keyof INominationViewModeList];

            return (
              <NominationCartMasterContainer
                key={nominationType}
                // insert eligibleCarrier
                list={handleNominatedEligibleCarrier(configurations, matchedTypeObj?.list || [])}
                maxNumberOfPerson={matchedTypeObj?.maxNumberOfPerson}
                nominationType={nominationType}
                isViewMode={true}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default NominationViewModeContainer;
