import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import '../../../assets/css/transition.css';

import { IBookingFlowOverlayData } from '../../../interfaces';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

export interface IOverlayContainerProps {
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
  handleBackClick?: () => void;
  handleFooterClick?: () => void;
}

const OverlayContainer = ({
  component: Component,
  data,
  handleBackClick,
  handleFooterClick,
}: IOverlayContainerProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const customHandleBackClick = data?.handleBackClick || handleBackClick;

  const displayContent = useMemo(() => {
    if (!Component) {
      return <></>;
    }

    return (
      <Component
        handleBackClick={() => {
          customHandleBackClick && customHandleBackClick();
        }}
        handleFooterClick={() => {
          handleFooterClick && handleFooterClick();
        }}
        {...data}
      />
    );
  }, [Component, data, customHandleBackClick, handleFooterClick]);

  const SwitchComponent = () => {
    return (
      <CSSTransition key={Component.name} timeout={400}>
        <Box
          sx={{
            backgroundColor: theme.color.secondary.light_slate.option_7,
          }}
        >
          <Box
            sx={{
              height: '100%',
            }}
          >
            {displayContent}
          </Box>
        </Box>
      </CSSTransition>
    );
  };

  return (
    <Box display={isDesktop ? { xs: 'none', sm: 'block' } : { xs: 'block', sm: 'none' }}>
      <>{SwitchComponent()}</>
    </Box>
  );
};

export default OverlayContainer;
