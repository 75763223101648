import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';

import { ParagraphMasterContainer } from '../..';

const ChangeTypeDialogMessageComponent = ({
  changeTypeDialogSubtitle,
  changeTypeDialogLabel,
  changeTypeContent,
}: {
  changeTypeDialogSubtitle: string;
  changeTypeDialogLabel: string;
  changeTypeContent: {
    text: string;
    formatType: string;
  }[];
}) => {
  return (
    <Box>
      <Typography
        sx={{ mt: 1 }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(changeTypeDialogSubtitle),
        }}
      />
      {changeTypeDialogLabel && <Typography variant="body_1_bold">{changeTypeDialogLabel}</Typography>}
      <ParagraphMasterContainer
        content={changeTypeContent}
        paragraphVariant="body_1_regular"
        paragraphSxProps={{
          mt: 4,
          pb: 0,
        }}
      />
    </Box>
  );
};

export default ChangeTypeDialogMessageComponent;
