import { useContext, useEffect } from 'react';
import { Box, useTheme, Typography, IconButton } from '@mui/material';
import { reduxForm, Field, getFormValues, reset, FormErrors } from 'redux-form';
import { connect } from 'react-redux';

import en from '../../../../translations/en';
import { FORM, INIT_EDIT_COMPANION_PASSPORT_CONTEXT } from '../../../../constants/constants';
import { BackIcon, PassportIcon } from '../../../../assets/images';

import { validateRequiredFields } from '../../../../helpers';

import { ICompanionPassportDetails } from '../../../../interfaces';

import { RootState } from '../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { NominationAction, NominationState } from '../../../../context';

import {
  ReduxFormTextInput,
  CountrySelectInput,
  PersonalOrPassportNameInput,
  PassportExpiryDatePicker,
} from '../../../../components';
import { NominationPageTitle, ParagraphMasterContainer } from '../../../../containers';

export const validatePassport = (
  values: ICompanionPassportDetails | null,
): FormErrors<ICompanionPassportDetails | null> => {
  const errors: FormErrors<ICompanionPassportDetails | null> = {};

  if (values) {
    validateRequiredFields(
      values,
      ['passportNumber', 'passportLastName', 'passportNationality', 'passportCountry'],
      errors,
    );

    if (!values.passportFirstName) {
      errors.passportFirstName =
        en.userProfile.nomination.companionRegistration.passportDetails.passportFirstNameErrorLabel;
    }
  }

  return errors;
};

const CompanionPassportContainer = (props: any) => {
  const {
    companionPassportFormValues,
  }: {
    companionPassportFormValues: ICompanionPassportDetails | null;
  } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};
  const { routeTo, editPassport } = companionRegistrationFlow || {};
  const { step: routeToStep } = routeTo || {};
  const isEditMode = editPassport?.isEditMode || false;

  const nominationLbl = en.userProfile.nomination;
  const companionRegistrationLbl = nominationLbl.companionRegistration;
  const passportDetailLbl = companionRegistrationLbl.passportDetails;

  const {
    passportNumber,
    passportFirstName,
    passportLastName,
    passportExpirationDate,
    passportNationality,
    passportCountry,
  } = companionPassportFormValues || {};

  // save the form values to context
  useEffect(() => {
    const basePassport = {
      passportNumber: passportNumber?.trim(),
      passportFirstName: passportFirstName?.trim(),
      passportLastName: passportLastName?.trim(),
      passportExpirationDate,
    };

    const consolidatedPassport = {
      ...basePassport,
      passportNationality,
      passportCountry,
    };

    // checked if all the passport mandatory fields are filled
    const isPassportInputValid = !!(
      Object.values(basePassport).every(Boolean) &&
      passportNationality?.code &&
      passportCountry?.code
    );

    // [ETP-3868] Edit Companion passport in Nominee detail page
    if (isEditMode) {
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        editPassport: {
          ...companionRegistrationFlow.editPassport,
          isPassportInputValid,
          requestParams: {
            dependentId: companionRegistrationFlow.editPassport.dependentId,
            passportNumber: basePassport.passportNumber,
            passportExpirationDate: basePassport.passportExpirationDate,
            passportNationality: passportNationality?.code,
            passportCountry: passportCountry?.code,
          },
        },
      });
    } else {
      // [ETP-4622] Fill-in Companion passport in Companion registration flow
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        isPassportInputValid,
        companionRegistrationData: {
          ...companionRegistrationFlow.companionRegistrationData,
          companionPassport: consolidatedPassport,
        },
      });
    }
  }, [companionPassportFormValues]);

  return (
    <Box className="companion_passport_container">
      {!isDesktop && (
        <IconButton
          onClick={() => {
            // edit companion passport flow
            if (isEditMode) {
              dispatch(reset(FORM.companionPassport));

              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
              });
            } else {
              // companion registration flow
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                routeTo: {
                  step: routeToStep - 1,
                },
              });
            }
          }}
          sx={{ py: 1.625, pl: 0 }}
        >
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box sx={{ px: { xs: 0, sm: 0 } }}>
        <Box
          sx={{
            ...(isDesktop
              ? {
                  mt: 2,
                  mb: 1,
                  width: '488px',
                  mx: 'auto',
                }
              : {
                  mt: 2.25,
                }),
          }}
        >
          <NominationPageTitle
            title={
              isEditMode
                ? companionRegistrationLbl.editCompanionPassport.contentTitle
                : nominationLbl.nomineesDetail.contentTitle
            }
            sxProps={{
              pb: 3,
            }}
          />

          {!isEditMode && (
            <ParagraphMasterContainer
              content={companionRegistrationLbl.passportDetails.descriptionContent}
              paragraphVariant="body_1_regular"
            />
          )}

          {!isEditMode && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: isDesktop ? 0.5 : 1.5 }}>
              <Box sx={{ display: 'flex', width: 20, height: 20, mr: 0.5, mt: 0.375 }}>
                <PassportIcon width={16} height={16} />
              </Box>

              <Typography
                variant="body_1_medium"
                sx={{
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                {companionRegistrationLbl.passportDetails.heading}
              </Typography>
            </Box>
          )}

          <Field
            component={ReduxFormTextInput}
            name="passportNumber"
            isNumOrAlphaOrSpace
            title={passportDetailLbl.fields.passportNumber}
            isAlphaOrSpace
            isUpperCase
          />

          <PersonalOrPassportNameInput
            name="passportFirstName"
            title={passportDetailLbl.fields.passportFirstName}
            isReadOnly={isEditMode}
          />

          <PersonalOrPassportNameInput
            name="passportLastName"
            title={passportDetailLbl.fields.surname}
            isReadOnly={isEditMode}
          />

          <PassportExpiryDatePicker name="passportExpirationDate" title={passportDetailLbl.fields.expiryDate} />

          <CountrySelectInput name="passportNationality" title={passportDetailLbl.fields.nationality} />

          <CountrySelectInput name="passportCountry" title={passportDetailLbl.fields.issuedCountry} />
        </Box>
      </Box>
    </Box>
  );
};

/* 
  TBC below coding, can not combine as single reduxForm() and mapStateToProps() due to different config & will trigger runtime rendering error
*/
const currentForm = FORM.companionPassport;

// [ETP-4622] Fill-in Companion passport in Companion registration flow
const fillInCompanionPassportForm = reduxForm({
  form: currentForm,
  validate: validatePassport,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: false,
})(CompanionPassportContainer);

const fillInCompanionPassportFormMapStateToProps = (state: RootState) => {
  return {
    companionPassportFormValues: getFormValues(currentForm)(state),
  };
};

// [ETP-3868] Edit Companion passport in Nominee detail page
const editCompanionPassportForm = reduxForm({
  form: currentForm,
  validate: validatePassport,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CompanionPassportContainer);

const editCompanionPassportFormMapStateToProps = (state: RootState, props: any) => {
  const { passportData, isEditMode } = props;
  return {
    initialValues: (isEditMode && passportData) || null,
    companionPassportFormValues: getFormValues(currentForm)(state),
  };
};

export const ConnectedFillInCompanionPassportForm = connect(fillInCompanionPassportFormMapStateToProps)(
  fillInCompanionPassportForm,
);
export const ConnectedEditCompanionPassportForm = connect(editCompanionPassportFormMapStateToProps)(
  editCompanionPassportForm,
);
