import { FunctionComponent, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import { DialogComponent, FormButton } from '../../components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectPaypal, setIsOpenBillingAgreementInterruptDialog } from '../../slice/paypalSlice';
import { selectApp } from '../../slice/appSlice';
import { AlertIcon } from '../../assets/images';
import en from '../../translations/en';

type BillingAgreementDialogInterruptDialogType = {
  handleCloseClick: () => void;
  handleConfirmClick: () => void;
};

const BillingAgreementDialogInterruptDialog: FunctionComponent<BillingAgreementDialogInterruptDialogType> = ({
  handleCloseClick,
  handleConfirmClick,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { isOpenBillingAgreementInterruptDialog } = useAppSelector(selectPaypal) || {};

  const buttonDefaultProps = {
    theme,
    colour: 'outline',
    customStyles: {
      borderRadius: 8,
      width: isDesktop ? '195px' : '140px',
      height: '44px',
      paddingLeft: '18px',
      paddingRight: '18px',
    },
    sx: {
      '&:not(:first-of-type), &:first-of-type': {
        ml: 0,
      },
    },
  };

  useEffect(() => {
    setOpen(isOpenBillingAgreementInterruptDialog);
  }, [isOpenBillingAgreementInterruptDialog]);

  useEffect(() => {
    if (!open) {
      dispatch(setIsOpenBillingAgreementInterruptDialog(false));
    }
  }, [open]);

  return (
    <DialogComponent
      open={open}
      title={en.payment.paypal.notCompletedDialog.title}
      message={en.payment.paypal.notCompletedDialog.description}
      IconComponent={<AlertIcon />}
      noErrorAlert={true}
      buttonComponent={
        <Box sx={{ mt: 6, mb: 4 }}>
          <FormButton
            {...buttonDefaultProps}
            customStyles={{
              ...buttonDefaultProps.customStyles,
              '&:hover': {
                backgroundColor: 'white',
                boxShadow: 'none',
              },
              marginRight: isDesktop ? '24px' : '15px',
            }}
            onClick={handleCloseClick}
          >
            {en.payment.paypal.notCompletedDialog.leftButton}
          </FormButton>

          <FormButton {...buttonDefaultProps} colour="blue" onClick={handleConfirmClick}>
            {en.payment.paypal.notCompletedDialog.rightButton}
          </FormButton>
        </Box>
      }
    />
  );
};

export default BillingAgreementDialogInterruptDialog;
