import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface IPaypalSlice {
  currentUrl: string;
  tokenId: string;
  isBillingAgreementConsentChecked: boolean;
  isOpenBillingAgreementDialog: boolean;
  isOpenBillingAgreementInterruptDialog: boolean;
}

const initialState: IPaypalSlice = {
  currentUrl: '',
  tokenId: '',
  isBillingAgreementConsentChecked: false,
  isOpenBillingAgreementDialog: false,
  isOpenBillingAgreementInterruptDialog: false,
};

const paypalSlice = createSlice({
  name: ' paypal',
  initialState,
  reducers: {
    setCurrentUrl: (state, { payload }: PayloadAction<string>) => {
      state.currentUrl = payload;
    },
    setTokenId: (state, { payload }: PayloadAction<string>) => {
      state.tokenId = payload;
    },
    setIsBillingAgreementConsentChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.isBillingAgreementConsentChecked = payload;
    },
    setIsOpenBillingAgreementDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenBillingAgreementDialog = payload;
    },
    setIsOpenBillingAgreementInterruptDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenBillingAgreementInterruptDialog = payload;
    },
    reset: () => initialState,
  },
});

const selectPaypal = (state: RootState) => state.paypal;
const {
  setCurrentUrl,
  setTokenId,
  setIsBillingAgreementConsentChecked,
  setIsOpenBillingAgreementDialog,
  setIsOpenBillingAgreementInterruptDialog,
  reset,
} = paypalSlice.actions;

export {
  paypalSlice,
  selectPaypal,
  setCurrentUrl,
  setTokenId,
  setIsBillingAgreementConsentChecked,
  setIsOpenBillingAgreementDialog,
  setIsOpenBillingAgreementInterruptDialog,
  reset,
  initialState,
};
export type { IPaypalSlice };

export default paypalSlice.reducer;
