import { useContext, useEffect, useMemo } from 'react';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import { Configuration } from 'configuration';

import en from '../../../../translations/en';
import {
  NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE,
  NOMINATION_OAL_ELIGIBILITY_SPO_FRD_SORT,
} from '../../../../constants/constants';
import { CloudsBackgroundImage } from '../../../../assets/images';

import { INominationViewModeItem } from '../../../../interfaces';

import {
  getDisplayName,
  handleEligibleCarrier,
  handleNominatedEligibleCarrier,
  sortByDependents,
} from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectConfiguration } from '../../../../slice/configurationSlice';
import { NominationAction, NominationState } from '../../../../context';

import { INominationCartMasterProps } from './NominationCart/NominationCartMasterContainer';

import { NominationCartMasterContainer, OALEligibilityItem } from '../..';

const NominationInterlineEligibilityContainer = () => {
  const theme = useTheme();
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { nominationFlow: nominationFlowLbl } = en.userProfile.nomination;
  const { OALEligibility: oalEligibilityLbl, travelEligibility: travelEligibilityLbl } = nominationFlowLbl;

  const { nominationFlow, nominationChangeTypeData, nominationTransformData } = useContext(NominationState) || {};
  const { setNominationFlow } = useContext(NominationAction) || {};
  const { selectedOALEligibilityDependentId } = nominationFlow || {};
  const { sortedDependents } = nominationFlow.nominationSelectionData || [];

  const filterAssignedOAL = (list: INominationViewModeItem[]) => {
    return list?.filter((item: INominationViewModeItem) => !_.isUndefined(item.isAssignedOAL));
  };

  // check previous assigned oal dependents
  const plusOneAssignedList = filterAssignedOAL(nominationFlow.nominationViewModeData.plusOne.list);
  const travelNomineesAssignedList = filterAssignedOAL(nominationFlow.nominationViewModeData.travelNominees.list);

  const prevAssignedDependents = [...plusOneAssignedList, ...travelNomineesAssignedList];

  // change type dependents includes SPO (Spouse) or FRD (Companion)
  const changeTypedOALEligibilityDependents = useMemo(() => {
    return nominationChangeTypeData.filter(
      (item: INominationViewModeItem) =>
        item.isSelected && NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE.includes(item.beneficiaryTypeCode),
    );
  }, [nominationChangeTypeData]);

  // check if assigned oal previously
  const isOALDependentAssignedPreviously: boolean = prevAssignedDependents.length > 0;
  // check if includes change type dependents
  const isIncludedChangeTypeDependents: boolean = changeTypedOALEligibilityDependents.length > 0;

  const nominateDependentToBeChangedList = useMemo(() => {
    return sortByDependents(nominationTransformData, nominationChangeTypeData, sortedDependents);
  }, [nominationTransformData, nominationChangeTypeData]);

  const [oalEligibilityDependents, travelEligibilityDependents] = useMemo(() => {
    return _.partition(
      nominateDependentToBeChangedList.filter((item: INominationViewModeItem) => item.isSelected && !item.isLocked),
      (item) => NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE.includes(item.beneficiaryTypeCode),
    );
  }, [nominateDependentToBeChangedList]);

  const isShowOALEligibilitySection: boolean = oalEligibilityDependents.length > 0;
  const isShowTravelEligibilitySection: boolean = travelEligibilityDependents.length > 0;

  const containerCustomProps: SxProps<Theme> = {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.color.secondary.dark_grey.option_8,
    backgroundImage: `url(${CloudsBackgroundImage})`,
    backgroundPosition: '92% 6%',
    backgroundSize: 'auto 40px',
    backgroundRepeat: 'no-repeat',
  };

  const travelEligibilityDataObj: INominationCartMasterProps = {
    list: handleNominatedEligibleCarrier(configurations, travelEligibilityDependents),
    maxNumberOfPerson: undefined, // set "undefined" to hide top right "0/0" label
    maxNumberOfSelectableNominees: undefined,
    nominationType: travelEligibilityLbl.label,
    isViewMode: true,
    containerCustomProps,
  };

  const currentOALEligibilityDependent = oalEligibilityDependents[0];

  // eligibility list need to sort: spouse first, then companion
  oalEligibilityDependents.sort((a: INominationViewModeItem, b: INominationViewModeItem) => {
    return (
      NOMINATION_OAL_ELIGIBILITY_SPO_FRD_SORT.indexOf(a.beneficiaryTypeCode) -
      NOMINATION_OAL_ELIGIBILITY_SPO_FRD_SORT.indexOf(b.beneficiaryTypeCode)
    );
  });

  // check if selected dependent is current
  const isSelectCurrentDependent = (dependent: INominationViewModeItem) =>
    selectedOALEligibilityDependentId === dependent.dependentId;

  const handleDependentsItem = (dependent: INominationViewModeItem) => {
    // checkDependentAssignedStatus:
    // scenario 1. change type dependent: keep its isAssignedOAL status
    // scenario 2. nomination flow dependent: check previous assigned dependent's isAssignedOAL status
    let checkDependentAssignedStatus = false;
    if (isOALDependentAssignedPreviously) {
      checkDependentAssignedStatus = isIncludedChangeTypeDependents
        ? !!dependent?.isAssignedOAL
        : !prevAssignedDependents[0]?.isAssignedOAL;
    }

    const oalEligibilityOptions = configurations.airlineEligibility.find(
      (oalEligibility: Configuration.OALEligibilityLabel) =>
        dependent.beneficiaryTypeCode === oalEligibility.beneficiaryTypeCode &&
        (oalEligibility.withOALEligibility === checkDependentAssignedStatus ||
          oalEligibility.withOALEligibility === isSelectCurrentDependent(dependent)),
    );

    const eligibleCarrier = handleEligibleCarrier(oalEligibilityOptions);

    return {
      eligibleCarrier,
      toggleDisabled: isOALDependentAssignedPreviously,
    };
  };

  const handleDependentsPartition = (toBeChangedDependentId?: string) => {
    const [currentDependent, otherDependent] = _.partition(
      oalEligibilityDependents,
      (item: INominationViewModeItem) => item.dependentId === toBeChangedDependentId,
    );

    return {
      currentDependent,
      otherDependent,
    };
  };

  useEffect(() => {
    if (!isShowOALEligibilitySection && isShowTravelEligibilitySection) {
      // not select spouse or companion
      setNominationFlow({
        ...nominationFlow,
        oalEligibilityTickedDependents: [],
        isOALEligibilityEnableContinue: true,
      });

      return;
    }

    // assigned oal before
    if (isOALDependentAssignedPreviously) {
      if (oalEligibilityDependents.length === 1) {
        // tick 1 spouse or 1 companion
        // tick dependent comes from type change data or not nominated list

        // if assigned oal before, keep its assign status
        // if NOT assigned oal before, use previous assigned dependents "isAssignedOAL" reverse status
        const checkCurrentDependentIsAssigned = _.isBoolean(currentOALEligibilityDependent.isAssignedOAL)
          ? currentOALEligibilityDependent.isAssignedOAL
          : !prevAssignedDependents[0].isAssignedOAL;

        setNominationFlow({
          ...nominationFlow,
          oalEligibilityTickedDependents: [
            {
              dependentId: currentOALEligibilityDependent.dependentId,
              isAssignedOAL: checkCurrentDependentIsAssigned,
            },
          ],
          isOALEligibilityEnableContinue: true, // assigned oal previously, default enable "Continue" button
          selectedOALEligibilityDependentId: checkCurrentDependentIsAssigned
            ? currentOALEligibilityDependent.dependentId
            : undefined,
        });
      } else {
        // have spo + companion, total 2 dependents (include type change data)
        const [assignedOALDependent, toBeAssignedOALDependent] = _.partition(oalEligibilityDependents, (item) =>
          _.isBoolean(item.isAssignedOAL),
        );
        const singleAssignedOALDependent = assignedOALDependent[0];
        const singleToBeAssignedOALDependent = toBeAssignedOALDependent[0];

        setNominationFlow({
          ...nominationFlow,
          oalEligibilityTickedDependents: [
            {
              // isAssignedOAL -> assigned before, get from its assignedOALDependent.isAssignedOAL
              dependentId: singleAssignedOALDependent.dependentId,
              isAssignedOAL: singleAssignedOALDependent.isAssignedOAL,
            },
            {
              // isAssignedOAL -> to be assigned , get from assignedOALDependent.isAssignedOAL reverse status
              dependentId: singleToBeAssignedOALDependent.dependentId,
              isAssignedOAL: !singleAssignedOALDependent.isAssignedOAL,
            },
          ],
          isOALEligibilityEnableContinue: true, // assigned oal previously, default enable "Continue" button
          selectedOALEligibilityDependentId:
            // if isAssignedOAL:true -> set its dependentId, otherwise set other's dependentId
            singleAssignedOALDependent.isAssignedOAL
              ? singleAssignedOALDependent.dependentId
              : singleToBeAssignedOALDependent.dependentId,
        });
      }
    } else {
      // not assign oal before
      if (oalEligibilityDependents.length === 1) {
        // if only have one spo or companion
        setNominationFlow({
          ...nominationFlow,
          oalEligibilityTickedDependents: [
            {
              dependentId: currentOALEligibilityDependent.dependentId,
              isAssignedOAL: !!selectedOALEligibilityDependentId,
            },
          ],
          isOALEligibilityEnableContinue: true,
          selectedOALEligibilityDependentId:
            selectedOALEligibilityDependentId === ''
              ? currentOALEligibilityDependent.dependentId
              : selectedOALEligibilityDependentId,
        });
      } else {
        // have spo + companion, total 2 dependents

        // currentDependent: from switch tick "yes", maybe [] or 1 item:
        // otherDependent: from default + switch tick "no" , maybe 1 item or 2 items:
        const { currentDependent, otherDependent } = handleDependentsPartition(selectedOALEligibilityDependentId);

        setNominationFlow({
          ...nominationFlow,
          ...(currentDependent.length !== 0 && {
            oalEligibilityTickedDependents: [
              {
                // switch "yes" dependent's isAssignedOAL set true
                dependentId: currentDependent[0].dependentId,
                isAssignedOAL: true,
              },
              {
                // switch "no" dependent's isAssignedOAL set false
                dependentId: otherDependent[0].dependentId,
                isAssignedOAL: false,
              },
            ],
          }),

          isOALEligibilityEnableContinue: !!selectedOALEligibilityDependentId,
          selectedOALEligibilityDependentId:
            // not set "", to show Switch Section
            selectedOALEligibilityDependentId === '' ? undefined : selectedOALEligibilityDependentId,
        });
      }
    }
  }, [selectedOALEligibilityDependentId, oalEligibilityDependents]);

  const handleSwitchAction = (dependent: INominationViewModeItem) => {
    const { currentDependent, otherDependent } = handleDependentsPartition(dependent.dependentId);
    const switchYesDependentId = isSelectCurrentDependent(dependent)
      ? otherDependent[0]?.dependentId
      : currentDependent[0]?.dependentId;

    setNominationFlow({
      ...nominationFlow,
      selectedOALEligibilityDependentId: switchYesDependentId,
      isOALEligibilityEnableContinue: true,
    });
  };

  return (
    <>
      <Box
        className="nomination_interline_eligibility_container"
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
          }),
        }}
      >
        <Typography
          variant="headline_medium"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 2,
          }}
        >
          {nominationFlowLbl.interlineTitle}
        </Typography>

        {isShowOALEligibilitySection && (
          <Box>
            <Typography
              variant="body_2_regular"
              lineHeight="24px"
              color={theme.color.secondary.dark_grey.option_3}
              sx={{ mb: 2 }}
            >
              {oalEligibilityLbl.desc}
            </Typography>
            <Box
              className="oal_eligibility_cart_container"
              sx={{
                borderRadius: '8px',
                p: 2,
                pb: 1,
                mb: 2,
                ...containerCustomProps,
              }}
            >
              <Typography
                className="oal_eligibility_cart_title"
                variant="headline_medium"
                sx={{
                  color: theme.color.secondary.dark_grey.option_3,
                  mb: 4,
                }}
              >
                {oalEligibilityLbl.title}
              </Typography>

              {oalEligibilityDependents.map((dependent: INominationViewModeItem) => {
                const { eligibleCarrier, toggleDisabled } = handleDependentsItem(dependent);

                return (
                  <Box
                    key={dependent.dependentId}
                    className="oal_eligibility_dependent"
                    sx={{ mt: 1.5, position: 'relative' }}
                  >
                    {selectedOALEligibilityDependentId !== '' && (
                      <OALEligibilityItem
                        isSelected={isSelectCurrentDependent(dependent)}
                        data={{
                          dependentName: getDisplayName({
                            firstName: dependent.firstName,
                            lastName: dependent.lastName,
                          }),
                          relationship: dependent.beneficiaryType,
                          eligibleCarrier,
                          toggleDisabled,
                        }}
                        handleOnChangeAction={() => handleSwitchAction(dependent)}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {isShowTravelEligibilitySection && (
          <Box>
            <Typography
              variant="body_2_regular"
              lineHeight="24px"
              color={theme.color.secondary.dark_grey.option_3}
              sx={{ mb: 2 }}
            >
              {travelEligibilityLbl.desc}
            </Typography>

            <NominationCartMasterContainer {...travelEligibilityDataObj} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default NominationInterlineEligibilityContainer;
