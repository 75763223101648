import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { DATE_FORMAT, SAVE_DATA_TO, USER_ROLE } from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';

import { formatDateAsString, formatStringToDate, getDepartureMaxDate, getLocalDateAdding } from '../../../../helpers';

import {
  EditSearchBarState,
  EditSearchBarAction,
  BookingSummaryAction,
  BookingSummaryState,
} from '../../../../context';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setStartDate, selectBooking } from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';
import { selectApp } from '../../../../slice/appSlice';

import { Footer, DateSinglePicker } from '../../../../components';

const DateSelection = ({
  handleFooterClick,
  saveTo = SAVE_DATA_TO.redux,
  isFromRebookFlow,
  isEditPopup,
}: {
  handleFooterClick?: () => void;
  saveTo?: string;
  isFromRebookFlow?: boolean;
  isEditPopup?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation<{
    isFooterUpdate?: boolean;
  }>();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const isEditSearch = saveTo === SAVE_DATA_TO.context || false;

  const editSearchData = useContext(EditSearchBarState) || {};
  const { setEditStartDate } = useContext(EditSearchBarAction) || {};
  const { originalFlightDate, rebookFlightDate } = useContext(BookingSummaryState) || {};
  const { setOriginalFlightDate, setRebookFlightDate } = useContext(BookingSummaryAction) || {};

  const { startDate, travelDetails } = isEditSearch ? editSearchData : useAppSelector(selectBooking) || {};

  const [currentStartDate, setCurrentStartDate] = useState<string>(
    isFromRebookFlow && !isEditPopup ? originalFlightDate : isFromRebookFlow ? rebookFlightDate : startDate,
  );

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType } = role || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  const isFooterUpdate = location.state?.isFooterUpdate;

  const screenHeight = use100vh();

  const saveStartDate = () => {
    if (isDesktop && !isFromRebookFlow) {
      dispatch(setStartDate(currentStartDate));
    } else {
      if (isEditSearch) {
        setEditStartDate(currentStartDate);
        if (isFromRebookFlow) {
          if (isEditPopup) {
            setRebookFlightDate(currentStartDate);
          } else {
            setOriginalFlightDate(currentStartDate);
          }
        }
      } else {
        dispatch(setStartDate(currentStartDate));
      }
    }
    handleFooterClick?.();
  };

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          bgcolor: '#FDFDFD',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
        }}
      >
        <DateSinglePicker
          isDesktop={false}
          defaultDate={new Date(formatStringToDate(currentStartDate, DATE_FORMAT.date))}
          onChange={(item: Date) => {
            setCurrentStartDate(formatDateAsString(item, DATE_FORMAT.date));
          }}
          minDate={getLocalDateAdding(-1)}
          maxDate={getDepartureMaxDate(getLocalDateAdding(travelDetails.advanceDay)).maxDate}
          calendarHeight={screenHeight ? screenHeight - (isAdminDelegation ? 301 : 213) : 0}
        />
      </Box>

      <Footer
        primaryBtn={{
          isFullWidth: true,
          text: isFooterUpdate ? en.common.update : en.common.continue,
          route: isFooterUpdate ? BOOKING_PATHS.booking : BOOKING_PATHS.travelTypeSelection,
          customOnClick: saveStartDate,
        }}
      />
    </Box>
  );
};

DateSelection.defaultProps = {
  isFromRebookFlow: false,
};

export default DateSelection;
