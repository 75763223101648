import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton, useTheme, Box, InputAdornment, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { shallowEqual } from 'react-redux';
import { isUndefined } from 'lodash';
import dayjs from 'dayjs';
import { use100vh } from 'react-div-100vh';

import {
  UI_STYLES,
  DATE_FORMAT,
  USER_ROLE,
  TRAVEL_PURPOSE,
  ID_TICKET_TYPE,
  LT_SEARCH_FLIGHT_OPTION,
  MULTIPLE_WARNING_TYPE,
} from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';
import { NavBarSearchIcon, LeftArrowIcon, RightArrowIcon } from '../../../../assets/images';

import { FlightSearch } from 'flightSearch';
import { ISelectedFlight } from '../../../../interfaces';

import {
  formatDateAsString,
  compareDate,
  getAdminBookingEmployeeErn,
  getFlightKey,
  getDepartureMaxDate,
  getInputDateAdding,
} from '../../../../helpers';

import { BookingSummaryAction, EditSearchBarAction, EditSearchBarState } from '../../../../context';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectAuth } from '../../../../slice/authSlice';
import {
  selectBooking,
  setStartDate,
  setOutwardFlight,
  setIsLegendsDisplay,
  setLeisureTravelDetails,
  setFlightLoadCache,
  setIsShowDTSecurityReminder,
} from '../../../../slice/bookingSlice';
import { selectApp } from '../../../../slice/appSlice';
import { selectUser } from '../../../../slice/userSlice';

import { searchFlight as searchFlightAdmin } from '../../../../services/admin/booking';
import { searchFlight as searchFlightDelegation } from '../../../../services/delegation/booking';
import { searchFlight as searchFlightSelf, searchFlightLT } from '../../../../services/booking';
import { getFlightLoad } from '../../../../services/flightload';

import { ScrollableView, ShadowContent, StyledOutlinedInput } from '../../../../components';
import {
  NoSearchResult,
  FlightCard,
  FlightODLabel,
  FlightCardFooter,
  GenericHeader,
  FlightSearchFooter,
  LtFlightCard,
  LtCXHeader,
} from '../../..';
import MultipleWarning from '../../Common/MultipleWarning';

// interface ILtCarrierFlightIsEmpty {
//   [key: string]: boolean;
// }

const FlightSearchResultContainer = ({
  isFromRebookFlow,
  handleFooterClick,
}: {
  isFromRebookFlow?: boolean;
  handleFooterClick?: () => void;
}) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    setEditOrigin,
    setEditDestination,
    setEditStartDate,
    setEditTravelDetails,
    setEditTravellerDetails,
    setEditTravelType,
    setEditLeisureTravelDetails,
  } = useContext(EditSearchBarAction) || {};

  // redux data
  const {
    origin,
    destination,
    startDate,
    isRefresh,
    isShowDTSecurityReminder,
    travelDetails: concession,
    travellerDetails,
    outwardFlight,
    travelType,
    leisureTravelDetails,
    flightLoadCache,
    bookingStep,
  } = useAppSelector(selectBooking, shallowEqual) || {};

  const editSearchData = useContext(EditSearchBarState) || {};
  const { travelType: contextTravelType } = editSearchData || {};
  const { setRebookFlightDate } = useContext(BookingSummaryAction) || {};

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  const [totalLTFlight, setTotalLTFlight] = useState(0);

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType, id: roleId } = role || {};

  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;
  const isHiddenFlightLoadFlag = window.config?.isHiddenFlightLoad;

  const {
    cx: cxLabel,
    oneWorld: oneWorldLabel,
    otherCarrier: otherCarrierLabel,
  } = en.booking.flightSearchResultCarrierOptions;
  const securityReminderJson = en.multipleWarning.DT.securityReminderJson;

  const [cXEmpty, setCXEmpty] = useState(false);
  const [oneworldEmpty, setOneworldEmpty] = useState(false);
  const [otherCarrierEmpty, setOtherCarrierEmpty] = useState(false);

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  const { airportCode: airportCodeOrigin = '' } = origin || {};
  const { airportCode: airportCodeDestination = '' } = destination || {};

  const [outwardFlights, setOutwardFlights] = useState<FlightSearch.ParsedFlightSearchResult[] | null>(null);
  const [oneWorldOutwardFlights, setOneWorldOutwardFlights] = useState<FlightSearch.ParsedFlightSearchResult[] | null>(
    null,
  );
  const [otherCarrierOutwardFlights, setOtherCarrierOutwardFlights] = useState<
    FlightSearch.ParsedFlightSearchResult[] | null
  >(null);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);
  const [initData, setInitData] = useState(false);
  const searchFlightAction = async () => {
    // (1) clear flight data
    setOutwardFlights(null);
    setOneWorldOutwardFlights(null);
    setOtherCarrierOutwardFlights(null);
    // setReturnFlights(null);

    // (2) search flight
    let data: any;

    const searchingCarrierType =
      LT_SEARCH_FLIGHT_OPTION?.[leisureTravelDetails?.carrierGroup as keyof typeof LT_SEARCH_FLIGHT_OPTION] || '';

    if (isLT) {
      const travellerCount =
        leisureTravelDetails.adultCount + leisureTravelDetails.childrenCount + leisureTravelDetails.infantsCount;

      data = await searchFlightLT({
        departureDate: startDate,
        departurePort: airportCodeOrigin,
        arrivalDate: startDate,
        arrivalPort: airportCodeDestination,
        userId: ern,
        // means - carrier / marketCompany
        type: searchingCarrierType,
        numberOfTravellers: leisureTravelDetails?.concessionRuleId ? travellerCount : 1,
        // for FOC/ID50 used
        ...(leisureTravelDetails?.concessionRuleId && {
          concessionRuleId: leisureTravelDetails?.concessionRuleId,
        }),
      });
    } else {
      if (roleType === USER_ROLE.admin) {
        data = await searchFlightAdmin(
          startDate,
          airportCodeOrigin,
          startDate,
          airportCodeDestination,
          concession.id,
          getAdminBookingEmployeeErn(travellerDetails),
        );
      } else if (roleType === USER_ROLE.delegation) {
        data = await searchFlightDelegation(
          startDate,
          airportCodeOrigin,
          startDate,
          airportCodeDestination,
          concession.id,
          roleId,
        );
      } else {
        data = await searchFlightSelf(
          startDate,
          airportCodeOrigin,
          startDate,
          airportCodeDestination,
          concession.id,
          ern,
        );
      }
    }

    if (data) {
      // (3) set search flight result
      let filterOutwardFlights;
      let filterOneWorldOutwardFlight;
      let filterOtherCarrierOutwardFlights;
      if (isLT) {
        filterOutwardFlights = data?.cx?.outwardFlights ?? [];
        setOneWorldOutwardFlights(data?.oneworld?.outwardFlights ?? []);
        setOtherCarrierOutwardFlights(data?.otherCarrier?.outwardFlights ?? []);
        filterOneWorldOutwardFlight = data?.oneworld?.outwardFlights ?? [];
        filterOtherCarrierOutwardFlights = data?.otherCarrier?.outwardFlights ?? [];
        setTotalLTFlight(
          (filterOutwardFlights.length || 0) +
            (data?.oneworld?.outwardFlights?.length || 0) +
            (data?.otherCarrier?.outwardFlights?.length || 0),
        );
        dispatch(setIsLegendsDisplay(!!(data?.cx?.outwardFlights?.length || 0)));
      } else {
        filterOutwardFlights =
          (data?.outwardFlights || []).filter((flight: FlightSearch.ParsedFlightSearchResult) => {
            // [ETP-354] not showing the flight - all classes are undefined from backend
            const { availability } = flight || {};
            const { econ, business, first } = availability || {};

            // return flights are not all undefined
            return !(isUndefined(first) && isUndefined(econ) && isUndefined(business));
          }) || [];
        dispatch(setIsLegendsDisplay(false));
      }
      sessionStorage.setItem('searchResultCache', JSON.stringify(filterOutwardFlights));
      sessionStorage.setItem('oneWorldSearchResultCache', JSON.stringify(filterOneWorldOutwardFlight));
      sessionStorage.setItem('otherCarrierSearchResultCache', JSON.stringify(filterOtherCarrierOutwardFlights));

      setOutwardFlights(filterOutwardFlights);
    } else {
      setOutwardFlights(null);
      sessionStorage.removeItem('searchResultCache');
      sessionStorage.removeItem('oneWorldSearchResultCache');
      sessionStorage.removeItem('otherCarrierSearchResultCache');
      setTotalLTFlight(0);
    }
  };

  const getFlightLoadAction = async (flight: FlightSearch.ParsedFlightSearchResult) => {
    const key = getFlightKey(flight);
    const data = await getFlightLoad(flight);

    if (data?.flightLoad) {
      dispatch(setFlightLoadCache({ key, value: data?.flightLoad }));
    }
  };

  const searchResultCache = sessionStorage.getItem('searchResultCache');
  const oneWorldSearchResultCache = sessionStorage.getItem('oneWorldSearchResultCache');
  const otherCarrierSearchResultCache = sessionStorage.getItem('otherCarrierSearchResultCache');
  useEffect(() => {
    if (bookingStep === null) {
      dispatch(setOutwardFlight(null));
    }
  }, [dispatch]);

  useEffect(() => {
    const clearCache = () => {
      sessionStorage.removeItem('searchResultCache');
    };

    if (!searchResultCache) {
      window.addEventListener('beforeunload', clearCache);
    }

    return () => {
      if (history.location.pathname !== BOOKING_PATHS.booking) {
        sessionStorage.removeItem('searchResultCache');
        otherCarrierSearchResultCache;
        window.removeEventListener('beforeunload', clearCache);
      }
    };
  }, []);

  const firstSearch = useRef(true);

  useEffect(() => {
    // 'debounceTimer' be use to fix double call 'searchFlight' api on first loadData of refresh
    let debounceTimer: NodeJS.Timeout | null = null;

    if (firstSearch.current) {
      firstSearch.current = false;
      if (searchResultCache) {
        const parseSearchResult = JSON.parse(searchResultCache);
        setOutwardFlights(parseSearchResult);
        if (isLT) {
          const parseOneWorldOutwardFlights = JSON.parse(oneWorldSearchResultCache || '');
          const parseOtherCarrierOutwardFlights = JSON.parse(otherCarrierSearchResultCache || '');

          setOneWorldOutwardFlights(parseOneWorldOutwardFlights);
          setOtherCarrierOutwardFlights(parseOtherCarrierOutwardFlights);
          setTotalLTFlight(
            (parseSearchResult?.length || 0) +
              (parseOneWorldOutwardFlights?.length || 0) +
              (parseOtherCarrierOutwardFlights?.length || 0),
          );
        }
      } else {
        debounceTimer = setTimeout(() => {
          origin && destination && concession && searchFlightAction();
        }, 0);
      }
    } else {
      origin && destination && concession && searchFlightAction();
    }
    return () => {
      debounceTimer && clearTimeout(debounceTimer);
      debounceTimer = null;
    };
  }, [startDate, origin, destination, concession, travellerDetails, isRefresh]);

  useEffect(() => {
    // (1) call the get flight load api
    // (2) block the flight load when calling FOC/ID50's flight
    if (isLT && !isHiddenFlightLoadFlag && leisureTravelDetails?.sub === ID_TICKET_TYPE.SUBLO) {
      outwardFlights?.forEach((flight) => {
        // Normal flow
        if (flight.embargo?.length === 0 && !flightLoadCache?.[getFlightKey(flight)]) {
          getFlightLoadAction(flight);
        }
      });
    }
  }, [outwardFlights, oneWorldOutwardFlights, otherCarrierOutwardFlights]);

  const firstInit = useRef(true);
  useEffect(() => {
    setLeftButtonDisabled(compareDate(startDate, new Date(), DATE_FORMAT.date) <= -1);

    // need getInputDateAdding + 1: coz if departureDate=blockedDate, also disable right button
    const { isPassedBlockedDate } = getDepartureMaxDate(getInputDateAdding(startDate, +1));
    setRightButtonDisabled(
      isPassedBlockedDate || compareDate(startDate, new Date(), DATE_FORMAT.date) + 1 >= concession.advanceDay,
    );
    if (firstInit.current) {
      firstInit.current = false;
    } else {
      setEditOrigin?.(origin);
      setEditDestination?.(destination);
      setEditStartDate?.(startDate);
      setEditTravelDetails?.(concession);
      setEditTravellerDetails?.(travellerDetails);
      setEditTravelType(travelType);
      setEditLeisureTravelDetails?.(leisureTravelDetails);
    }
  }, [startDate]);

  useEffect(() => {
    if (contextTravelType !== travelType) {
      // to handle: if route back result page, will reset context data to data from redux
      setEditOrigin?.(origin);
      setEditDestination?.(destination);
      setEditStartDate?.(startDate);
      setEditTravelDetails?.(concession);
      setEditTravellerDetails?.(travellerDetails);
      setEditTravelType(travelType);
      setEditLeisureTravelDetails?.(leisureTravelDetails);
    }

    setTimeout(() => {
      setInitData(true);
    }, 1000);
  }, []);

  const changeDate = (dayChanges: number) => {
    const changedDate = dayjs(startDate, DATE_FORMAT.date).add(dayChanges, 'day').format(DATE_FORMAT.date);
    dispatch(setOutwardFlight(null));
    dispatch(setStartDate(changedDate));
    isFromRebookFlow && setRebookFlightDate(changedDate);
  };

  const SelectDate = () => {
    const getArrowColor = (isDisabled: boolean) => {
      return isDisabled ? theme.color.secondary.light_slate.option_3 : theme.status.selected;
    };

    return (
      <Box
        sx={{
          display: 'flex',
          width: isDesktop ? '200px' : 'auto',
          // borderBottom: `2px ${theme.color.utility.link.option_3} solid`,
          height: '32px',
        }}
      >
        <IconButton onClick={() => changeDate(-1)} disabled={leftButtonDisabled}>
          <LeftArrowIcon fill={getArrowColor(leftButtonDisabled)} />
        </IconButton>
        <Typography
          variant={isDesktop ? 'body_1_bold' : 'body_1_medium'}
          sx={{
            textAlign: 'center',
            color: theme.color.utility.link.option_3,
            m: 'auto',
          }}
        >
          {formatDateAsString(startDate, DATE_FORMAT.dddDDMMM, DATE_FORMAT.date)}
        </Typography>
        <IconButton
          onClick={() => changeDate(1)}
          disabled={rightButtonDisabled}
          sx={{ color: theme.color.utility.link.option_3 }}
        >
          <RightArrowIcon fill={getArrowColor(rightButtonDisabled)} />
        </IconButton>
      </Box>
    );
  };

  const FlightList = ({ flights, flag }: { flights: FlightSearch.ParsedFlightSearchResult[] | null; flag: string }) => {
    if (!flights) {
      return null;
    }

    if (isDT && flights.length === 0) {
      return <NoSearchResult desc={en.booking.searchFlight.noSearchResult} customStyles={{ px: 1 }} />;
    }

    const filteredFlights = filterKeyword
      ? flights.filter((flight) => flight.callSign.toUpperCase()?.includes(filterKeyword.toUpperCase()))
      : flights;

    const isCX = flag === cxLabel;
    const isOneworld = flag === oneWorldLabel;
    const isOtherCarrier = flag === otherCarrierLabel;

    if (isLT) {
      if (filteredFlights.length === 0) {
        // Set true when the carrier is not matched to the filter keyword
        if (isCX) {
          setCXEmpty(true);
        } else if (isOneworld) {
          setOneworldEmpty(true);
        } else if (isOtherCarrier) {
          setOtherCarrierEmpty(true);
        }
        return <></>;
      } else {
        // Reset empty checker
        if (isCX) {
          setCXEmpty(false);
        } else if (isOneworld) {
          setOneworldEmpty(false);
        } else if (isOtherCarrier) {
          setOtherCarrierEmpty(false);
        }
      }
    } else if (isDT && filteredFlights.length === 0) {
      return (
        <Box sx={{ mx: 2 }}>
          <NoSearchResult
            isShowIcon={false}
            desc={`${en.booking.searchFlight.noFilterResult} (${0} ${en.booking.searchFlight.outOf} ${flights.length})`}
          />
        </Box>
      );
    }

    return (
      <>
        {/* isLT && Header section: CX only | Other carrier | Any carrier */}
        {isLT && (
          <>
            {isCX && !isHiddenFlightLoadFlag && <LtCXHeader />}
            {isOneworld && <GenericHeader carrierLabel={oneWorldLabel} />}
            {isOtherCarrier && <GenericHeader carrierLabel={otherCarrierLabel} />}
          </>
        )}
        <Box sx={{ overflowX: 'hidden', py: isLT ? 0 : 0.5, px: 0.5 }}>
          {filteredFlights.map((flight) => {
            return (
              <>
                {isDT && (
                  <FlightCard
                    flight={flight}
                    key={flight.callSign}
                    isSelected={flight.callSign === (outwardFlight && outwardFlight.callSign)}
                    selectFlight={(flight: ISelectedFlight | null) => {
                      dispatch(setOutwardFlight(flight));
                    }}
                  />
                )}
                {isLT && (
                  <LtFlightCard
                    isShowCabinHeader={false}
                    key={flight.callSign}
                    flight={flight}
                    flag={flag}
                    initData={initData}
                    isSelected={flight.callSign === (outwardFlight && outwardFlight.callSign)}
                    selectFlight={(flight: ISelectedFlight | null) => {
                      dispatch(setOutwardFlight(flight));
                    }}
                    isFromRebookFlow={isFromRebookFlow}
                  />
                )}
              </>
            );
          })}
        </Box>
      </>
    );
  };

  const screenHeight = use100vh();

  const isSelectedFlight = outwardFlight && outwardFlight.callSign;

  const isCXDisplay = outwardFlights && outwardFlights.length !== 0;

  const isShowOtherCarries = otherCarrierOutwardFlights && otherCarrierOutwardFlights.length !== 0;

  const isShowOneWorld = oneWorldOutwardFlights && oneWorldOutwardFlights.length !== 0;

  const showFilterNoMatchMsg = cXEmpty && oneworldEmpty && otherCarrierEmpty;

  // reset LT booking flow in leisureTravelDetails inside redux
  useEffect(() => {
    if (isLT && bookingStep === null) {
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          seatConcession: '',
          seatOption: '',
          ltSeatConcessionTravellerList: [],
          ltSelectTravellerList: [],
          ltConcessions: [],
          isAccompanyFormChecked: false,
        }),
      );
    }
  }, [bookingStep]);

  return (
    <>
      <Box
        className="mobile_search_result"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {/* <SelectToggleButton
          value={isSelectedOutwardTab}
          airportCodeOrigin={airportCodeOrigin}
          airportCodeDestination={airportCodeDestination}
          onChange={(_, value) => {
            if (value !== null) {
              setIsSelectedOutwardTab(value);
            }
          }}
        /> */}

        <ShadowContent
          sx={{
            py: 1.5,
            mt: { xs: isFromRebookFlow ? 1 : -4, sm: 0 },
            mx: { xs: 2, sm: 0 },
            width: { xs: 'calc(100vw - 32px)', sm: 'calc(100% - 8px)' },
            height: isDesktop ? '50px' : '56px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!isDesktop && (
            <FlightODLabel
              justifyContent={'start'}
              departurePort={airportCodeOrigin}
              arrivalPort={airportCodeDestination}
              variant="body_1_medium"
            />
          )}
          <Box m={'auto'}>
            <SelectDate />
          </Box>
        </ShadowContent>

        <Grid
          component={isDesktop ? Box : ScrollableView}
          sx={{
            height: isDesktop
              ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.searchBarResultPageHeight} - ${
                  isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                } - 98px)` // result header height
              : `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${
                  isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                } - ${isSelectedFlight ? UI_STYLES.footerHeight : '0px'} - ${isFromRebookFlow ? '21px' : '34px'} )`,
            mt: 2,
          }}
        >
          <Box>
            {/* Add the DT warning component here. */}
            {isDT && !isDesktop && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mx: isDesktop ? 0 : 2,
                  mt: isDesktop ? 0 : 1,
                  mb: 2,
                }}
              >
                <MultipleWarning
                  onChange={() => {
                    dispatch(setIsShowDTSecurityReminder(!isShowDTSecurityReminder));
                  }}
                  type={MULTIPLE_WARNING_TYPE.DTSecurityReminder}
                  isExpand={isShowDTSecurityReminder}
                  data={[
                    {
                      headingCode: 'message1',
                    },
                  ]}
                  messageFormatter={securityReminderJson}
                />
              </Box>
            )}
            {/* Filter bar */}
            {(isCXDisplay || isShowOneWorld || isShowOtherCarries) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mx: isDesktop ? 0 : 2,
                  mb: 2,
                }}
              >
                <StyledOutlinedInput
                  notched={false}
                  placeholder={en.booking.searchFlight.filterPlaceholder}
                  value={filterKeyword}
                  onChange={(event: any) => {
                    setFilterKeyword(event.target.value);
                  }}
                  sx={{
                    borderRadius: theme.spacing(3),
                    height: '44px',
                    width: '100%',
                    pl: 2.5,
                    background: theme.color.secondary.light_slate.option_4,
                    '& .MuiInputBase-input': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  }}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <NavBarSearchIcon fill={theme.color.utility.link.option_3} />
                    </InputAdornment>
                  }
                  endAdornment={
                    filterKeyword && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setFilterKeyword('')}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          <CloseIcon
                            sx={{
                              p: '1px',
                              width: '15.59px',
                              height: '15.58px',
                              bgcolor: theme.color.secondary.grey.option_3,
                              borderRadius: '50%',
                              color: 'white',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </Box>
            )}

            {/* CX DT flights list */}
            {isDT && <FlightList flights={outwardFlights} flag={cxLabel} />}

            {/* LT flight list */}
            {isLT && (
              <>
                <FlightList flights={outwardFlights} flag={cxLabel} />

                <FlightList flights={oneWorldOutwardFlights} flag={oneWorldLabel} />

                <FlightList flights={otherCarrierOutwardFlights} flag={otherCarrierLabel} />

                {!isLoading &&
                  (totalLTFlight === 0 ? (
                    <NoSearchResult desc={en.booking.searchFlight.noSearchResult} customStyles={{ px: 1 }} />
                  ) : (
                    /**
                     * showFilterNoMatchMsg
                     * Explain: checking all carrier in LT is empty
                     */
                    showFilterNoMatchMsg && (
                      <Box sx={{ mx: 2 }}>
                        <NoSearchResult
                          isShowIcon={false}
                          desc={`${en.booking.searchFlight.noFilterResult} (${0} ${
                            en.booking.searchFlight.outOf
                          } ${totalLTFlight})`}
                        />
                      </Box>
                    )
                  ))}
              </>
            )}

            <Box sx={{ mx: 2, mt: 1, mb: 3, bgcolor: theme.palette.primary.light }}>
              {!isDesktop && isLT && !cXEmpty && outwardFlights && outwardFlights.length !== 0 && (
                <FlightSearchFooter
                  desc={en.booking.legends.desc}
                  title={en.booking.legends.title}
                  dateList={en.booking.legends.remarks}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Box>

      {!isDesktop && outwardFlight && (
        <FlightCardFooter
          origin={airportCodeOrigin}
          destination={airportCodeDestination}
          outwardCallSign={outwardFlight ? outwardFlight.callSign : ''}
          handleFooterClick={handleFooterClick}
          isFromRebookFlow={isFromRebookFlow}
        />
      )}
    </>
  );
};

FlightSearchResultContainer.defaultProps = {
  isFromRebookFlow: false,
  handleFooterClick: undefined,
};

export default FlightSearchResultContainer;
