import { Typography, SxProps, Theme, useTheme } from '@mui/material';

const NominationPageTitle = ({ sxProps, title }: { sxProps?: SxProps<Theme>; title: string }) => {
  const theme = useTheme();

  return (
    <Typography
      className="nomination_page_title"
      variant="headline_medium"
      sx={{
        color: theme.color.secondary.dark_grey.option_1,
        pb: 1.5,
        ...sxProps,
      }}
    >
      {title}
    </Typography>
  );
};

export default NominationPageTitle;
