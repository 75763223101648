/* eslint-disable @typescript-eslint/no-unused-vars */
// import { IPublicClientApplication } from "@azure/msal-browser";
import { reset as resetAppSlice, setIsQuitCppFlow } from '../slice/appSlice';
import { IAuthSlice, reset as resetAuthSlice } from '../slice/authSlice';
import { reset as resetBookingSlice } from '../slice/bookingSlice';
import { reset as resetBookingFilterSlice } from '../slice/bookingFilterSlice';
import {
  IConfigurationSlice,
  getAirportsThunk,
  getConfigurationsThunk,
  reset as resetConfigurationSlice,
} from '../slice/configurationSlice';
import { reset as resetBookingUserSlice } from '../slice/bookingUserSlice';
import { getProfileThunk, resetPaymentHistoryPage, reset as resetUserSlice } from '../slice/userSlice';
import { logoutRedirect } from '../config/authConfig';
import { ETP_CLIENT_ID_KEY } from '../constants/constants';
import { isEmpty } from 'lodash';
import { retrieveUserModeStatus } from './user';
import { IBookingSearchData } from '../interfaces';

export function clearSliceData(dispatch: any) {
  dispatch(resetAppSlice());
  dispatch(resetAuthSlice());
  dispatch(resetBookingSlice());
  dispatch(resetConfigurationSlice());
  dispatch(resetBookingUserSlice());
  dispatch(resetUserSlice());
}

// export function logout(dispatch: any, instance: IPublicClientApplication) {
export function logout(dispatch: any) {
  clearSliceData(dispatch);
  sessionStorage.removeItem(ETP_CLIENT_ID_KEY);
  // instance.logoutRedirect({ postLogoutRedirectUri: "/" }); // use "/logout" url if need logout page
  logoutRedirect();
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K) {
  return list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>,
  );
}

export function homePageClearSliceData({
  dispatch,
  bookingState,
  authState,
  configurationState,
  isKeepSearchCriteria,
  setIsShowDefaultTravelTypeOption,
}: {
  dispatch: any;
  bookingState: IBookingSearchData;
  authState: IAuthSlice;
  configurationState: IConfigurationSlice;
  isKeepSearchCriteria: boolean;
  setIsShowDefaultTravelTypeOption: any;
}) {
  const isConfigurationRefreshOnReload = window.config?.isConfigurationRefreshOnReload;

  const { ern } = authState;
  const { viewBookingApplicationId, isCppBookingCreated } = bookingState;
  const { airports, configurations } = configurationState;

  // (1) Clear slice data
  if ((!isKeepSearchCriteria && !viewBookingApplicationId) || isCppBookingCreated) {
    // Clear booking Slice data, including the CPP
    dispatch(resetBookingSlice());
  }
  dispatch(resetBookingUserSlice());
  dispatch(resetPaymentHistoryPage());
  dispatch(resetBookingFilterSlice());

  // (2) calling APIs
  if (isConfigurationRefreshOnReload) {
    // (2.1) get the airport and configuration on every entry to home screen
    dispatch(getAirportsThunk());
    dispatch(getConfigurationsThunk());
  } else {
    if (isEmpty(airports) || isEmpty(configurations)) {
      // (2.2) get the airport and configuration only when airport and configuration is empty
      dispatch(getAirportsThunk());
      dispatch(getConfigurationsThunk());
    }
  }
  dispatch(getProfileThunk(ern));

  // (3) Retrieve user mode
  retrieveUserModeStatus(ern, dispatch);

  // (4) clear the selected concession selection option
  setIsShowDefaultTravelTypeOption?.(false);

  // (5) reset user refresh flag
  // dispatch(setIsQuitCppFlow(false));
}
