import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Button, IconButton, useTheme } from '@mui/material';

import en from '../../translations/en';
import { CloseIcon } from '../../assets/images';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { selectConfiguration } from '../../slice/configurationSlice';
import { CountryOfResidenceState, CountryOfResidenceAction } from '../../context';

import { ReduxFormSelectInput } from '../../components';
import { DrawerOverlay } from '../../components/View';
import { FormAlert } from '../../components/Alert';
interface CountryOfResidenceDialog {
  selectCountryOfResidential: (countryOfResidential: string) => void;
  handleConfirmAction: () => Promise<void>;
}

const CountryOfResidenceDialog: FunctionComponent<CountryOfResidenceDialog> = ({
  selectCountryOfResidential,
  handleConfirmAction,
}) => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const { isOpen } = useContext(CountryOfResidenceState) || {};
  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const [countryOfResidence, setCountryOfResidence] = useState<{ code: string; label: string }>();

  useEffect(() => {
    selectCountryOfResidential(countryOfResidence?.code ? countryOfResidence.code : '');
  }, [countryOfResidence]);

  const regRegionOfficesCountries = useMemo(() => {
    return configurations?.countries?.filter(
      (country) => configurations?.regRegionOffices?.find((regRegionOffice) => country.code === regRegionOffice.code),
    );
  }, [configurations?.regRegionOffices, configurations?.countries]);

  const dialogContent = () => (
    <Box
      className="country_of_residence_dialog"
      sx={{
        width: '100%',
        height: '100%',
        pt: isDesktop ? 2.375 : 1.5,
        px: 2,
        pb: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ '&.MuiBox-root': { flex: 'none', pt: 0 } }}>
        <Typography
          variant="body_1_bold"
          sx={{ flex: 1, height: '24px', float: 'left', fontSize: theme.typography.headline_light.fontSize }}
        >
          {en.userProfile.personal.declareCountryOfResidence}
        </Typography>

        {isDesktop && (
          <IconButton
            onClick={() => {
              setOpenCountryOfResidenceDialog(false);
            }}
            sx={{ width: '24px', height: '24px', p: 0, float: 'right' }}
          >
            <CloseIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        )}
      </Box>
      {countryOfResidence && (
        <FormAlert
          severity="info"
          customStyles={{ mt: 1.5, mb: 0 }}
          component={
            <Typography
              variant="body_2_regular"
              sx={{
                ml: 1,
                color: theme.color.secondary.dark_grey.option_3,
              }}
            >
              {en.userProfile.personal.countryOfResidenceWarning}
            </Typography>
          }
        />
      )}
      <ReduxFormSelectInput
        input={{
          value: countryOfResidence,
          onChange: setCountryOfResidence,
        }}
        title={en.userProfile.personal.countryOfResidence}
        autocomplete={{
          matchFrom: 'start',
        }}
        options={regRegionOfficesCountries}
        sx={{ mt: 3, clear: 'both' }}
        customInputStyle={{
          mt: 3,
          clear: 'both',
          '& .MuiInputLabel-root': {
            left: '8px',
          },
          '& .MuiFilledInput-root': {
            pt: 1.25,
            px: 2,
          },
        }}
      />
      {countryOfResidence && (
        <Button
          sx={{
            width: '100%',
            height: '44px',
            m: 0,
            p: 0,
            color: theme.color.secondary.light_slate.option_7,
            background: theme.color.utility.link.option_3,
            '&: hover': {
              color: theme.color.secondary.light_slate.option_7,
              background: theme.color.utility.link.option_3,
            },
          }}
          onClick={() => {
            handleConfirmAction();
            setOpenCountryOfResidenceDialog(false);
            setCountryOfResidence({ code: '', label: '' });
          }}
        >
          <Typography variant="body_1_bold">{en.common.confirm}</Typography>
        </Button>
      )}
    </Box>
  );

  return (
    <>
      {isDesktop ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 200,
            top: 0,
            left: 0,
            overflowX: 'hidden',
            background: theme.backgroundColor.popup,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          display={isOpen ? 'flex' : 'none'}
        >
          <Box
            sx={{
              width: '478px',
              height: '480px',
              borderRadius: 1,
              background: theme.color.secondary.light_slate.option_7,
              display: 'flex',
            }}
          >
            <Box component={dialogContent}></Box>
          </Box>
        </Box>
      ) : (
        <DrawerOverlay
          title={en.userProfile.personal.yourProfile}
          showOverlay={isOpen}
          component={dialogContent}
          isShowBackArrow={true}
          handleClose={() => {
            setOpenCountryOfResidenceDialog(false);
            setCountryOfResidence({ code: '', label: '' });
          }}
        />
      )}
    </>
  );
};

export default CountryOfResidenceDialog;
