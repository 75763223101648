import React from 'react';
import { Box, Typography, useTheme, SxProps, Theme } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import { selectApp } from '../../../../slice/appSlice';
import { useAppSelector } from '../../../../app/hooks';

interface Dependent {
  dependentName: string;
  status: string;
  relationship?: string;
  isPrimaryLabel?: boolean; // for [ETP-4724] nomination flow > review and confirm page
}

interface DependentListProps {
  dependentList: Dependent[];
  mainTitle: string;
  dependentNameCustomStyles?: SxProps<Theme>;
  statusCustomStyles?: SxProps<Theme>;
}

const NominationDependentList = ({
  dependentList,
  mainTitle,
  dependentNameCustomStyles,
  statusCustomStyles,
}: DependentListProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const getDependentStatusTypoProps = (dependent: Dependent) => {
    let color: string = theme.palette.text.primary;
    let variant: TypographyProps['variant'] = 'body_2_regular';

    // [ETP-4724] nomination flow > review and confirm page > for the to be added dependent
    if (dependent?.isPrimaryLabel) {
      color = theme.palette.cathayJadeLight.contrastText;
      variant = 'navigation_bold';
    }

    return {
      variant,
      color,
    };
  };

  return (
    <Box
      className="nomination_dependent_list"
      sx={{
        width: isDesktop ? '488px' : '100%',
        p: 3,
        mx: 'auto',
        background: 'white',
        boxShadow: theme.boxShadow.important,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography variant="headline_bold" sx={{ textAlign: 'center', pb: 2 }}>
          {mainTitle}
        </Typography>
      </Box>

      <Box
        sx={{
          borderTop: `1px solid ${theme.color.secondary.slate.option_3}`,
        }}
      >
        {dependentList.map((dependent: Dependent, index: number) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
            }}
          >
            <Box sx={dependentNameCustomStyles}>
              <Typography variant="body_1_medium">{dependent.dependentName}</Typography>
              {dependent?.relationship && <Typography variant="body_2_regular">{dependent.relationship}</Typography>}
            </Box>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                ...statusCustomStyles,
              }}
            >
              <Typography
                {...getDependentStatusTypoProps(dependent)}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dependent.status) }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NominationDependentList;
