import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { CARRIER_OPTION, ID_TICKET_TYPE, NOMINATED_PASSENGER_AGE_TYPE, UI_STYLES } from '../../../constants/constants';
import en from '../../../translations/en';
import { Configuration } from 'configuration';

import { INominatedPassenger, ISelectedFlight } from '../../../interfaces';

import { constructFlightSectors, checkNominatedPassengersQuotaRestriction } from '../../../helpers';
import { checkNominatedPassengers } from '../../../helpers/traveller';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking, setLeisureTravelDetails } from '../../../slice/bookingSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { getConcessionListLT } from '../../../services/booking';

import { Footer, Header, DesktopBreadcrumb, ScrollableView } from '../../../components';
import { LtMiniFlightInfo, LtMasterTravellerList, LtBookingReminder } from '../..';
import { selectOrPrefillConcession } from '../../../hooks/booking';

const LtSelectTraveller = ({
  handleBackClick,
  handleFooterClick,
}: {
  handleBackClick?: () => void;
  handleFooterClick?: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { profile } = useAppSelector(selectUser);
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { leisureTravelDetails, outwardFlight, startDate } = useAppSelector(selectBooking, shallowEqual) || {};

  const { retireeWidow } = profile || {};
  const { callSign, departureTime, arrivalTime, departureDate, arrivalDate } = outwardFlight || {};

  const { ltSelectTravellerList, originLtTravelers } = leisureTravelDetails || {};

  const [leisureTravellers, setLeisureTravellers] = useState<INominatedPassenger[]>(ltSelectTravellerList);

  const saveDataToRedux = () => {
    dispatch(
      setLeisureTravelDetails({
        ...leisureTravelDetails,
        ltSelectTravellerList: leisureTravellers,
        originLtTravelers: leisureTravellers,
        ltConcessions: [],
        seatConcession: '',
        seatOption: '',
        isAccompanyFormChecked: false,
        restrictionBeneficiaryType: [],
      }),
    );
  };

  const handleTravellerSelect = (index: number) => {
    const item = eligibleTravellers?.[index];

    const { validationInfo } = item || {};
    const { ageType } = validationInfo || {};

    if (ageType === NOMINATED_PASSENGER_AGE_TYPE.infant) {
      // if deselect infant, need to set seat toggle to default
      item.infantWithSeat = false;
    }

    item.isSelected = !item.isSelected;

    const list = leisureTravellers.map((mt: INominatedPassenger) => {
      return mt.keyIndex === item.keyIndex ? item : mt;
    });

    setLeisureTravellers(list);
    saveDataToRedux();
  };

  const handleInfantsWithoutSeat = (index: number) => {
    const item = eligibleTravellers?.[index];

    item.infantWithSeat = !item.infantWithSeat;
    const list = leisureTravellers.map((mt: INominatedPassenger) => {
      return mt.keyIndex === item.keyIndex ? item : mt;
    });

    setLeisureTravellers(list);
    saveDataToRedux();
  };

  const {
    canProceed: isFooterValid,
    eligibleTravellers,
    nonEligibleTravellers,
  } = checkNominatedPassengers(leisureTravellers) || {};

  const masterTravellerList = [
    {
      list: eligibleTravellers,
      label: en.booking.travelType.leisureTravel.eligibleTravellers,
      isFooterValid,
      isDesktop,
      isEligibleList: true,
      handleTravellerSelect,
      handleInfantsWithoutSeat,
    },
    {
      list: nonEligibleTravellers,
      label: en.booking.travelType.leisureTravel.nonEligibleTravellers,
      isFooterValid,
      isDesktop,
      isEligibleList: false,
    },
  ];

  const constructTravellerInfos = (ltSelectTravellerList: INominatedPassenger[]) => {
    return ltSelectTravellerList
      .filter((traveller: INominatedPassenger) => traveller.isSelected)
      .map((traveller: INominatedPassenger) => ({
        dependentId: traveller.dependentId,
        beneficiaryTypeCode: traveller.beneficiaryTypeCode,
        firstName: traveller.firstName,
        lastName: traveller.lastName,
      }));
  };

  const handleConcessionResults = useCallback(
    (results: any, outwardFlight: ISelectedFlight | null) => {
      if (!results?.concessionRule || !outwardFlight) {
        return;
      }

      const updateDetails = selectOrPrefillConcession({
        ltConcessions: results?.concessionRule,
        ltSeatConcessionTravellerList: leisureTravellers,
        restrictionBeneficiaryType: results?.restrictionBeneficiaryType,
        outwardFlight,
        leisureTravelDetails,
        startDate,
      });

      dispatch(setLeisureTravelDetails(updateDetails));

      // let updatedDetails;

      // const currentItemAdvanceDay = results?.concessionRule[0]?.concessionDef?.advanceBookingDay;
      // const LT_ADVANCE_BOOKING_DAYS = currentItemAdvanceDay?.value;
      // const LT_ADVANCE_BOOKING_DAY_UNIT = currentItemAdvanceDay?.unit;
      // const isInvalidAdvanceBookingDay = checkLtIsExceedAdvanceBookingDay({
      //   startDate,
      //   advanceBookingDays: LT_ADVANCE_BOOKING_DAYS,
      //   advanceBookingDayUnitCode: LT_ADVANCE_BOOKING_DAY_UNIT,
      // });

      // const isClassEligible = results?.concessionRule[0]?.bookingClass.every(
      //   (classItem: string) => !Object.keys(outwardFlight?.availability || {})?.includes(classItem),
      // );

      // if (isInvalidAdvanceBookingDay || isClassEligible) {
      //   updatedDetails = {
      //     ...leisureTravelDetails,
      //     ltConcessions: results.concessionRule,
      //     restrictionBeneficiaryType: results.restrictionBeneficiaryType,
      //     ltSeatConcessionTravellerList: leisureTravellers,
      //     ltSelectTravellerList: leisureTravellers,
      //     seatConcession: '',
      //     seatOption: '',
      //     isAccompanyFormChecked: false,
      //     infantAdultMapFromRedux: {},
      //   };
      // } else {
      //   const preselectedSeatOption = getCanPreselectAvailableSeatOption({
      //     bookingClassInConcession: results.concessionRule[0]?.bookingClass?.slice(),
      //     outwardFlight,
      //   });

      //   const updatedLeisureTravellers = _.cloneDeep(leisureTravellers);
      //   const updateTravellerInfo = _.cloneDeep(results.concessionRule[0]?.travellerInfo || []);

      //   updatedLeisureTravellers.forEach((traveller: INominatedPassenger) => {
      //     const matchingTravellerInfo = updateTravellerInfo.find(
      //       (info: ILtTravellerInfo) => info.dependentId === traveller.dependentId,
      //     );

      //     if (matchingTravellerInfo) {
      //       // Handle updates based on matchingTravellerInfo
      //       traveller.validationInfo.restriction = matchingTravellerInfo.restrictions;

      //       if (matchingTravellerInfo.quotaInfo) {
      //         traveller.quotaInfo = matchingTravellerInfo.quotaInfo;
      //       }

      //       // Check for restrictions and add them if conditions are met
      //       if (
      //         matchingTravellerInfo.restrictConfirmStatusBookingMessage &&
      //         _.get(availability, preselectedSeatOption) === FLIGHT_AVAILABILITY_STATUS.confirm
      //       ) {
      //         traveller.validationInfo.restriction.push(matchingTravellerInfo.restrictConfirmStatusBookingMessage);
      //       }
      //     }
      //   });

      //   // Handle restrictions based on restrictionBeneficiaryType
      //   results.restrictionBeneficiaryType.forEach((item: IRestrictionBeneficiaryType) => {
      //     updatedLeisureTravellers.forEach((traveller: INominatedPassenger) => {
      //       if (results.concessionRule[0]?.restriction?.beneficiaryType?.includes(item._id)) {
      //         const { ageToYear: age, ageFromYear, beneficiaryType, bookingClass } = item || {};

      //         const errorMes = getInvalidFJClassTravellerWarningMessage(
      //           en.error.invalidAgeFirst,
      //           age,
      //           en.error.invalidAgeSecond,
      //           bookingClass,
      //         );

      //         const travellerRegistrationInConcession = results.concessionRule[0]?.travellerInfo.find(
      //           (info: ILtTravellerInfo) => {
      //             return (
      //               traveller.beneficiaryTypeCode === info.beneficiaryTypeCode &&
      //               traveller.dependentId === info.dependentId
      //             );
      //           },
      //         );

      //         if (
      //           traveller.beneficiaryTypeCode === beneficiaryType &&
      //           traveller.age < age &&
      //           traveller.age >= ageFromYear &&
      //           !traveller.validationInfo.isAddedRestrictionFlag &&
      //           bookingClass === preselectedSeatOption
      //         ) {
      //           traveller.validationInfo.restriction.push(errorMes);
      //           traveller.validationInfo.isAddedRestrictionFlag = true;
      //         }

      //         if (
      //           _.intersection(traveller.validationInfo.restriction, travellerRegistrationInConcession?.restrictions)
      //             .length === 0
      //         ) {
      //           traveller.validationInfo.restriction.push(...(travellerRegistrationInConcession?.restrictions || []));
      //         }
      //       }
      //     });
      //   });

      //   updatedDetails = {
      //     ...leisureTravelDetails,
      //     ltConcessions: results.concessionRule,
      //     restrictionBeneficiaryType: results.restrictionBeneficiaryType,
      //     ltSeatConcessionTravellerList:
      //       results.concessionRule?.length === 1 ? updatedLeisureTravellers : leisureTravellers,
      //     ltSelectTravellerList: leisureTravellers,
      //     seatConcession: results.concessionRule?.length === 1 ? results.concessionRule?.[0]?._id : '',
      //     seatOption: results.concessionRule?.length === 1 ? preselectedSeatOption : '',
      //     isAccompanyFormChecked: false,
      //     infantAdultMapFromRedux: {},
      //   };
      // }

      // dispatch(setLeisureTravelDetails(updatedDetails));
    },
    [leisureTravellers, leisureTravellers],
  );

  const getConcessionAction = useCallback(
    async (ltSelectTravellerList) => {
      // Construct request data
      const travellerInfos = constructTravellerInfos(ltSelectTravellerList);

      const { arrivalDate } = outwardFlight || {};
      const flightSectors = constructFlightSectors(outwardFlight, { arrivalDate });

      const results = await getConcessionListLT({
        userId: ern,
        flightSectors,
        travellerInfo: travellerInfos,
        concessionRuleId: leisureTravelDetails?.concessionRuleId,
      });

      handleConcessionResults(results, outwardFlight);

      return results;
    },
    [leisureTravelDetails],
  );

  useEffect(() => {
    // TODO trust useState data instead of redux !!!
    setLeisureTravellers(
      ltSelectTravellerList.map((traveller: INominatedPassenger, index: number) => {
        const keyIndex = traveller.keyIndex;
        const originLtTravelersItem = originLtTravelers.find((item: INominatedPassenger) => item.keyIndex === keyIndex);

        // handle quota restriction for empty case
        const restriction = checkNominatedPassengersQuotaRestriction({
          originalRestriction: originLtTravelersItem?.validationInfo?.restriction,
          existingRestriction: traveller?.validationInfo?.restriction,
          quotaInfo: traveller?.quotaInfo,
        });

        // clear accompanyAdult and accompanyFormData data
        const validationInfo = {
          ageType: traveller?.validationInfo?.ageType,
          allowAccompany: traveller?.validationInfo?.allowAccompany,
          restriction,
          isAddedRestrictionFlag: false,
          mustTravelWithBeneficiaryTypeCode: traveller?.validationInfo?.mustTravelWithBeneficiaryTypeCode,
        };
        const newTraveller = { ...traveller, validationInfo };
        const retireeCountryOfResidential = configurations?.countries?.find(
          (option: Configuration.DropdownOptionClient) => option.code === retireeWidow?.countryOfResidential,
        );
        return {
          ...newTraveller,
          // isSelected is read only property
          isSelected: ltSelectTravellerList[index].isSelected,
          accompanyAdult: undefined,
          accompanyFormData: undefined,
          retireeCountryOfResidential: retireeCountryOfResidential,
        };
      }),
    );
  }, [ltSelectTravellerList]);

  return (
    <Box
      sx={{
        background: isDesktop ? theme.color.secondary.light_slate.option_7 : 'transparent',
      }}
    >
      {!isDesktop && (
        <LtMiniFlightInfo
          {...{
            callSign,
            departureTime,
            arrivalTime,
            departureDate,
            arrivalDate,
          }}
        />
      )}
      <Grid
        component={ScrollableView}
        sx={{
          mt: isDesktop ? 0 : 2,
          px: isDesktop ? 0 : 2,
          pb: 2,
          height: isDesktop
            ? `calc(${screenHeight}px  - ${UI_STYLES.desktopHeaderHeight}  - ${
                isFooterValid ? UI_STYLES.desktopFooterHeight : '0px'
              } )`
            : `calc(${screenHeight}px  - ${UI_STYLES.navBarHeight}
                - ${UI_STYLES.searchBarResultPageHeight} - ${isFooterValid ? UI_STYLES.footerHeight : '0px'})`,
        }}
      >
        <DesktopBreadcrumb
          step={1}
          customStyles={{ height: UI_STYLES.overlayHeaderHeightSearchCriteria }}
          customCrumbs={en.booking.ltBreadCrumbs}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: isDesktop ? UI_STYLES.desktopHeaderNavWidth : '100%',
            mx: 'auto',
          }}
        >
          {isDesktop && (
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleBackClick?.();
                  }}
                >
                  {en.booking.flightClass.backSearchResult}
                </Typography>
              }
              customStyles={{
                mb: 2,
              }}
              handleOnBack={handleBackClick}
            />
          )}

          {outwardFlight?.marketingCompany === CARRIER_OPTION.CX &&
            leisureTravelDetails.sub === ID_TICKET_TYPE.SUBLO && (
              <LtBookingReminder
                title={en.booking.travelType.leisureTravel.travelReminder}
                content={en.booking.travelType.leisureTravel.travelReminderContent}
              />
            )}

          {isDesktop && (
            <LtMiniFlightInfo
              {...{
                callSign,
                departureTime,
                arrivalTime,
                departureDate,
                arrivalDate,
              }}
            />
          )}

          {masterTravellerList.map((masterTravellerListItem, masterTravellerListItemIndex: number) => (
            <LtMasterTravellerList key={masterTravellerListItemIndex} {...masterTravellerListItem} />
          ))}
        </Box>
      </Grid>
      {isFooterValid && (
        <Footer
          customStyles={
            isDesktop && {
              height: UI_STYLES.desktopFooterHeight,
              pr: 22,
              boxShadow: theme.palette.boxShadow.dark,
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
            }
          }
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.common.continue,
            customOnClick: async () => {
              const ltConcessionResult = await getConcessionAction(leisureTravellers);
              ltConcessionResult && handleFooterClick?.();
            },
          }}
        />
      )}
    </Box>
  );
};

export default LtSelectTraveller;
