import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';

const DetailsContainer = ({
  title,
  titleVariant = 'title_2_bold',
  titleTypoSxProps,
  customStyles,
  children,
}: {
  title: string;
  titleVariant?: string | any;
  customStyles?: SxProps<Theme>;
  titleTypoSxProps?: SxProps<Theme>;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 4,
        ...customStyles,
      }}
    >
      <Typography variant={titleVariant} sx={{ color: theme.palette.text.secondary, mb: 3, ...titleTypoSxProps }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default DetailsContainer;
