import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';

import en from '../../../../translations/en';
import { TickIcon } from '../../../../assets/images';
import { TRAVEL_PURPOSE, USER_ROLE } from '../../../../constants/constants';

import { getNonEnglishCharacterTravellers, handleBookOneWayOnClick } from '../../../../helpers';

import { usePaymentMethodIndicator } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectAuth } from '../../../../slice/authSlice';
import { selectBooking } from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';

import { Footer } from '../../../../components';
interface IFlightFooterProps {
  origin: string;
  destination: string;
  outwardCallSign: string;
  // returnCallSign: string;
  handleFooterClick?: () => void;
  isFromRebookFlow?: boolean;
}

interface IFlightDetailsProps {
  origin: string;
  destination: string;
  callSign: string;
  // isRoundTrip: boolean;
}

const FlightDetails = ({
  origin,
  destination,
  callSign,
}: // isRoundTrip,
IFlightDetailsProps) => {
  // const color = callSign && !isRoundTrip ? theme.palette.gray.main : "black";
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Box display="flex">
        <Typography variant="body_1_regular">
          {origin}
          {en.common.hyphen}
          {destination}
        </Typography>
      </Box>
      <Typography variant="body_2_medium" color={theme.color.utility.link.option_3}>
        <TickIcon style={{ width: 12, height: 12, marginRight: 4 }} />
        {callSign || en.booking.selectFlight.selecting}
      </Typography>
    </Box>
  );
};

const FlightCardFooter = ({
  origin,
  destination,
  outwardCallSign,
  handleFooterClick,
  isFromRebookFlow,
}: // returnCallSign,
IFlightFooterProps) => {
  // if (!outwardCallSign) {
  //   return null;
  // }
  // const isRoundTrip = returnCallSign !== "" && outwardCallSign !== "";
  const dispatch = useAppDispatch();

  const { profile, concession, role } = useAppSelector(selectUser) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { leisureTravelDetails, travelType, outwardFlight, startDate } =
    useAppSelector(selectBooking, shallowEqual) || {};

  const isLt = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();
  const regulatoryRegion = profile?.regulatoryRegion;

  const paxList = (concession?.paxList || []) as any;
  const isShowNonEnglishCharacter = !isLt && !!getNonEnglishCharacterTravellers(paxList, true);

  // handle delegation role
  const isDelegationRole = role.type === USER_ROLE.delegation;
  return (
    <>
      <Footer
        customStyles={{
          borderRadius: '16px 16px 0px 0px',
          border: 0,
          boxShadow: '0px -2px 4px rgba(199, 199, 199, 0.25)',
        }}
        leftChild={
          <FlightDetails
            origin={origin}
            destination={destination}
            // isRoundTrip={isRoundTrip}
            callSign={outwardCallSign}
          />
        }
        primaryBtn={{
          // isRoundTrip? en.booking.selectFlight.roundTrip:
          text: isFromRebookFlow ? en.common.continue : en.booking.selectFlight.oneWay,
          customOnClick: () => {
            isFromRebookFlow
              ? handleFooterClick?.()
              : handleBookOneWayOnClick({
                  isLt,
                  ern,
                  startDate,
                  outwardFlight,
                  leisureTravelDetails,
                  concession: (isLt ? concession?.leisureTravel : concession?.dutyTravel) || [],
                  handleFooterClick,
                  dispatch,
                  paymentMethodIndicator,
                  regulatoryRegion: regulatoryRegion || '',
                  isShowNonEnglishCharacter,
                  isDelegationRole,
                  concessionRuleId: leisureTravelDetails?.concessionRuleId,
                  profile: profile,
                });
          },
          customButtonStyles: {
            padding: '10px 16px',
            width: 'auto',
            minWidth: '143px',
          },
        }}
      />
    </>
  );
};

FlightCardFooter.defaultProps = {
  isFromRebookFlow: false,
  handleFooterClick: undefined,
};

export default FlightCardFooter;
