import React, { useContext } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import _ from 'lodash';

import {
  BOOKING_CLASS,
  BOOKING_STATUS,
  CARRIER_OPTION,
  FLIGHT_LOAD_STATUS,
  ID_TICKET_TYPE,
  NOT_APPLICABLE,
  SEAT_CLASS_MAX_LOADING,
  UI_STYLES,
  SEAT_CLASS,
} from '../../../../constants/constants';
import en from '../../../../translations/en';
import { FONT_WEIGHT } from '../../../../constants/font';

import { CompanyLogoSmall, LeisureTravelBackgroundLessIcon, TakeOffIcon, TickIcon } from '../../../../assets/images';
import { LaughIcon, SmileIcon, SadIcon, LoadingIcon } from '../../../../assets/images';

import { ISelectedFlight, IFlightLoadCabinData } from '../../../../interfaces';
import { FlightSearch } from 'flightSearch';

import { calcStops, clearSign, getFlightKey, isContainNegativeSign, isEqualToZero } from '../../../../helpers';
import { checkRestrictedStatus, checkSeatNotAvailStatus } from './FlightCard';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectBooking, setLTCabinClassData } from '../../../../slice/bookingSlice';
import { BookingSummaryState } from '../../../../context';

import { WarningAlert } from '../../../../components';
import { FlightClass } from '../../../../containers';
import { FlightDateLabel } from '../../..';

interface IFlightCardProps {
  flight: FlightSearch.ParsedFlightSearchResult;
  selectFlight: (flight: ISelectedFlight | null) => void;
  isSelected: boolean;
  isFromCart?: boolean;
  flag: string;
  initData?: boolean;
  isShowCabinHeader?: boolean;
  isFromRebookFlow?: boolean;
}

const LtFlightCard = ({
  flight,
  flag,
  isSelected,
  selectFlight,
  isFromCart,
  isShowCabinHeader,
  isFromRebookFlow,
}: IFlightCardProps) => {
  const {
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departurePort,
    arrivalPort,
    callSign,
    aircraft,
    numberOfStops,
    embargo,
    //     marketingCompany, // "CX" -> Cathay flight
    duration,
    flightRestriction,
    availability,
  } = flight;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { origin, destination, flightLoadCache, leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const {
    sub, // NOSUB/SUBLO
  } = leisureTravelDetails || {};

  const flightLoadCacheKey = getFlightKey(flight);
  const flightLoadData = flightLoadCache?.[flightLoadCacheKey] || {};

  const { cabins, color } = flightLoadData || {};

  const isLoading = flightLoadCache?.[flightLoadCacheKey];
  const isCX = flag === CARRIER_OPTION.CX;
  const isHiddenFlightLoadFlag = window.config?.isHiddenFlightLoad;

  const border = isFromCart
    ? `${theme.spacing(0.25)} solid ${theme.palette.secondary.main}`
    : isSelected
    ? isShowCabinHeader
      ? `${theme.spacing(0.25)} solid ${theme.color.secondary.dark_grey.option_7}}`
      : `${theme.spacing(0.25)} solid ${theme.color.utility.link.option_3}`
    : `${theme.spacing(0.25)} solid transparent`;

  // if any of OD - isVirtualAirport is true
  const isVirtualAirport = origin?.isVirtualAirport || destination?.isVirtualAirport;

  // the flight is embargo
  const isEmbargo = embargo && embargo.length > 0;

  const embargoDescription = embargo && embargo[0]?.description;

  // seat not avail.
  const isSeatNotAvail = availability && checkSeatNotAvailStatus(availability);

  const isDisabled = isEmbargo || isSeatNotAvail;

  // the flight is restricted
  const isWholeFlightRestricted = checkRestrictedStatus(flightRestriction);

  const LoadingDisplay = ({ count, isLabelRed }: { count: string; isLabelRed: boolean }) => {
    return (
      <Typography
        variant="body_2_regular"
        sx={{
          textAlign: 'center',
          maxWidth: '28px',
          mt: 0.5,
          color: isLabelRed ? theme.palette.warning.contrastText : theme.color.secondary.dark_grey.option_3,
        }}
      >
        {isLoading && count}
      </Typography>
    );
  };

  const BarComponent = ({
    isShowCabinHeader = false,
    cabinClass,
    cabinClassData,
  }: {
    isShowCabinHeader: boolean | undefined;
    cabinClass: string;
    cabinClassData: IFlightLoadCabinData | null | undefined;
  }) => {
    let barData;
    if (cabinClassData) {
      const { cabin, avStr } = cabinClassData;
      const maxLoading: string = SEAT_CLASS_MAX_LOADING[cabin];
      if (isContainNegativeSign(avStr)) {
        // Handle loading with "-" sign case
        barData = {
          barPercentage: 0,
          label: avStr,
          isLabelRed: true,
        };
      } else {
        // Handle loading "0" case and "+" loading case
        const currentLoading: number = parseInt(clearSign(avStr));
        barData = {
          barPercentage: currentLoading / parseFloat(maxLoading),
          label: avStr,
          isLabelRed: isEqualToZero(avStr),
        };
      }
    } else {
      // Handle no data case
      barData = {
        barPercentage: 0,
        label: NOT_APPLICABLE,
        isLabelRed: false,
      };
    }
    return (
      <Box
        sx={{
          mr: 1.25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isShowCabinHeader && (
          <Typography
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
              textAlign: 'center',
              height: '16px',
              width: '20px',
              mb: 0.5,
            }}
            variant="navigation_regular"
          >
            {cabinClass}
          </Typography>
        )}
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              height: UI_STYLES.flightLoadBarHeight,
              width: UI_STYLES.flightLoadBarWidth,
              bgcolor: theme.palette.gray.contrastText,
            }}
          />
          <Box
            sx={{
              height: `${36 * (barData?.barPercentage || 0)}px`, // need to reference to UI_STYLES.flightLoadBarHeight value(36px), have to make sure they are sync
              width: UI_STYLES.flightLoadBarWidth,
              bgcolor: theme.palette.cathayJade.contrastText,
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          />
        </Box>
        <LoadingDisplay count={barData?.label} isLabelRed={barData?.isLabelRed} />
      </Box>
    );
  };

  // ------------- start ------------
  // etp-2915: TBC: backend will do this part ?
  // from rebook flow, need to check flight is booked
  // 1.if not cancelled, cannot rebook same flight
  // 2.if cancelled, can rebook all flight
  // 3. same regrade class has flight
  const { bookingSummaryData } = useContext(BookingSummaryState) || {};
  const { ticketsBySegment, status } = bookingSummaryData || {};
  const {
    arrivalDate: bookedArrivalDate,
    carrier: bookedCarrier,
    flightNum: bookedFlightNum,
    bookingParentClass,
  } = (ticketsBySegment && ticketsBySegment[0]) || {};

  const bookedCallSign = `${bookedCarrier}${bookedFlightNum}`;

  const foundCabin = cabins && cabins.find((element) => element.cabin === bookingParentClass);

  const checkEnoughCabin = () => {
    if (!foundCabin?.avStr || isContainNegativeSign(foundCabin?.avStr)) {
      return false;
    }
    return true;
  };

  const isNoSub = sub === ID_TICKET_TYPE.NOSUB;
  const clonedAvailability = _.cloneDeep(availability);

  const getSeatClassKeyByValue = (targetValue: string) => {
    let seatClass = '';
    Object.entries(SEAT_CLASS).forEach(([key, value]) => {
      if (value === targetValue) seatClass = key;
    });

    return seatClass;
  };

  const noSubAvailabilityClassList =
    isNoSub && Object.keys(clonedAvailability).length > 0
      ? Object.keys(clonedAvailability).map((cabinClass) => {
          const matchedSeatClassKey = getSeatClassKeyByValue(cabinClass);
          return {
            key: cabinClass,
            isRestricted: flightRestriction?.[matchedSeatClassKey as keyof typeof flightRestriction] || false,
            availability: clonedAvailability?.[cabinClass as keyof typeof clonedAvailability],
          };
        })
      : [];

  // 1. has regrade class and flight has enough seat
  // 2. booking is not cancelled and not same date & same flight
  const isFlightCabinNotEnough = isFromRebookFlow && !checkEnoughCabin();
  const isSameFlight =
    isFromRebookFlow &&
    status !== BOOKING_STATUS.cancelled &&
    bookedArrivalDate === arrivalDate &&
    bookedCallSign === callSign;
  // ------------- end --------------------------------

  const isDimmedStyling = isDisabled || isSameFlight || isFlightCabinNotEnough;

  return (
    <Box
      sx={{
        borderRadius: '12px',
        boxShadow:
          isFromCart || isDimmedStyling
            ? 'none'
            : isSelected
            ? isShowCabinHeader
              ? ''
              : theme.boxShadow.selected
            : theme.boxShadow.important,
        my: 2,
        mx: { xs: 2, sm: 0 },
        p: 2,
        width: isDesktop ? '100%' : 'auto',
        minWidth: '343px',
        minHeight: '136px',
        bgcolor: isDimmedStyling
          ? theme.color.secondary.light_slate.option_6
          : isSelected && !isFromCart && !isShowCabinHeader
          ? theme.backgroundColor.rockGrey
          : theme.palette.primary.light,
        boxSizing: 'border-box',
        border: border,
        cursor: isShowCabinHeader || isDimmedStyling ? '' : 'pointer',
        opacity: isDimmedStyling ? 0.8 : 1,
      }}
      onClick={() => {
        if (isDimmedStyling) {
          return;
        }
        if (isSelected) {
          selectFlight(null);
          return;
        }
        selectFlight({
          ...flight,
          // seatClass: getDefaultSeatClassSelection(
          //   getSeatClassDetails(availability, flightRestriction)
          // ),
        });
        dispatch(setLTCabinClassData({ type: flag, value: {} }));
      }}
    >
      {isEmbargo && (
        <WarningAlert
          customStyles={{
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8px',
            backgroundColor: theme.color.secondary.slate.option_6,
          }}
          warningMsg={
            <>
              <Typography variant="navigation_bold" sx={{ display: 'inline-block' }}>
                {en.booking.searchFlight.embargoFlight}
              </Typography>
              {' - '}
              <Typography variant="navigation_regular" sx={{ display: 'inline-block' }}>
                {en.booking.searchFlight.bookingNotAllowed}
              </Typography>
            </>
          }
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: isShowCabinHeader ? 'start' : 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: '100%',
            width: '100%',
          }}
        >
          {isCX && !isHiddenFlightLoadFlag && !isDisabled && !leisureTravelDetails?.concessionRuleId && (
            <Box>
              {isLoading ? (
                isSelected ? (
                  <TickIcon style={{ width: 20, height: 20 }} />
                ) : color === FLIGHT_LOAD_STATUS.green ? (
                  <LaughIcon width="20px" height="20px" />
                ) : color === FLIGHT_LOAD_STATUS.yellow ? (
                  <SmileIcon width="20px" height="20px" />
                ) : (
                  <SadIcon width="20px" height="20px" />
                )
              ) : (
                <LoadingIcon style={{ width: 20, height: 20 }} />
              )}
            </Box>
          )}
          <Box sx={{ pl: isCX ? 1.5 : 0, width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: 0.5,
              }}
            >
              {isCX && (
                <CompanyLogoSmall
                  fill={theme.color.primary.cathay_jade.option_3}
                  style={{ marginRight: 4, width: 16, height: 16 }}
                />
              )}
              <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
                {callSign}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FlightDateLabel
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  departureDate={departureDate}
                  arrivalDate={arrivalDate}
                  textColor={
                    isDimmedStyling
                      ? theme.color.secondary.grey.option_3
                      : isWholeFlightRestricted
                      ? theme.color.secondary.dark_grey.option_3
                      : 'black'
                  }
                />
                <Typography variant="navigation_medium" color={theme.color.secondary.grey.option_3}>
                  {`${calcStops(numberOfStops)} ${aircraft || ''}`}
                </Typography>
              </Box>
              {isEmbargo
                ? embargoDescription && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '134px',
                        minHeight: '43px',
                        bgcolor: theme.color.secondary.light_slate.option_5,
                        borderRadius: 1,
                        p: 1,
                      }}
                    >
                      {embargoDescription.split('\n').map((description, index) => {
                        return (
                          <Typography
                            key={index}
                            variant="navigation_regular"
                            sx={{
                              textAlign: 'center',
                              color: theme.color.secondary.dark_grey.option_1,
                            }}
                          >
                            {description}
                          </Typography>
                        );
                      })}
                    </Box>
                  )
                : isCX &&
                  !isHiddenFlightLoadFlag && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {/** if booked / not enough cabin, show booked status icon  */}
                      {(isSameFlight || isFlightCabinNotEnough) && isLoading && (
                        <Box
                          sx={{
                            bgcolor: theme.color.utility.unselectable.option_1,
                            height: '22px',
                            borderRadius: '11px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: isSameFlight ? '72px' : '112px',
                            mt: -3,
                            mb: 1,
                            ml: 'auto',
                          }}
                        >
                          {isSameFlight && <LeisureTravelBackgroundLessIcon fill="white" width="12px" height="12px" />}
                          <Typography
                            sx={{
                              color: 'white',
                              fontSize: '11px',
                              lineHeight: '14px',
                              fontWeight: FONT_WEIGHT.medium,
                            }}
                          >
                            {isSameFlight
                              ? en.booking.searchFlight.booked
                              : en.booking.confirmation.cancelActions.rebook.naTicketClass}
                          </Typography>
                        </Box>
                      )}

                      {/* // display for NOSUB type */}
                      {isNoSub ? (
                        <>
                          {noSubAvailabilityClassList?.length > 0 &&
                            noSubAvailabilityClassList.map((flightClassItem, flightClassIndex) => (
                              <FlightClass
                                key={flightClassIndex}
                                title={flightClassItem.key}
                                availability={flightClassItem.availability}
                                isRestricted={flightClassItem.isRestricted}
                              />
                            ))}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                          }}
                        >
                          {BOOKING_CLASS.map((cabinClass: string, index: number) => {
                            const matchedCabinClassData = cabins?.find(
                              (cabinClassFromFlight: IFlightLoadCabinData) => cabinClass === cabinClassFromFlight.cabin,
                            );

                            return (
                              <BarComponent
                                key={index}
                                cabinClass={cabinClass}
                                isShowCabinHeader={isShowCabinHeader}
                                cabinClassData={matchedCabinClassData}
                              />
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  )}
            </Box>
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {isVirtualAirport && (
                  <Box
                    sx={{
                      display: 'flex',
                      bgcolor: theme.color.cabin_class.premium_economy.option_6,
                      borderRadius: '12px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 0.75,
                      mt: 1.25,
                      height: '24px',
                      width: '96px',
                    }}
                  >
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                      sx={{ color: theme.color.secondary.dark_grey.option_2 }}
                    >
                      {departurePort}
                    </Typography>
                    <TakeOffIcon fill={theme.palette.gray.dark} width="16" style={{ marginLeft: 4, marginRight: 4 }} />
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                      sx={{ color: theme.color.secondary.dark_grey.option_2 }}
                    >
                      {arrivalPort}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    mr: 0.75,
                    mt: 1.25,
                    height: '24px',
                    width: '58px',
                    borderRadius: '12px',
                    bgcolor: theme.color.secondary.light_slate.option_4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="navigation_bold"
                    sx={{
                      color: theme.color.secondary.dark_grey.option_3,
                    }}
                  >
                    {duration}
                  </Typography>
                </Box>
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LtFlightCard.defaultProps = {
  isFromCart: false,
  initData: false,
  isShowCabinHeader: false,
};

export default LtFlightCard;
