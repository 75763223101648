import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  useTheme,
  Typography,
  SxProps,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import _ from 'lodash';
import DOMPurify from 'dompurify';

import { NOMINATION_SUB_ROUTE_PAGE, NOMINATION_TYPE } from '../../../../constants/constants';
import en from '../../../../translations/en';
import { AirCraftBackgroundImage, InfoIcon, DownArrowIcon, LockedIcon } from '../../../../assets/images';

import { INominationCartMasterProps } from './NominationCart/NominationCartMasterContainer';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { NominationAction, NominationState } from '../../../../context';

import { INominationViewModeItem } from '../../../../interfaces';
import { sortByDependents } from '../../../../helpers';

import { NominationCartMasterContainer } from '../../..';

const cartPageInitialState = {
  list: [],
  maxNumberOfSelectableNominees: undefined,
  maxNumberOfPerson: undefined,
  nominationType: '',
  isViewMode: false,
  isAllowAddToCart: false,
  containerCustomProps: {},
  addNomineeToCartAction: () => {},
};

const NominationLegends = (props: { title: string; dataList: string[] }) => {
  const theme = useTheme();
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        borderRadius: '4px !important',
        minHeight: '36px',
        backgroundColor: theme.color.secondary.light_slate.option_6,
        '&:before': {
          display: 'none',
        },
        '.MuiAccordionDetails-root': {
          pt: 0,
          pb: 1,
          px: 2,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<DownArrowIcon />}
        aria-controls="nomination-legends-content"
        sx={{
          minHeight: '36px',
          height: '36px',
          lineHeight: '36px',
          color: theme.color.utility.link.option_3,
          pl: 1.3125,
          pr: 2.5,
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <InfoIcon width="16" height="16" fill={theme.color.utility.link.option_3} />
        <Typography variant="navigation_medium" sx={{ ml: 0.8125 }}>
          {props.title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {props.dataList.map((item, key) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box className="legend_item_left" sx={{ my: 0.5 }}>
              <Box sx={{ width: '42px', display: 'flex', justifyContent: 'center', mx: 1.5 }}>
                {key === 0 ? (
                  <LockedIcon width="10" height="14" />
                ) : (
                  <Typography variant="fine_print_bold" color={theme.color.utility.link.option_3}>
                    {en.common.remove}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography
              className="legend_item_right"
              variant="navigation_regular"
              sx={{ my: 0.5, color: theme.color.secondary.dark_grey.option_2 }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}
            />
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const NominationCartPageContainer = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const [nominationCartData, setNominationCartData] = useState<INominationCartMasterProps>(cartPageInitialState);

  const { nominationFlow, nominationTransformData, nominationChangeTypeData } = useContext(NominationState) || {};
  const { sortedDependents } = nominationFlow.nominationSelectionData || [];

  const { setNominationFlow, setClonedNominationTempData, setClonedNominationChangeTypeData } =
    useContext(NominationAction) || {};

  const { nominationSubRoutePage, nominationFlowSelectedType: selectedType } = nominationFlow || {};

  const selectedNominatedData = nominationFlow?.nominationViewModeData[selectedType];
  const upToNominees = selectedNominatedData?.maxNumberOfPerson - selectedNominatedData?.list.length;

  const isNotNominatedDependentsSelection =
    nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection;

  const containerCustomProps: SxProps<Theme> = {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url(${AirCraftBackgroundImage})`,
    backgroundPosition: 'right 0 top 0',
    backgroundSize: 'auto 90px',
    backgroundRepeat: 'no-repeat',
    ...(selectedType === NOMINATION_TYPE.plusOne && {
      backgroundColor: theme.nomination.plusOne,
    }),
    ...(selectedType === NOMINATION_TYPE.travelNominees && {
      backgroundColor: theme.nomination.travelNominee,
    }),
  };

  // nominationTransformData: not nominated dependent + nominated dependent
  // nominationChangeTypeData: nominated change type data
  // nominateDependentToBeChangedList: nominationTransformData + nominationChangeTypeData + sorting
  const nominateDependentToBeChangedList = useMemo(() => {
    return sortByDependents(nominationTransformData, nominationChangeTypeData, sortedDependents);
  }, [nominationTransformData, nominationChangeTypeData]);

  useEffect(() => {
    let newCartDataObj: INominationCartMasterProps = cartPageInitialState;

    if (isNotNominatedDependentsSelection) {
      // This sub route page content is showing the list of dependents that are not nominated yet and able for selection
      newCartDataObj = {
        list: nominationTransformData.filter((item: INominationViewModeItem) => !item.isLocked),
        maxNumberOfPerson: undefined, // set "undefined" to hide top right "0/0" label
        maxNumberOfSelectableNominees: upToNominees,
        nominationType: selectedType,
        isViewMode: false,
        containerCustomProps,
        secondCartItemList: nominationChangeTypeData,
      };
    } else {
      // This sub route page content is showing the list including the nominated dependents and the to be added dependents
      const nominateToBeChangedDependents = nominateDependentToBeChangedList.filter(
        (item: INominationViewModeItem) => item.isSelected,
      );

      // list includes -> transform data + change type data
      newCartDataObj = {
        list: nominateToBeChangedDependents,
        maxNumberOfPerson: selectedNominatedData?.maxNumberOfPerson,
        nominationType: selectedType,
        isViewMode: false,
        isAllowAddToCart: selectedNominatedData?.maxNumberOfPerson > nominateToBeChangedDependents.length,
        addNomineeToCartAction: () => {
          const tempClonedList = _.cloneDeep(nominationTransformData);
          setClonedNominationTempData(tempClonedList);

          const tempClonedChangeTypeList = _.cloneDeep(nominationChangeTypeData);
          setClonedNominationChangeTypeData(tempClonedChangeTypeList);

          setNominationFlow({
            ...nominationFlow,
            nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection,
          });
        },
        containerCustomProps,
      };
    }

    setNominationCartData(newCartDataObj);

    if (nominationSubRoutePage === undefined) {
      // default set sub route to 2-1
      setNominationFlow({
        ...nominationFlow,
        nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart,
      });
    }
  }, [nominationSubRoutePage, nominationTransformData]);

  return (
    <>
      <Box
        className="nomination_cart_page_container"
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
          }),
        }}
      >
        <Typography
          variant="headline_medium"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 2,
          }}
        >
          {nominationFlowLbl.cathayTitle}
        </Typography>

        <NominationCartMasterContainer {...nominationCartData} />

        {nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart && (
          <NominationLegends title={nominationFlowLbl.legends.title} dataList={nominationFlowLbl.legends.remarks} />
        )}
      </Box>
    </>
  );
};

export default NominationCartPageContainer;
