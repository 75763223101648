import React, { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { Box, useTheme, Autocomplete, TextField, Typography } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import {
  UI_STYLES,
  CONCESSION_GROUPING,
  DATE_FORMAT,
  ADMIN_CONCESSION,
  ALLOTMENT_TYPE,
} from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import { imgAdminTableArrowRight, DownArrowInheritIcon } from '../../../assets/images';

import {
  adminConcessionCarrierGroupSort,
  checkAgeInEligibilityDependent,
  checkEligibilityWithOALEligibility,
  checkEligibilityNominationType,
  formatDateAsString,
  getAdminPersonalDisplayName,
} from '../../../helpers';

import {
  IAdminConcession,
  IAdminQuotaList,
  IAdminNomination,
  IAdminConcessionFilterDependent,
  IAdminAllotmentType,
} from '../../../interfaces';
import { AdminAccessEmployeeState, AdminAccessEmployeeAction } from '../../../context';
import { BaseTable } from '../../../components';

const ConcessionList = () => {
  const theme = useTheme();
  const fieldLabel = en.admin.adminConcession.fields;

  const { employeeDetail, concessionGrouping, concessionName, concessions } =
    useContext(AdminAccessEmployeeState) || {};
  const { setFocusConcession, setConcessionName, setConcessionGrouping } = useContext(AdminAccessEmployeeAction) || {};

  // personalInfo
  const { personalInfo, nominationList } = employeeDetail || {};
  const { dependentId, firstName, middleName, lastName, age } = personalInfo || {};
  const employeeName = getAdminPersonalDisplayName({
    firstName,
    lastName,
    middleName,
  });

  const [filterData, setFilterData] = useState<IAdminConcession[]>([]);
  const [filterGrouping, setFilterGrouping] = useState<string>(ADMIN_CONCESSION.ALL);
  const [filterName, setFilterName] = useState<IAdminConcessionFilterDependent>({
    name: employeeName,
    relationship: ADMIN_CONCESSION.EMPLOYEE,
    dependentId: null,
    beneficiaryTypeCode: ADMIN_CONCESSION.EMP,
    age,
  });

  const getFilterByList = () =>
    nominationList
      .filter((item: IAdminNomination) => item.isNominationActive && item.isActive)
      .map((item: IAdminNomination) => {
        const fieldLabel = getAdminPersonalDisplayName({
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
        });
        const fieldValue = item.dependentId;

        return { label: fieldLabel, value: fieldValue };
      });

  const concessionGroupingOptions = CONCESSION_GROUPING;
  // dependents add employee
  const employeeOptions = [
    {
      label: employeeName,
      value: dependentId,
    },
  ];
  const nameOptions = employeeOptions.concat(getFilterByList());

  const handleRowDataOnClick = (rowData: any) => {
    setConcessionGrouping(filterGrouping);
    filterName.dependentId === null // employee
      ? setConcessionName({
          name: employeeName,
          relationship: ADMIN_CONCESSION.EMPLOYEE,
          dependentId: null,
          beneficiaryTypeCode: ADMIN_CONCESSION.EMP,
        })
      : setConcessionName(nominationList.find((item: IAdminNomination) => item.dependentId === filterName.dependentId));

    setFocusConcession(rowData);
  };

  const tableSchema = [
    {
      key: 'concession',
    },
    {
      key: 'travelFromDate',
    },
    {
      key: 'travelToDate',
    },
    {
      key: 'allotedTicket',
    },
    {
      key: 'used',
    },
    {
      key: 'bookingPriority',
    },
    {
      key: 'concessionStatus',
    },
  ];

  const getTableContentValue = (tableHeader: string, dataItem: any) => {
    const isAllotmentUnlimited = dataItem?.numberOfAllotmentSectors === -1;
    const quoteItem = dataItem?.focusQuota;

    switch (tableHeader) {
      case 'concession':
        return dataItem?.displayName;
      case 'travelFromDate':
        return isAllotmentUnlimited ? en.common.empty : formatDateAsString(quoteItem?.startDate, DATE_FORMAT.ddmmmyyyy);
      case 'travelToDate':
        return isAllotmentUnlimited ? en.common.empty : formatDateAsString(quoteItem?.endDate, DATE_FORMAT.ddmmmyyyy);
      case 'allotedTicket':
        return isAllotmentUnlimited ? fieldLabel.unlimited : quoteItem?.quota;
      case 'used':
        return isAllotmentUnlimited ? en.common.empty : quoteItem?.used;
      case 'bookingPriority':
        return dataItem?.boardingPriority;
      case 'concessionStatus':
        return isAllotmentUnlimited ? fieldLabel.active : quoteItem?.status;
      default:
        return en.common.empty;
    }
  };

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          pr: item.key === 'concessionStatus' ? 3 : 2,
          flex: index + 1 === tableSchema.length || item.key === 'concession' ? 1.5 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
          width: '135px',
          ml: -2,
        },
        isHiddenField: false,
        sortingFunc: null,
      };
    });
  }, [filterData]);

  const transformedTableContents = useCallback(
    (item: any) => {
      const tmpData = _.cloneDeep(item) || {};
      if (!tmpData) {
        return [];
      }

      const temTableArray = [];
      temTableArray.push(tmpData);

      return temTableArray.map((dataItem: any) => {
        return {
          id: dataItem._id,
          concessionId: dataItem._id,
          sxProps: {
            display: 'flex',
            position: 'relative',
            '&:: after': {
              content: `""`,
              width: '28px',
              height: '28px',
              position: 'absolute',
              right: 0,
              top: '50%',
              marginTop: '-14px',
              backgroundSize: 'cover',
              backgroundImage: `url(${imgAdminTableArrowRight})`,
            },
          },
          columns: tableSchema.map(({ key }, index) => {
            const columnSxProps = {};
            const newValue = getTableContentValue(key, dataItem);

            return {
              columnkey: key,
              value: newValue,
              sxProps: {
                py: 1.25,
                pr: key === 'concessionStatus' ? 3 : 2,
                overflow: 'hidden',
                cursor: 'pointer',
                flex: index + 1 === tableSchema.length || key === 'concession' ? 1.5 : 1,
                display: 'flex',
                alignItems: 'center',
                width: '135px',
                ml: -2,
                ...columnSxProps,
              },
              isHiddenField: false,
              columnOnClick: dataItem?.handleColumnOnClick,
            };
          }),
          rowOnClick: () => {
            handleRowDataOnClick(dataItem);
          },
        };
      });
    },
    [filterName, filterGrouping, filterData],
  );

  const checkConcessionByQuota = (concession: IAdminConcession, allotmentType: keyof IAdminAllotmentType) => {
    return (
      concession.allotmentType === allotmentType &&
      concession.quotaList?.some((quota: IAdminQuotaList) => quota?.quotaType === allotmentType)
    );
  };

  useEffect(() => {
    // handle route back this page
    if (concessionGrouping) {
      setFilterGrouping(concessionGrouping);
    }

    if (concessionName?.dependentId !== undefined) {
      setFilterName({
        name: getAdminPersonalDisplayName({
          firstName: concessionName?.firstName,
          middleName: concessionName?.middleName,
          lastName: concessionName?.lastName,
        }),
        relationship: concessionName?.relationship,
        dependentId: concessionName?.dependentId,
        beneficiaryTypeCode: concessionName?.beneficiaryTypeCode,
        age: concessionName?.age,
        isAssignedOAL: concessionName?.isAssignedOAL,
        nominationType: concessionName?.nominationType,
      });
    }
  }, []);

  useEffect(() => {
    // when implement filter action
    let filterList: any[] = [];
    const filterConcessionType = CONCESSION_GROUPING.find((item) => item.code === filterGrouping)?.value;

    filterList = concessions?.filter((item: IAdminConcession) => {
      // check eligibilityDependent if contains filter user's beneficiaryTypeCode
      const eligibility =
        item?.eligibilityDependent?.some(
          (eligibility) =>
            eligibility?.beneficiaryType === filterName?.beneficiaryTypeCode &&
            checkAgeInEligibilityDependent(eligibility, filterName),
        ) &&
        checkEligibilityNominationType(item?.nominationType || [], filterName) &&
        checkEligibilityWithOALEligibility(item?.withOALEligibility || false, filterName);

      if (!eligibility) return false;

      if (item?.numberOfAllotmentSectors === -1) {
        // means no quotaList, only need to check allotmentType === filter concession group
        return filterConcessionType === '' ? true : item?.allotmentType === filterConcessionType;
      } else {
        if (filterConcessionType === '') {
          return checkConcessionByQuota(item, ALLOTMENT_TYPE.P) || checkConcessionByQuota(item, ALLOTMENT_TYPE.I);
        } else if (filterConcessionType === ALLOTMENT_TYPE.P) {
          return checkConcessionByQuota(item, ALLOTMENT_TYPE.P);
        } else if (filterConcessionType === ALLOTMENT_TYPE.I) {
          return checkConcessionByQuota(item, ALLOTMENT_TYPE.I);
        }
      }
    });

    setFilterData(filterList);
  }, [filterGrouping, filterName, concessions]);

  const tableSummary = useMemo(() => {
    return adminConcessionCarrierGroupSort(filterData, filterName).map((list: any) => {
      return (
        <>
          <Typography sx={{ mt: 4 }} variant="title_2_bold">
            {list.title}
          </Typography>
          {list.data.map((item: any, index: number) => {
            return (
              <>
                <BaseTable
                  key={index}
                  tableHeaders={index === 0 ? transformedTableHeaders : []}
                  tableHeadersSXProps={{ display: 'flex' }}
                  tableContents={transformedTableContents(item)}
                />
              </>
            );
          })}
        </>
      );
    });
  }, [filterData]);

  return (
    <Box
      className="concession_list"
      sx={{
        mt: 4,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Autocomplete
          options={concessionGroupingOptions}
          popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
          clearOnEscape
          disablePortal
          clearIcon={null}
          value={
            filterGrouping === ''
              ? concessionGroupingOptions[0]
              : concessionGroupingOptions.find((item) => item.code === filterGrouping)
          }
          sx={{
            width: '200px',
            height: '48px',
            float: 'left',
            mr: 2,
            '.MuiFilledInput-root': {
              height: UI_STYLES.concessionTravellerHeight,
              borderRadius: 0.5,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              background: theme.palette.primary.light,
              color: theme.color.secondary.dark_grey.option_3,
              paddingTop: '0px',
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
              '&.Mui-selected, &:hover, &.Mui-filled': {
                background: 'white',
              },
              '&.Mui-focused': {
                background: theme.palette.primary.light,
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
                '&.Mui-error': {
                  border: `2px solid ${theme.color.secondary.slate.option_3}`,
                },
              },
              '&.Mui-disabled': {
                background: theme.color.utility.unselectable.option_7,
              },
              '&: after, &: before': {
                display: 'none',
              },
              // input style
              '.MuiFilledInput-input': {
                mt: 1.5,
                fontWeight: FONT_WEIGHT.medium,
                fontSize: theme.typography.body_2_medium.fontSize,
                color: theme.color.secondary.dark_grey.option_1,
              },
            },
            '.MuiInputLabel-root': {
              top: '-2px',
              color: theme.color.secondary.grey.option_3,
              fontSize: theme.typography.body_2_medium.fontSize,
              '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
                color: theme.color.secondary.grey.option_3,
              },
            },
          }}
          onChange={(_, newValue) => {
            setFilterGrouping(newValue?.label || '');
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                height: UI_STYLES.concessionTravellerHeight,
              }}
              variant="filled"
              {...params}
              label={fieldLabel.concessionGrouping || ''}
            />
          )}
        />

        <Autocomplete
          options={nameOptions}
          popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
          clearOnEscape
          clearIcon={null}
          disablePortal
          value={
            filterName.dependentId === null
              ? nameOptions[0]
              : nameOptions.find((item) => item.value === filterName?.dependentId)
          }
          sx={{
            width: '240px',
            height: '48px',
            float: 'left',
            '.MuiFilledInput-root': {
              height: UI_STYLES.concessionTravellerHeight,
              borderRadius: 0.5,
              border: `2px solid ${theme.color.secondary.slate.option_3}`,
              background: theme.palette.primary.light,
              color: theme.color.secondary.dark_grey.option_3,
              paddingTop: '0px',
              '&.Mui-error': {
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
              },
              '&.Mui-selected, &:hover, &.Mui-filled': {
                background: 'white',
              },
              '&.Mui-focused': {
                background: theme.palette.primary.light,
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
                '&.Mui-error': {
                  border: `2px solid ${theme.color.secondary.slate.option_3}`,
                },
              },
              '&.Mui-disabled': {
                background: theme.color.utility.unselectable.option_7,
              },
              '&: after, &: before': {
                display: 'none',
              },
              // input style
              '.MuiFilledInput-input': {
                mt: 1.5,
                fontWeight: FONT_WEIGHT.medium,
                fontSize: theme.typography.body_2_medium.fontSize,
                color: theme.color.secondary.dark_grey.option_1,
              },
            },
            '.MuiInputLabel-root': {
              top: '-2px',
              color: theme.color.secondary.grey.option_3,
              fontSize: theme.typography.body_2_medium.fontSize,
              '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
                color: theme.color.secondary.grey.option_3,
              },
            },
          }}
          onChange={(_, newValue) => {
            const selectedDependent = nominationList.find(
              (item: IAdminNomination) => item.dependentId === newValue?.value,
            );

            setFilterName({
              name: newValue?.label,
              relationship:
                selectedDependent === undefined ? ADMIN_CONCESSION.EMPLOYEE : selectedDependent?.relationship,
              dependentId: selectedDependent === undefined ? null : selectedDependent?.dependentId,
              beneficiaryTypeCode:
                selectedDependent === undefined ? ADMIN_CONCESSION.EMP : selectedDependent?.beneficiaryTypeCode,
              age: selectedDependent === undefined ? null : selectedDependent?.age,
              isAssignedOAL: selectedDependent?.isAssignedOAL,
              nominationType: selectedDependent?.nominationType,
            });
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                height: UI_STYLES.concessionTravellerHeight,
              }}
              variant="filled"
              {...params}
              label={fieldLabel.name || ''}
            />
          )}
        />
      </Box>
      {tableSummary}
    </Box>
  );
};

export default ConcessionList;
