import { AUTH_API, HTTP_REQUEST_TYPE, USER_API, NOMINATION_API } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

const getProfile = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.personalInfo,
    apiTitle: en.errorAlert.getProfile,
    payload: { userId },
  });
  return resp;
};

const getPassport = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.passportInfo,
    apiTitle: en.errorAlert.getPassport,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getEndorser = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.getEndorser,
    payload: { userId },
  });
  return resp;
};

const getConcession = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.concession,
    apiTitle: en.errorAlert.getConcession,
    payload: { userId },
  });
  return resp;
};

const validateEndorser = async (endorsingStaffGalaCXyId: string, userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

const changeRole = async (id: string, type: string, needLoading?: boolean) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: AUTH_API.changeRole,
    apiTitle: en.errorAlert.changeRole,
    payload: { id, type },
    needLoading,
  });
  return resp;
};

const getRole = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: AUTH_API.getRole,
    apiTitle: en.errorAlert.getRole,
    payload: {},
  });
  return resp;
};

const getPaymentHistory = async (payload: any) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.paymentHistory,
    apiTitle: en.errorAlert.paymentHistory,
    payload,
  });

  return resp;
};

const getDependent = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.dependent,
    apiTitle: en.errorAlert.getDependent,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getAllotmentDetails = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.allotment,
    apiTitle: en.errorAlert.getAllotmentDetails,
    payload: { userId },
  });
  return resp;
};

const addCompanionRegistration = async ({
  userId,
  companionInfo,
  companionPassport,
}: {
  userId: string;
  companionInfo: {
    yearKnown?: number;
    monthKnown?: number;
    relationshipCode?: string;
    dependentId?: string;
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    gender?: string;
  };
  companionPassport?: {
    passportNumber: string;
    passportFirstName: string;
    passportLastName: string;
    passportExpirationDate: string;
    passportNationality: string;
    passportCountry: string;
  };
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.companionRegistration,
    apiTitle: en.errorAlert.addCompanionRegistration,
    payload: { userId, companionInfo, companionPassport },
  });
  return resp;
};

const editCompanionPassport = async (
  userId: string,
  requestParams: {
    dependentId: string;
    passportCountry: string;
    passportNumber: string;
    passportExpirationDate: string;
    passportNationality: string;
  },
) => {
  const { dependentId, passportCountry, passportNumber, passportExpirationDate, passportNationality } =
    requestParams || {};
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.editCompanionPassport,
    apiTitle: en.errorAlert.editCompanionPassport,
    payload: {
      userId,
      dependentId,
      passportCountry,
      passportNumber,
      passportExpirationDate,
      passportNationality,
    },
  });
  return resp;
};

const getNominationViewModeList = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: NOMINATION_API.nominationViewModeList,
    apiTitle: en.errorAlert.nominationViewModeList,
    payload: { userId },
    needLoading: false,
  });
  return resp;
};

const getNominationSelectionList = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: NOMINATION_API.nominationSelectionList,
    apiTitle: en.errorAlert.nominationSelectionList,
    payload: { userId },
    needLoading: true,
  });
  return resp;
};

const saveNomination = async (
  userId: string,
  requestParams: {
    nominationType: string;
    dependentList: {
      dependentId: string;
    }[];
  },
) => {
  const { nominationType, dependentList } = requestParams || {};
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: NOMINATION_API.saveNomination,
    apiTitle: en.errorAlert.saveNomination,
    payload: { userId, nominationType, dependentList },
    needLoading: true,
  });
  return resp;
};

const updateSetupCountryOfResidential = async (userId: string, countryOfResidential: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.countryOfResidential,
    apiTitle: en.errorAlert.updateSetupCountryOfResidential,
    payload: { userId, countryOfResidential },
  });
  return resp;
};

const validateEligibleUpdateCountryOfResidential = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.validateEligibleUpdateCountryOfResidential,
    apiTitle: en.errorAlert.updateSetupCountryOfResidential,
    payload: { userId },
  });
  return resp;
};

export {
  getProfile,
  getPassport,
  getEndorser,
  validateEndorser,
  getConcession,
  changeRole,
  getRole,
  getPaymentHistory,
  getDependent,
  getAllotmentDetails,
  addCompanionRegistration,
  editCompanionPassport,
  getNominationViewModeList,
  getNominationSelectionList,
  saveNomination,
  updateSetupCountryOfResidential,
  validateEligibleUpdateCountryOfResidential,
};
