import { Employee } from 'employee';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/store';

import {
  IAdminRole,
  IAllotmentDetails,
  IDelegatee,
  IDelegationRole,
  IDependent,
  IPaymentHistoryList,
  IPaymentHistorySearch,
  IPeriod,
  IUserConcessionProps,
  IUserRole,
} from '../interfaces';

import { getProfile, getPassport, getConcession, getDependent, getAllotmentDetails } from '../services/user';
import { getConcession as getConcessionAdmin } from '../services/admin/user';
import { getConcession as getConcessionDelegation } from '../services/delegation/bookingUser';

export const getProfileThunk = createAsyncThunk('user/getProfile', async (userId: string) => {
  return await getProfile(userId);
});

export const getPassportThunk = createAsyncThunk(
  'user/getPassport',
  async (params: { userId: string; expirationDate?: string }) => {
    return await getPassport(params.userId, params.expirationDate);
  },
);

export const getDependentThunk = createAsyncThunk(
  'user/getDependent',
  async (params: { userId: string; expirationDate?: string }) => {
    return await getDependent(params.userId, params.expirationDate);
  },
);

const transformConcessionThunkResult = (result: any) => {
  const { dutyTravel, leisureTravel } = result || {
    dutyTravel: [],
    leisureTravel: [],
  };
  return {
    dutyTravel,
    leisureTravel: leisureTravel?.concession || [],
    paxList: leisureTravel?.paxList || [],
    restrictionBeneficiaryType: leisureTravel?.restrictionBeneficiaryType || [],
  };
};

export const getConcessionThunk = createAsyncThunk('user/getConcession', async (userId: string) => {
  const result = await getConcession(userId);

  return transformConcessionThunkResult(result);
});

export const getConcessionAdminThunk = createAsyncThunk('user/getConcessionAdmin', async () => {
  const result = await getConcessionAdmin();
  return transformConcessionThunkResult(result);
});

export const getConcessionDelegationThunk = createAsyncThunk(
  'user/getConcessionDelegationThunk',
  async (userId: string) => {
    const result = await getConcessionDelegation(userId);
    return transformConcessionThunkResult(result);
  },
);

export const getAllotmentDetailsThunk = createAsyncThunk(
  'user/getAllotmentDetailsThunk',
  async (params: { userId: string }) => {
    return getAllotmentDetails(params.userId);
  },
);

interface IUserConcession {
  dutyTravel: IUserConcessionProps[];
  leisureTravel: IUserConcessionProps[];
  paxList: []; //TODO will use on next release
  restrictionBeneficiaryType: []; //TODO will use on next release
}

export interface IUserSlice extends IPaymentHistorySearch {
  role: IUserRole;
  adminRoles: IAdminRole[];
  profile: Employee.Profile | null;
  passports: Employee.PassportInfo[];
  concession: IUserConcession;
  delegatee: IDelegatee;
  allowDelegation: boolean;
  delegatedBy: IDelegationRole[];
  paymentHistoryList: IPaymentHistoryList[];
  isShowPaymentHistoryDialog: boolean;
  dependent: IDependent[];
  isLoadedDependent: boolean;
  allotment: IAllotmentDetails[];
  isShowSelectProfileDialog?: boolean;
  isShowProfileIndicator: boolean;
}

const initialState: IUserSlice = {
  role: {
    id: '',
    type: '',
    name: '',
  },
  adminRoles: [],
  profile: null,
  passports: [],
  concession: {
    dutyTravel: [],
    leisureTravel: [],
    paxList: [], //TODO may affect story 2043
    restrictionBeneficiaryType: [], //TODO will use on next release
  },
  delegatee: {
    employeeId: '',
    name: {
      preferredFirstName: '',
      firstName: '',
      lastName: '',
      middleName: '',
    },
    from: '',
    to: '',
  },
  allowDelegation: false,
  delegatedBy: [],
  paymentHistoryList: [],
  selectedPaymentHistoryPeriod: {},
  isShowPaymentHistoryDialog: false,
  dependent: [],
  isLoadedDependent: false,
  allotment: [],
  isShowSelectProfileDialog: undefined,
  isShowProfileIndicator: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRole: (state, { payload }: PayloadAction<IUserRole>) => {
      state.role = payload;
    },
    setAdminRoles: (state, { payload }: PayloadAction<IAdminRole[]>) => {
      state.adminRoles = payload;
    },
    setAllowDelegation: (state, { payload }: PayloadAction<boolean>) => {
      state.allowDelegation = payload;
    },
    setDelegatedBy: (state, { payload }: PayloadAction<IDelegationRole[]>) => {
      state.delegatedBy = payload;
    },
    setPaymentHistory: (state, { payload }: PayloadAction<IPaymentHistoryList[]>) => {
      state.paymentHistoryList = payload;
    },
    setSelectedPaymentHistoryPeriod: (state, { payload }: PayloadAction<IPeriod>) => {
      state.selectedPaymentHistoryPeriod = payload;
    },
    setIsShowPaymentHistoryDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPaymentHistoryDialog = payload;
    },
    reset: () => initialState,
    resetPaymentHistorySearch: (state) => {
      state.selectedPaymentHistoryPeriod = initialState.selectedPaymentHistoryPeriod;
    },
    resetPaymentHistoryPage: (state) => {
      state.paymentHistoryList = initialState.paymentHistoryList;
      state.selectedPaymentHistoryPeriod = initialState.selectedPaymentHistoryPeriod;
    },
    setIsLoadedDependent: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadedDependent = payload;
    },
    setProfile: (state, { payload }: PayloadAction<Employee.Profile>) => {
      state.profile = payload;
    },
    setIsShowSelectProfileDialog: (state, { payload }: PayloadAction<boolean>) => {
      // etp-5088 add: Show Switch Retiree Profile Dialog:
      // After login success, only show dialog when checked the profile retiree + have special profile data
      state.isShowSelectProfileDialog = payload;
    },
    setIsShowProfileIndicator: (state, { payload }: PayloadAction<boolean>) => {
      // etp-5088 add: After selected profile,home page will show current using profile
      state.isShowProfileIndicator = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileThunk.pending, (state) => {
        state.profile = initialState.profile;
      })
      .addCase(getPassportThunk.pending, (state) => {
        state.passports = initialState.passports;
      })
      .addCase(getDependentThunk.pending, (state) => {
        state.dependent = initialState.dependent;
      })
      .addCase(getAllotmentDetailsThunk.pending, (state) => {
        state.allotment = initialState.allotment;
      })
      .addCase(getProfileThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profile = payload;
        }
      })
      .addCase(getPassportThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.passports = payload;
        }
      })
      .addCase(getConcessionThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.concession = payload;
        }
      })
      .addCase(getConcessionAdminThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.concession = payload;
        }
      })
      .addCase(getConcessionDelegationThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.concession = payload;
        }
      })
      .addCase(getDependentThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.dependent = payload;
          state.isLoadedDependent = true;
        }
      })
      .addCase(getAllotmentDetailsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.allotment = payload;
        }
      });
  },
});

const {
  reset,
  resetPaymentHistoryPage,
  setRole,
  setAdminRoles,
  setAllowDelegation,
  setDelegatedBy,
  setPaymentHistory,
  setSelectedPaymentHistoryPeriod,
  setIsShowPaymentHistoryDialog,
  resetPaymentHistorySearch,
  setIsLoadedDependent,
  setProfile,
  setIsShowSelectProfileDialog,
  setIsShowProfileIndicator,
} = userSlice.actions;

const selectUser = (state: RootState) => state.user;

export {
  reset,
  resetPaymentHistoryPage,
  selectUser,
  setRole,
  setAdminRoles,
  setAllowDelegation,
  setDelegatedBy,
  setPaymentHistory,
  setSelectedPaymentHistoryPeriod,
  setIsShowPaymentHistoryDialog,
  resetPaymentHistorySearch,
  initialState,
  setIsLoadedDependent,
  setProfile,
  setIsShowSelectProfileDialog,
  setIsShowProfileIndicator,
};

export default userSlice.reducer;
