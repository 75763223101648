import { Box, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DATE_UNIT,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  ID_TICKET_TYPE,
  SAVE_DATA_TO,
  TRAVEL_PURPOSE,
  UI_STYLES,
  USER_ROLE,
} from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';
import en from '../../../../translations/en';

import { IButtonValue, ILtSelectedConcessionDetailType, ITravellersOptions } from '../../../../interfaces';

import { isRetireeWidow, getTravelTypeSelectionList, isHideLtFeatures } from '../../../../helpers';
import { useLtTravellerCount } from '../../../../hooks';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import { selectApp } from '../../../../slice/appSlice';
import {
  leisureTravelDetailInitialState,
  selectBooking,
  setDetail,
  setLeisureTravelDetails,
  setType,
  travelDetailInitialState,
} from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';

import { EditSearchBarAction, EditSearchBarState, TravelTypeListLtAction } from '../../../../context';

import { DtConcessionSection, LtConcessionSection, TravelPurposeToggleGroup } from '../../..';
import { Footer, ScrollableView } from '../../../../components';

const ConcessionSelectionContainer = ({
  isMobile = false,
  isSearchBar,
  saveTo = SAVE_DATA_TO.redux,
  handleFooterClick,
  isShowOverlay = false,
}: {
  isMobile?: boolean;
  isSearchBar?: boolean;
  saveTo?: string;
  handleFooterClick?: () => void;
  isShowOverlay?: boolean;
}) => {
  const history = useHistory();
  const theme = useTheme();
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const editSearchData = useContext(EditSearchBarState) || {};
  const { setEditTravelDetails, setEditLeisureTravelDetails, setEditTravelType } =
    useContext(EditSearchBarAction) || {};
  const { setIsShowDefaultTravelTypeOption } = useContext(TravelTypeListLtAction);

  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;

  // In this file `travelType` data meaning (from Redux or context ): backend side travel purpose like ELT, EDT..etc
  const { startDate, travelType, travelDetails, leisureTravelDetails } = isSearchResultPage
    ? editSearchData
    : useAppSelector(selectBooking) || {};
  const { concession, role, profile } = useAppSelector(selectUser) || {};

  const [currentTravelType, setCurrentTravelType] = useState(travelType || TRAVEL_PURPOSE.employeeLeisureTravel);
  const [dtCurrentDetails, setDtCurrentDetails] = useState(travelDetails || {});
  const defaultLtCurrentDetails = {
    id: '',
    label: '',
    fareType: '',
    type: '',
    sub: '',
    carrier: '',
    carrierGroup: '',
    concessionRuleId: '',
  };
  const [ltCurrentDetails, setLtCurrentDetails] = useState<ILtSelectedConcessionDetailType>(
    leisureTravelDetails
      ? {
          id: leisureTravelDetails.id,
          label: leisureTravelDetails.label, // for display used
          fareType: leisureTravelDetails.fareType, // ZED, ID
          type: leisureTravelDetails.type, // ZONE/FOC/ID50
          sub: leisureTravelDetails.sub, // NOSUB|SUBLO
          carrier: leisureTravelDetails.carrier, // `CX` currently
          carrierGroup: leisureTravelDetails.carrierGroup, // CX|OW|OAL
          concessionRuleId: leisureTravelDetails.concessionRuleId,
          allowSeatForInfant: leisureTravelDetails.allowSeatForInfant,
        }
      : defaultLtCurrentDetails,
  );

  // TODO: adding in OtherTravelType
  const isDT = (isMobile ? currentTravelType : travelType) === TRAVEL_PURPOSE.employeeDutyTravel;
  const isLT = (isMobile ? currentTravelType : travelType) === TRAVEL_PURPOSE.employeeLeisureTravel;

  // handle delegation role + empty LT concession data
  const isHideLtFeaturesControl = useMemo(() => {
    return isHideLtFeatures({
      isDelegation: role.type === USER_ROLE.delegation,
      isEmptyLtConcession: concession?.leisureTravel?.length === 0,
    });
  }, [role, concession?.leisureTravel]);

  const travelTypeSelectionList = useMemo(() => {
    if ((profile && isRetireeWidow(profile)) || concession?.dutyTravel?.length === 0) {
      return getTravelTypeSelectionList(false, true);
    }
    return getTravelTypeSelectionList(isHideLtFeaturesControl);
  }, [isHideLtFeaturesControl]);

  // etp-3017 add
  const [isAllowSeatForInfant, setIsAllowSeatForInfant] = useState<boolean>(!!ltCurrentDetails.allowSeatForInfant);

  // custom hook for LT traveller count
  const { ltTravellerCountHook, setLtTravellerCountHook, travellersOptions } = useLtTravellerCount({
    isSearchResultPage,
    isMobile,
    isAllowSeatForInfant,
  });

  const checkConcessionSelected = (): boolean => {
    if (isDT && dtCurrentDetails?.id === '') return false;

    if (isLT) {
      // TBC
      const calcInfantsCount = isAllowSeatForInfant ? ltTravellerCountHook.infantsCount : 0;
      const totalTravellerCount =
        ltTravellerCountHook.adultCount + ltTravellerCountHook.childrenCount + calcInfantsCount;

      if (!(ltCurrentDetails?.id && totalTravellerCount > 0)) return false;
      if (ltCurrentDetails?.id === '-1') return false;
    }

    return true;
  };

  const isFooterValid = isMobile && checkConcessionSelected();

  const saveSelectedConcession = ({
    ltUpdatedObj,
    dtUpdatedObj,
  }: {
    ltUpdatedObj: ILtSelectedConcessionDetailType;
    dtUpdatedObj: any;
  }) => {
    if (isMobile) {
      setLtCurrentDetails({
        ...ltUpdatedObj,
      });
    } else if (isSearchResultPage) {
      setLtCurrentDetails({
        ...ltUpdatedObj,
      });

      setEditLeisureTravelDetails({
        ...leisureTravelDetails,
        ...ltUpdatedObj,
      });

      // set new travel detail to context
      setEditTravelDetails({
        ...travelDetailInitialState,
        ...dtUpdatedObj,
      });
    } else {
      setLtCurrentDetails({
        ...ltUpdatedObj,
      });

      // desktop, non-search result page.
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          ...ltUpdatedObj,
        }),
      );

      // set new travel detail to redux
      dispatch(
        setDetail({
          ...travelDetails,
          ...dtUpdatedObj,
        }),
      );
    }
  };

  const ltConcessionItemOnChange = (value: IButtonValue) => {
    const { val: selectedConcessionValue, id } = value || {};
    const ltUpdatedObj: ILtSelectedConcessionDetailType = {
      id,
      label: selectedConcessionValue.label,
      sub: selectedConcessionValue.sub,
      fareType: selectedConcessionValue.fareType,
      type: selectedConcessionValue.type,
      carrier: selectedConcessionValue.carrier,
      carrierGroup: selectedConcessionValue.carrierGroup,
      concessionRuleId: selectedConcessionValue.concessionRuleId,
      allowSeatForInfant: !!selectedConcessionValue.allowSeatForInfant,
      advanceBookingDay: selectedConcessionValue?.advanceBookingDay,
    };

    const dtUpdatedObj = {
      id: '',
      title: '', // clear title for `SearchBar` component display LT label on <FormPopover>
      advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
      advanceDayUnit: DATE_UNIT.DYS,
    };

    saveSelectedConcession({
      ltUpdatedObj,
      dtUpdatedObj,
    });

    if (selectedConcessionValue.allowSeatForInfant !== undefined) {
      setIsAllowSeatForInfant(!!selectedConcessionValue.allowSeatForInfant);

      // selected concession's `allowSeatForInfant` is false, then clear infant with seat count
      if (!selectedConcessionValue.allowSeatForInfant) {
        if (isDesktop) {
          if (!isSearchResultPage) {
            dispatch(
              setLeisureTravelDetails({
                ...leisureTravelDetails,
                ...ltUpdatedObj,
                infantsCount: 0,
              }),
            );
          }
        } else {
          setLtTravellerCountHook({
            ...ltTravellerCountHook,
            infantsCount: 0,
          });
        }
      }
    }
  };

  // clear useState value when redux data updated
  const clearUseStateData = () => {
    if (isDT) {
      setLtCurrentDetails(defaultLtCurrentDetails);
      setLtTravellerCountHook({
        adultCount: 1,
        childrenCount: 0,
        infantsCount: 0,
        infantsWithoutSeatCount: 0,
      });

      setEditLeisureTravelDetails({
        ...leisureTravelDetailInitialState,
        adultCount: 1,
      });
    } else if (isLT) {
      const advanceDayObj = {
        advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
        advanceDayUnit: DATE_UNIT.DYS,
      };
      setDtCurrentDetails({
        ...travelDetailInitialState,
        ...advanceDayObj,
      });

      // update default advance booking days
      setEditTravelDetails({
        ...travelDetailInitialState,
        ...advanceDayObj,
      });
    }
  };

  /**
   * Special Handling 4 cases:
   * 1. Save DT data to context
   * 2. Save LT data to context
   * 3. Save DT data to redux
   * 4. Save LT data to redux
   */
  const saveData = () => {
    // handle mobile display default LT concession option when first time open `ConcessionSelectionContainer` and continue
    if (!isDesktop) {
      setIsShowDefaultTravelTypeOption(true);
    }

    // mobile ver
    if (saveTo === SAVE_DATA_TO.context) {
      setEditTravelType?.(currentTravelType);

      // 1. Save DT data to context
      isDT && setEditTravelDetails?.(dtCurrentDetails);

      // 2. Save LT data to context
      if (isLT) {
        setEditLeisureTravelDetails?.({
          ...ltCurrentDetails,
          adultCount: ltTravellerCountHook.adultCount,
          childrenCount: ltTravellerCountHook.childrenCount,
          infantsCount: ltTravellerCountHook.infantsCount,
          infantsWithoutSeatCount: ltTravellerCountHook.infantsWithoutSeatCount,
        });
      }
      // 3. preset LT max advance booking day on mobile view
      if (isLT) {
        setEditTravelDetails({
          ...travelDetailInitialState,
          id: '',
          title: '', // clear title for `SearchBar` component display LT label on <FormPopover>
          advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
          advanceDayUnit: DATE_UNIT.DYS,
        });
      }
    } else {
      dispatch(setType(currentTravelType));

      // 3. Save DT data to redux
      isDT && dispatch(setDetail(dtCurrentDetails));

      if (isLT) {
        // 4. Save LT data to redux
        dispatch(
          setLeisureTravelDetails({
            ...ltCurrentDetails,
            adultCount: ltTravellerCountHook.adultCount,
            childrenCount: ltTravellerCountHook.childrenCount,
            infantsCount: ltTravellerCountHook.infantsCount,
            infantsWithoutSeatCount: ltTravellerCountHook.infantsWithoutSeatCount,
            /* adultCount: editSearchData.leisureTravelDetails.adultCount,
            childrenCount: editSearchData.leisureTravelDetails.childrenCount,
            infantsCount: editSearchData.leisureTravelDetails.infantsCount,
            infantsWithoutSeatCount:
              editSearchData.leisureTravelDetails.infantsWithoutSeatCount, */
          }),
        );

        // 5. If selected LT concession (ZONE), set advance day to be 180 days
        dispatch(
          setDetail({
            advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
            advanceDayUnit: DATE_UNIT.DYS,
            empOrNonEmpType: '',
            id: '',
            subTitle: '',
            title: '',
            value: '',
          }),
        );
      }
    }

    clearUseStateData();
    handleFooterClick && handleFooterClick();
  };

  // handle desktop display default LT concession option when first time open `ConcessionSelectionContainer`
  useEffect(() => {
    if (isDesktop) {
      setIsShowDefaultTravelTypeOption(true);
    }
  }, [isDesktop]);

  // Pre select item when only 1 selection item exist
  useEffect(() => {
    if (travelTypeSelectionList?.length === 1) {
      const selectionItemID = travelTypeSelectionList[0].id;
      if (isMobile) {
        setCurrentTravelType(selectionItemID);
      } else if (isSearchResultPage) {
        setEditTravelType(selectionItemID);
      } else {
        dispatch(setType(selectionItemID));
      }
    }
  }, [travelTypeSelectionList]);

  useEffect(() => {
    if (ltCurrentDetails?.sub === ID_TICKET_TYPE.NOSUB && isDesktop) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [ltCurrentDetails]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: isDesktop ? '100%' : `calc(100dvh - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
        ...(isSearchBar
          ? {
              bgcolor: 'white',
              borderRadius: '8px',
            }
          : {
              bgcolor: theme.backgroundColor.wheat,
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
            }),
      }}
    >
      <Box
        sx={{
          height: '100%',
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          border: 'none',
        }}
      >
        {/* Select DT or LT as travel purpose */}
        {concession.dutyTravel.length === 0 && concession.leisureTravel.length === 0 ? (
          <></>
        ) : (
          <TravelPurposeToggleGroup
            list={travelTypeSelectionList}
            itemOnChange={(value: IButtonValue) => {
              //TODO for desktop search result page...need to edit travel type to LT type.

              if (isMobile) {
                setCurrentTravelType(value.id);
              } else if (isSearchResultPage) {
                setEditTravelType(value.id);
              } else {
                dispatch(setType(value.id));
              }
            }}
            defaultVal={isMobile ? currentTravelType : travelType}
          />
        )}

        <Box
          component={ScrollableView}
          sx={{
            flex: 1,
            overflowY: 'scroll',
          }}
          ref={scrollViewRef}
        >
          <Box px={1} mb={2}>
            {/* DT */}
            {isDT && (
              <DtConcessionSection
                concession={concession?.dutyTravel}
                defaultConcessionValue={isMobile ? dtCurrentDetails?.id : travelDetails?.id}
                concessionOnChange={(value: IButtonValue) => {
                  const valueObj = {
                    id: value.id,
                    ...value.val,
                  };
                  if (isMobile) {
                    setDtCurrentDetails({
                      ...dtCurrentDetails,
                      ...valueObj,
                    });
                  } else if (isSearchResultPage) {
                    setEditTravelDetails({
                      ...travelDetailInitialState,
                      ...valueObj,
                    });
                  } else {
                    // dekstop + non search result page
                    dispatch(
                      setDetail({
                        ...travelDetails,
                        ...valueObj,
                      }),
                    );
                  }
                }}
                startDate={startDate}
              />
            )}

            {/* LT */}
            {isLT && (
              <LtConcessionSection
                ltCurrentDetails={ltCurrentDetails}
                selectedTypeId={isMobile ? ltCurrentDetails?.id : leisureTravelDetails?.id || '0'}
                isDesktop={isDesktop}
                ltFareType={isDesktop ? leisureTravelDetails?.fareType : ltCurrentDetails?.fareType}
                concessionOnChange={ltConcessionItemOnChange}
                travellersOptions={
                  isAllowSeatForInfant
                    ? travellersOptions
                    : travellersOptions.filter(
                        (item: ITravellersOptions) =>
                          item.title !== en.booking.travelType.leisureTravel.infantsWithSeat,
                      )
                }
                isShowConcessionOverlay={isShowOverlay}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* [mobile] Continue button */}
      {isFooterValid && (
        <Footer
          primaryBtn={{
            isFullWidth: true,
            text: en.common.continue,
            route: BOOKING_PATHS.booking,
            customOnClick: saveData,
          }}
        />
      )}
    </Box>
  );
};

ConcessionSelectionContainer.defaultProps = {
  isMobile: true,
  isSearchBar: false,
};

export default ConcessionSelectionContainer;
