import { Field } from 'redux-form';

import en from '../../translations/en';

import { getLocalDate } from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

import { ReduxFormDatePicker } from '.';

const DateOfBirthDatePicker = ({ isPositionUnset = false }: { isPositionUnset?: boolean }) => {
  const { isDesktop } = useAppSelector(selectApp) || {};

  const commonLbl = en.common;

  const today = getLocalDate();

  return (
    <Field
      component={ReduxFormDatePicker}
      name="dateOfBirth"
      title={commonLbl.formFields.dateOfBirth}
      calendarDefaultDate={today}
      maxDate={today}
      {...(isPositionUnset && {
        containerStyles: {
          ...(isDesktop && {
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: `rgba(0,0,0,.5)`,
            zIndex: 120,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
        },
      })}
      {...(isPositionUnset && {
        contentStyles: {
          ...(isDesktop
            ? {
                position: 'unset',
              }
            : { top: 0 }),
        },
      })}
    />
  );
};

export default DateOfBirthDatePicker;
