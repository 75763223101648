import React, { useState, useContext } from 'react';
import { Collapse, Typography, useTheme, Box, SxProps, Theme } from '@mui/material';
import { reset } from 'redux-form';

import { NominatedIcon } from '../../../assets/images';
import en from '../../../translations/en';
import { INFINITE_DATE, FORM, LABEL_CATEGORY } from '../../../constants/constants';

import { IDependent } from '../../../interfaces';
import {
  compareDate,
  findDropdownOptionClient,
  getDisplayName,
  getDisplayVal,
  formatDMYDateWithSpace,
  getDisplayLabel,
} from '../../../helpers';

import { NominationAction, NominationState } from '../../../context';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

type TExpandedDataItem = string | boolean;

interface IExpandedData {
  leftSideLabel?: TExpandedDataItem;
  rightSideLabel?: TExpandedDataItem;
  leftSideValue?: TExpandedDataItem;
  rightSideValue?: TExpandedDataItem;
  isAllowEdit?: boolean;
  originDependentData?: IDependent;
  title?: string;
}

interface IFieldRowData {
  label: TExpandedDataItem;
  value: TExpandedDataItem;
  fieldCustomStyles?: SxProps<Theme>;
}

const dependentLbl = en.userProfile.dependent;

const RenderFieldRow = ({ fieldRowData }: { fieldRowData: IFieldRowData[] }) => {
  const theme = useTheme();
  return (
    <>
      {fieldRowData.map((field, index) => {
        return (
          field.label && (
            <Box className="field_row_item" key={index} sx={{ width: '50%', ...field.fieldCustomStyles }}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {field.label}
              </Typography>
              <Typography variant="body_1_medium">{field.value}</Typography>
            </Box>
          )
        );
      })}
    </>
  );
};

const RenderHorizontalView = ({
  title,
  leftSideValue,
  leftSideLabel,
  rightSideValue,
  rightSideLabel,
  isAllowEdit = false,
  originDependentData,
}: IExpandedData) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};
  const { companionRegistrationFlow } = useContext(NominationState) || {};

  const { configurations } = useAppSelector(selectConfiguration) || {};

  if (title) {
    return (
      <Box className="dependent_details_card_title" sx={{ display: 'flex' }}>
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 1.75,
            mt: 2,
          }}
        >
          {title}
        </Typography>

        {isAllowEdit && (
          <Typography
            variant="body_2_bold"
            sx={{
              color: theme.color.utility.link.option_3,
              mt: 2,
              cursor: 'pointer',
              textAlign: 'end',
              ml: 'auto',
            }}
            onClick={() => {
              // reset form first
              dispatch(reset(FORM.companionPassport));

              // mapping the nationality, country with configuration codelabel data, to set an object with `code` and `label`.
              const mappedPassportNationality = findDropdownOptionClient(
                configurations.countries,
                originDependentData?.passportNationality,
              );
              const mappedPassportCountry = findDropdownOptionClient(
                configurations.countries,
                originDependentData?.passportCountry,
              );

              // save passport data to context
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: {
                  ...companionRegistrationFlow.editPassport,
                  isEditMode: true,
                  dependentId: originDependentData?.dependentId,
                  initFormData: {
                    passportNumber: originDependentData?.passportNumber,
                    passportFirstName: originDependentData?.firstName,
                    passportLastName: originDependentData?.lastName,
                    passportExpirationDate: originDependentData?.passportExpirationDate,
                    passportNationality: mappedPassportNationality,
                    passportCountry: mappedPassportCountry,
                  },
                },
              });
            }}
          >
            {en.common.edit}
          </Typography>
        )}
      </Box>
    );
  }

  if (!leftSideLabel && !rightSideLabel) {
    return <></>;
  }

  const transformedFieldRowData: IFieldRowData[] = [
    {
      label: leftSideLabel || '',
      value: getDisplayVal(leftSideValue),
      fieldCustomStyles: {
        minWidth: '130px',
        width: rightSideLabel ? '50%' : '100%',
      },
    },
    {
      label: rightSideLabel || '',
      value: getDisplayVal(rightSideValue),
      fieldCustomStyles: {
        minWidth: '130px',
        pl: 1,
      },
    },
  ];

  return (
    <Box
      className="dependent_details_card_horizontal_view"
      sx={{
        display: 'flex',
        mb: 2,
      }}
    >
      <RenderFieldRow fieldRowData={transformedFieldRowData} />
    </Box>
  );
};

const DependentDetailsCard = ({ data, isNominatedList }: { data: IDependent; isNominatedList: boolean }) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const [isExpand, setIsExpand] = useState(false);

  const passportFieldLabel = dependentLbl.lastNameAsInPassport;
  const passportFieldValue = data.passportName.lastName;

  const expandedData: IExpandedData[] = [
    {
      title: dependentLbl.dependentDetails,
      isAllowEdit: false,
    },
    {
      leftSideLabel: dependentLbl.relationship,
      leftSideValue: data.relationship,
      rightSideLabel: dependentLbl.nomineeType,
      // display `--` when the data is not nominated
      rightSideValue: isNominatedList
        ? getDisplayLabel(configurations.labels, LABEL_CATEGORY.nomineeType, data.nominationType)
        : '',
    },
    {
      leftSideLabel: dependentLbl.nominationStatus,
      leftSideValue: data.isNominationActive ? dependentLbl.currentNominee : dependentLbl.pastNominee,
      rightSideLabel: dependentLbl.dependentStatus,
      rightSideValue: data.isActive ? dependentLbl.active : dependentLbl.inActive,
    },
    {
      leftSideLabel: dependentLbl.dateOfBirth,
      leftSideValue: formatDMYDateWithSpace(data.dateOfBirth),
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      leftSideLabel: dependentLbl.nominationDate,
      leftSideValue: `${getDisplayVal(formatDMYDateWithSpace(data.nominationFrom))} - ${
        compareDate(getDisplayVal(data.nominationTo), INFINITE_DATE) > 0
          ? en.userProfile.delegation.noDueDate
          : getDisplayVal(formatDMYDateWithSpace(data.nominationTo))
      }`,
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      title: dependentLbl.passportDetails,
      isAllowEdit: data.isAllowEdit || false,
    },
    {
      leftSideLabel: dependentLbl.firstOtherNameAsInPassport,
      leftSideValue: data.passportName.firstName,
      // only display in desktop view
      rightSideLabel: isDesktop ? passportFieldLabel : false,
      rightSideValue: isDesktop ? passportFieldValue : false,
    },
    // only display in mobile view
    {
      leftSideLabel: !isDesktop ? passportFieldLabel : false,
      leftSideValue: !isDesktop ? passportFieldValue : false,
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      leftSideLabel: dependentLbl.passportNumber,
      leftSideValue: data.passportNumber,
      rightSideLabel: dependentLbl.passportExpiryDate,
      rightSideValue: formatDMYDateWithSpace(data.passportExpirationDate),
    },
    {
      leftSideLabel: dependentLbl.nationalityInPassport,
      leftSideValue: findDropdownOptionClient(configurations?.countries, data.passportNationality || '')?.label,
      rightSideLabel: dependentLbl.issuedCountry,
      rightSideValue: findDropdownOptionClient(configurations?.countries, data.passportCountry || '')?.label,
    },
  ];

  const defaultFieldRowData: IFieldRowData[] = [
    {
      label: dependentLbl.name,
      value: getDisplayName({
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
      }),
    },
    {
      label: dependentLbl.type,
      value: getDisplayVal(data.beneficiaryType),
      fieldCustomStyles: {
        pl: 1,
      },
    },
  ];

  return (
    <Box
      className="dependent_details_card"
      sx={{
        boxShadow: theme.palette.boxShadow.dark,
        borderRadius: 1,
        p: 2,
        mt: 1,
        bgcolor: isNominatedList ? theme.palette.bgColor.main : theme.color.secondary.light_slate.option_6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <RenderFieldRow fieldRowData={defaultFieldRowData} />

        {isNominatedList ? (
          <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />
        ) : (
          <Box sx={{ width: '20px' }} />
        )}
      </Box>
      <Collapse in={isExpand} collapsedSize={`${17 * 2}px`}>
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.utility.link.option_3,
            mt: 2,
            cursor: 'pointer',
            textAlign: 'end',
          }}
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? en.common.viewLess : data.isAllowEdit ? en.common.viewMoreAndEdit : en.common.viewMore}
        </Typography>

        {isExpand &&
          expandedData?.map((item: IExpandedData, index: number) => {
            return <RenderHorizontalView key={index} {...item} originDependentData={data} />;
          })}
      </Collapse>
    </Box>
  );
};

export default DependentDetailsCard;
