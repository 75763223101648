import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { Employee } from 'employee';

import theme from '../../../style/theme';
import en from '../../../translations/en';
import { DATE_FORMAT, USER_PROFILE_DETAILS } from '../../../constants/constants';

import { findDropdownOptionClient, getDisplayVal, formatDateAsString, isRetireeWidow } from '../../../helpers';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectAuth } from '../../../slice/authSlice';
import { selectUser, getPassportThunk } from '../../../slice/userSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { IInformationListItem } from '../Common/InformationList';
import { InformationList } from '../..';

const PassportContent = ({ passport, title }: { passport?: Employee.PassportInfo; title: string }) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const defaultPassport: IInformationListItem[] = [
    {
      key: en.userProfile.passport.firstName,
      value: getDisplayVal(passport?.passportFirstName),
    },
    {
      key: en.userProfile.passport.lastName,
      value: getDisplayVal(passport?.passportLastName),
    },
    {
      key: en.userProfile.passport.number,
      value: getDisplayVal(passport?.passportNumber),
    },
    {
      key: en.userProfile.passport.expiryDate,
      value: formatDateAsString(
        getDisplayVal(passport?.passportExpirationDate),
        DATE_FORMAT.ddmmmyyyy,
        DATE_FORMAT.date,
      ),
    },
    {
      key: en.userProfile.passport.nationality,
      value: findDropdownOptionClient(configurations?.countries, passport?.passportNationality)?.label,
    },
    {
      key: en.userProfile.passport.issuedCountry,
      value: findDropdownOptionClient(configurations?.countries, passport?.passportCountry || '')?.label,
    },
  ];

  return <InformationList key={title} title={title} listData={defaultPassport} />;
};

const PassportDetailsContainer = () => {
  const dispatch = useAppDispatch();
  const { passports, profile } = useAppSelector(selectUser) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const title = en.userProfile.passport.defaultPassport;
  const { isDesktop } = useAppSelector(selectApp) || {};

  useEffect(() => {
    dispatch(getPassportThunk({ userId: ern }));
    return () => {};
  }, [dispatch]);

  return (
    <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
      {passports && passports.length > 0 ? (
        passports.map((passport: Employee.PassportInfo) => {
          return <PassportContent key={title} title={title} passport={passport} />;
        })
      ) : (
        <PassportContent key={title} title={title} />
      )}
      <Typography
        variant="body_2_regular"
        sx={{
          mt: 5,
          color: theme.color.secondary.dark_grey.option_3,
          width: { sm: USER_PROFILE_DETAILS.width, xs: 'auto' },
        }}
      >
        {profile && isRetireeWidow(profile)
          ? en.userProfile.passport.updateMessage
          : en.userProfile.passport.noticeMessage}
      </Typography>
    </Box>
  );
};

export default PassportDetailsContainer;
