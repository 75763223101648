import React, { useContext, useEffect, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { LABEL_CATEGORY, DATE_FORMAT, USER_PROFILE_DETAILS, PAYMENT_MEDIUM } from '../../../constants/constants';

import { IInformationListItem } from '../../../containers/UserProfile/Common/InformationList';

import {
  getDisplayLabel,
  findDropdownOptionClient,
  formatDateAsString,
  getDisplayVal,
  getDisplayName,
  isRetireeWidow,
  formatProfileYOS,
  trimRetireeSpecialEmployeeId,
} from '../../../helpers';

import { selectAuth } from '../../../slice/authSlice';
import { selectUser, getProfileThunk } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectApp } from '../../../slice/appSlice';
import { setIsOpenBillingAgreementDialog } from '../../../slice/paypalSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { usePaymentMethodIndicator } from '../../../hooks';
import { CountryOfResidenceAction } from '../../../context/CountryOfResidenceContext';

import { CommonWarning } from '../../../components';
import { InformationList } from '../../../containers';
import UpdateSetupCountryOfResidenceFlowContainer from '../UpdateSetupCountryOfResidenceFlowContainer/UpdateSetupCountryOfResidenceFlowContainer';

const BasicDetailsContainer = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUser);
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration);
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  useEffect(() => {
    dispatch(getProfileThunk(ernFromLogin));
    return () => {};
  }, [dispatch]);

  const {
    employeeId,
    personalInfo,
    company,
    companySeniorityDate,
    activeBenefitDTJobLevel,
    activeBenefitLTJobLevel,
    jobLevel,
    costCentre,
    retireeWidow,
    yearOfService,
    yearOfServiceByAdmin,
  } = profile || {};

  const { firstName, lastName, middleName, countryOfResidential } = personalInfo || {};

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  const listDataBasicInfo: IInformationListItem[] = [
    {
      key: en.userProfile.personal.basicInfo.firstName,
      value: getDisplayName({ firstName, middleName }),
    },
    {
      key: en.userProfile.personal.basicInfo.lastName,
      value: getDisplayVal(lastName),
    },
    {
      key: en.userProfile.personal.countryOfResidence,
      value: findDropdownOptionClient(
        configurations.countries,
        profile && retireeWidow && isRetireeWidow(profile)
          ? retireeWidow.countryOfResidential
          : countryOfResidential || undefined,
      )?.label,
      isShowWarning: (paymentMethodIndicator?.isRetireeWidowRole && paymentMethodIndicator?.isShowWarning) || false,
    },
    {
      key: en.userProfile.personal.basicInfo.ern,
      value: getDisplayVal(employeeId && trimRetireeSpecialEmployeeId(employeeId)),
    },
    {
      key: en.userProfile.personal.basicInfo.company,
      value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.company, company),
    },
    {
      key: en.userProfile.personal.basicInfo.companySeniorityDate,
      value: formatDateAsString(getDisplayVal(companySeniorityDate), DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
    },
    {
      key: en.userProfile.personal.basicInfo.benefitDutyJobLevel,
      value: getDisplayVal(`${en.userProfile.personal.basicInfo.level} ${activeBenefitDTJobLevel || jobLevel}`),
    },
    {
      key: en.userProfile.personal.basicInfo.costCentre,
      value: getDisplayVal(costCentre),
    },
    {
      key: en.userProfile.personal.basicInfo.benefitLeisureJobLevel,
      value: `${en.userProfile.personal.basicInfo.level} ${activeBenefitLTJobLevel || jobLevel}`,
    },
    //TODO: Release 2 add back leisure travel job level
    // {
    //   key: en.userProfile.personal.basicInfo.benefitLeisureJobLevel,
    //   value: `${en.userProfile.personal.basicInfo.level} ${
    //     activeBenefitLTJobLevel || jobLevel
    //   }`,
    // },
    // {
    //   key: en.userProfile.personal.basicInfo.leisureTravelTicketingStatus,
    //   value: activeBenefitLTJobLevel ? en.common.active : en.common.inactive,
    // },
  ];

  //TODO: Relase 2 add back payment info as a new page
  // const PaymentInfo: IInformationListItem[] = [
  //   {
  //     key: en.userProfile.personal.paymentInfo.paymentConcept,
  //     value: getDisplayLabel(
  //       configurations.labels,
  //       LABEL_CATEGORY.paymentConcept,
  //       paymentConcept
  //     ),
  //   },
  //   {
  //     key: en.userProfile.personal.paymentInfo.paymentMedium,
  //     value: paymentMedium,
  //   },
  // ];

  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const filterListDataBasicInfo = useMemo(() => {
    if (profile && isRetireeWidow(profile)) {
      return listDataBasicInfo
        .filter(
          ({ key }) =>
            ![
              en.userProfile.personal.basicInfo.benefitDutyJobLevel,
              en.userProfile.personal.basicInfo.costCentre,
            ].includes(key),
        )
        .map((item) =>
          item.key === en.userProfile.personal.basicInfo.companySeniorityDate
            ? {
                ...item,
                key: en.userProfile.personal.basicInfo.yearOfService,
                value: profile.isAdminEditedYOS
                  ? `${yearOfServiceByAdmin} ${en.dateUnit.years}`
                  : formatProfileYOS(yearOfService) || '',
              }
            : item,
        );
    }
    return listDataBasicInfo;
  }, [profile]);

  return (
    <>
      <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
        <InformationList
          title={en.userProfile.personal.basicInfo.title}
          listData={filterListDataBasicInfo as IInformationListItem[]}
        />

        {!isLoading &&
          paymentMethodIndicator?.isRetireeWidowRole &&
          paymentMethodIndicator?.isShowWarning &&
          paymentMethodIndicator?.paymentSetUpWarningMessage && (
            <Box
              sx={{
                mt: 3,
              }}
            >
              <CommonWarning
                isShowIcon={true}
                customIconFill={theme.color.utility.error.option_3}
                msgText={paymentMethodIndicator?.paymentSetUpWarningMessage}
                replaceTarget={paymentMethodIndicator.replaceTarget}
                actionFunc={() => {
                  if (paymentMethodIndicator.isRetireeWidowRole) {
                    if (profile && !profile.retireeWidow?.countryOfResidential) {
                      setOpenCountryOfResidenceDialog(true);
                    } else if (
                      profile &&
                      profile.retireeWidow?.backendCalculatePaymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT
                    ) {
                      dispatch(setIsOpenBillingAgreementDialog(true));
                    }
                  } else {
                    // Paypal flow - open billing agreement dialog
                    if (paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT) {
                      dispatch(setIsOpenBillingAgreementDialog(true));
                    }
                  }
                }}
                customStyles={{
                  mr: 0,
                }}
              />
            </Box>
          )}
        <UpdateSetupCountryOfResidenceFlowContainer />
      </Box>
    </>
  );
};

export default BasicDetailsContainer;
