import React from 'react';
import { SxProps, Box, Typography, useTheme, Theme } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';

import { useAppSelector } from '../../app/hooks';

import { selectApp } from '../../slice/appSlice';

import { ExclamationIcon } from '../../assets/images';

const CommonWarning = ({
  isShowIcon = true,
  iconCustomStyles,
  customStyles,
  customIconFill,
  msgText,
  replaceTarget,
  replaceTargetDisplayLabel = '',
  actionFunc,
  msgColor,
  msgTextVariant,
}: {
  isShowIcon?: boolean;
  iconCustomStyles?: React.SVGAttributes<SVGSVGElement>['style'] | React.CSSProperties;
  customStyles?: SxProps<Theme>;
  customIconFill?: string;
  msgColor?: string;
  msgText: string;
  replaceTarget?: string; // example: {clickHere}
  replaceTargetDisplayLabel?: string; // example: Click here
  isShowMore?: boolean;
  actionFunc?: () => void;
  msgTextVariant?: TypographyProps['variant'];
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const warningSplit = msgText.split(replaceTarget || '');
  return (
    <Box
      className="common_warning_component"
      sx={{
        display: 'flex',
        mt: isDesktop ? 1.25 : 0.375,
        mb: 1,
        mr: isDesktop ? 2 : 0,
        px: 0.5,
        color: theme.color.secondary.dark_grey.option_3,
        ...customStyles,
      }}
    >
      {isShowIcon && (
        <ExclamationIcon
          fill={customIconFill || theme.color.secondary.dark_grey.option_3}
          style={{
            width: '16px',
            height: '16px',
            flex: 'none',
            display: 'block',
            marginTop: '2px',
            marginRight: '8px',
            ...iconCustomStyles,
          }}
        ></ExclamationIcon>
      )}
      <Typography variant={msgTextVariant || 'body_2_medium'} style={{ whiteSpace: 'pre-wrap' }}>
        {replaceTarget && warningSplit[0]}
        <Typography
          variant={msgTextVariant || 'body_2_medium'}
          sx={{
            display: 'inline',
            cursor: 'pointer',
            color: msgColor ? msgColor : theme.color.utility.link.option_3,
          }}
          onClick={() => actionFunc?.()}
        >
          {replaceTargetDisplayLabel || replaceTarget}
        </Typography>
        {replaceTarget ? warningSplit[1] : msgText}
      </Typography>
    </Box>
  );
};

export default CommonWarning;
