import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useTheme, Typography } from '@mui/material';

import { DownloadIcon, SearchOutlineIcon } from '../../../assets/images';
import en from '../../../translations/en';

import {
  DATE_FORMAT,
  LABEL_CATEGORY,
  MISSING_INFO,
  PAYMENT_MEDIUM,
  // PREPAID_STATUS,
} from '../../../constants/constants';

import { IPaymentHistoryList, IPaymentHistoryResponseData } from '../../../interfaces';
import { Frontend } from 'booking';

import { handleBackToUserProfile } from '../../../helpers';

import {
  formatDateAsString,
  formatTicketNumForDisplay,
  getDisplayLabel,
  getDisplayName,
  getSeatClass,
  getPaymentStatus,
} from '../../../helpers';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import {
  selectUser,
  setPaymentHistory,
  setSelectedPaymentHistoryPeriod,
  initialState as userInitialState,
  setIsShowPaymentHistoryDialog,
} from '../../../slice/userSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { EditPaymentHistoryAction } from '../../../context/PaymentHistoryContext';

import { getPaymentHistory } from '../../../services/user';

import { FormButton, FormAlert, DrawerOverlay } from '../../../components';
import {
  PaymentHistoryNavBar,
  PaymentHistorySearch,
  PaymentHistoryDialog,
  BookingSummaryOverlay,
  SearchFilterIconWithCount,
  PaymentHistoryTable,
  PaymentHistoryCardList,
} from '../..';

const PaymentHistory = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration);
  const { paymentHistoryList, selectedPaymentHistoryPeriod, isShowPaymentHistoryDialog } =
    useAppSelector(selectUser) || {};
  const { from, to } = selectedPaymentHistoryPeriod || {};
  const { setEditPeriod } = useContext(EditPaymentHistoryAction) || {};
  const enPaymentHistory = en.userProfile.employmentDetail.paymentHistory;

  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);

  const getPaymentHistoryAction = async (payload: any) => {
    const result: IPaymentHistoryResponseData[] | undefined = await getPaymentHistory(payload);

    const getItineraryStr = (data: IPaymentHistoryResponseData) => {
      const itineraryArr: string[] = [];

      const isEmptyMissingInfo = (str: string) => {
        return !str || str === MISSING_INFO;
      };

      const {
        actualArrivalPort,
        actualBookingClass,
        actualCarrier,
        actualFlightNum,
        actualDepartureDate,
        actualDeparturePort,
        arrivalPort,
        bookingParentClass,
        carrier,
        flightNum,
        departureDate,
        departurePort,
      } = data;

      itineraryArr.push;

      itineraryArr.push(isEmptyMissingInfo(actualCarrier) ? carrier : actualCarrier);
      itineraryArr.push(isEmptyMissingInfo(actualFlightNum) ? flightNum : actualFlightNum);

      itineraryArr.push(' ');

      itineraryArr.push(
        isEmptyMissingInfo(actualBookingClass)
          ? getSeatClass(bookingParentClass, true)
          : getSeatClass(actualBookingClass, true),
      );

      itineraryArr.push(' ');

      itineraryArr.push(
        isEmptyMissingInfo(actualDepartureDate)
          ? formatDateAsString(departureDate, DATE_FORMAT.ddmmmyyyy)
          : actualDepartureDate,
      );

      itineraryArr.push(' ');

      itineraryArr.push(isEmptyMissingInfo(actualDeparturePort) ? departurePort : actualDeparturePort);

      itineraryArr.push('-');

      itineraryArr.push(isEmptyMissingInfo(actualArrivalPort) ? arrivalPort : actualArrivalPort);

      return itineraryArr.join('');
    };

    // Massage response data into IPaymentHistoryList type
    const paymentHistoryData: IPaymentHistoryList[] = [];
    if (result) {
      result.map((data: IPaymentHistoryResponseData) => {
        const {
          applicationId,
          ticketNumber,
          concessionName,
          currency,
          firstName,
          lastName,
          paymentMedium,
          processDateHKT,
          status: paymentHistoryStatus,
          paypalStatus: paymentHistoryPaypalStatus,
          title,
          total,
          type,
        } = data;

        let status = en.common.empty;

        // handle paypal status
        if (paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT && paymentHistoryPaypalStatus) {
          status = getDisplayLabel(configurations.labels, LABEL_CATEGORY.paypalStatus, paymentHistoryPaypalStatus);
        }

        // [ETP-4562] special handle latest prepaid status
        // reference the logic on getBookingStatus()
        // handle prepaid status
        if (paymentMedium === PAYMENT_MEDIUM.CREDIT_CARD_PAYMENT && paymentHistoryStatus) {
          status = getPaymentStatus(paymentHistoryStatus, configurations.labels);
        }

        paymentHistoryData.push({
          rawData: data,
          type,
          applicationId,
          concession: concessionName,
          travellerName: getDisplayName({ title, firstName, lastName }),
          ticketNumber: formatTicketNumForDisplay(ticketNumber),
          itinerary: getItineraryStr(data),
          processedDate: processDateHKT ? formatDateAsString(processDateHKT, DATE_FORMAT.ddmmmyyyy) : en.common.empty,
          currency,
          totalAmount: total,
          paymentMedium: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentMedium, paymentMedium),
          status,
          // //TODO will integrate with backend later
          // ...(isAllowRequestRefund && {
          //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
          //   handleColumnOnClick: (selectedRowData: any) => {
          //     //...
          //   },
          // }),
        });
      });
    }

    dispatch(setPaymentHistory(paymentHistoryData));
  };

  const handlePaymentHistoryRecordOnClick = (data: any) => {
    if (data) {
      setBookingSelected(data);
    }
  };

  // Header UI
  const Header = () => {
    const theme = useTheme();
    const { isDesktop } = useAppSelector(selectApp) || {};

    //HOLD hidden download button first
    const isShowDownloadBtn = false;
    return (
      <Box
        sx={{
          width: '100%',
          display: isDesktop || (!isDesktop && isShowDownloadBtn) ? 'flex' : 'none',
          justifyContent: isDesktop ? 'space-between' : 'flex-end',
          alignItems: 'center',
          px: isDesktop ? 12 : 1,
          py: 3,
        }}
      >
        {isDesktop && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <Typography variant="title_1_medium">{enPaymentHistory.header}</Typography>
            <SearchFilterIconWithCount
              onClick={() => {
                dispatch(setIsShowPaymentHistoryDialog(true));
                setEditPeriod &&
                  setEditPeriod({
                    from,
                    to,
                  });
              }}
              Icon={SearchOutlineIcon}
              data={{ selectedPaymentHistoryPeriod }}
            />
          </Box>
        )}

        {/* //HOLD */}
        {isShowDownloadBtn && (
          <FormButton
            theme={theme}
            colour={isDesktop ? 'outline' : 'transparent'}
            color="success"
            size="large"
            customStyles={{
              width: isDesktop ? '140px' : '48px',
              height: '48px',
              padding: 0,
              minWidth: '32px',
              borderRadius: 8,
              boxShadow: theme.palette.boxShadow.dark,
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            {isDesktop ? <Typography variant="body_1_medium">{en.common.download}</Typography> : <DownloadIcon />}
          </FormButton>
        )}
      </Box>
    );
  };

  // clear selected date when leaved current page
  useEffect(() => {
    return () => {
      dispatch(setSelectedPaymentHistoryPeriod(userInitialState.selectedPaymentHistoryPeriod));
    };
  }, []);

  // Fetch payment history API when ERN & Redux's selectedPaymentHistoryPeriod data exist
  useEffect(() => {
    if (from && to) {
      getPaymentHistoryAction({
        userId: ernFromLogin,
        startDate: from,
        endDate: to,
      });
    } else {
      // clear history list when empty selected period
      dispatch(setPaymentHistory([]));
    }
  }, [selectedPaymentHistoryPeriod]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {!isDesktop && (
        <PaymentHistoryNavBar
          title={enPaymentHistory.header}
          bottomComponent={{
            component: SearchFilterIconWithCount,
            props: {
              onClick: () => {
                dispatch(setIsShowPaymentHistoryDialog(true));
                setEditPeriod &&
                  setEditPeriod({
                    from,
                    to,
                  });
              },
              Icon: SearchOutlineIcon,
              data: { selectedPaymentHistoryPeriod },
            },
          }}
          handleBackClick={() => handleBackToUserProfile(history)}
        />
      )}

      <Header />

      {from &&
        to &&
        (isDesktop ? (
          <PaymentHistoryTable
            data={paymentHistoryList}
            isNoResult={!paymentHistoryList?.length}
            handlePaymentHistoryRecordOnClick={handlePaymentHistoryRecordOnClick}
          />
        ) : (
          <Box className="mobile_payment_history_content" sx={{ mt: 2 }}>
            {!!paymentHistoryList?.length && (
              <Box sx={{ my: 2, mx: 2 }}>
                <FormAlert
                  customStyles={{ px: 1 }}
                  severity="info"
                  component={
                    <Typography
                      variant="body_2_regular"
                      sx={{
                        ml: 1,
                        color: theme.color.secondary.dark_grey.option_3,
                      }}
                    >
                      {en.common.suggestFullVersionView}
                    </Typography>
                  }
                />
              </Box>
            )}
            <PaymentHistoryCardList
              paymentHistoryData={paymentHistoryList}
              isNoResult={!paymentHistoryList?.length}
              handlePaymentHistoryRecordOnClick={handlePaymentHistoryRecordOnClick}
            />
          </Box>
        ))}

      {isDesktop ? (
        <PaymentHistoryDialog
          open={isShowPaymentHistoryDialog}
          handleCloseDialog={() => {
            dispatch(setIsShowPaymentHistoryDialog(false));
          }}
          dialogContent={{
            yesTitle: en.common.apply,
            noTitle: en.common.clear,
          }}
        />
      ) : (
        <DrawerOverlay
          showOverlay={isShowPaymentHistoryDialog}
          isShowBackArrow={true}
          handleClose={() => {
            dispatch(setIsShowPaymentHistoryDialog(false));
          }}
          title={enPaymentHistory.header}
          component={PaymentHistorySearch}
          data={{
            goToRouteStep: () => {},
            handleBackClick: () => {
              dispatch(setIsShowPaymentHistoryDialog(false));
            },
            handleFooterClick: () => {
              dispatch(setIsShowPaymentHistoryDialog(false));
            },
          }}
        />
      )}

      {bookingSelected && (
        <BookingSummaryOverlay
          bookingSelected={bookingSelected}
          handleClose={() => {
            setBookingSelected(null);
          }}
          data={{
            applicationId: bookingSelected?.applicationId,
            type: bookingSelected?.type,
          }}
        />
      )}
    </Box>
  );
};

export default PaymentHistory;
