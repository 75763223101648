import { Box, useTheme, SxProps, Theme } from '@mui/material';
import { Field } from 'redux-form';

import { FormFieldLabel } from '../../components';

interface IFormFieldGroupItem<T> {
  component: React.ComponentType<any>;
  fieldProps: T & Partial<Field>;
  fieldCustomStyles?: SxProps<Theme>;
}

export interface IDoubleFormFieldInput<T> {
  formFieldGroupList: IFormFieldGroupItem<T>[];
  formFieldGroupListCustomStyles?: SxProps<Theme>;
  mixedErrorMessage: string;
  customStyles?: SxProps<Theme>;
}

const DoubleFormFieldInput = <T,>(props: IDoubleFormFieldInput<T>) => {
  const { formFieldGroupList = [], mixedErrorMessage = '', formFieldGroupListCustomStyles, customStyles } = props;

  const theme = useTheme();

  return (
    <Box className="double_form_field_input" sx={customStyles}>
      {formFieldGroupList.length > 0 && (
        <>
          <Box
            className="form_field_group_list"
            sx={{ display: 'flex', gap: '16px', ...formFieldGroupListCustomStyles }}
          >
            {formFieldGroupList.map((formFieldGroupItem: IFormFieldGroupItem<T>, index) => {
              const { component: FormFieldComponent, fieldCustomStyles, fieldProps } = formFieldGroupItem;
              return (
                <Box
                  sx={{
                    width: '50%',
                    ...fieldCustomStyles,
                  }}
                  key={`${FormFieldComponent.name}_${index}`}
                >
                  <Field component={FormFieldComponent} {...fieldProps} />
                </Box>
              );
            })}
          </Box>

          {!!mixedErrorMessage && (
            <Box sx={{ width: '100%' }}>
              <FormFieldLabel
                style={{
                  fontSize: '11px',
                  color: theme.color.secondary.dark_grey.option_3,
                  mb: '6px',
                }}
                title={mixedErrorMessage}
                isError
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DoubleFormFieldInput;
