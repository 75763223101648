import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { Configuration } from 'configuration';
import { Frontend } from 'booking';

import { RootState } from '../app/store';
import { formatDateAsString } from '../helpers';

import {
  IBookingSearchData,
  ICabinClass,
  IClassAvailability,
  IContactDetails,
  IDocsDoca,
  IFlightLoadCachePayload,
  ILtEditContactDetails,
  INominatedPassenger,
  IPassportDetails,
  ISelectedFlight,
  ITravellerDetails,
  ITravellerName,
  IUsAddress,
  IRetrieveAndPayCppParams,
  IRetrieveAndPayUnhappyFlowFinalPageControl,
} from '../interfaces';

import {
  TRAVEL_PURPOSE,
  DATE_FORMAT,
  DATE_UNIT,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  DEFAULT_ZONE_CX_CARRIER,
} from '../constants/constants';

export const travelDetailInitialState = {
  id: '',
  title: '',
  advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  advanceDayUnit: DATE_UNIT.DYS,
  subTitle: '',
  empOrNonEmpType: '',
};

export const leisureTravelDetailInitialState = {
  id: DEFAULT_ZONE_CX_CARRIER.id,
  type: DEFAULT_ZONE_CX_CARRIER.type, // ZONE|FOC|ID50...etc
  fareType: DEFAULT_ZONE_CX_CARRIER.fareType, // ZED, ID
  concessionRuleId: '', // default empty rule id for ZONE CX, OW/OAL
  sub: DEFAULT_ZONE_CX_CARRIER.sub, // NOSUB|SUBLO
  carrier: '',
  carrierGroup: '',
  label: '', // selected concession `displayName`
  adultCount: DEFAULT_ZONE_CX_CARRIER.adultCount,
  childrenCount: DEFAULT_ZONE_CX_CARRIER.childrenCount,
  infantsCount: DEFAULT_ZONE_CX_CARRIER.infantsCount,
  infantsWithoutSeatCount: DEFAULT_ZONE_CX_CARRIER.infantsWithoutSeatCount,
  seatConcession: '',
  seatOption: '',
  ltSelectTravellerList: [],
  ltSeatConcessionTravellerList: [],
  originLtTravelers: [],
  ltConcessions: [],
  restrictionBeneficiaryType: [],
  isAccompanyFormChecked: false,
  contactDetails: null,
  editTravellerKeyIndex: null,
  fareDetail: null,
  infantAdultMapFromRedux: {},
  retireeCountryOfResidential: {},
};

export const cabinClassDataInitialState = {
  type: '',
  value: {},
};

export const initialState: IBookingSearchData = {
  isCppBookingCreated: false,
  bookingStep: null,
  isCalledPrepaidAuth: false,
  prepaidAuthFlowType: '', // NORMAL_PREPAID | RETRIEVE_AND_PAY
  viewBookingApplicationId: null,
  tpAppRef: null,
  retrieveAndPayCppParams: null,
  retrieveAndPayUnhappyFlowFinalPageControl: null,
  isTriggerReviewAndConfirmByNormalPrepaid: false,
  isTriggerGetFareByNormalPrepaid: false,
  origin: null,
  destination: null,
  // carrier: "CX",
  startDate: formatDateAsString(new Date(), DATE_FORMAT.date),
  isRefresh: false,
  endDate: '',
  travelType: TRAVEL_PURPOSE.employeeLeisureTravel,
  travelDetails: travelDetailInitialState,
  leisureTravelDetails: leisureTravelDetailInitialState,
  isLegendsDisplay: false,
  lTCabinClassData: cabinClassDataInitialState,
  isShowDTSecurityReminder: true,
  isShowMyBookingFilterDialog: false,
  isShowCancelDialog: false,
  flightLoadCache: {},
  outwardFlight: null,
  returnFlight: null,
  passportDetails: null,
  travellerDetails: null,
  contactDetails: null,
  docsDoca: {
    docs: false,
    docaResidence: false,
    docaAddress: false,
  },
  usAddress: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
  },
  travellerName: {
    firstName: '',
    lastName: '',
  },
  bookingCompleted: null,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setOrigin: (state, { payload }: PayloadAction<Configuration.Airport | null>) => {
      state.origin = payload;
    },
    setDestination: (state, { payload }: PayloadAction<Configuration.Airport | null>) => {
      state.destination = payload;
    },
    // setCarrier: (state, { payload }: PayloadAction<string>) => {
    //   state.carrier = payload;
    // },
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setIsRefresh: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefresh = payload;
    },
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setType: (state, { payload }: PayloadAction<string>) => {
      state.travelType = payload || 'Duty Travel';
    },
    setDetail: (state, { payload }) => {
      state.travelDetails = payload;
    },
    setLeisureTravelDetails: (state, { payload }) => {
      state.leisureTravelDetails = payload;
    },
    cleanUpLTFareDetail: (state) => {
      state.leisureTravelDetails.fareDetail = null;
    },
    setOutwardFlight: (state, { payload }: PayloadAction<ISelectedFlight | null>) => {
      state.outwardFlight = payload;
    },
    setOutwardFlightClass: (state, { payload }: PayloadAction<IClassAvailability>) => {
      if (state.outwardFlight) {
        state.outwardFlight = { ...state.outwardFlight, seatClass: payload };
      }
    },
    setReturnFlight: (state, { payload }: PayloadAction<ISelectedFlight | null>) => {
      state.returnFlight = payload;
    },
    setReturnFlightClass: (state, { payload }: PayloadAction<IClassAvailability>) => {
      if (state.returnFlight) {
        state.returnFlight = { ...state.returnFlight, seatClass: payload };
      }
    },
    setTravellerDetails: (state, { payload }: PayloadAction<ITravellerDetails | null>) => {
      state.travellerDetails = merge({}, state.travellerDetails, payload);
    },
    setTravellerInfo: (state, { payload }: PayloadAction<ITravellerDetails['travellerInfo'] | null>) => {
      state.travellerDetails = merge({}, state.travellerDetails, {
        travellerInfo: payload,
      });
    },
    setFirstName: (state, { payload }: PayloadAction<string>) => {
      state.travellerDetails = merge({}, state.travellerDetails, {
        travellerInfo: { firstName: payload },
      });
    },
    setLastName: (state, { payload }: PayloadAction<string>) => {
      state.travellerDetails = merge({}, state.travellerDetails, {
        travellerInfo: { lastName: payload },
      });
    },
    setPassportDetails: (state, { payload }: PayloadAction<IPassportDetails | null>) => {
      state.passportDetails = payload;
    },
    setContactDetails: (state, { payload }: PayloadAction<IContactDetails | ILtEditContactDetails | null>) => {
      state.contactDetails = payload;
    },
    setDocsDoca: (state, { payload }: PayloadAction<IDocsDoca>) => {
      state.docsDoca = payload;
    },
    setUsAddress: (state, { payload }: PayloadAction<IUsAddress>) => {
      state.usAddress = payload;
    },
    setTravellerName: (state, { payload }: PayloadAction<ITravellerName>) => {
      state.travellerName = payload;
    },
    setBookingCompleted: (state, { payload }: PayloadAction<Frontend.ParsedBooking | null>) => {
      state.bookingCompleted = payload;
    },
    clearBookingForm: (state) => {
      state.passportDetails = initialState.passportDetails;
      state.contactDetails = initialState.contactDetails;
      state.usAddress = initialState.usAddress;
      state.travellerName = initialState.travellerName;
    },
    setIsLegendsDisplay: (state, { payload }: PayloadAction<boolean>) => {
      state.isLegendsDisplay = payload;
    },
    setLTCabinClassData: (state, { payload }: PayloadAction<ICabinClass>) => {
      state.lTCabinClassData = payload;
    },
    setEditTravellerKeyIndex: (state, { payload }: PayloadAction<number>) => {
      state.leisureTravelDetails.editTravellerKeyIndex = payload;
    },
    setLtSelectTravellerList: (state, { payload }: PayloadAction<INominatedPassenger[]>) => {
      state.leisureTravelDetails.ltSelectTravellerList = payload;
    },
    setLtSeatConcessionTravellerList: (state, { payload }: PayloadAction<INominatedPassenger[]>) => {
      state.leisureTravelDetails.ltSeatConcessionTravellerList = payload;
    },
    setSeatConcession: (state, { payload }: PayloadAction<string>) => {
      state.leisureTravelDetails.seatConcession = payload;
    },
    setSeatOption: (state, { payload }: PayloadAction<string>) => {
      state.leisureTravelDetails.seatOption = payload;
    },
    setFlightLoadCache: (state, { payload }: PayloadAction<IFlightLoadCachePayload>) => {
      const { key, value } = payload;
      state.flightLoadCache = {
        ...state.flightLoadCache,
        [key]: value,
      };
    },
    setIsShowCancelDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCancelDialog = payload;
    },
    setInfantAdultMapFromRedux: (
      state,
      {
        payload,
      }: PayloadAction<{
        [key: string]: number;
      }>,
    ) => {
      state.leisureTravelDetails.infantAdultMapFromRedux = payload;
    },
    setIsShowMyBookingFilterDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowMyBookingFilterDialog = payload;
    },
    setBookingStep: (state, { payload }: PayloadAction<number | null>) => {
      state.bookingStep = payload;
    },
    setViewBookingApplicationId: (state, { payload }: PayloadAction<string | null>) => {
      state.viewBookingApplicationId = payload;
    },
    setTpAppRef: (state, { payload }: PayloadAction<string>) => {
      state.tpAppRef = payload;
    },
    setRetrieveAndPayCppParams: (state, { payload }: PayloadAction<IRetrieveAndPayCppParams | null>) => {
      state.retrieveAndPayCppParams = payload;
    },
    setCarrierGroup: (state, { payload }: PayloadAction<string>) => {
      state.leisureTravelDetails.carrierGroup = payload;
    },
    setIsShowDTSecurityReminder: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowDTSecurityReminder = payload;
    },
    setIsCppBookingCreated: (state, { payload }: PayloadAction<boolean>) => {
      state.isCppBookingCreated = payload;
    },
    setIsCalledPrepaidAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isCalledPrepaidAuth = payload;
    },
    setPrepaidAuthFlowType: (state, { payload }: PayloadAction<string>) => {
      state.prepaidAuthFlowType = payload;
    },
    setIsTriggerReviewAndConfirmByNormalPrepaid: (state, { payload }: PayloadAction<boolean>) => {
      state.isTriggerReviewAndConfirmByNormalPrepaid = payload;
    },
    setIsTriggerGetFareByNormalPrepaid: (state, { payload }: PayloadAction<boolean>) => {
      state.isTriggerGetFareByNormalPrepaid = payload;
    },
    setRetrieveAndPayUnhappyFlowFinalPageControl: (
      state,
      { payload }: PayloadAction<IRetrieveAndPayUnhappyFlowFinalPageControl>,
    ) => {
      state.retrieveAndPayUnhappyFlowFinalPageControl = payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

const {
  setOrigin,
  setDestination,
  // setCarrier,
  setStartDate,
  setIsRefresh,
  setEndDate,
  setType,
  setDetail,
  setLeisureTravelDetails,
  cleanUpLTFareDetail,
  setOutwardFlight,
  setOutwardFlightClass,
  setReturnFlight,
  setReturnFlightClass,
  setTravellerDetails,
  setTravellerInfo,
  setFirstName,
  setLastName,
  setPassportDetails,
  setContactDetails,
  setDocsDoca,
  setUsAddress,
  setTravellerName,
  setBookingCompleted,
  clearBookingForm,
  setIsLegendsDisplay,
  setLTCabinClassData,
  setEditTravellerKeyIndex,
  setLtSeatConcessionTravellerList,
  setLtSelectTravellerList,
  setSeatOption,
  setSeatConcession,
  setFlightLoadCache,
  setIsShowCancelDialog,
  setInfantAdultMapFromRedux,
  setIsShowMyBookingFilterDialog,
  setBookingStep,
  setTpAppRef,
  setRetrieveAndPayCppParams,
  setRetrieveAndPayUnhappyFlowFinalPageControl,
  setIsTriggerReviewAndConfirmByNormalPrepaid,
  setIsTriggerGetFareByNormalPrepaid,
  setViewBookingApplicationId,
  setCarrierGroup,
  setIsShowDTSecurityReminder,
  setIsCppBookingCreated,
  setIsCalledPrepaidAuth,
  setPrepaidAuthFlowType,
  reset,
} = bookingSlice.actions;

const selectBooking = (state: RootState) => state.bookingSearch;

export {
  bookingSlice,
  setOrigin,
  setDestination,
  // setCarrier,
  setStartDate,
  setIsRefresh,
  setEndDate,
  setType,
  setDetail,
  setLeisureTravelDetails,
  cleanUpLTFareDetail,
  setOutwardFlight,
  setOutwardFlightClass,
  setReturnFlight,
  setReturnFlightClass,
  setTravellerDetails,
  setTravellerInfo,
  setFirstName,
  setLastName,
  setPassportDetails,
  setContactDetails,
  setDocsDoca,
  setUsAddress,
  setTravellerName,
  setBookingCompleted,
  clearBookingForm,
  setIsLegendsDisplay,
  setLTCabinClassData,
  setEditTravellerKeyIndex,
  setLtSeatConcessionTravellerList,
  setLtSelectTravellerList,
  setSeatOption,
  setSeatConcession,
  setFlightLoadCache,
  setIsShowCancelDialog,
  setInfantAdultMapFromRedux,
  setIsShowMyBookingFilterDialog,
  setBookingStep,
  setTpAppRef,
  setRetrieveAndPayCppParams, // [ETP-4379]
  setRetrieveAndPayUnhappyFlowFinalPageControl, // [ETP-4379]
  setIsTriggerReviewAndConfirmByNormalPrepaid,
  setIsTriggerGetFareByNormalPrepaid,
  setViewBookingApplicationId,
  setCarrierGroup,
  setIsShowDTSecurityReminder,
  setIsCppBookingCreated,
  setIsCalledPrepaidAuth,
  setPrepaidAuthFlowType,
  reset,
  selectBooking,
};

export default bookingSlice.reducer;
