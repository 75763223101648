import { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';
import { shallowEqual, useDispatch } from 'react-redux';

import { Pnr } from 'booking';
import { Configuration } from 'configuration';

import { BOOKING_STATUS, LABEL_CATEGORY, UI_STYLES } from '../../../constants/constants';
import en from '../../../translations/en';

import { selectBooking, setDocsDoca, setLeisureTravelDetails } from '../../../slice/bookingSlice';
import { useAppSelector } from '../../../app/hooks';
import { BookingSummaryState } from '../../../context';

import { IPassport, ITravellerDetailsItem } from '../../../interfaces';

import { constructFlightSectors } from '../../../helpers';
import { getDocsDoca, getFareAndTravellerDetailsLT } from '../../../services/booking';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { Footer, FormAlert, FormButton, ScrollableView, ShadowContent } from '../../../components';
import { FlightInfo } from '..';

const RebookReviewFlight = ({
  handleCancelClick,
  handleContinueClick,
}: {
  handleCancelClick: () => void;
  handleContinueClick: () => void;
}) => {
  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { ern } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const { bookingSummaryData } = useContext(BookingSummaryState) || {};
  const { recLoc, type, ticketsBySegment, concession, etpPassengerList } = bookingSummaryData || {};
  const { bookingParentClass, tickets } = (ticketsBySegment && ticketsBySegment[0]) || {};

  const { outwardFlight, leisureTravelDetails } = useAppSelector(selectBooking, shallowEqual) || {};
  const {
    arrivalDate,
    arrivalTime,
    departureTime,
    departureDate,
    flightNo,
    marketingCompany,
    numberOfStops,
    aircraft,
  } = outwardFlight || {};

  // to update rebook data: TBC:
  // bookingStatus: --> need to confirm with backend
  // If cancel before rebook -> status ‘--’
  // If rebook without cancel -> display status (e.g. standby)
  const rebookTicketsBySegment: Pnr.TicketBySegment[] = _.cloneDeep(ticketsBySegment);
  if (rebookTicketsBySegment?.[0]) {
    rebookTicketsBySegment[0].arrivalDate = arrivalDate || '';
    rebookTicketsBySegment[0].arrivalTime = arrivalTime || '';
    rebookTicketsBySegment[0].departureDate = departureDate || '';
    rebookTicketsBySegment[0].departureTime = departureTime || '';
    rebookTicketsBySegment[0].flightNum = flightNo || '';
    rebookTicketsBySegment[0].carrier = marketingCompany || '';
    rebookTicketsBySegment[0].numOfStops = numberOfStops || 0;
    rebookTicketsBySegment[0].aircraftType = aircraft || '';
  }

  const getDocsDocaAction = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = bookingParentClass;
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const docsDocaResult = await getDocsDoca(flightSectors);

    dispatch(setDocsDoca(docsDocaResult));

    return docsDocaResult;
  };

  const getFareAndTravellerDetails = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = bookingParentClass;
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const travellerInfo: any = [];

    for (let i = 0; i < tickets.length; i++) {
      const latestTicket: Pnr.Ticket = tickets[i].latestTicket || {};
      const { paxObj } = latestTicket || {};

      travellerInfo.push(paxObj);
    }

    const results =
      (await getFareAndTravellerDetailsLT(ern, concession?.id || '', flightSectors, travellerInfo)) || null;

    if (results) {
      // 1. Extract the travellerDetail array
      const travellerDetails: ITravellerDetailsItem[] = results.travellerDetails;

      const returnedTravellerDependentId: Array<string | null> = travellerDetails.map(
        (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId,
      );

      //TBC: etp-2915: ltSeatConcessionTravellerList is empty,
      // backend will handle missing data ?
      let updatedltTravellerList = _.cloneDeep(travellerDetails);

      // 2. Set travelDetail and passportDetail to ltTraveller
      updatedltTravellerList = updatedltTravellerList.map((traveller: any) => {
        // Get the travellerDetail from the getFare API
        if (returnedTravellerDependentId?.includes(traveller.dependentId)) {
          // from api resp data
          const matchedTravellerDetails = travellerDetails.find(
            (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId === traveller.dependentId,
          );
          // 3. Process the countryOfResidential into Configuration.CodeLabel
          let countryOfResidentialOption;
          if (traveller.isUpdated && traveller?.countryOfResidential) {
            // get lastest data from Redux
            countryOfResidentialOption = traveller.countryOfResidential;
          } else {
            countryOfResidentialOption = configurations?.countries?.find(
              (option: Configuration.DropdownOptionClient) =>
                option.code === matchedTravellerDetails?.countryOfResidential,
            );
          }

          //4. Process passport Detail (passportCountry, passportNationality and gender)
          let passportDetail: IPassport | undefined;
          let accompanyAdult: any;

          if (traveller.isUpdated && traveller?.passportDetail) {
            passportDetail = traveller?.passportDetail;
          } else {
            passportDetail = _.cloneDeep(matchedTravellerDetails?.passport);

            // update passport data from fare API
            if (passportDetail) {
              passportDetail.gender = configurations?.labels?.find(
                (option: Configuration.CodeLabel) =>
                  option.category === LABEL_CATEGORY.gender && option.code === passportDetail?.gender,
              );
              passportDetail.passportCountry = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportCountry,
              );
              passportDetail.passportNationality = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportNationality,
              );
            }
          }

          if (matchedTravellerDetails?.attachedTo) {
            accompanyAdult = travellerDetails.find(
              (travellerDetail: ITravellerDetailsItem) =>
                travellerDetail.dependentId === matchedTravellerDetails?.attachedTo.dependentId,
            );
          }

          return {
            ...traveller,
            accompanyAdult: accompanyAdult,
            countryOfResidential: countryOfResidentialOption,
            passportDetail: passportDetail,
          };
        } else {
          return traveller;
        }
      });

      // Set contactDetails
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          ltSeatConcessionTravellerList: updatedltTravellerList,
          contactDetails: results.contactDetails || {},
          fareDetail: results.fare,
        }),
      );
    }

    return results;
  };

  return (
    <>
      <Box
        component={ScrollableView}
        sx={
          isDesktop
            ? {
                height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight}
                   - ${UI_STYLES.desktopFooterHeight}
                   - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
              }
            : {
                pb: 2,
                px: 2,
                height: `calc(${screenHeight}px - ${UI_STYLES.footerHeight} - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
                background: theme.backgroundColor.wheat,
                width: '100%',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
        }
      >
        <Box
          sx={
            isDesktop
              ? {
                  width: UI_STYLES.flightInfoWidth,
                  mt: 5,
                  mx: 'auto',
                }
              : {
                  position: 'absolute',
                  top: '-8px',
                  width: 'calc(100% - 32px)',
                }
          }
        >
          <FormAlert
            severity="info"
            component={
              <Typography
                variant="body_2_regular"
                sx={{
                  ml: 1,
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                {en.booking.confirmation.cancelActions.rebook.confirmNote}
              </Typography>
            }
          />
        </Box>

        {/* origin flight */}
        <Box
          component={ShadowContent}
          sx={{
            background: theme.color.secondary.light_slate.option_6,
            mt: { xs: 7.75, sm: 2 },
            width: isDesktop ? UI_STYLES.flightInfoWidth : '100%',
            mx: 'auto',
          }}
        >
          <FlightInfo
            bookingStatus={BOOKING_STATUS.original} // hardcode now
            recLoc={recLoc}
            type={type}
            ticketsBySegment={ticketsBySegment}
            isViewBookingDetails={true}
            showPax={false}
            etpPassengerList={etpPassengerList}
          />
        </Box>

        {/* rebook flight */}
        {/* TODO: new flight data */}
        <Box
          component={ShadowContent}
          sx={{
            my: 2,
            width: isDesktop ? UI_STYLES.flightInfoWidth : '100%',
            mx: 'auto',
          }}
        >
          <FlightInfo
            bookingStatus={BOOKING_STATUS.rebook}
            recLoc={recLoc}
            type={type}
            ticketsBySegment={rebookTicketsBySegment}
            isViewBookingDetails={true}
            showPax={false}
            etpPassengerList={etpPassengerList}
          />
        </Box>
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '44px',
              width: '60px',
              borderRadius: 1,
            }}
            onClick={() => {
              handleCancelClick?.();
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={{
          text: en.common.continue,
          customOnClick: async () => {
            const docsDocaResult = await getDocsDocaAction();
            if (!_.isNil(docsDocaResult)) {
              const fareResult = await getFareAndTravellerDetails();
              fareResult && handleContinueClick?.();
            }
          },
          customButtonStyles: {
            marginRight: 0,
            width: '160px',
          },
        }}
        customStyles={
          isDesktop && {
            height: UI_STYLES.desktopFooterHeight,
            px: 22,
            boxShadow: theme.palette.boxShadow.dark,
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
          }
        }
      />
    </>
  );
};

export default RebookReviewFlight;
