import React from 'react';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';

import en from '../../translations/en';

import { ExclamationIcon } from '../../assets/images';

const RetireeIndicatorBar = ({
  customStyles,
  isSpecialRetireeProfile,
}: {
  customStyles?: SxProps<Theme>;
  isSpecialRetireeProfile: boolean;
}) => {
  const theme = useTheme();

  const retireeSpecialProfileLbl = en.userProfile.retireeSpecialProfile;

  const replaceValue = isSpecialRetireeProfile
    ? retireeSpecialProfileLbl.specialProfileTitle
    : retireeSpecialProfileLbl.retireeProfileTitle;

  return (
    <Box
      className="retiree_indicator_bar_component"
      sx={{
        width: '100%',
        py: 1,
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        ...customStyles,
      }}
    >
      <ExclamationIcon
        width="16"
        height="16"
        fill={theme.color.secondary.dark_grey.option_3}
        style={{ flex: 'none' }}
      />
      <Typography
        variant="body_1_medium"
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 1.5,
          whiteSpace: 'pre-line',
          color: theme.color.secondary.dark_grey.option_2,
        }}
      >
        <Typography variant="body_2_regular" sx={{ float: 'left', mr: 0.5 }}>
          {retireeSpecialProfileLbl.notification}
        </Typography>
        <Typography variant="body_1_medium" sx={{ float: 'left' }}>
          {replaceValue}
        </Typography>
      </Typography>
    </Box>
  );
};

export default RetireeIndicatorBar;
