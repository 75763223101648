import _ from 'lodash';
import { INominationViewModeItem } from '../interfaces';

const stringAlphabeticSort = (a: string, b: string): number => {
  if (a && b) {
    return a.localeCompare(b);
  }
  return 0;
};

const sort = (array: string[] | undefined, sortBy: string[]): string[] => {
  if (!array) {
    return [];
  }

  return array.sort(function (data1, data2) {
    return sortBy.indexOf(data1) - sortBy.indexOf(data2);
  });
};

const getFilterOptions = (sourceData: any, field: string) => {
  if (!sourceData) return [];
  return _.uniqBy(sourceData, field)
    .map((item: any) => {
      const fiedlValue = _.get(item, field);
      return { label: fiedlValue, value: fiedlValue };
    })
    .sort((a, b) => {
      const titleA = a.label.toUpperCase();
      const titleB = b.label.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleB < titleA) {
        return 1;
      }
      return 0;
    });
};

const sortByDependents = (
  nominationTransformData: INominationViewModeItem[],
  nominationChangeTypeData: INominationViewModeItem[],
  sortedDependents: string[],
) => {
  const [nominatedTransformData, notNominatedTransformData] = _.partition(
    nominationTransformData,
    (nominee) => nominee.isLocked,
  );

  // only sort not lock data: not nominated + change type data
  const sortedToBeChangedData = [...notNominatedTransformData, ...nominationChangeTypeData].sort(
    (a: INominationViewModeItem, b: INominationViewModeItem) => {
      return sortedDependents?.indexOf(a.dependentId) - sortedDependents?.indexOf(b.dependentId);
    },
  );

  return [...nominatedTransformData, ...sortedToBeChangedData];
};

export { stringAlphabeticSort, sort, getFilterOptions, sortByDependents };
