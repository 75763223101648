import { Checkbox, useTheme } from '@mui/material';
import { AdminCheckBoxTickedIcon, AdminCheckBoxUnTickedIcon } from '../../assets/images';

const AdminCheckbox = ({ checked, onChange }: { checked: boolean; onChange: () => void }) => {
  const theme = useTheme();

  return (
    <Checkbox
      sx={{
        '&.MuiCheckbox-root': {
          color: theme.status.selected,
        },
        '&:hover': {
          background: 'transparent',
        },
      }}
      checkedIcon={<AdminCheckBoxTickedIcon />}
      icon={<AdminCheckBoxUnTickedIcon />}
      color="info"
      checked={checked}
      onChange={onChange}
    />
  );
};

export default AdminCheckbox;
