import { Box, Typography } from '@mui/material';

import { USER_ROLE } from '../../constants/constants';
import { DesktopBackground } from '../../assets/images';
import en from '../../translations/en';

import { getShortDisplayName, isWidow } from '../../helpers';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import {
  setBookingStep,
  setTpAppRef,
  setViewBookingApplicationId,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
} from '../../slice/bookingSlice';
import { setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData, selectApp } from '../../slice/appSlice';

import { NavBar } from '../../components';
import { RetireeIndicatorBar, RoleIndicatorBar } from '..';

const DesktopHeader = ({
  isRetireeProfileWithRetireeSpecial,
  isSpecialRetireeProfile,
}: {
  isRetireeProfileWithRetireeSpecial: boolean;
  isSpecialRetireeProfile: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(selectApp) || {};
  const { profile, role, isShowProfileIndicator, isShowSelectProfileDialog } = useAppSelector(selectUser) || {};

  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const { type: roleType, delegator = {} } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);
  const isWidowProfile = isWidow(profile);

  const isShowRetireeIndicatorBar = isRetireeProfileWithRetireeSpecial || isSpecialRetireeProfile;

  return (
    <Box
      sx={{
        backgroundImage: `url(${DesktopBackground})`,
        height: isAdminDelegation ? 220 : isShowRetireeIndicatorBar && !isShowSelectProfileDialog ? 200 : 180,
        backgroundSize: '100% 190%',
        color: 'white',
        position: 'relative',
        backgroundPositionY: '-82px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <NavBar
        handleCloseOverlay={() => {
          // [ETP-4379] clear normal prepaid flow + retrieve and pay flow related Redux data
          dispatch(setIsCalledPrepaidAuth(false));
          dispatch(setRetrieveAndPayCppParams(null));

          dispatch(setBookingStep(null));

          dispatch(setPrepaidAuthFlowType(''));
          dispatch(setRetrieveAndPaySuccessData(null));
          dispatch(setNormalPrepaidSuccessData(null));

          dispatch(setViewBookingApplicationId(''));
          dispatch(setTpAppRef(''));

          history.go(0);
        }}
      />
      {isAdminDelegation && (
        <RoleIndicatorBar
          customStyles={{
            position: 'absolute',
            top: '72px',
            px: '14%',
            background: 'rgba(252, 252, 252, 0.7)',
          }}
        />
      )}

      {/* if selected retiree or special profile + loading animation dismissed, homepage top will display `RetireeIndicatorBar` after loading */}
      {isShowRetireeIndicatorBar && isShowProfileIndicator && !isLoading && (
        <RetireeIndicatorBar
          customStyles={{
            position: 'absolute',
            top: '72px',
            px: '14%',
            background: 'rgba(252, 252, 252, 0.7)',
          }}
          isSpecialRetireeProfile={isSpecialRetireeProfile}
        />
      )}

      <Box
        sx={{
          mt: isAdminDelegation ? 6 : isShowRetireeIndicatorBar && !isShowSelectProfileDialog ? 3 : 1,
          ml: '14%',
        }}
      >
        <Typography variant="title_1_bold">
          {isWidowProfile ? (
            en.home.helloWidow
          ) : (
            <>
              {en.home.hello}
              {getShortDisplayName(
                roleType === USER_ROLE.delegation ? delegator : { preferredFirstName, firstName, lastName, middleName },
              )}
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
