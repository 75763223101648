import { CSSProperties } from 'react';
import { Typography, useTheme, SxProps, Theme } from '@mui/material';
import { ExclamationMark } from '../../assets/images';

interface IFieldLabelProps {
  title?: string;
  isError?: boolean;
  style?: SxProps<Theme>;
  iconStyle?: CSSProperties;
}

const FormFieldLabel = ({ title, isError, style, iconStyle }: IFieldLabelProps) => {
  const theme = useTheme();

  return (
    <Typography
      variant="fine_print_regular"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        color: isError ? theme.palette.gray.dark : theme.palette.text.secondary,
        ...style,
      }}
    >
      {isError && (
        <ExclamationMark
          style={{ marginRight: 4, flex: 'none', marginTop: 2, ...iconStyle }}
          fill={theme.color.utility.error.option_3}
        />
      )}
      {title}
    </Typography>
  );
};

FormFieldLabel.defaultProps = {
  isError: false,
  style: {},
  iconStyle: {},
};

export default FormFieldLabel;
