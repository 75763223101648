import React, { useContext } from 'react';
import { Typography, Autocomplete, TextField, useTheme, MenuItem } from '@mui/material';

import { DownArrowIcon } from '../../../assets/images';
import { UI_STYLES } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import en from '../../../translations/en';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { ConcessionInProfileState, ConcessionInProfileAction } from '../../../context';

const ConcessionWithTraveller = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { middleTraveller, travellerList } = useContext(ConcessionInProfileState) || {};
  const { setMiddleTraveller } = useContext(ConcessionInProfileAction) || {};

  return (
    <>
      {travellerList && (
        <Autocomplete
          disablePortal
          options={travellerList}
          size="small"
          sx={{
            ...(isDesktop ? { width: '327px' } : { width: '100%', mb: 1 }),
            '.MuiFilledInput-root': {
              height: UI_STYLES.concessionTravellerHeight,
              borderRadius: 0.5,
              border: `1px solid ${theme.color.secondary.slate.option_3}`,
              background: theme.palette.primary.light,
              color: theme.color.secondary.dark_grey.option_3,
              paddingTop: '0px',
              '&.Mui-error': {
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
              },
              '&.Mui-selected, &:hover, &.Mui-filled': {
                background: 'white',
              },
              '&.Mui-focused': {
                background: theme.palette.primary.light,
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
                '&.Mui-error': {
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                },
              },
              '&.Mui-disabled': {
                background: theme.color.utility.unselectable.option_7,
              },
              '&: after, &: before': {
                display: 'none',
              },
              // input style
              '.MuiFilledInput-input': {
                mt: 1.75,
                fontWeight: FONT_WEIGHT.medium,
              },
            },
            '.MuiInputLabel-root': {
              color: theme.color.secondary.grey.option_3,
              '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
                color: theme.color.secondary.grey.option_3,
              },
            },
          }}
          clearIcon={null}
          popupIcon={<DownArrowIcon />}
          value={middleTraveller}
          onChange={(_, newValue) => {
            setMiddleTraveller(newValue);
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                height: UI_STYLES.concessionTravellerHeight,
              }}
              variant="filled"
              {...params}
              label={en.booking.travelType.traveller}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem
              sx={{
                whiteSpace: 'pre-line',
                background: 'white',
                width: '100%',
                height: UI_STYLES.concessionTravellerHeight,
                '.Mui-focused': {
                  backgroundColor: theme.palette.primary.light,
                },
                '&: last-of-type': {
                  '.MuiTypography-root': {
                    borderBottom: 'none',
                  },
                },
              }}
              {...props}
            >
              <Typography
                flex="1"
                variant="body_2_regular"
                sx={{
                  height: UI_STYLES.concessionTravellerHeight,
                  lineHeight: UI_STYLES.concessionTravellerHeight,
                  borderBottom: `1px solid ${theme.color.secondary.slate.option_3}`,
                }}
              >
                {option.label}
              </Typography>
            </MenuItem>
          )}
        />
      )}
    </>
  );
};

export default ConcessionWithTraveller;
