import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';

import { Configuration } from 'configuration';
import { Employee } from 'employee';
import { IAdminToggleBarTabListProps } from '../../../interfaces';

import {
  ADMIN_PERSONALINFO_INPUT_TYPE,
  ADMIN_PERSONALINFO_SELECT_TYPE,
  BENEFIT_JOB_LEVEL_OPTIONS,
  INFINITE_DATE,
  LABEL_CATEGORY,
  LABEL_VALUE_TYPE,
  PAYMENT_CONCEPT,
  PAYMENT_MEDIUM,
  PAYMENT_STATUS_OPTIONS,
} from '../../../constants/constants';
import en from '../../../translations/en';

import {
  constructDefaultJobLevelValue,
  formatDMYDateWithSlash,
  formatDMYDateWithSpace,
  getDisplayLabel,
  getPaymentStatusLabelInfo,
  isPaymentMediumDirectDebit,
  isPaymentMediumPaypal,
  isRetireeSpecialProfile,
  isRetireeWidow,
} from '../../../helpers';

import { selectConfiguration } from '../../../slice/configurationSlice';
import { useAppSelector } from '../../../app/hooks';
import { AdminAccessEmployeeAction, AdminAccessEmployeeState } from '../../../context';

import LabelValueContainer, { IDisplayDetails } from './LabelValueContainer';
import { AdminFlownSuspensionList, ClearBillingAgreementContainer } from '.';
import AdminToggleButtonGroup from './AdminToggleButtonGroup';
import DetailsContainer from './DetailsContainer';

interface IMassagedPersonalInfoData {
  [key: string]: IDisplayDetails[];
}

interface IAdminPersonalInfoDetailsProps {
  defaultId: number;
}
interface IInputValue {
  inputType: string;
  value: string;
}

const AdminPersonalInfoDetails = (props: IAdminPersonalInfoDetailsProps) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const [adminMaintainId, setAdminMaintainId] = useState(props.defaultId);
  const [updatedSelectedValue, setUpdatedSelectedValue] = useState<any>(null);
  const [updatedInputValue, setUpdatedInputValue] = useState<IInputValue | null>(null);
  const { employeeDetail, isEditingAdminProfile, editEmployeeDetail, editEmployeeError } =
    useContext(AdminAccessEmployeeState) || {};
  const { setEditEmployeeDetail } = useContext(AdminAccessEmployeeAction) || {};
  const { personalInfo: personalInfoData, profile: profileData, passportDetail: passportData } = employeeDetail || {};
  const [textInputValue, setTextInputValue] = useState<string>(
    profileData?.isAdminEditedYOS ? profileData?.yearOfServiceByAdmin : profileData?.yearOfService.years,
  );
  const employeePassport = passportData.find((item: Employee.PassportInfo) => item.dependentId === null) || {};
  const isSpecialRetireeProfile = !!(profileData && isRetireeSpecialProfile(profileData));

  const PAYMENT_CONCEPT_OPTIONS = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.paymentConcept,
  );

  const [PRP_CONFIGURATIONS_LABELS, FWN_CONFIGURATIONS_LABELS] = _.partition(
    configurations.labels.filter((item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.paymentMedium),
    (item: Configuration.CodeLabel) => item.code === PAYMENT_MEDIUM.CREDIT_CARD_PAYMENT,
  );

  const emptyMediumOptions: Configuration.CodeLabel[] = [
    { category: LABEL_CATEGORY.paymentMedium, code: en.common.empty, label: en.common.empty },
  ];
  const PAYMENT_MEDIUM_FWN_OPTIONS = [...emptyMediumOptions, ...FWN_CONFIGURATIONS_LABELS];
  const PAYMENT_MEDIUM_PRP_OPTIONS = [...emptyMediumOptions, ...PRP_CONFIGURATIONS_LABELS];

  // 1. Call api to get api from the backend
  // Since api not ready, now just use Sample data.
  const {
    all,
    profile,
    employment,
    payment,
    benefit,
    contact,
    passport,
    flownSuspension,
    retireeWidowBadgeInformation,
  } = en.admin.adminToggleList;

  const { personalInformation } = en.admin.feature;
  const {
    profileDetails: profileDetailsLbl,
    employmentDetails: employmentDetailsLbl,
    paymentDetails: paymentDetailsLbl,
    benefitDetails: benefitDetailsLbl,
    flownSuspensionDetails: flownSuspensionDetailsLbl,
    contactDetails: contactDetailsLbl,
    passportDetails: passportDetailsLbl,
    retireeWidowBadgeInformationDetails: retireeWidowBadgeInformationDetailsLbl,
  } = personalInformation;

  const handleUpdateValue = (selectType: string, value: string) => {
    setUpdatedSelectedValue({ selectType, value });
  };

  const handleUpdateInput = (inputType: string, value: string) => {
    setUpdatedInputValue({ inputType, value });
    setTextInputValue(value);
  };

  // hide "Edit" button flag, need to hide "flown suspension edit UI" & "clear billing agreement"
  // etp-5019 added: hide "Edit" button for retiree special profile
  const isShowAdminEmployeeEditAction = !isSpecialRetireeProfile && window.config.isShowAdminEditMode;

  // construct Payment Setup Status Label & Type
  const { labelValue: paymentStatusLabelValue, labelType: paymentStatusLabelType } = getPaymentStatusLabelInfo({
    profileData,
    editEmployeeDetail,
    isEditingAdminProfile,
  });

  // etp-4808 added: benefit fields as view only even under edit mode
  const isBenefitJobLevelFieldsEditable = false;

  // ETP-5019 consolidate the travel end date
  const getTravelEndDate = () => {
    // retiree widow role
    if (isRetireeWidow(profileData) && profileData?.retireeBadgeExpirationDate)
      return formatDMYDateWithSpace(profileData.retireeBadgeExpirationDate);

    // special retiree profile
    if (isSpecialRetireeProfile)
      return profileData?.normalRetirementTravelEndDate
        ? formatDMYDateWithSpace(profileData.normalRetirementTravelEndDate)
        : en.common.empty;

    // default
    return formatDMYDateWithSpace(profileData.travelEndDate);
  };

  // 2. Massage data for display
  const massagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        label: profileDetailsLbl.title,
        value: personalInfoData?.title,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.firstName,
        value: personalInfoData?.firstName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.middleName,
        value: personalInfoData?.middleName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.surname,
        value: personalInfoData?.lastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.preferredFirstName,
        value: personalInfoData?.firstNamePreferred,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.preferredLastName,
        value: personalInfoData?.lastNamePreferred,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.gender,
        value: personalInfoData?.gender,
        type: LABEL_VALUE_TYPE.CODE_LABELS,
        configurationField: LABEL_CATEGORY.gender,
        confidential: true,
        column: 4,
      },
      {
        label: profileDetailsLbl.dateOfBirth,
        value: formatDMYDateWithSpace(personalInfoData?.dateOfBirth),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: true,
        column: 4,
      },
      {
        label: profileDetailsLbl.employeeID,
        value: personalInfoData?.employeeId,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.company,
        value: profileData.hiringCompany,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.countryOfResidence,
        value: !isRetireeWidow(profileData)
          ? personalInfoData?.countryOfResidential
          : profileData?.retireeWidow?.countryOfResidential
          ? profileData.retireeWidow.countryOfResidential
          : en.common.empty,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.maritalStatus,
        value: personalInfoData?.maritalStatus,
        type: LABEL_VALUE_TYPE.STRING,
        options: en.labels.maritalStatusOptions, // Need to get from configuration
        column: 4,
      },
    ],
    employmentDetails: [
      {
        label: employmentDetailsLbl.jobLevel,
        value: profileData.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.jobTitle,
        value: profileData.jobTitle,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.department,
        value: profileData.highLevelOfDepartment,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.contractType,
        value: profileData.contractType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.employmentType,
        value: profileData.employmentType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.poiType,
        value: profileData.personOfInterestType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.officerCode,
        value: profileData.officerCode,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.hrStatus,
        value: profileData.hrStatus,
        type: LABEL_VALUE_TYPE.STRING,
        options: en.labels.employmentStatusOptions,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceStartDate,
        value: formatDMYDateWithSpace(profileData.lastHireDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceEndDate,
        value: formatDMYDateWithSpace(profileData.terminationDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.travelEndDate,
        value: getTravelEndDate(),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.resignationCode,
        value: profileData.terminationActionReason,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.employeeCategory,
        value: profileData.employeeClassification,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.fullOrPartTime,
        value: profileData.fullPartTime,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.regularOrTemp,
        value: profileData.regularTemporary,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.domicilePort,
        value: profileData.domicilePort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.recruitmentPort,
        value: profileData.recruitmentPort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.countryOfEmployment,
        value: profileData.regulatoryRegion,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.workingLocation,
        value: profileData.location,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.homePort,
        value: profileData.homePort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.houseStaff,
        value: profileData.isHouseStaff,
        options: [en.common.yes, en.common.no],
        type: LABEL_VALUE_TYPE.BOOLEAN,
        confidential: false,
        column: 4,
      },
    ],
    retireeWidowBadgeInformationDetails: [
      {
        label: retireeWidowBadgeInformationDetailsLbl.badgeNumber,
        value: profileData.retireeBadgeNumber,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: retireeWidowBadgeInformationDetailsLbl.badgeEffectiveDate,
        value: formatDMYDateWithSpace(profileData.retireeBadgeEffectiveDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: retireeWidowBadgeInformationDetailsLbl.badgeExpiryDate,
        value: formatDMYDateWithSpace(profileData.retireeBadgeExpirationDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    paymentDetails: [
      {
        label: paymentDetailsLbl.paymentConcept,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentConcept, profileData.paymentConcept),
        type: isEditingAdminProfile ? LABEL_VALUE_TYPE.FORM_SELECT : LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept,
          value: editEmployeeDetail?.payment?.paymentConcept ?? profileData.paymentConcept,
        },
        selectOptions: PAYMENT_CONCEPT_OPTIONS,
        setSelectedValue: handleUpdateValue,
      },
      {
        label: paymentDetailsLbl.paymentMedium,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentMedium, profileData.paymentMedium),
        type: isEditingAdminProfile ? LABEL_VALUE_TYPE.FORM_SELECT : LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        defaultProps: {
          type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium,
          value: editEmployeeDetail?.payment?.paymentMedium ?? profileData.paymentMedium,
        },
        selectOptions:
          (editEmployeeDetail?.payment?.paymentConcept ?? profileData.paymentConcept) === PAYMENT_CONCEPT.FLOWN
            ? PAYMENT_MEDIUM_FWN_OPTIONS
            : PAYMENT_MEDIUM_PRP_OPTIONS,
        setSelectedValue: handleUpdateValue,
      },
      // Payment set up status is just for direct debit if edit mode
      ...(isPaymentMediumDirectDebit({
        // select direct
        ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData }),
        ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
      }) ||
      isPaymentMediumPaypal({
        // select paypal
        ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData }),
        ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
      })
        ? [
            {
              label: paymentDetailsLbl.paymentSetUpStatus,
              value: paymentStatusLabelValue,
              options: [en.common.yes, en.common.no],
              type: paymentStatusLabelType,
              confidential: false,
              column: editEmployeeDetail?.payment?.paymentConcept === PAYMENT_MEDIUM.MANUAL_PAYMENT ? 0 : 4,
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus,
                // if select "Direct Debit"(boolean type), will need value checking
                value:
                  // use edit data first
                  editEmployeeDetail?.payment?.paymentStatus ||
                  // use BE profile data if not edit
                  PAYMENT_STATUS_OPTIONS.find((item) => item.value === profileData.directDebitStatus)?.label ||
                  // default "empty"
                  en.common.empty,
              },
              selectOptions: PAYMENT_STATUS_OPTIONS,
              setSelectedValue: handleUpdateValue,
            },
          ]
        : [{ label: '', value: '', type: LABEL_VALUE_TYPE.BOOLEAN, confidential: false, column: 4 }]),
    ],
    benefitDetails: [
      {
        // dt job level
        label: benefitDetailsLbl.benefitJobLevelDuty,
        value: isRetireeWidow(profileData) ? en.common.empty : profileData.benefitDTJobLevel.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        // defaultProps for "edit" only, no need in view mode
        // same as benefit details 6 fields
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.benefitDTJobLevel,
                value: constructDefaultJobLevelValue(
                  editEmployeeDetail?.benefit?.benefitDTJobLevel,
                  profileData.benefitDTJobLevel.jobLevel,
                ),
              },
              selectOptions: BENEFIT_JOB_LEVEL_OPTIONS,
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        // dt from date
        label: benefitDetailsLbl.effectiveFromDateDuty,
        value:
          // if jobLevel is null, show "--". or show backend date (date is null show infinite date)
          // same logic as dt to date, lt from & to date
          isRetireeWidow(profileData)
            ? en.common.empty
            : formatDMYDateWithSpace(
                !profileData.benefitDTJobLevel.jobLevel ? '' : profileData.benefitDTJobLevel.from ?? INFINITE_DATE,
              ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateDuty,
                value: editEmployeeDetail?.benefit?.effectiveFromDateDuty
                  ? formatDMYDateWithSlash(editEmployeeDetail?.benefit?.effectiveFromDateDuty)
                  : formatDMYDateWithSlash(
                      editEmployeeDetail?.benefit?.effectiveFromDateDuty === '' ||
                        !profileData.benefitDTJobLevel.jobLevel
                        ? ''
                        : profileData.benefitDTJobLevel.from ?? INFINITE_DATE,
                    ),
                errorMessage: editEmployeeError?.benefitDTJobLevel?.from,
              },
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        // dt to date
        label: benefitDetailsLbl.effectiveToDateDuty,
        value: isRetireeWidow(profileData)
          ? en.common.empty
          : formatDMYDateWithSpace(
              !profileData.benefitDTJobLevel.jobLevel ? '' : profileData.benefitDTJobLevel.to ?? INFINITE_DATE,
            ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateDuty,
                value: editEmployeeDetail?.benefit?.effectiveToDateDuty
                  ? formatDMYDateWithSlash(editEmployeeDetail?.benefit?.effectiveToDateDuty)
                  : formatDMYDateWithSlash(
                      editEmployeeDetail?.benefit?.effectiveToDateDuty === '' || !profileData.benefitDTJobLevel.jobLevel
                        ? ''
                        : profileData.benefitDTJobLevel.to ?? INFINITE_DATE,
                    ),
                errorMessage: editEmployeeError?.benefitDTJobLevel?.to,
              },
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        // lt job level
        label: benefitDetailsLbl.benefitJobLevelLeisure,
        value: profileData.benefitLTJobLevel.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.benefitLTJobLevel,
                value: constructDefaultJobLevelValue(
                  editEmployeeDetail?.benefit?.benefitLTJobLevel,
                  profileData.benefitLTJobLevel.jobLevel,
                ),
              },
              selectOptions: BENEFIT_JOB_LEVEL_OPTIONS,
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        // lt from date
        label: benefitDetailsLbl.effectiveFromDateLeisure,
        value: formatDMYDateWithSpace(
          !profileData.benefitLTJobLevel.jobLevel ? '' : profileData.benefitLTJobLevel.from ?? INFINITE_DATE,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateLeisure,
                value: editEmployeeDetail?.benefit?.effectiveFromDateLeisure
                  ? formatDMYDateWithSlash(editEmployeeDetail?.benefit?.effectiveFromDateLeisure)
                  : formatDMYDateWithSlash(
                      editEmployeeDetail?.benefit?.effectiveFromDateLeisure === '' ||
                        !profileData.benefitLTJobLevel.jobLevel
                        ? ''
                        : profileData.benefitLTJobLevel.from ?? INFINITE_DATE,
                    ),
                errorMessage: editEmployeeError?.benefitLTJobLevel?.from,
              },
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        // lt to date
        label: benefitDetailsLbl.effectiveToDateLeisure,
        value: formatDMYDateWithSpace(
          !profileData.benefitLTJobLevel.jobLevel ? '' : profileData.benefitLTJobLevel.to ?? INFINITE_DATE,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
        ...(isBenefitJobLevelFieldsEditable
          ? {
              defaultProps: {
                type: ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateLeisure,
                value: editEmployeeDetail?.benefit?.effectiveToDateLeisure
                  ? formatDMYDateWithSlash(editEmployeeDetail?.benefit?.effectiveToDateLeisure)
                  : formatDMYDateWithSlash(
                      editEmployeeDetail?.benefit?.effectiveToDateLeisure === '' ||
                        !profileData.benefitLTJobLevel.jobLevel
                        ? ''
                        : profileData.benefitLTJobLevel.to ?? INFINITE_DATE,
                    ),
                errorMessage: editEmployeeError?.benefitLTJobLevel?.to,
              },
              setSelectedValue: handleUpdateValue,
            }
          : {}),
      },
      {
        label: benefitDetailsLbl.profileStatus,
        value: profileData.isActive,
        options: [en.common.active, en.common.inactive],
        type: LABEL_VALUE_TYPE.BOOLEAN,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.profileType,
        value: profileData.type,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 8,
      },
      {
        label: benefitDetailsLbl.age,
        value: personalInfoData?.age || en.common.empty,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.yearOfService,
        value:
          isRetireeWidow(profileData) && profileData?.isAdminEditedYOS
            ? `${profileData?.yearOfServiceByAdmin} ${en.dateUnit.years}`
            : `${profileData?.yearOfService.years} ${en.dateUnit.years} ${profileData?.yearOfService.months} ${en.dateUnit.months} ${profileData?.yearOfService.days} ${en.dateUnit.days}`,
        type:
          isEditingAdminProfile && isRetireeWidow(profileData)
            ? LABEL_VALUE_TYPE.FORM_FIELD_TEXT_INPUT
            : LABEL_VALUE_TYPE.STRING,
        defaultInputProps: {
          type: ADMIN_PERSONALINFO_INPUT_TYPE.yearOfService,
          value: textInputValue,
          regexForInputOnChange: { isNum: true },
        },
        setOnChange: handleUpdateInput,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.companySeniorityDate,
        value: formatDMYDateWithSpace(profileData.companySeniorityDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],

    flownSuspensionDetails: isShowAdminEmployeeEditAction
      ? [
          {
            label: isEditingAdminProfile
              ? `${flownSuspensionDetailsLbl.newFlownSuspensionStartDate} ${benefitDetailsLbl.dateEditFormat}`
              : flownSuspensionDetailsLbl.flownSuspensionStartDate,
            value: en.common.empty,
            type: isEditingAdminProfile ? LABEL_VALUE_TYPE.FORM_DATE_PICKER : LABEL_VALUE_TYPE.STRING,
            confidential: false,
            column: 4,
            defaultProps: {
              type: ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate,
              value: editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate
                ? formatDMYDateWithSlash(editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate)
                : '',
              errorMessage: editEmployeeError?.flownSuspension?.from,
            },
            setSelectedValue: handleUpdateValue,
          },
          {
            label: isEditingAdminProfile
              ? `${flownSuspensionDetailsLbl.newFlownSuspensionEndDate} ${benefitDetailsLbl.dateEditFormat}`
              : flownSuspensionDetailsLbl.flownSuspensionEndDate,
            value: en.common.empty,
            type: isEditingAdminProfile ? LABEL_VALUE_TYPE.FORM_DATE_PICKER : LABEL_VALUE_TYPE.STRING,
            confidential: false,
            column: 4,
            defaultProps: {
              type: ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate,
              value: editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate
                ? formatDMYDateWithSlash(editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate)
                : '',
              errorMessage: editEmployeeError?.flownSuspension?.to,
            },
            setSelectedValue: handleUpdateValue,
          },
        ]
      : [], // hide "flown suspension" edit UI
    contactDetails: [
      {
        label: contactDetailsLbl.personalEmail,
        value: personalInfoData?.personalEmail,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.officeEmail,
        value: personalInfoData?.businessEmail,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.phoneNumberMobile,
        value: personalInfoData?.mobilePhone,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.phoneNumberOffice,
        value: personalInfoData?.workPhone,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    passportDetails: [
      {
        label: passportDetailsLbl.passportNumber,
        value: employeePassport.passportNumber,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.firstOtherNameAsInPassport,
        value: employeePassport.passportFirstName || employeePassport.passportLastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.surnameAsInPassport,
        value: employeePassport.passportLastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.passportExpiryDate,
        value: formatDMYDateWithSpace(employeePassport.passportExpirationDate),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.nationalityInPassport,
        value: employeePassport.passportNationality,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.issuedCountry,
        value: employeePassport.passportCountry,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.countryOfResidence,
        value: personalInfoData?.countryOfResidential,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
  };

  // The second toggle bar tab array
  const secondToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: 1,
      title: all,
      component: <></>,
    },
    {
      id: 2,
      title: profile,
      component: <></>,
    },
    {
      id: 3,
      title: employment,
      component: <></>,
    },
    {
      id: 4,
      title: retireeWidowBadgeInformation,
      component: <></>,
      isDisplayByRetireeWidowRole: true,
    },
    {
      id: 5,
      title: payment,
      component: <></>,
    },
    {
      id: 6,
      title: flownSuspension,
      component: <></>,
    },
    {
      id: 7,
      title: benefit,
      component: <></>,
    },
    {
      id: 8,
      title: contact,
      component: <></>,
    },
    {
      id: 9,
      title: passport,
      component: <></>,
    },
  ];

  useEffect(() => {
    // update edit mode data
    if (updatedSelectedValue || updatedInputValue) {
      const updatedSelectType = updatedSelectedValue?.selectType;
      const updatedSelectValue = updatedSelectedValue?.value;

      const paymentConceptValue = editEmployeeDetail?.payment?.paymentConcept;
      const paymentMediumValue = editEmployeeDetail?.payment?.paymentMedium;
      const paymentStatusValue = editEmployeeDetail?.payment?.paymentStatus;
      const benefitDTJobLevelValue = editEmployeeDetail?.benefit?.benefitDTJobLevel; // dt level
      const effectiveFromDateDuty = editEmployeeDetail?.benefit?.effectiveFromDateDuty; // dt from date
      const effectiveToDateDuty = editEmployeeDetail?.benefit?.effectiveToDateDuty; // dt to date
      const benefitLTJobLevelValue = editEmployeeDetail?.benefit?.benefitLTJobLevel; // lt level
      const effectiveFromDateLeisure = editEmployeeDetail?.benefit?.effectiveFromDateLeisure; // lt from date
      const effectiveToDateLeisure = editEmployeeDetail?.benefit?.effectiveToDateLeisure; // lt to date
      const flownSuspensionStartDate = editEmployeeDetail?.flownSuspension?.flownSuspensionStartDate; // add suspension start date
      const flownSuspensionEndDate = editEmployeeDetail?.flownSuspension?.flownSuspensionEndDate; // add suspension end date

      // if select payment 3 fields
      const isUpdatePaymentFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus;

      // if select dt 3 fields
      const isUpdateBenefitDTFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.benefitDTJobLevel ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateDuty ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateDuty;

      // if select lt 3 fields
      const isUpdateBenefitLTFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.benefitLTJobLevel ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateLeisure ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateLeisure;

      // if edit flown suspension 2 fields
      const isUpdateFlownSuspensionFields =
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate ||
        updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate;

      // handle select data
      setEditEmployeeDetail({
        ...editEmployeeDetail,
        ...(isUpdatePaymentFields && {
          payment: {
            paymentConcept:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept
                ? updatedSelectValue
                : paymentConceptValue ?? profileData.paymentConcept,
            paymentMedium:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentConcept
                ? updatedSelectValue === PAYMENT_CONCEPT.FLOWN
                  ? en.common.empty // need to clear medium select data if payment concept is changed
                  : PAYMENT_MEDIUM_PRP_OPTIONS[1].code // if payment concept is prepaid, set default medium to credit card
                : updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentMedium
                ? updatedSelectValue
                : paymentMediumValue ?? profileData.paymentMedium ?? en.common.empty,
            paymentStatus:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.paymentStatus
                ? updatedSelectValue
                : paymentStatusValue,
          },
        }),

        benefit: {
          ...editEmployeeDetail?.benefit,
          ...(isUpdateBenefitDTFields && {
            benefitDTJobLevel:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.benefitDTJobLevel
                ? updatedSelectValue
                : benefitDTJobLevelValue ?? profileData.benefitDTJobLevel.jobLevel ?? en.common.empty,
            effectiveFromDateDuty:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateDuty
                ? updatedSelectValue
                : effectiveFromDateDuty ?? profileData.benefitDTJobLevel.from,
            effectiveToDateDuty:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateDuty
                ? updatedSelectValue
                : effectiveToDateDuty ?? profileData.benefitDTJobLevel.to,
          }),
          yearOfServiceByAdmin:
            updatedInputValue?.inputType === ADMIN_PERSONALINFO_INPUT_TYPE.yearOfService
              ? parseInt(updatedInputValue.value)
              : undefined,
          ...(isUpdateBenefitLTFields && {
            benefitLTJobLevel:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.benefitLTJobLevel
                ? updatedSelectValue
                : benefitLTJobLevelValue ?? profileData.benefitLTJobLevel.jobLevel ?? en.common.empty,
            effectiveFromDateLeisure:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveFromDateLeisure
                ? updatedSelectValue
                : effectiveFromDateLeisure ?? profileData.benefitLTJobLevel.from,
            effectiveToDateLeisure:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.effectiveToDateLeisure
                ? updatedSelectValue
                : effectiveToDateLeisure ?? profileData.benefitLTJobLevel.to,
          }),
        },

        ...(isUpdateFlownSuspensionFields && {
          flownSuspension: {
            flownSuspensionStartDate:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate
                ? updatedSelectValue
                : flownSuspensionStartDate,
            flownSuspensionEndDate:
              updatedSelectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate
                ? updatedSelectValue
                : flownSuspensionEndDate,
          },
        }),
      });
    }
  }, [updatedSelectedValue, updatedInputValue]);

  return (
    <>
      <AdminToggleButtonGroup
        list={
          isRetireeWidow(profileData)
            ? secondToggleBarTabList
            : secondToggleBarTabList.filter((item) => item.isDisplayByRetireeWidowRole === undefined) //Badge Information tap will only display when profile type is retiree or widow
        }
        onClick={(e: number) => {
          setAdminMaintainId(e);
        }}
      />

      {/* Profile Detail */}
      {Object.entries(massagedPersonalInfoData).map(([key, value]: [string, IDisplayDetails[]]) => {
        const matchItem = secondToggleBarTabList.find((item) => item.id === adminMaintainId);
        const isFlownSuspensionItem =
          matchItem?.title === flownSuspension || (matchItem?.title === all && key === flownSuspensionDetailsLbl.title);

        // comment in etp-4808 story
        // const isShowBillingAgreementItem =
        //   matchItem?.title === payment || (matchItem?.title === all && key === paymentDetailsLbl.title);

        // TODO: In future enhancement story, tune on isShowBillingAgreementItem (base on EMPLOYEE type profile data)
        // in special retiree profile mode: need to hide "clear billing agreement" button (isShowAdminEmployeeEditAction:false)
        const isShowBillingAgreementItem = false; // etp-4808 added: to hide button
        if (!isRetireeWidow(profileData) && key === retireeWidowBadgeInformationDetailsLbl.title) {
          // making sure Badge information only display when profile.type==="RETIREE or WIDOW"
          return;
        }
        if (
          matchItem?.title === all ||
          key.toUpperCase()?.includes(matchItem?.title?.replace(/\s/g, '').toUpperCase() || '')
        ) {
          return (
            <>
              <DetailsContainer key={key} title={personalInformation[key as keyof typeof personalInformation].heading}>
                <Grid container spacing={2}>
                  {value.map((data: IDisplayDetails, index: number) => {
                    return <LabelValueContainer key={index} {...data} />;
                  })}
                </Grid>
              </DetailsContainer>

              {isShowBillingAgreementItem && isShowAdminEmployeeEditAction && <ClearBillingAgreementContainer />}
              {isFlownSuspensionItem && <AdminFlownSuspensionList />}
            </>
          );
        }
      })}
    </>
  );
};

export default AdminPersonalInfoDetails;
