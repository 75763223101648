import React from 'react';
import { Box, Typography, CardActionArea, useTheme } from '@mui/material';

import { RightArrowIcon, ExclamationIcon } from '../../../assets/images';

import { IUserProfileList, IUserProfileListProps } from '../../../interfaces';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { ETPLink } from '../../../components';

const UserProfileNavBar = ({ list, header }: IUserProfileListProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const textColor = theme.color.secondary.dark_grey.option_1;

  return (
    <Box
      className="user_profile_nav_bar_section"
      sx={{
        width: '100%',
        ...(isDesktop
          ? {
              mb: 3,
              display: 'grid',
              'grid-column-gap': '16px',
              'grid-template-columns': '1fr 1fr 1fr',
              'place-content': 'space-around space-evenly',
            }
          : {
              display: 'flex',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              overflow: 'hidden',
              borderRadius: 1,
              boxShadow: theme.boxShadow.important,
              mb: 2,
            }),
      }}
    >
      {!isDesktop && (
        <Typography
          variant="headline_medium"
          sx={{
            color: textColor,
            mt: 2,
            px: 2,
          }}
        >
          {header}
        </Typography>
      )}

      {list.map((item: IUserProfileList) => (
        <Box
          key={item.title}
          sx={{
            ...(!isDesktop && {
              width: '100%',
              '.card_action_content': {
                borderBottom: `1px solid ${theme.color.secondary.slate.option_3}`,
              },
              '&: last-of-type': {
                '.card_action_content': {
                  borderBottom: 'none',
                },
              },
            }),
          }}
        >
          <CardActionArea
            component={ETPLink}
            theme={theme}
            to={item.url}
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              '& .MuiCardActionArea-focusHighlight': {
                backgroundColor: 'transparent',
              },
              ...(isDesktop && {
                overflow: 'hidden',
                borderRadius: 1,
                boxShadow: theme.boxShadow.important,
              }),
            }}
          >
            <Box
              className="card_action_content"
              sx={{
                display: 'flex',
                width: '100%',
                bgcolor: 'white',
                ...(isDesktop
                  ? {
                      p: 2,
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '96px',
                    }
                  : {
                      py: 2,
                      mx: 2,
                      alignItems: 'center',
                    }),
              }}
            >
              {isDesktop ? item.desktopIcon : item.icon}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  ...(!isDesktop && {
                    width: '100%',
                  }),
                }}
              >
                <Typography
                  variant="headline_medium"
                  sx={{
                    flex: 1,
                    color: textColor,
                  }}
                >
                  {item.title}
                </Typography>
                {item?.isShowWarning && (
                  <ExclamationIcon
                    width={24}
                    height={24}
                    style={{ padding: '4px 4px' }}
                    fill={theme.color.utility.error.option_3}
                  />
                )}
                <RightArrowIcon
                  width={24}
                  height={24}
                  style={{ padding: '5px 8px' }}
                  fill={theme.color.utility.link.option_3}
                />
              </Box>
            </Box>
          </CardActionArea>
        </Box>
      ))}
    </Box>
  );
};

export default UserProfileNavBar;
