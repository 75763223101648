import React, { Dispatch, SetStateAction } from 'react';
import { Box, Grid, List, ListItemButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { UI_STYLES, USER_ROLE } from '../../../../constants/constants';
import { CompanyLogo } from '../../../../assets/images';

import { Configuration } from 'configuration';

import { formatAirportValue, setInputValue } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';

import NoMatchResult from './NoMatchResult';
import { MatchAndHighlightLabel, ScrollableView } from '../../../../components';

const FilterView = ({
  filteredPortList,
  theme,
  styles,
  chooseAirport,
  keyword,
}: {
  filteredPortList: Configuration.Airport[] | null;
  theme: Theme;
  styles: SxProps;
  chooseAirport: (item: Configuration.Airport) => void;
  keyword: string;
}) => {
  if (filteredPortList && filteredPortList.length === 0) {
    return <NoMatchResult boxStyles={styles} />;
  }
  return (
    <List>
      {filteredPortList &&
        filteredPortList.map((item: Configuration.Airport) => (
          <ListItemButton
            sx={{
              height: '64px',
            }}
            key={item.airportCode}
            onClick={() => {
              chooseAirport(item);
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  bgcolor: theme.color.secondary.light_slate.option_6,
                  borderRadius: theme.spacing(0.5),
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant="navigation_bold"
              >
                {item.airportCode}
              </Typography>
              {item.isOperatedByCX && <CompanyLogo width="24px" height="24px" style={{ paddingLeft: '12px' }} />}
            </Box>
            <Typography flex="1" variant="body_2_regular" sx={{ ml: 1 }}>
              {keyword.length > 1 && (
                <MatchAndHighlightLabel label={item?.label || ''} keyword={keyword} fontWeight={900} />
              )}
            </Typography>
          </ListItemButton>
        ))}
    </List>
  );
};

const MobileODFilterList = ({
  originRef,
  destinationRef,
  filterODPortList,
  previousFieldIsOrigin,
  withFooter,
  keyword,
  setCurrentOrigin,
  setCurrentDestination,
}: {
  originRef: React.RefObject<HTMLInputElement>;
  destinationRef: React.RefObject<HTMLInputElement>;
  filterODPortList: Configuration.Airport[] | null;
  previousFieldIsOrigin: boolean;
  withFooter?: boolean;
  keyword: string;
  setCurrentOrigin: Dispatch<SetStateAction<Configuration.Airport | null>>;
  setCurrentDestination: Dispatch<SetStateAction<Configuration.Airport | null>>;
}) => {
  const theme = useTheme();

  const chooseAirport = (item: Configuration.Airport) => {
    const { current } = previousFieldIsOrigin ? originRef : destinationRef;

    if (current) {
      setInputValue(current, formatAirportValue(item));

      if (previousFieldIsOrigin) {
        setCurrentOrigin(item);
        if (destinationRef.current) {
          destinationRef.current.focus();
        }
      } else {
        setCurrentDestination(item);
      }
    }
  };

  const screenHeight = use100vh();
  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType } = role || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  return (
    <Grid
      component={ScrollableView}
      sx={{
        height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightSearchCriteria}  - ${
          withFooter ? UI_STYLES.footerHeight : '0px'
        } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'} - 160px )`, //160px -> od content Height + paddingTop
        mx: -2,
      }}
    >
      <FilterView
        keyword={keyword}
        filteredPortList={filterODPortList}
        theme={theme}
        styles={{ mt: 4 }}
        chooseAirport={chooseAirport}
      />
    </Grid>
  );
};

export default MobileODFilterList;
