/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';

import en from '../../translations/en';
import { BOOKING_STATUS } from '../../constants/constants';

import { Frontend, Pnr } from 'booking';

import {
  setBookingStep,
  setIsCalledPrepaidAuth,
  setRetrieveAndPayCppParams,
  setPrepaidAuthFlowType,
  setTpAppRef,
  setViewBookingApplicationId,
} from '../../slice/bookingSlice';
import { setRetrieveAndPaySuccessData, setNormalPrepaidSuccessData } from '../../slice/appSlice';

import { getBookingStatus, getPassengerDisplayName, getTicketsBySegment } from '../../helpers';
import { showErrorAlert } from '../../services/api';

import { useAppDispatch } from '../../app/hooks';

import { ShadowContent } from '../../components';
import { FlightInfo, EmptyContent } from '../../containers';

enum BOOKING_LAYOUT_VARIANT {
  HOME_PAGE = 'homePage',
  MY_BOOKING_PAGE = 'myBookingPage',
  ADMIN_BOOKING_PAGE = 'adminBookingPage',
}

const TravelerLayout = ({ tickets, bookingStatus }: { tickets: Pnr.TicketByVersion[]; bookingStatus: string }) => {
  const theme = useTheme();
  const paxTravelers: string[] = [];

  for (let i = 0; i < tickets.length; i++) {
    const latestTicket: Pnr.Ticket = tickets[i].latestTicket || {};
    const { paxObj } = latestTicket || {};

    paxTravelers.push(
      getPassengerDisplayName({
        lastName: paxObj?.lastName,
        firstName: paxObj.firstName,
      }),
    );
  }

  const travelers: string[] = Array.from(new Set(paxTravelers));

  return (
    <>
      <Divider
        light
        sx={{
          mt: 2,
          mb: 3,
          ml: 13,
          width: '724px',
        }}
      />
      <Box sx={{ ml: 13 }}>
        <Grid container spacing={1} sx={{ width: '724px' }}>
          {travelers &&
            travelers.map((traveler: string, index: number) => {
              return (
                <>
                  <Typography
                    variant="body_2_medium"
                    color={
                      bookingStatus === BOOKING_STATUS.cancelled
                        ? theme.color.secondary.grey.option_4
                        : theme.color.secondary.dark_grey.option_1
                    }
                    sx={{
                      display: 'flex',
                    }}
                  >
                    {index > 0 && (
                      <Typography
                        sx={{
                          color: theme.color.secondary.dark_grey.option_7,
                          mx: 1,
                        }}
                      >
                        {'//'}
                      </Typography>
                    )}
                    {traveler}
                  </Typography>
                </>
              );
            })}
        </Grid>
      </Box>
    </>
  );
};

const BookingLayout = ({
  bookings,
  handleClick,
  emptyTitle,
  variant,
}: {
  bookings: Frontend.ParsedBooking[];
  emptyTitle: string;
  handleClick: (selectedItem: Frontend.ParsedBooking) => void;
  variant?: BOOKING_LAYOUT_VARIANT;
}) => {
  const dispatch = useAppDispatch();
  return (
    <Grid container columns={12} columnSpacing={2} className="upcoming_booking_layout_container">
      {bookings && bookings.length > 0 ? (
        bookings.map((bookingItem: Frontend.ParsedBooking) => {
          const {
            status = '',
            recLoc,
            type,
            ticketsBySegment,
            concession,
            prepaidStatus,
            etpPassengerList,
            paymentConcept,
            prepaidInfo,
          } = bookingItem || {};

          const { tickets } = ticketsBySegment?.[0] || {};

          const bookingStatus = getBookingStatus({
            status,
            prepaidStatus,
            paymentConcept,
            prepaidInfo,
          });

          return (
            <Grid
              item
              className="upcoming_booking_layout_item"
              md={variant === BOOKING_LAYOUT_VARIANT.HOME_PAGE ? 4 : 12}
              sm={variant === BOOKING_LAYOUT_VARIANT.HOME_PAGE ? 6 : 12}
              xs={12}
              key={bookingItem.applicationId}
            >
              <Button
                component={ShadowContent}
                sx={{
                  width: '100%',
                  mb: 2,
                  px: 2,
                  py: 2,
                  background: 'white',
                }}
                onClick={() => {
                  // [ETP-4379] clear normal prepaid flow + retrieve and pay flow related Redux data
                  dispatch(setIsCalledPrepaidAuth(false));
                  dispatch(setRetrieveAndPayCppParams(null));

                  dispatch(setBookingStep(null));

                  dispatch(setPrepaidAuthFlowType(''));
                  dispatch(setRetrieveAndPaySuccessData(null));
                  dispatch(setNormalPrepaidSuccessData(null));

                  dispatch(setViewBookingApplicationId(''));
                  dispatch(setTpAppRef(''));

                  if (bookingStatus === BOOKING_STATUS.pendingSupport) {
                    showErrorAlert({
                      title: ' ',
                      message: en.booking.confirmation.ticketWillUpdate,
                      noErrorAlert: true,
                    });
                    return;
                  }
                  handleClick(bookingItem);
                }}
              >
                <FlightInfo
                  bookingStatus={bookingStatus}
                  recLoc={recLoc}
                  type={type}
                  concessionName={concession.name}
                  ticketsBySegment={getTicketsBySegment(bookingStatus, ticketsBySegment)}
                  isViewBookingDetails={false}
                  variant={variant}
                  etpPassengerList={etpPassengerList}
                />
                {/* adminBooking show travelers */}
                {variant === BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE && tickets && (
                  <TravelerLayout tickets={tickets} bookingStatus={bookingStatus} />
                )}
              </Button>
            </Grid>
          );
        })
      ) : (
        <EmptyContent title={emptyTitle} />
      )}
    </Grid>
  );
};

BookingLayout.defaultProps = {
  variant: BOOKING_LAYOUT_VARIANT.HOME_PAGE,
};

export default BookingLayout;
export { BOOKING_LAYOUT_VARIANT };
