import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { IDetailsInfoProps } from '../../../interfaces';

import { ShadowContent } from '../../../components';
import { DetailsInfo } from '../..';

const BaseDetailsInfo = ({
  data,
  title,
  customStyles,
  childStyles,
  isRetrieveAndPayStatus = false,
}: {
  data?: IDetailsInfoProps[];
  title: string;
  customStyles?: SxProps<Theme>;
  childStyles?: SxProps<Theme>;
  isRetrieveAndPayStatus?: boolean;
}) => {
  return data ? (
    <Box component={ShadowContent} sx={{ mb: 2, ...customStyles }}>
      <DetailsInfo
        data={data}
        title={{ title }}
        childStyles={childStyles}
        isRetrieveAndPayStatus={isRetrieveAndPayStatus}
      />
    </Box>
  ) : null;
};

export default BaseDetailsInfo;
