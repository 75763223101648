import { Box, SxProps, Theme } from '@mui/material';

import { IContentProps } from '../../../containers/Common/Paragraph/ParagraphMasterContainer';

import { ParagraphMasterContainer } from '../../../containers';

interface IMessageCartItem {
  content: IContentProps[];
  messageCartItemCustomProps?: SxProps<Theme>;
}

const MessageCartItem = ({ content, messageCartItemCustomProps }: IMessageCartItem) => {
  return (
    <Box
      className="message_cart_item"
      sx={{
        backgroundColor: 'white',
        py: '13px',
        px: 2,
        mb: 1,
        borderRadius: '8px',
        ...messageCartItemCustomProps,
      }}
    >
      <ParagraphMasterContainer content={content} paragraphSxProps={{ pb: 0 }} />
    </Box>
  );
};

export default MessageCartItem;
