import React, { useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { merge } from 'lodash';

import en from '../../translations/en';
import { SALUTATION, TRAVELLER_TYPE, REGEX } from '../../constants/constants';
import { BOOKING_PATHS } from '../../constants/paths';
import { NavBarSearchIcon, DownArrowIcon } from '../../assets/images';
import theme from '../../style/theme';

import { getDisplayName } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { EditSearchBarState, EditSearchBarAction } from '../../context';

import { selectBooking, setTravellerInfo } from '../../slice/bookingSlice';

import { getErnEmpTraveller } from '../../services/admin/booking';

import { Header, TextInput, StyledOutlinedInput } from '../../components';

interface INonEmployeeFormData {
  salutation: string;
  firstName: string;
  lastName: string;
}

const EMPLOYEE_SEARCH_RESULT = {
  Default: '',
  NoMatch: 'NoMatch',
  NotEligible: 'NotEligible',
};

const EmployeeTraveller = ({ handleBackClick }: { handleBackClick?: () => void }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;

  const editSearchData = useContext(EditSearchBarState);
  const { setEditTravellerDetails } = useContext(EditSearchBarAction);

  const { travelDetails, travellerDetails } = isSearchResultPage ? editSearchData : useAppSelector(selectBooking) || {};
  const { id: concessionId } = travelDetails || {};
  const [filterKeyword, setFilterKeyword] = useState('');
  const [searchResult, setSearchResult] = useState<null | string>('');

  const searchErnEmpTravellerAction = async (event: any) => {
    const result = await getErnEmpTraveller(filterKeyword.toUpperCase(), concessionId);
    const { employee, isNoMatchingResult, isEmployeeNotEligible } = result || {};
    const { ern, firstName, lastName } = employee || {};

    if (isEmployeeNotEligible) {
      setSearchResult(EMPLOYEE_SEARCH_RESULT.NotEligible);
    } else if (isNoMatchingResult) {
      setSearchResult(EMPLOYEE_SEARCH_RESULT.NoMatch);
    } else {
      if (ern) {
        const travellerInfo = {
          employee: {
            ern: ern,
            name: getDisplayName({
              firstName,
              lastName,
            }),
          },
        };
        if (isSearchResultPage) {
          setEditTravellerDetails(
            merge({}, travellerDetails, {
              travellerInfo,
            }),
          );
        } else {
          dispatch(setTravellerInfo(travellerInfo));
        }
      } else {
        setSearchResult(null);
      }
      handleBackClick?.();
    }
    event.stopPropagation();
  };

  return (
    <>
      <StyledOutlinedInput
        autoFocus
        notched={false}
        inputProps={{ maxLength: 7 }}
        placeholder={en.booking.travelType.searchByErn}
        value={filterKeyword}
        onChange={(event: any) => {
          setFilterKeyword(event.target.value);
          setSearchResult(EMPLOYEE_SEARCH_RESULT.Default);
        }}
        onKeyPress={(event: any) => {
          if (event.key === 'Enter' && filterKeyword.length === 7) {
            searchErnEmpTravellerAction(event);
          }
        }}
        sx={{
          borderRadius: 2.5,
          height: '40px',
          width: 'calc(100% - 16px)',
          pl: 2.5,
          pr: 0.5,
          mt: 1.25,
          mx: 1,
          mb: 3,
          background: theme.color.secondary.light_slate.option_5,
          '& .MuiInputBase-input': {
            color: theme.color.secondary.dark_grey.option_3,
          },
        }}
        startAdornment={
          <InputAdornment
            position="start"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NavBarSearchIcon fill={theme.color.utility.link.option_3} />
          </InputAdornment>
        }
        endAdornment={
          filterKeyword.length === 7 && (
            <InputAdornment position="end">
              <IconButton
                sx={{
                  p: 0,
                }}
                onClick={(event: any) => {
                  searchErnEmpTravellerAction(event);
                }}
              >
                <Typography
                  variant="navigation_bold"
                  sx={{
                    width: '56px',
                    height: '32px',
                    lineHeight: '32px',
                    background: theme.color.utility.link.option_3,
                    borderRadius: 3,
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {en.booking.travelType.go}
                </Typography>
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {searchResult && (
        <Typography
          variant="body_2_regular"
          style={{
            whiteSpace: searchResult === EMPLOYEE_SEARCH_RESULT.NoMatch ? 'pre' : 'normal',
          }}
          sx={{
            textAlign: 'center',
            color: theme.color.secondary.dark_grey.option_3,
          }}
        >
          {searchResult === EMPLOYEE_SEARCH_RESULT.NoMatch
            ? `${en.booking.travelType.noErnResult} \n${en.booking.travelType.tryAgain}`
            : en.booking.travelType.searchErnNotEligible}
        </Typography>
      )}
    </>
  );
};

const NonEmployeeTraveller = ({ handleBackClick }: { handleBackClick?: () => void }) => {
  const { travellerDetails } = useContext(EditSearchBarState);
  const { setEditTravellerDetails } = useContext(EditSearchBarAction);
  const [formData, setFormData] = useState<INonEmployeeFormData>({
    salutation: '',
    firstName: '',
    lastName: '',
  });
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const confirmBtnStatus = useMemo(() => {
    return Object.values(formData).filter((item) => item).length === 3;
  }, [formData]);

  const history = useHistory();
  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;

  const confirmCallback = () => {
    const travellerInfo = {
      salutation: formData.salutation,
      firstName: formData.firstName,
      lastName: formData.lastName,
    };
    if (isSearchResultPage) {
      setEditTravellerDetails(
        merge({}, travellerDetails, {
          travellerInfo,
        }),
      );
    } else {
      dispatch(setTravellerInfo(travellerInfo));
    }
    handleBackClick?.();
  };

  return (
    <>
      <Box
        className="add_non_employee_traveller"
        sx={{
          px: 1,
          pt: '0.65rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl
            variant="filled"
            size="small"
            sx={{
              mb: 2,
              pr: 1.875,
              flex: 0.5,
            }}
          >
            <InputLabel color="info" sx={{ color: theme.color.secondary.grey.option_3 }}>
              {en.booking.travelType.salutation}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={formData.salutation}
              label={en.booking.travelType.salutation}
              IconComponent={DownArrowIcon}
              sx={{
                backgroundColor: 'white',
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
                borderRadius: 0.5,
                '&:hover': {
                  backgroundColor: 'white',
                },
                '&.Mui-focused': {
                  backgroundColor: 'white',
                },
                '&:before, &:after': {
                  display: 'none',
                },
                '& .MuiSelect-icon': {
                  top: 'calc(50% - 0.75em)',
                  right: '10px',
                },
              }}
              onChange={(event: any) => {
                setFormData({
                  ...formData,
                  salutation: event.target.value,
                });
              }}
              autoFocus
              inputProps={{ tabIndex: 1 }}
            >
              {SALUTATION.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl
            sx={{
              flex: 1,
              pr: 1.875,
              '.MuiFilledInput-input': {
                width: 'calc(100% - 24px)',
              },
            }}
            variant="outlined"
          >
            <TextInput
              variant="filled"
              label={en.common.firstNameAndOtherName}
              InputLabelProps={{
                sx: { color: theme.color.secondary.grey.option_3 },
              }}
              autoComplete="off"
              color="info"
              value={formData.firstName}
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                  borderRadius: 4,
                  color: theme.color.secondary.dark_grey.option_3,
                  ...theme.typography.body_1_medium,
                },
                disableUnderline: true,
              }}
              size="small"
              sx={{ m: 0 }}
              onChange={(event: any) => {
                const firstName = event.target.value.replace(REGEX.alphaOrSpace, '');
                setFormData({
                  ...formData,
                  firstName,
                });
              }}
              onKeyPress={(event) => {
                event.key === 'Enter' && confirmBtnStatus && confirmCallback();
                event.stopPropagation();
              }}
              inputProps={{ tabIndex: 2 }}
            />
            <FormHelperText
              sx={{
                mt: 0.5,
                mx: 0,
                color: theme.color.secondary.dark_grey.option_3,
              }}
            >
              {en.booking.travelType.samePassportName}
            </FormHelperText>
          </FormControl>
          <FormControl
            sx={{
              flex: 1,
              '.MuiFilledInput-input': {
                width: 'calc(100% - 24px)',
              },
            }}
            variant="outlined"
          >
            <TextInput
              variant="filled"
              label={en.common.lastName}
              InputLabelProps={{
                sx: { color: theme.color.secondary.grey.option_3 },
              }}
              autoComplete="off"
              color="info"
              value={formData.lastName}
              InputProps={{
                style: {
                  backgroundColor: 'white',
                  border: `1px solid ${theme.color.secondary.slate.option_3}`,
                  borderRadius: 4,
                  color: theme.color.secondary.dark_grey.option_3,
                  ...theme.typography.body_1_medium,
                },
                disableUnderline: true,
              }}
              size="small"
              sx={{ m: 0 }}
              onChange={(event: any) => {
                const lastName = event.target.value.replace(REGEX.alphaOrSpace, '');
                setFormData({
                  ...formData,
                  lastName,
                });
              }}
              onKeyPress={(event) => {
                event.key === 'Enter' && confirmBtnStatus && confirmCallback();
                event.stopPropagation();
              }}
              inputProps={{ tabIndex: 3 }}
            />
            <FormHelperText
              sx={{
                mt: 0.5,
                mx: 0,
                color: theme.color.secondary.dark_grey.option_3,
              }}
            >
              {en.booking.travelType.samePassportName}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      {confirmBtnStatus && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            borderTop: `1px solid ${theme.color.secondary.slate.option_3}`,
          }}
        >
          <Typography
            component={Button}
            variant="body_1_bold"
            sx={{
              width: '104px',
              height: '38px',
              borderRadius: 1,
              color: 'white',
              ml: 'auto',
              mr: 2,
              background: theme.color.utility.link.option_3,
              '&:hover': {
                background: theme.color.utility.link.option_3,
              },
              '&.Mui-disabled': {
                color: 'white',
              },
            }}
            onClick={(event: any) => {
              confirmCallback();
              event.stopPropagation();
            }}
            tabIndex={4}
          >
            {en.booking.flightClassStatus.confirm}
          </Typography>
        </Box>
      )}
    </>
  );
};

const AdminRoleAddTraveller = ({ handleBackClick }: { handleBackClick?: () => void }) => {
  const history = useHistory();
  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;

  const editSearchData = useContext(EditSearchBarState);
  const { travellerDetails } = isSearchResultPage ? editSearchData : useAppSelector(selectBooking) || {};
  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: 'white',
        borderRadius: 0.5,
        px: 1,
        pt: 2,
        position: 'relative',
      }}
    >
      <Header handleOnBack={handleBackClick} />
      <Typography
        variant="headline_medium"
        gutterBottom
        component="div"
        sx={{ mt: 2, pl: 1, color: theme.color.secondary.dark_grey.option_3 }}
      >
        {travellerDetails?.travellerInfo?.type === TRAVELLER_TYPE.employee
          ? en.booking.travelType.traveller
          : en.booking.travelType.nonEmployeeTraveller}
      </Typography>
      {travellerDetails?.travellerInfo?.type === TRAVELLER_TYPE.employee ? (
        <EmployeeTraveller handleBackClick={handleBackClick} />
      ) : (
        <NonEmployeeTraveller handleBackClick={handleBackClick} />
      )}
    </Box>
  );
};

export default AdminRoleAddTraveller;
