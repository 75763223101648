import { Box, useTheme, Typography, SxProps, Theme } from '@mui/material';

import en from '../../../../../translations/en';
import { CheckCircleIcon, CheckCircleOutlineIcon, InfoIcon, LockedIcon } from '../../../../../assets/images';

import { getEligibleCarrierJoinedText } from '../../../../../helpers';

import { INominationCartListProps } from './NominationCartMasterContainer';

import { CommonWarning } from '../../../../../components';

interface IDependentCartItem {
  dependentDetail: INominationCartListProps['dependentDetail'];
  dependentCartItemCustomProps?: SxProps<Theme>;
}

const DependentCartItem = ({ dependentDetail, dependentCartItemCustomProps }: IDependentCartItem) => {
  const theme = useTheme();

  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const {
    dependentName,
    relationship,
    eligibleCarrier,
    isLocked,
    isViewMode,
    isGreyedOut,
    isSelected,
    handleAction,
    changeTypeLabel,
    warningMessage: dependentWarningMessage,
  } = dependentDetail || {};

  const eligibleCarrierJoinedText = getEligibleCarrierJoinedText(eligibleCarrier);

  const isShowSelection = !isLocked && !isViewMode && !isGreyedOut;
  const isShowLockedIcon = isLocked && !isViewMode && !isGreyedOut;

  return (
    <Box
      className="dependent_cart_item"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: theme.palette.primary.light,
        marginBottom: 1,
        padding: 2,
        ...(!isViewMode && !isGreyedOut && { boxShadow: theme.palette.boxShadow.dark }),
        ...(isGreyedOut && {
          opacity: 0.8,
        }),
        ...dependentCartItemCustomProps,
      }}
    >
      <Box className="dependent_cart_item_left">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 0.75,
          }}
        >
          <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
            {dependentName}
          </Typography>
        </Box>

        {/* relationship layout */}
        <Box
          sx={{
            pb: 1,
          }}
        >
          <Typography variant="navigation_regular" color={theme.color.secondary.dark_grey.option_3}>
            {relationship}
          </Typography>
        </Box>

        {/* eligibility: cx, oneworld, zed layout */}
        {eligibleCarrierJoinedText && (
          <Box className="eligibility_tag" sx={{ display: 'flex', alignItems: 'center', pr: 0.5 }}>
            <Box
              sx={{
                backgroundColor: theme.color.secondary.dark_sand.option_6,
                borderRadius: '4px',
                padding: '1px 8px',
                mr: 0.5,
              }}
            >
              <Typography variant="fine_print_medium" color={theme.color.secondary.dark_grey.option_2}>
                {nominationFlowLbl.eligibilityTagName}
              </Typography>
            </Box>

            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {eligibleCarrierJoinedText}
            </Typography>
          </Box>
        )}

        {dependentWarningMessage && (
          <CommonWarning
            iconCustomStyles={{
              width: '11px',
              height: '11px',
              marginRight: '6px',
            }}
            customStyles={{ px: 0, mt: 1, mb: 0, ml: '2px' }}
            customIconFill={theme.color.utility.error.option_3}
            msgColor={theme.color.secondary.dark_grey.option_1}
            msgText={dependentWarningMessage}
            msgTextVariant={'navigation_regular'}
          />
        )}

        {/* nomination type change layout */}
        {!!changeTypeLabel && (
          <Box className="nomination_type_tag" sx={{ display: 'flex', alignItems: 'center', pr: 0.5 }}>
            <InfoIcon width="16" height="16" fill={theme.color.utility.link.option_3} />
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2} sx={{ ml: 0.5 }}>
              {`${nominationFlowLbl.maySelectAs} ${changeTypeLabel}`}
            </Typography>
          </Box>
        )}
      </Box>

      <Box className="dependent_cart_item_right" sx={{ ml: 'auto', pl: '16px' }}>
        {isShowSelection && (
          <Box
            className="dependent_cart_item_selection"
            sx={{ mr: 1, cursor: 'pointer' }}
            onClick={() => {
              handleAction?.({ selectIndicate: isSelected });
            }}
          >
            {isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
          </Box>
        )}

        {isShowLockedIcon && (
          <Box className="locked_dependent_cart_item" sx={{ mr: 1.75 }}>
            <Box
              sx={{
                width: '14px',
                height: '20px',
              }}
            >
              <LockedIcon width={14} height={20} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DependentCartItem;
