import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import { getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import {
  UI_STYLES,
  FORM,
  MANDATORY_BOOKING_FIELDS,
  BOOKING_FLOW_PAGE_NO,
  LABEL_CATEGORY,
  USER_ROLE,
  TRAVELLER_TYPE,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { Pnr } from 'booking';
import { IBookFlightParams, IBookingFlowOverlayData, IContactDetails, IFareDetailsProps } from '../../../interfaces';
import { OVERLAY_VARIANT } from '../Common/BookingFlowOverlay';

import {
  constructBookingDetailsKeyValues,
  constructFlightSectors,
  findDropdownOptionClient,
  formatPrice,
  getAdminBookingEmployeeErn,
  getDisplayName,
  getDisplayVal,
  getLastDepartureDate,
  haveAllRequiredFields,
  isLevelZEmployee,
  isSomePassportDetailsMissing,
} from '../../../helpers';

import {
  selectBooking,
  setTravellerDetails,
  setPassportDetails,
  setContactDetails,
  setDocsDoca,
  setTravellerName,
} from '../../../slice/bookingSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import {
  getProfileThunk,
  getProfileAdminThunk,
  getProfileDelegationThunk,
  getPassportThunk,
  getPassportAdminThunk,
  getPassportDelegationThunk,
  selectBookingUser,
} from '../../../slice/bookingUserSlice';
import { selectUser } from '../../../slice/userSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

import { getFare, getDocsDoca, getTravellerName, bookFlights } from '../../../services/booking';
import {
  getFare as getFareAdmin,
  getDocsDoca as getDocsDocaAdmin,
  getTravellerName as getTravellerNameAdmin,
  bookFlights as bookFlightsAdmin,
} from '../../../services/admin/booking';
import {
  getFare as getFareDelegation,
  getDocsDoca as getDocsDocaDelegation,
  getTravellerName as getTravellerNameDelegation,
  bookFlights as bookFlightsDelegation,
} from '../../../services/delegation/booking';

import { Header, Footer, FormButton, ScrollableView, ShadowContent, DesktopBreadcrumb } from '../../../components';
import {
  DetailsInfo,
  EditTravellerDetails,
  EditPassportDetails,
  EditContactDetails,
  EditUsAddressDetails,
  BookingFlowOverlay,
  DtFareDetails,
  SegmentInfo,
  BaseConcessionInfo,
} from '../../../containers';

const { fareDetails, travelDetails } = en.booking.flightConfirmation;
const {
  travellerDetails: travellerDetailsLbl,
  passportDetails: passportDetailsLbl,
  usAddress: usAddressLbl,
  contactDetails: contactDetailsLbl,
  dutyTravelDetails: dutyTravelDetailsLbl,
  paymentDetails: paymentDetailsLbl,
} = travelDetails;

// TODO: add the interface of fare (depend on backend interface)
const getFareDetails = (fare: any): IFareDetailsProps => {
  return {
    currency: fare?.currency || '',
    fareDetails: {
      travellerType: {
        key: fareDetails.travellerType,
        value: fareDetails.adult,
      },
      noOfPassengers: {
        key: fareDetails.noOfPassengers,
        value: fareDetails.onePassenger,
      },
      baseFare: {
        key: fareDetails.baseFare,
        value: formatPrice(fare?.baseFare) || en.common.empty,
      },
      tax: {
        key: fareDetails.tax,
        value: formatPrice(fare?.totalTax) || en.common.empty,
      },
      total: {
        key: fareDetails.total,
        value: formatPrice(fare?.totalFare) || en.common.empty,
      },
    },
  };
};

const DtConfirmation = ({
  travelDetailsFormValues,
  handleBackClick,
  goToRouteStep,
  routeToStep,
}: IBookingFlowOverlayData) => {
  const formValues = {
    travelDetails: travelDetailsFormValues,
  };

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();

  const [editForm, setEditForm] = useState('');
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);
  const [travellerWarningMsg, setTravellerWarningMsg] = useState('');
  const [passportWarningMsg, setPassportWarningMsg] = useState('');
  const [contactsWarningMsg, setContactsWarningMsg] = useState('');
  const [usAddressWarningMsg, setUsAddressWarningMsg] = useState('');

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType, id: roleId } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  const { profile, passports } = useAppSelector(selectBookingUser) || {};
  const isLevelZUser = isLevelZEmployee(profile);

  const editTravelDetails = useCallback(() => {
    goToRouteStep &&
      goToRouteStep({
        step: BOOKING_FLOW_PAGE_NO.travelDetailsInputPage,
        data: {
          BackButtonText: en.booking.flightClass.backReviewAndConfirm,
          handleBackClick: () => goToRouteStep({ step: BOOKING_FLOW_PAGE_NO.confirmation }),
        },
      });
  }, [goToRouteStep]);

  const {
    outwardFlight,
    returnFlight,
    travelDetails: concession,
    passportDetails,
    travellerDetails,
    contactDetails,
    docsDoca,
    usAddress,
    travellerName,
  } = useAppSelector(selectBooking) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const adminBookingEmployeeErn = getAdminBookingEmployeeErn(travellerDetails);

  const {
    docs: isPassportMandatory,
    docaResidence: isResidenceMandatory,
    docaAddress: isUsAddressMandatory,
  } = docsDoca || {};

  const { email, phoneNumber } = (contactDetails as IContactDetails) || {};

  const editComponents = {
    [FORM.travellerDetails]: EditTravellerDetails,
    [FORM.passportDetails]: EditPassportDetails,
    [FORM.contactDetails]: EditContactDetails,
    [FORM.usAddress]: EditUsAddressDetails,
  };

  // TODO: add the interface of fare (depend on backend interface)
  const [outwardFlightFare, setOutwardFlightFare] = useState<{
    [key: string]: any;
  }>({});
  const [returnFlightFare, setReturnFlightFare] = useState<{
    [key: string]: any;
  }>({});

  const getFareAction = async (isOutwardFlight: boolean) => {
    const [flight, fare, setFareAction] = isOutwardFlight
      ? [outwardFlight, outwardFlightFare, setOutwardFlightFare]
      : [returnFlight, returnFlightFare, setReturnFlightFare];

    const selectedClass = flight?.seatClass?.type;

    if (flight && selectedClass && !fare[selectedClass]) {
      const flightSectors = [
        {
          departurePort: flight.departurePort,
          departureDate: flight.departureDate,
          departureTime: flight.departureTime,
          arrivalPort: flight.arrivalPort,
          arrivalDate: flight.arrivalDate,
          arrivalTime: flight.arrivalTime,
          marketingCompany: flight.marketingCompany,
          flightNo: flight.flightNo,
          bookingClass: selectedClass,
        },
      ];

      let data;
      if (roleType === USER_ROLE.admin) {
        data = await getFareAdmin(flightSectors, concession.id, adminBookingEmployeeErn);
      } else if (roleType === USER_ROLE.delegation) {
        data = await getFareDelegation(flightSectors, concession.id, roleId);
      } else {
        data = await getFare(flightSectors, concession.id, ern);
      }

      setFareAction({
        ...fare,
        [selectedClass]: data,
      });
    }
  };

  const getDocsDocaAction = async () => {
    let docsDocaResult;

    if (roleType === USER_ROLE.admin) {
      docsDocaResult = await getDocsDocaAdmin(flightSectors);
    } else if (roleType == USER_ROLE.delegation) {
      docsDocaResult = await getDocsDocaDelegation(flightSectors);
    } else {
      docsDocaResult = await getDocsDoca(flightSectors);
    }

    dispatch(setDocsDoca(docsDocaResult));
  };

  const getTravellerNameAction = async () => {
    let travellerName;

    if (roleType === USER_ROLE.admin) {
      if (!adminBookingEmployeeErn) {
        const { travellerInfo } = travellerDetails || {};
        const { firstName, lastName } = travellerInfo || {};

        dispatch(
          setTravellerName({
            firstName,
            lastName,
          }),
        );

        return;
      }

      travellerName = await getTravellerNameAdmin(adminBookingEmployeeErn, flightSectors);
    } else if (roleType === USER_ROLE.delegation) {
      travellerName = await getTravellerNameDelegation(roleId, flightSectors);
    } else {
      travellerName = await getTravellerName(ern, flightSectors);
    }

    if (travellerName) {
      dispatch(setTravellerName(travellerName));
    }
  };

  const { departureTime, arrivalDate, arrivalTime } = outwardFlight || {};

  const flightSectors = constructFlightSectors(outwardFlight, {
    departureTime,
    arrivalDate,
    arrivalTime,
    bookingClass: outwardFlight?.seatClass?.type || '',
  });

  useEffect(() => {
    if (!outwardFlight) return;
    getFareAction(true);
  }, [outwardFlight]);

  useEffect(() => {
    if (!returnFlight) return;
    getFareAction(false);
  }, [returnFlight]);

  useEffect(() => {
    if (!passportDetails) {
      if (roleType === USER_ROLE.admin) {
        dispatch(
          getPassportAdminThunk({
            userId: adminBookingEmployeeErn,
            expirationDate: getLastDepartureDate(flightSectors),
          }),
        );
      } else if (roleType === USER_ROLE.delegation) {
        dispatch(
          getPassportDelegationThunk({
            userId: roleId,
            expirationDate: getLastDepartureDate(flightSectors),
          }),
        );
      } else {
        dispatch(
          getPassportThunk({
            userId: ern,
            expirationDate: getLastDepartureDate(flightSectors),
          }),
        );
      }

      if (!(travellerDetails && contactDetails)) {
        if (roleType === USER_ROLE.admin) {
          dispatch(getProfileAdminThunk(adminBookingEmployeeErn));
        } else if (roleType === USER_ROLE.delegation) {
          dispatch(getProfileDelegationThunk(roleId));
        } else {
          dispatch(getProfileThunk(ern));
        }
      }
    }

    getDocsDocaAction();

    getTravellerNameAction();
  }, []);

  useEffect(() => {
    if (profile) {
      const { personalInfo } = profile || {};

      const { countryOfResidential, mobilePhone, businessEmail } = personalInfo || {};

      // (1) Set up countryOfResidential
      if (!travellerDetails || !travellerDetails.countryOfResidential) {
        dispatch(
          setTravellerDetails({
            countryOfResidential: findDropdownOptionClient(configurations.countries, countryOfResidential || ''),
          }),
        );
      }

      // (2) Set up contactDetails
      if (!contactDetails) {
        dispatch(
          setContactDetails({
            phoneNumber: mobilePhone || '',
            email: businessEmail || '',
          }),
        );
      }

      // (3) Set up passport
      if (passports && passports.length > 0) {
        const { passportNumber, passportExpirationDate, passportNationality, passportCountry, dateOfBirth, gender } =
          passports[0] || {};

        dispatch(
          setPassportDetails({
            ...passportDetails,
            dateOfBirth: dateOfBirth || '',
            gender: findDropdownOptionClient(
              configurations.labels.filter((label) => label.category === LABEL_CATEGORY.gender),
              gender || '',
            ),
            passportNumber,
            expiryDate: passportExpirationDate || '',
            nationality: findDropdownOptionClient(configurations.countries, passportNationality),
            issuingCountry: findDropdownOptionClient(configurations.countries, passportCountry || ''),
          }),
        );
      }
    }
  }, [profile, passports]);

  useEffect(() => {
    dispatch(
      setPassportDetails({
        ...passportDetails,
        passportName: getDisplayName(travellerName),
      }),
    );
  }, [travellerName]);

  useEffect(() => {
    if (isResidenceMandatory && !travellerDetails?.countryOfResidential) {
      setTravellerWarningMsg(en.booking.confirmation.mandatory);
    } else {
      setTravellerWarningMsg('');
    }
  }, [isResidenceMandatory, travellerDetails]);

  useEffect(() => {
    if (isPassportMandatory && !haveAllRequiredFields(passportDetails, MANDATORY_BOOKING_FIELDS.passport)) {
      setPassportWarningMsg(en.booking.confirmation.mandatory);
    } else if (!isPassportMandatory && isSomePassportDetailsMissing(passportDetails)) {
      setPassportWarningMsg(en.booking.flightConfirmation.travelDetails.passportDetails.warning);
    } else {
      setPassportWarningMsg('');
    }
  }, [isPassportMandatory, passportDetails]);

  useEffect(() => {
    if (!email || !phoneNumber) {
      setContactsWarningMsg(en.booking.confirmation.mandatory);
    } else {
      setContactsWarningMsg('');
    }
  }, [contactDetails]);

  const { countryOfResidential: travellerDetailResidential } = travellerDetails || {};
  const { street, city, state, zipCode } = usAddress || {};

  useEffect(() => {
    if (street && city && state && zipCode) {
      setUsAddressWarningMsg('');
    } else {
      setUsAddressWarningMsg(en.booking.confirmation.mandatory);
    }
  }, [usAddress]);

  const openOverlayOfType = (type: string) => () => {
    setEditForm(type);
    setIsOpenOverlay(true);
  };

  const checkBookFlightParams = (): boolean => {
    // return null if some fields are not inputted
    if (!outwardFlight) {
      return false;
    }

    if (!isLevelZUser && !formValues.travelDetails.endorserGalaCXyId) {
      return false;
    }

    if (isResidenceMandatory && !travellerDetailResidential) {
      return false;
    }

    if (!haveAllRequiredFields(formValues.travelDetails, MANDATORY_BOOKING_FIELDS.travelDetails)) {
      return false;
    }

    if (!haveAllRequiredFields(contactDetails, MANDATORY_BOOKING_FIELDS.contactInformation)) {
      return false;
    }

    if (
      (isPassportMandatory && !haveAllRequiredFields(passportDetails, MANDATORY_BOOKING_FIELDS.passport)) ||
      (!isPassportMandatory && isSomePassportDetailsMissing(passportDetails))
    ) {
      return false;
    }

    if (isUsAddressMandatory && !haveAllRequiredFields(usAddress, MANDATORY_BOOKING_FIELDS.usAddress)) {
      return false;
    }

    return true;
  };

  const isBookFlightParamsValid = checkBookFlightParams();

  const constructBookFlightParams = (): IBookFlightParams => {
    // return the result if all fields are inputted

    const { travellerInfo } = travellerDetails || {};
    const {
      type: travellerType,
      salutation: travellerTitle,
      firstName: travellerFirstName,
      lastName: travellerLastName,
    } = travellerInfo || {};

    return {
      userId: roleType === USER_ROLE.admin ? adminBookingEmployeeErn : roleType === USER_ROLE.delegation ? roleId : ern,
      travellerInfo: {
        ...(isUsAddressMandatory && {
          usAddress: {
            street,
            city,
            state,
            zipCode,
          },
        }),
        countryOfResidential: travellerDetailResidential?.code || '',
        // TODO: R1 hardcode as "ADT", R2 support different paxType
        type: 'ADT',
        ...(passportDetails &&
          passportDetails.passportNumber && {
            passport: {
              ...passportDetails,
              gender: passportDetails.gender?.code || '',
              issuingCountry: passportDetails.issuingCountry?.code || '',
              nationality: passportDetails.nationality?.code || '',
            },
          }),
      },
      flightSectors,
      dutyTravel: {
        travelDetail: {
          purpose: formValues.travelDetails.purpose?.code || '',
          description: formValues.travelDetails.description || '',
          endorsingStaff: isLevelZUser ? undefined : formValues.travelDetails.endorserGalaCXyId?.toUpperCase() || '',
          projectCode: formValues.travelDetails.projectCode,
          costCentre: formValues.travelDetails.costCentre?.code || '',
          generalLedgerAccount: formValues.travelDetails.accountCode?.code || '',
        },
      },
      email: email || '',
      phoneNo: phoneNumber || '',
      concessionId: concession.id,
      nonEmployee:
        roleType === USER_ROLE.admin && travellerType === TRAVELLER_TYPE.nonEmployee
          ? {
              title: travellerTitle || '',
              firstName: travellerFirstName || '',
              lastName: travellerLastName || '',
            }
          : undefined,
    };
  };

  const bookFlightParams = constructBookFlightParams();

  const {
    passportDetailsData,
    travellerDetailsData,
    contactDetailsData,
    dutyTravelDetailsData,
    paymentDetailsData,
    usAddressData,
  } = constructBookingDetailsKeyValues({
    passengerDetails: {
      ...bookFlightParams.travellerInfo,
      ...travellerName,
    } as Pnr.Passenger,
    configurations: configurations,
    contactInformation: {
      phone: bookFlightParams.phoneNo, // TODO: should align to the query body format with the return format to prevent having multiple different format everywhere :(
      email: bookFlightParams.email,
    },
    dutyTravel: bookFlightParams.dutyTravel,
    isLevelZUser: isLevelZUser || false,
  });

  const screenHeight = use100vh();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: grey[50],
      }}
    >
      <Box
        component={ScrollableView}
        sx={
          isDesktop
            ? {
                height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${
                  UI_STYLES.desktopFooterHeight
                } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'})`,
              }
            : {
                height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${
                  UI_STYLES.footerHeight
                } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'} + 43px)`,
                mt: -5.3,
              }
        }
      >
        <DesktopBreadcrumb step={routeToStep} />
        <Box
          sx={
            isDesktop
              ? {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignContent: 'flex-start',
                  mx: 'auto',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '854px',
                  mt: 0,
                  px: 1,
                }
              : {
                  mx: 2,
                  borderRadius: 1,

                  backgroundColor: 'white',
                }
          }
        >
          {isDesktop && (
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  variant="body_1_bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleBackClick?.();
                  }}
                >
                  {en.booking.flightClass.backTravelDetails}
                </Typography>
              }
              handleOnBack={handleBackClick}
              customStyles={{
                width: '100%',
                mb: 3.25,
              }}
            />
          )}

          {outwardFlight && (
            <Box sx={{ width: isDesktop ? '320px' : 'auto' }}>
              <SegmentInfo
                flightInfo={outwardFlight}
                subcomponent={{
                  component: DtFareDetails,
                  props: getFareDetails(outwardFlight.seatClass && outwardFlightFare[outwardFlight.seatClass?.type]),
                }}
                isShowSegmentStatus={false}
              />
            </Box>
          )}

          <Box
            sx={{
              mb: 1,
              ...(isDesktop ? { width: '478px' } : { mt: 3 }),
            }}
          >
            <Box>
              <BaseConcessionInfo data={getDisplayVal(concession.title)} customStyles={{ p: 2 }} />
              <Box component={ShadowContent} sx={{ p: 2, pb: 1 }}>
                <DetailsInfo
                  data={travellerDetailsData}
                  title={{
                    title: travellerDetailsLbl.title,
                    handleEditClick: openOverlayOfType(FORM.travellerDetails),
                  }}
                  warningMsg={travellerWarningMsg}
                />
              </Box>
              <Box component={ShadowContent} sx={{ p: 2, pb: 1, mt: 2 }}>
                <DetailsInfo
                  data={passportDetailsData}
                  title={{
                    title: passportDetailsLbl.title,
                    handleEditClick: openOverlayOfType(FORM.passportDetails),
                  }}
                  warningMsg={passportWarningMsg}
                />
              </Box>
              {isUsAddressMandatory && (
                <Box component={ShadowContent} sx={{ p: 2, pb: 1, mt: 2 }}>
                  <DetailsInfo
                    data={usAddressData}
                    title={{
                      title: usAddressLbl.title,
                      handleEditClick: openOverlayOfType(FORM.usAddress),
                    }}
                    warningMsg={usAddressWarningMsg}
                  />
                </Box>
              )}
              <Box component={ShadowContent} sx={{ p: 2, pb: 1, mt: 2 }}>
                <DetailsInfo
                  data={contactDetailsData}
                  title={{
                    title: contactDetailsLbl.title,
                    handleEditClick: openOverlayOfType(FORM.contactDetails),
                  }}
                  warningMsg={contactsWarningMsg}
                />
              </Box>
              <Box component={ShadowContent} sx={{ p: 2, pb: 1, mt: 2 }}>
                <DetailsInfo
                  data={dutyTravelDetailsData}
                  title={{
                    title: dutyTravelDetailsLbl.title,
                    handleEditClick: () => editTravelDetails(),
                  }}
                />
              </Box>
              <Box component={ShadowContent} sx={{ p: 2, pb: 1, mt: 2 }}>
                <DetailsInfo
                  data={paymentDetailsData}
                  title={{
                    title: paymentDetailsLbl.title,
                    handleEditClick: () => editTravelDetails(),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...(isDesktop ? { width: '105px' } : { minWidth: 0 }),
            }}
            onClick={() => {
              sessionStorage.removeItem('searchResultCache');
              history.go(0);
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={
          isBookFlightParamsValid
            ? {
                text: en.common.createBooking,
                customOnClick: async () => {
                  let result;
                  if (roleType === USER_ROLE.admin) {
                    result = await bookFlightsAdmin(bookFlightParams);
                  } else if (roleType === USER_ROLE.delegation) {
                    result = await bookFlightsDelegation(bookFlightParams);
                  } else {
                    result = await bookFlights(bookFlightParams);
                  }

                  if (result) {
                    goToRouteStep &&
                      goToRouteStep({
                        step: BOOKING_FLOW_PAGE_NO.completed,
                        data: {
                          bookingResult: result,
                          recLoc: result.booking.recLoc,
                        },
                      });
                  }
                },
                customButtonStyles: {
                  marginRight: isDesktop ? 22 : 0,
                },
              }
            : undefined
        }
        customStyles={
          isDesktop
            ? {
                px: 22,
                borderRadius: '24px 24px 0px 0px',
                boxShadow: theme.boxShadow.important,
              }
            : {
                px: 2,
              }
        }
      />

      {editComponents[editForm] && (
        <BookingFlowOverlay
          component={editComponents[editForm]}
          title={en.booking.flightConfirmation.reviewBooking}
          open={isOpenOverlay}
          variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
          isDrawer={true}
          data={{
            handleClose: () => {
              setIsOpenOverlay(false);
              setTimeout(() => {
                dispatch(reset(editForm));
                setEditForm('');
              }, 500);
            },
          }}
          customStyles={isDesktop ? { display: 'none' } : {}}
        />
      )}
    </Box>
  );
};

export default connect((state: RootState) => ({
  travelDetailsFormValues: getFormValues(FORM.travelDetails)(state),
}))(DtConfirmation);
