import {
  LogLevel,
  InteractionType,
  EventMessage,
  EventType,
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';

import jwt_decode from 'jwt-decode';

import { store } from '../app/store';
import { login, reset } from '../slice/authSlice';
import { isNonProd } from '../helpers/common';

const LOGOUT_URL = '/'; // use "/logout" url if need logout page

const azureCacheLocation = window.config?.azureCacheLocation || 'sessionStorage';

let accessTokenCache = '';

export const msalConfig = {
  auth: {
    clientId: window.config.azureClientId,
    authority: window.config.azureAuthority,
    validateAuthority: true,
    redirectUri: '/',
    postLogoutRedirectUri: LOGOUT_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    // cacheLocation: "sessionStorage", // This configures where your cache will be stored
    cacheLocation: azureCacheLocation,
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Error,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access', `api://${window.config.azureClientId}/API`],
  forceRefresh: true,
  ...(isNonProd() ? { prompt: 'select_account' } : undefined),
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

// ------------------- END OF CONFIG -----------------------

export function displayTokenExp(token: string) {
  const jsonToken: { exp?: number; ver?: string } = jwt_decode(token);
  if (jsonToken && jsonToken.exp) {
    const expireAt = jsonToken.exp;
    if (jsonToken.ver && parseFloat(jsonToken.ver) < 2) {
      console.warn('Token is not v2');
    }
    console.debug(`token version: ${jsonToken.ver} will expire at [${expireAt}] ${new Date(expireAt * 1000)}`);
  }
}
export interface ITokenClaimsInterface {
  sAMAccountName: string;
  cpaern: string;
  mail: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  // eslint-disable-next-line camelcase
  given_name: string;
}

export const etpEventCallBack = (message: EventMessage) => {
  console.debug('msalEvent:', message);
  // const dispatch = useAppDispatch();
  // if (message.eventType === EventType.ACCOUNT_ADDED) {
  //   // Update UI with new account
  // } else if (message.eventType === EventType.ACCOUNT_REMOVED) {
  //   // Update UI with account logged out
  // }
  if (message.eventType === EventType.ACCOUNT_REMOVED) {
    console.debug('login reset');
    store.dispatch(reset());
    alert('Re-authentication required');
    msalInstance.loginRedirect(loginRequest);
  }
  if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    console.debug(message.interactionType);
    if (message.interactionType === InteractionType.Silent) {
      if (message.error) {
        console.error(message.error);
      }
      const payload = message.payload as AuthenticationResult;
      if (payload.accessToken && payload.idTokenClaims) {
        const { idTokenClaims, accessToken } = payload;
        const { cpaern: ern, sAMAccountName: galacxyId } = idTokenClaims as ITokenClaimsInterface;
        // console.log(idTokenClaims);
        // console.log(accessToken);
        // console.log(idToken);
        displayTokenExp(accessToken);
        accessTokenCache = accessToken;
        // store.dispatch(login({ accessToken, galacxyId, ern }));
        store.dispatch(login({ galacxyId, ern }));
      }
    }
  }
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    if (message.error) {
      console.error(message.error);
    } else {
      const payload = message.payload as AuthenticationResult;
      if (payload.accessToken && payload.idTokenClaims) {
        const { idTokenClaims, accessToken } = payload;
        const { cpaern: ern, sAMAccountName: galacxyId } = idTokenClaims as ITokenClaimsInterface;
        // console.log(idTokenClaims);
        // console.log(accessToken);
        // console.log(idToken);
        displayTokenExp(accessToken);
        // NOTE: first login's accessToken is version 1.0 (not 2.0)
        accessTokenCache = accessToken;
        // store.dispatch(login({ accessToken, galacxyId, ern }));
        store.dispatch(login({ galacxyId, ern }));
      }
    }
  }
  // if (message.eventType === EventType.INITIALIZE_START) {
  //   console.log("reset login");
  //   store.dispatch(reset());
  // }
};

export function getTokenExpireAt(accessToken: string) {
  const jsonToken: { exp?: number; ver?: string } = jwt_decode(accessToken);
  if (jsonToken.ver && parseFloat(jsonToken.ver) < 2) {
    console.warn('Token is not v2 - require to get new token');
    return null; // force user to get a new access token (which should be v2)
  }
  if (jsonToken && jsonToken.exp) {
    const expireAt = jsonToken.exp;
    return expireAt;
  }
  return null;
}

// function delay(ms: number) {
//   return new Promise((resolve) => setTimeout(() => resolve(null), ms));
// }

export async function getNewAccessToken(): Promise<string> {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    store.dispatch(reset);
    throw new Error('User not login');
  }

  const authResult = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .catch((error) => {
      console.error('acquireTokenSilentError', error);
      throw new Error('Failed to refresh accessToken');
    });
  return authResult.accessToken;
}

export async function getAccessToken() {
  const state = store.getState();
  const isLogin = state.auth.isLogin;
  // console.debug(`isLogin=${state.auth.isLogin}`);
  // console.log(`accessToken=${state.auth.accessToken}`);
  if (isLogin) {
    // const accessToken = state.auth.accessToken;
    const accessToken = accessTokenCache;
    if (accessToken) {
      const expireAt = getTokenExpireAt(accessToken);
      if (expireAt) {
        const expireBuffer = 30;
        const expirationInMs = (expireAt - expireBuffer) * 1000;
        if (expirationInMs >= Date.now()) {
          // console.log(`expireAt:${expireAt}`);
          // console.log(
          //   `existing token will expire at ${new Date(expireAt * 1000)}`
          // );
          return accessToken;
        } else {
          // if (expirationInMs < Date.now()) {
          console.debug(
            `${Date.now()} vs ${expirationInMs} ${
              expirationInMs - Date.now()
            } - token is about to expire in ${expireBuffer} seconds`,
          );
        }
      }
    }
  }
  const newAccessToken = await getNewAccessToken();
  return newAccessToken;
}

export function logoutRedirect() {
  const accounts = msalInstance.getAllAccounts();
  accessTokenCache = '';
  msalInstance.logoutRedirect({
    account: accounts[0],
    postLogoutRedirectUri: '/',
  });
}

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback(etpEventCallBack);
msalInstance.enableAccountStorageEvents();
