import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { DATE_FORMAT, LABEL_CATEGORY, UI_STYLES, REFUND_TYPE, PAYMENT_CONCEPT } from '../../../constants/constants';
import en from '../../../translations/en';
import { BookingSummaryState } from '../../../context';

import { Frontend } from 'booking';

import {
  formatDateAsString,
  getDisplayLabel,
  getDisplayVal,
  getPassengerDisplayName,
  isOWOALBooking,
} from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';

import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectApp } from '../../../slice/appSlice';

import { HorizontalDivider, ScrollableView, ShadowContent } from '../../../components';

const InfoLayout = ({ data }: { data: { title: string; info?: string } }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mx: 1,
        alignItems: 'center',
        textAlign: 'start',
      }}
    >
      <Typography sx={{ display: 'flex', flex: 1 }}>{data.title}</Typography>
      <Typography sx={{ display: 'flex', flex: 1 }}>{getDisplayVal(data.info)}</Typography>
    </Box>
  );
};

const FlownRefundContainer = ({ bookingResult }: { bookingResult: Frontend.Booking }) => {
  const screenHeight = use100vh();
  const { booking } = bookingResult || {};

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { recLoc, concession, ticketsBySegment, paymentConcept } = booking || {};
  const { carrier, flightNum, departureDate, departureTime, arrivalTime, departurePort, arrivalPort, status } =
    (ticketsBySegment && ticketsBySegment[0]) || {};

  const isPrepaid = paymentConcept === PAYMENT_CONCEPT.PREPAID;

  const { travellerList } = useContext(BookingSummaryState) || {};
  const remainingTravellerList = travellerList?.filter((item: any) => isPrepaid || !item.isRemoved) || [];

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const segmentStatus = getDisplayLabel(configurations.labels, LABEL_CATEGORY.segmentStatus, status);

  const isOWOALFlight = isOWOALBooking(flightNum);
  const { flightDate, flightTime, flightNo, tripType, status: enStatus } = en.booking.confirmation.confirmCancel;
  const { od, fullRefund, downgradeRefund } = en.booking.confirmation.cancelActions.refund;
  const { bookingReference, concession: enConcession } = en.booking.confirmation;

  const flightDateTimeDetails = [
    {
      title: flightDate,
      info: `${formatDateAsString(departureDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}`,
    },
    {
      title: flightTime,
      info: `${getDisplayVal(departureTime)}-${getDisplayVal(arrivalTime)}`,
    },
  ];
  const bookingDetails = [
    {
      title: flightNo,
      info: isOWOALFlight ? getDisplayVal(carrier) : `${getDisplayVal(carrier)}${getDisplayVal(flightNum)}`,
    },
    ...(isOWOALFlight ? [] : flightDateTimeDetails),
    {
      title: tripType,
      info: en.booking.travelType.leisureTravel.title,
    },
    {
      title: od,
      info: `${getDisplayVal(departurePort)} ${en.booking.selectFlight.to} ${getDisplayVal(arrivalPort)}`,
    },
    {
      title: enStatus,
      info: segmentStatus,
    },
  ];

  const sortedTickets = remainingTravellerList?.slice().sort((a: any, b: any) => {
    return a.traveler.ticketNum - b.traveler.ticketNum;
  });

  const fullRefundList = sortedTickets.filter((item: any) => item.refundType === REFUND_TYPE.FULL_REFUND);
  const downgradeRefundList = sortedTickets.filter((item: any) => item.refundType === REFUND_TYPE.DOWNGRADE_REFUND);
  const fullRefundTravelersList =
    fullRefundList && fullRefundList.map((ticket: any) => getPassengerDisplayName(ticket?.traveler));
  const downgradeRefundTravelersList =
    downgradeRefundList && downgradeRefundList.map((ticket: any) => getPassengerDisplayName(ticket?.traveler));

  return (
    <Box
      component={ScrollableView}
      sx={{
        pb: 2,
        px: 1,
        height: isDesktop
          ? `calc(${screenHeight}px - ${UI_STYLES.footerHeight}` // footer button + padding
          : `calc(${screenHeight}px - ${UI_STYLES.footerHeight} - ${UI_STYLES.overlayHeaderHeightBookingFlow} + 56px )`, // +56px -> mt: -7
        borderRadius: 1,
      }}
    >
      <Box component={isDesktop ? 'div' : ShadowContent}>
        <Box component={Typography} variant="headline_bold" sx={{ mx: 'auto', mt: 1 }}>
          {`${bookingReference}: ${isOWOALFlight ? en.common.empty : getDisplayVal(recLoc)}`}
        </Box>

        <HorizontalDivider />

        {bookingDetails.map((item, index) => {
          return <InfoLayout key={index} data={item} />;
        })}

        <HorizontalDivider />

        <InfoLayout
          data={{
            title: enConcession,
            info: concession?.name,
          }}
        />

        {fullRefundTravelersList && fullRefundTravelersList.length > 0 && (
          <>
            <HorizontalDivider />

            <Box component={Typography} variant="body_2_bold" sx={{ mx: 1, mb: 1, textAlign: 'start' }}>
              {fullRefund}
            </Box>

            {fullRefundTravelersList.map((traveller: string, index: number) => {
              return (
                <Typography
                  key={index}
                  variant="body_2_regular"
                  sx={{
                    mx: 1,
                    mb: 0.5,
                    textAlign: 'start',
                  }}
                >
                  {traveller}
                </Typography>
              );
            })}
          </>
        )}
        {downgradeRefundTravelersList && downgradeRefundTravelersList.length > 0 && (
          <>
            <HorizontalDivider />
            <Box component={Typography} variant="body_2_bold" sx={{ mx: 1, mb: 1, textAlign: 'start' }}>
              {downgradeRefund}
            </Box>

            {downgradeRefundTravelersList.map((traveller: string, index: number) => {
              return (
                <Typography
                  key={index}
                  variant="body_2_regular"
                  sx={{
                    mx: 1,
                    mb: 0.5,
                    textAlign: 'start',
                  }}
                >
                  {traveller}
                </Typography>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default FlownRefundContainer;
