import { Field } from 'redux-form';

import { useAppSelector } from '../../app/hooks';
import { selectConfiguration } from '../../slice/configurationSlice';

import { ReduxFormSelectInput } from '.';

const CountrySelectInput = ({ name, title }: { name: string; title: string }) => {
  const { configurations } = useAppSelector(selectConfiguration) || {};

  return (
    <Field
      component={ReduxFormSelectInput}
      name={name}
      title={title}
      options={configurations?.countries}
      autocomplete={{
        matchFrom: 'start',
      }}
    />
  );
};

export default CountrySelectInput;
