import { Box, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { Frontend } from 'booking';

import { Footer, FormButton } from '../../../components';
import { FlownRefundContainer } from './';

const MobileRefundDialog = ({
  handleCloseClick,
  handleCancelBookingAction,
  bookingResult,
}: {
  handleCloseClick?: () => void;
  handleCancelBookingAction?: () => void;
  bookingResult: Frontend.Booking;
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ mt: -7, mx: 1 }}>
      <FlownRefundContainer bookingResult={bookingResult} />
      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="outline"
            size="large"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '44px',
              width: 'calc(50vw - 24px)', // 24px->ml:16px + mr: 8px
              borderRadius: 1,
            }}
            onClick={() => {
              handleCloseClick?.();
            }}
          >
            {en.common.no}
          </FormButton>
        }
        primaryBtn={{
          text: en.common.yesConfirm,
          customOnClick: () => {
            handleCancelBookingAction?.();
          },
          customButtonStyles: {
            marginRight: 0,
            width: 'calc(50vw - 24px)', // 24px->mr:16px + ml: 8px
          },
        }}
        customStyles={{
          mx: -1,
          width: '100vw',
        }}
      />
    </Box>
  );
};

export default MobileRefundDialog;
