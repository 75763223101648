import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../../../translations/en';

import { getEligibleCarrierJoinedText } from '../../../../../helpers';
import { NominationOALEligibilitySwitch } from '../../..';

interface IOALEligibilityDataProps {
  dependentName: string;
  relationship: string;
  eligibleCarrier?: string[];
  toggleDisabled: boolean;
}

const OALEligibilityItem = ({
  isSelected,
  data,
  handleOnChangeAction,
}: {
  isSelected: boolean;
  data: IOALEligibilityDataProps;
  handleOnChangeAction: () => void;
}) => {
  const theme = useTheme();
  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const eligibleCarrierJoinedText = getEligibleCarrierJoinedText(data.eligibleCarrier);

  return (
    <Box
      className="oal_eligibility_cart_item"
      sx={{
        borderRadius: '8px',
        backgroundColor: theme.palette.primary.light,
        marginBottom: 1,
        padding: 2,
      }}
    >
      <Box className="oal_eligibility_item_dependent_info">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_1}>
            {data.dependentName}
          </Typography>
        </Box>

        <Box
          sx={{
            py: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
            {data.relationship}
          </Typography>
        </Box>

        <Box className="oal_eligibility_tag" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
          <Box
            sx={{
              backgroundColor: theme.color.secondary.dark_sand.option_6,
              borderRadius: '4px',
              padding: '1px 8px',
              mr: 0.5,
            }}
          >
            <Typography variant="fine_print_medium" color={theme.color.secondary.dark_grey.option_2}>
              {nominationFlowLbl.eligibilityTagName}
            </Typography>
          </Box>

          <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
            {eligibleCarrierJoinedText}
          </Typography>
        </Box>
      </Box>

      <Box
        className="assign_oal_with_switch_box"
        sx={{
          mt: 1.5,
          height: '48px',
          borderRadius: 1,
          background: theme.color.secondary.light_slate.option_6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1.25,
        }}
      >
        <Typography
          variant="body_2_regular"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
          }}
        >
          {nominationFlowLbl.OALEligibility.toggleLabel}
        </Typography>

        <NominationOALEligibilitySwitch
          disabled={data.toggleDisabled}
          checked={isSelected}
          onChange={handleOnChangeAction}
        />
      </Box>

      <Typography
        variant="navigation_regular"
        sx={{
          color: theme.color.secondary.dark_grey.option_3,
          mt: 1.5,
        }}
      >
        {nominationFlowLbl.OALEligibility.toggleDesc}
      </Typography>
    </Box>
  );
};

export default OALEligibilityItem;
