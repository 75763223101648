import { useContext, useState } from 'react';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { DATE_FORMAT, SAVE_DATA_TO, UI_STYLES } from '../../../constants/constants';
import en from '../../../translations/en';

import { formatAirportValue, formatDateAsString, formatStringToDate, getLocalDateAdding } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { BookingSummaryAction, BookingSummaryState } from '../../../context';

import { DateFieldBoxComponent } from '.';
import { DateSelection } from '..';
import { DateSinglePicker, FormPopover, ReduxFormTextInput } from '../../../components';

const RebookDateFieldContainer = ({
  isEditPopUp,
  customStyles,
}: {
  isEditPopUp: boolean;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { airports } = useAppSelector(selectConfiguration) || {};
  const rebookLabel = en.booking.confirmation.cancelActions.rebook;

  const [curShowPop, setCurShowPop] = useState('');

  const { originalFlightDate, bookingSummaryData, rebookFlightDate } = useContext(BookingSummaryState) || {};
  const { setOriginalFlightDate } = useContext(BookingSummaryAction) || {};

  const { ticketsBySegment } = bookingSummaryData || {};
  const { arrivalPort, departurePort, arrivalDate } = (ticketsBySegment && ticketsBySegment[0]) || {};

  const formatOriginFlightDate = formatDateAsString(
    originalFlightDate || arrivalDate,
    DATE_FORMAT.ddmmmyyyy,
    DATE_FORMAT.date,
  );

  const formatRebookFlightDate = formatDateAsString(rebookFlightDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date);

  const ODToAirport = (portCode: string) => {
    const originPort = airports.find((airport: any) => airport.airportCode && airport.airportCode === portCode) || null;

    return formatAirportValue(originPort);
  };

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setCurShowPop('');
      }}
    >
      <Box sx={{ mt: 0, width: isDesktop ? '343px' : '100%', mx: 'auto' }}>
        {isDesktop && (
          <Typography variant="body_2_medium" sx={{ mt: 4, mb: 1 }}>
            {rebookLabel.editFlightDate}
          </Typography>
        )}

        <ReduxFormTextInput
          input={{
            value: ODToAirport(departurePort),
          }}
          title={en.booking.from}
          isReadOnly={true}
          customStyles={customStyles}
        />

        <ReduxFormTextInput
          input={{
            value: ODToAirport(arrivalPort),
          }}
          title={en.booking.to}
          isReadOnly={true}
          customStyles={customStyles}
        />

        {isDesktop ? (
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Box
              component="div"
              sx={{
                justifyContent: 'center',
                pl: 1.5,
                background: 'white',
                width: '100%',
                border: `1px solid ${theme.palette.borderColor.contrastText}`,
                my: 0.5,
                borderRadius: 0.5,
                height: '48px',
              }}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                setCurShowPop?.('date');
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  setCurShowPop?.('date');

                  event.target?.click?.();
                }
              }}
            >
              <FormPopover
                label={en.booking.searchBar.date}
                value={formatOriginFlightDate || formatDateAsString(new Date(), DATE_FORMAT.ddmmmyyyy)}
                style={{
                  height: UI_STYLES.searchBarDatePickerOverlayHeight,
                  width: '664px',
                  left: '16px',
                  mt: 0,
                }}
                component={
                  curShowPop === 'date' && (
                    <DateSinglePicker
                      isDesktop
                      defaultDate={new Date(formatStringToDate(originalFlightDate, DATE_FORMAT.date))}
                      onChange={(date: Date) => {
                        const newStartDate = formatDateAsString(date, DATE_FORMAT.date);

                        setOriginalFlightDate(newStartDate);
                      }}
                      minDate={getLocalDateAdding(-1)}
                      maxDate={getLocalDateAdding(180)} // TODO: need to get departure max date from helper/date
                      months={2}
                    />
                  )
                }
              />
            </Box>
          </Box>
        ) : (
          <DateFieldBoxComponent
            label={en.booking.searchBar.date}
            value={
              isEditPopUp
                ? formatRebookFlightDate
                : formatOriginFlightDate || formatDateAsString(new Date(), DATE_FORMAT.ddmmmyyyy)
            }
            shrink={{
              shrink: true,
            }}
            title={rebookLabel.newFlightDate}
            component={DateSelection}
            data={{
              saveTo: SAVE_DATA_TO.context,
              isRebookTitle: true,
              isFromRebookFlow: true,
              isEditPopup: isEditPopUp,
            }}
          />
        )}

        <ReduxFormTextInput
          input={{
            value: 'Zone - CX', // TODO: etp-2915: hardcode for test
          }}
          title={en.booking.searchBar.leisureTravelConcession}
          isReadOnly={true}
          customStyles={customStyles}
        />
      </Box>
    </Box>
  );
};

export default RebookDateFieldContainer;
