import en from '../translations/en';
import { HTTP_REQUEST_TYPE, BOOKING_API, LT_API, COMMON_API } from '../constants/api';

import { sendHttpRequest } from './api';

import {
  IBookFlightLtParams,
  IBookFlightParams,
  IFlightSectors,
  ILtTravellerInfo,
  ITravelWithInfo,
} from '../interfaces';

// DT
const searchFlight = async (
  departureDate: string,
  departurePort: string,
  arrivalDate: string,
  arrivalPort: string,
  concessionId: string,
  userId: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: BOOKING_API.searchFlight,
    apiTitle: en.errorAlert.searchFlight,
    payload: {
      departureDate,
      departurePort,
      arrivalDate,
      arrivalPort,
      concessionId,
      userId,
    },
  });
  return resp;
};

const getFare = async (flightSectors: IFlightSectors[], concessionId: string, userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: BOOKING_API.fare,
    apiTitle: en.errorAlert.getFare,
    payload: { flightSectors, concessionId, userId },
  });
  return resp;
};

const bookFlights = async (param: IBookFlightParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: BOOKING_API.book,
    apiTitle: en.errorAlert.bookFlights,
    payload: param,
  });
  return resp;
};

const getBooking = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: BOOKING_API.pnr,
    apiTitle: en.errorAlert.getBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

const getUpcomingBooking = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: BOOKING_API.upcomingBooking,
    apiTitle: en.errorAlert.getUpcomingBooking,
    payload: { userId },
  });
  return resp;
};

const getMyBookings = async ({
  userId,
  from,
  path,
  startDate,
  endDate,
  type,
  isSearchAndFilter,
}: {
  userId: string;
  from: number;
  path?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
  isSearchAndFilter?: boolean;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: path || COMMON_API.myBooking,
    apiTitle: en.errorAlert.getMyBookings,
    payload: { userId, from, startDate, endDate, type, isSearchAndFilter },
  });
  return resp;
};

const getDocsDoca = async (flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: BOOKING_API.docsDoca,
    apiTitle: en.errorAlert.getDocsDoca,
    payload: { flightSectors },
  });
  return resp;
};

const getTravellerName = async (userId: string, flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: BOOKING_API.travellerName,
    apiTitle: en.errorAlert.getTravellerName,
    payload: { userId, flightSectors },
  });

  return resp;
};

const cancelBooking = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: BOOKING_API.cancel,
    apiTitle: en.errorAlert.cancelBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

// LT
const searchFlightLT = async ({
  departureDate,
  departurePort,
  arrivalDate,
  arrivalPort,
  userId,
  type,
  numberOfTravellers = 1,
  concessionRuleId,
}: {
  departureDate: string;
  departurePort: string;
  arrivalDate: string;
  arrivalPort: string;
  userId: string;
  type: string;
  numberOfTravellers?: number;
  concessionRuleId?: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: LT_API.BOOKING_API.searchFlight,
    apiTitle: en.errorAlert.searchFlight,
    payload: {
      departureDate,
      departurePort,
      arrivalDate,
      arrivalPort,
      userId,
      type,
      numberOfTravellers,
      ...(concessionRuleId && {
        concessionRuleId,
      }),
    },
  });

  return resp;
};

const getNominatedPassengerListLt = async (
  userId: string,
  flightSectors: IFlightSectors[],
  concessionRuleId?: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: LT_API.BOOKING_API.nominatedPassengerList,
    apiTitle: en.errorAlert.getNominatedPassenger,
    payload: {
      userId,
      flightSectors,
      concessionRuleId,
    },
  });

  return resp;
};

const getConcessionListLT = async ({
  userId,
  flightSectors,
  travellerInfo,
  concessionRuleId,
}: {
  userId: string;
  flightSectors: IFlightSectors[];
  travellerInfo: ILtTravellerInfo[];
  concessionRuleId?: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: LT_API.BOOKING_API.concession,
    apiTitle: en.errorAlert.getLtConcession,
    payload: {
      userId,
      flightSectors,
      travellerInfo,
      ...(concessionRuleId && {
        concessionRuleId,
      }),
    },
  });

  return resp;
};

const getFareAndTravellerDetailsLT = async (
  userId: string,
  concessionRuleId: string,
  flightSectors: IFlightSectors[],
  travellerInfo: ILtTravellerInfo[],
  apiTitle?: string,
  dismissCallBackFunc?: () => void,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: LT_API.BOOKING_API.fare,
    apiTitle: apiTitle || en.errorAlert.getFareAndTravellerLT,
    payload: {
      userId,
      flightSectors,
      travellerInfo,
      concessionRuleId,
      ...(dismissCallBackFunc && {
        dismissCallBackFunc,
      }),
    },
  });

  return resp;
};

const bookFlightsLT = async (param: IBookFlightLtParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: LT_API.BOOKING_API.book,
    apiTitle: en.errorAlert.bookFlights,
    payload: param,
  });
  return resp;
};

const getBookingLT = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: LT_API.BOOKING_API.pnr,
    apiTitle: en.errorAlert.getBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

const cancelBookingLT = async (userId: string, applicationId: string, action: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: LT_API.BOOKING_API.cancel,
    apiTitle: en.errorAlert.cancelBooking,
    payload: { userId, applicationId, action },
  });
  return resp;
};

const refundBookingLT = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: LT_API.BOOKING_API.refund,
    apiTitle: en.errorAlert.refundBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

const removePassengerLT = async (
  userId: string,
  applicationId: string,
  travellerInfo: ILtTravellerInfo[],
  travelWith?: ITravelWithInfo,
  dismissCallBackFunc?: () => void,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: LT_API.BOOKING_API.removePassenger,
    apiTitle: en.errorAlert.removePassenger,
    payload: {
      userId,
      applicationId,
      travellerInfo,
      travelWith,
      ...(dismissCallBackFunc && {
        dismissCallBackFunc,
      }),
    },
  });
  return resp;
};

export {
  // DT
  searchFlight,
  getFare,
  bookFlights,
  getBooking,
  getUpcomingBooking,
  getMyBookings,
  getDocsDoca,
  getTravellerName,
  cancelBooking,

  // LT
  searchFlightLT,
  getNominatedPassengerListLt,
  getConcessionListLT,
  getFareAndTravellerDetailsLT,
  bookFlightsLT,
  getBookingLT,
  cancelBookingLT,
  refundBookingLT,
  removePassengerLT,
};
