import React, { useEffect, useState, useRef, Dispatch, SetStateAction, useContext } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { UI_METRICS_IN_PX, UI_STYLES, USER_ROLE } from '../../constants/constants';

import { Frontend } from 'booking';
import { BOOKING_LAYOUT_VARIANT } from './BookingLayout';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../../slice/authSlice';
import {
  ICancelBookingSuccessData,
  selectApp,
  setCancelBookingSuccessData,
  setRefundBookingSuccessData,
} from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';
import { selectBooking, setIsShowMyBookingFilterDialog } from '../../slice/bookingSlice';
import { selectBookingFilter } from '../../slice/bookingFilterSlice';
import { EditBookingFilterState, BookingSummaryState } from '../../context';

import { getMyBookings } from '../../services/booking';
import { getMyBookings as getMyBookingsDelegation } from '../../services/delegation/booking';

import BookingLayout from './BookingLayout';
import { ScrollableView } from '../../components';
import { BookingSummaryOverlay, SearchFilterIconWithCount, MyBookingFilterDialog } from '../../containers';
import { DownWardIcon } from '../../assets/images';

const MyBookings = ({
  searchResult,
  setSearchResult,
  searchedBooking,
  headerComponent,
}: {
  searchResult: any;
  setSearchResult: Dispatch<SetStateAction<string[] | null | undefined>>;
  searchedBooking?: Frontend.ParsedBooking | null;
  headerComponent: React.ReactNode;
}) => {
  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useAppDispatch();
  const { ern } = useAppSelector(selectAuth) || {};
  const { isShowMyBookingFilterDialog } = useAppSelector(selectBooking) || {};
  const bookingFilterData = useAppSelector(selectBookingFilter) || {};
  const { isDesktop, cancelBookingSuccessData } = useAppSelector(selectApp) || {};
  const { refundBookingSuccessData } = useAppSelector(selectApp) || {};

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType, id: roleId } = role || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation]?.includes(roleType);

  const [myBookings, setMyBookings] = useState<Frontend.ParsedBooking[]>([]);
  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);
  const [callingGetMyBooking, setCallingGetMyBooking] = useState(false);
  const [isShowViewMore, setIsShowViewMore] = useState(false);

  const { editTravelTypes, editPeriod } = useContext(EditBookingFilterState) || {};
  const { bookingSummaryData } = useContext(BookingSummaryState) || {};

  const { from, to } = editPeriod || {};

  const fromIndexRef = useRef(0);
  const isNoMoreDataRef = useRef(false);

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const bookingListRef = useRef<HTMLDivElement>(null);
  const myBookingsLabelRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const scrollTop = event.currentTarget?.scrollTop || 0;
    const clientHeight = event.currentTarget?.clientHeight || 0;
    const scrollHeight = event.currentTarget?.scrollHeight || 0;
    const isReachEndOfPage = scrollTop + clientHeight + UI_METRICS_IN_PX.scrollBuffer > scrollHeight;

    if (!isNoMoreDataRef.current && isReachEndOfPage) {
      setCallingGetMyBooking(true);
    }
  };

  const getMyBookingAction = async (cancelBookingSuccessData?: ICancelBookingSuccessData | null) => {
    if (cancelBookingSuccessData && !cancelBookingSuccessData.isShow) {
      fromIndexRef.current = 0;
      isNoMoreDataRef.current = false;
    }

    let results: Frontend.ParsedBooking[] = [];

    if (roleType === USER_ROLE.delegation) {
      results = await getMyBookingsDelegation(roleId, fromIndexRef.current);
    } else {
      const searchQuery = { userId: ern, from: fromIndexRef.current };
      if (searchResult) {
        Object.assign(searchQuery, {
          startDate: from,
          endDate: to,
          type: JSON.stringify(editTravelTypes),
          isSearchAndFilter: true,
        });
      }
      results = await getMyBookings(searchQuery);
    }

    fromIndexRef.current++;
    setCallingGetMyBooking(false);

    if (cancelBookingSuccessData && !cancelBookingSuccessData.isShow) {
      if (results) {
        setMyBookings(results);

        if (scrollViewRef.current) {
          scrollViewRef.current.scrollTop = 0;
        }
      }

      dispatch(
        setCancelBookingSuccessData({
          ...cancelBookingSuccessData,
          isShow: cancelBookingSuccessData?.removePaxLabel ? false : true,
          removePaxLabel: undefined,
        }),
      );
    } else {
      if (results) {
        if (results.length === 0) {
          isNoMoreDataRef.current = true;
          setIsShowViewMore(false);
        } else {
          triggerViewMoreAction();
          setMyBookings((previousMyBookings) => previousMyBookings.concat(results));
        }
      }
    }
  };
  const triggerViewMoreAction = () => {
    if (myBookings.length < 9) {
      setIsShowViewMore(false);
      return;
    }
    const myBookingsLabelHeight = myBookingsLabelRef.current?.clientHeight || 0;
    const bookingListHeight = bookingListRef.current?.clientHeight || 0;
    const windowHeight = screenHeight || 0;
    const desktopHeaderHeight = Number(UI_STYLES.desktopHeaderHeight.slice(0, -2)) || 0;
    const overlayHeaderHeight = Number(UI_STYLES.overlayHeaderHeightSearchCriteria.slice(0, -2)) || 0;
    if (windowHeight - myBookingsLabelHeight - desktopHeaderHeight - overlayHeaderHeight - bookingListHeight < 0) {
      setIsShowViewMore(false);
    } else {
      setIsShowViewMore(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      triggerViewMoreAction();
    }, 500);
  }, [screenHeight, isDesktop, myBookings]);

  useEffect(() => {
    if (callingGetMyBooking) {
      getMyBookingAction(cancelBookingSuccessData);
    }
  }, [callingGetMyBooking]);

  useEffect(() => {
    if (bookingSummaryData) {
      const updateMyBookings = myBookings.map((booking) =>
        booking.applicationId === bookingSummaryData.applicationId ? bookingSummaryData : booking,
      );
      setMyBookings(updateMyBookings);
    }
  }, [bookingSummaryData]);

  useEffect(() => {
    setCallingGetMyBooking(true);
  }, []);

  useEffect(() => {
    if (searchResult) {
      isNoMoreDataRef.current = false;
      fromIndexRef.current = 1;
      setMyBookings(searchResult);
    }
  }, [searchResult]);

  useEffect(() => {
    // open booking details view
    searchedBooking && setBookingSelected(searchedBooking);
  }, [searchedBooking]);

  return (
    <>
      <Box
        component={ScrollableView}
        ref={scrollViewRef}
        onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
          handleScroll(event);
        }}
        sx={{
          height: isDesktop
            ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} )`
            : `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${
                isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
              })`,
        }}
      >
        {headerComponent}
        {isDesktop && (
          <Box
            ref={myBookingsLabelRef}
            sx={{
              px: 'calc(50vw - 480px)',
              pt: 4.5,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="title_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {en.home.myBooking}
            </Typography>
            <SearchFilterIconWithCount
              onClick={() => dispatch(setIsShowMyBookingFilterDialog(true))}
              data={bookingFilterData}
            />
          </Box>
        )}
        <Box
          ref={bookingListRef}
          sx={{
            py: 2,
            mb: { sm: 1, xs: 3 },
            width: isDesktop ? '960px' : 'calc(100% - 32px)',
            mx: 'auto',
          }}
        >
          <BookingLayout
            bookings={myBookings}
            emptyTitle={searchResult && searchResult.length === 0 ? en.home.notFindBooking : en.home.noBooking}
            handleClick={(selectedItem: Frontend.ParsedBooking) => {
              setBookingSelected(selectedItem);
            }}
            variant={BOOKING_LAYOUT_VARIANT.MY_BOOKING_PAGE}
          />
          {isShowViewMore && myBookings.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  width: '56px',
                }}
                onClick={() => {
                  setCallingGetMyBooking(true);
                }}
              >
                <DownWardIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* Desktop View Booking Filter Dialog */}
      {isDesktop && (
        <MyBookingFilterDialog
          open={isShowMyBookingFilterDialog}
          // TODO: [Filter] please update this section during integration
          handleCloseDialog={() => {
            dispatch(setIsShowMyBookingFilterDialog(false));
          }}
          setSearchResult={setSearchResult}
          dialogContent={{
            yesTitle: en.common.apply,
            noTitle: en.common.clear,
          }}
        />
      )}

      <BookingSummaryOverlay
        bookingSelected={bookingSelected}
        handleClose={() => {
          setBookingSelected(null);
          if (refundBookingSuccessData?.applicationId) {
            dispatch(setRefundBookingSuccessData({ applicationId: '' }));
          }
        }}
        data={{
          applicationId: bookingSelected?.applicationId,
          type: bookingSelected?.type,
          isRebookFromMyBooking: true,
        }}
      />
    </>
  );
};

export default MyBookings;
