import { useContext, useMemo } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import _ from 'lodash';

import en from '../../../../translations/en';
import { NOMINATION_TYPE } from '../../../../constants/constants';

import { INominationViewModeItem, IOALEligibilitySelectedData } from '../../../../interfaces';

import { getDisplayName, sortByDependents } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { NominationState } from '../../../../context';

import { NominationDependentList } from '../..';

interface INominationDependentListData {
  dependentName: string;
  status: string;
  isPrimaryLabel?: boolean;
}

const NominationReviewAndConfirmContainer = () => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { nominationTransformData, nominationFlow, nominationChangeTypeData } = useContext(NominationState) || {};
  const { sortedDependents } = nominationFlow.nominationSelectionData || [];

  const nominationFlowLbl = en.userProfile.nomination.nominationFlow;

  const mainTitle = (
    nominationFlowLbl?.[nominationFlow.nominationFlowSelectedType as keyof typeof nominationFlowLbl] as {
      title: string;
    }
  )?.title;

  const toBeChangeTypeDependent = nominationChangeTypeData.find(
    (dependent: INominationViewModeItem) => dependent.isSelected,
  );

  const nominateDependentToBeChangedList = useMemo(() => {
    return sortByDependents(nominationTransformData, nominationChangeTypeData, sortedDependents);
  }, [nominationTransformData, nominationChangeTypeData]);

  const changeTypeLabel =
    nominationFlow.nominationFlowSelectedType === NOMINATION_TYPE.plusOne
      ? `${nominationFlowLbl.fromTravelNomineeLabel} <br />${nominationFlowLbl.toPlusOneLabel}`
      : `${nominationFlowLbl.fromPlusOneToLabel} <br />${nominationFlowLbl.travelNomineeLabel}`;

  const transformedDependentListData: INominationDependentListData[] = _.cloneDeep(
    nominateDependentToBeChangedList || [],
  )
    .filter((dependent: INominationViewModeItem) => {
      return dependent.isSelected;
    })
    .map((dependent: INominationViewModeItem) => {
      const matchedOAL = nominationFlow.oalEligibilityTickedDependents?.find(
        (oal: IOALEligibilitySelectedData) => oal.dependentId === dependent.dependentId,
      );

      return {
        dependentName: getDisplayName({ firstName: dependent.firstName, lastName: dependent.lastName }),
        status: '',
        relationship: dependent.beneficiaryType,
        // indicate "to be added" dependents
        ...(!dependent.isLocked && {
          isPrimaryLabel: true,
          status: matchedOAL?.isAssignedOAL // check if assign aol to this dependent or not
            ? `${nominationFlowLbl.toBeAdded} <br />${nominationFlowLbl.oalAssigned}`
            : nominationFlowLbl.toBeAdded,
        }),
        // show change type label
        ...(toBeChangeTypeDependent &&
          toBeChangeTypeDependent.dependentId === dependent.dependentId && {
            isPrimaryLabel: true,
            status: changeTypeLabel,
          }),
      };
    });

  return (
    <>
      <Box
        sx={{
          mb: 9.5,
          ...(isDesktop && {
            width: '488px',
            mx: 'auto',
          }),
        }}
      >
        <Typography
          variant="headline_medium"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 2,
          }}
        >
          {nominationFlowLbl.confirmation}
        </Typography>

        <NominationDependentList
          dependentList={transformedDependentListData}
          mainTitle={mainTitle}
          dependentNameCustomStyles={{
            maxWidth: '56%',
          }}
          statusCustomStyles={{
            maxWidth: '44%',
            textAlign: 'right',
          }}
        />
      </Box>
    </>
  );
};

export default NominationReviewAndConfirmContainer;
